import React, { useState, useMemo } from 'react';
import { MapContainer, TileLayer, Circle, useMap, Popup } from 'react-leaflet';
import { Button, Container, Grid } from 'semantic-ui-react';
import { Range } from 'react-range';
import styled from '@emotion/styled';

const daysOfTheWeek = [
  { abbr: 'sun', name: 'Sunday' },
  { abbr: 'mon', name: 'Monday' },
  { abbr: 'tue', name: 'Tuesday' },
  { abbr: 'wed', name: 'Wednesday' },
  { abbr: 'thu', name: 'Thursday' },
  { abbr: 'fri', name: 'Friday' },
  { abbr: 'sat', name: 'Saturday' },
];

interface ClusterData {
  lat: number;
  lon: number;
  [key: string]: any;
}

interface MapData {
  data: Array<ClusterData>;
  center: [number, number];
  zoom: number;
}

interface ClusterMapProps {
  mapData: MapData;
  renderDetailPaneForCluster: (clusterId: string, data: ClusterData) => JSX.Element;
  width?: number;
  height?: number;
}

interface MapProps {
  data: Array<ClusterData>;
  renderDetailPaneForCluster: (clusterId: string, data: ClusterData) => JSX.Element;
  width: number;
  height: number;
  paneWidth: number;
  dayOfWeek: string;
  values: Array<number>;
}

const Map: React.FC<MapProps> = (props: MapProps) => {
  const map = useMap();
  const { data, renderDetailPaneForCluster, width, height, paneWidth, dayOfWeek, values } = props;

  const RightPanePopup = styled(Popup)`
    transform: translate3d(${width - paneWidth / 2}px, 0px, 0px) !important;
    width: ${paneWidth}px !important;
    height: ${height}px !important;
    left: 0px;
    top: 0px;
    .leaflet-popup-content-wrapper {
      position: absolute;
      border-radius: 0;
      width: 100%;
      height: 100%;
    }

    .leaflet-popup-tip-container {
      visibility: hidden;
    }
  `;

  return (
    <>
      {' '}
      {data.map((row: ClusterData, idx: number) => (
        <Circle
          center={[row.lat, row.lon]}
          radius={200 * Math.sqrt(row[`${dayOfWeek}${values[0]}`])}
          key={`circle-${row.cluster_id}`}
          fillColor="#bccd19"
          color="#bccd19"
          weight={1}
        >
          <RightPanePopup
            position={map.getBounds().getNorthEast()}
            autoPan={false}
            autoClose={true}
            key={`pane-${row.cluster_id}`}
          >
            <Container key={`pane-container-${row.cluster_id}`} fluid>
              {/* <Header key={`pane-container-header-${row.cluster_id}`} as='h4'>{row.cluster_id}</Header> */}
              {renderDetailPaneForCluster(row.cluster_id, row)}
            </Container>
          </RightPanePopup>
        </Circle>
      ))}
    </>
  );
};

const ClusterMap: React.FC<ClusterMapProps> = (props: ClusterMapProps) => {
  const { mapData, renderDetailPaneForCluster, width = 800, height = 500 } = props;
  const { data, center, zoom } = mapData;
  const paneWidth = width / 3;

  const [dayOfWeek, setDayOfWeek] = useState('fri');
  const [values, setValues] = useState([1200]);

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
    ),
    []
  );

  return (
    <Grid style={{ width: width }}>
      <Grid.Row>
        <MapContainer
          center={center}
          zoom={zoom}
          scrollWheelZoom={false}
          style={{ width: width, height: height }}
          zoomControl={false}
          dragging={false}
          keyboard={false}
          doubleClickZoom={false}
        >
          <Map
            data={data}
            renderDetailPaneForCluster={renderDetailPaneForCluster}
            values={values}
            width={width}
            height={height}
            paneWidth={paneWidth}
            dayOfWeek={dayOfWeek}
          />
          {Tiles}
        </MapContainer>
      </Grid.Row>

      <Grid.Row>
        <Button.Group widths="7" toggle fluid>
          {daysOfTheWeek.map((day) => (
            <Button
              key={`button-${day.abbr}`}
              basic={day.abbr != dayOfWeek}
              color={day.abbr == dayOfWeek ? 'olive' : undefined}
              value={day.abbr}
              content={day.name}
              onClick={(_, data) => setDayOfWeek(data.value)}
            />
          ))}
        </Button.Group>
      </Grid.Row>
      <Grid.Row>
        <Range
          step={100}
          min={0}
          max={2300}
          values={values}
          onChange={(values) => setValues(values)}
          draggableTrack={false}
          renderMark={({ props, index }) =>
            index % 3 == 0 && index != 0 ? (
              <p
                {...props}
                style={{
                  ...props.style,
                  paddingTop: '2em',
                  color: '#aaa',
                  textAlign: 'center',
                  lineHeight: '2em',
                }}
              >
                {' '}
                | <br />
                {index != 12 ? `${index % 12}${index < 12 ? 'AM' : 'PM'}` : 'NOON'}
              </p>
            ) : (
              <></>
            )
          }
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '1px',
                width: '100%',
                backgroundColor: '#aaa',
                marginBottom: '3em',
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '20px',
                width: '20px',
                backgroundColor: '#999',
                borderRadius: '3px',
              }}
            />
          )}
        />
      </Grid.Row>
    </Grid>
  );
};

export default ClusterMap;
