import React from 'react';

interface Props {
  cancel: boolean;
}

const Header = ({ cancel }: Props) => {
  return (
    <>
      <div className="tw-p-[0.26rem] tw-bg-[#bccd19]" />
      <header className="tw-px-[3%] tw-py-[1%]">
        <div className="tw-min-h-[80px] tw-flex tw-items-center tw-justify-between">
          <div className="tw-w-[20%] tw-h-[58px] tw-flex-initial">
            <a href="https://bornereport.com" className="tw-inline-block">
              <img
                src="https://bornereport.com/wp-content/uploads/2021/04/Borne-H-Core-RGB.svg"
                width="882"
                height="205"
                className="tw-w-[80%] tw-align-middle"
              />
            </a>
          </div>
          <div>
            <a
              href="https://bornereport.com"
              className="tw-pt-[12px] tw-pb-[10px] tw-px-[15px] tw-border tw-border-gray-300 tw-uppercase tw-text-[0.8em] tw-text-[#707070] hover:tw-bg-[#bccd19] hover:tw-text-[#2f2e2b] tw-transition-colors tw-duration-300"
            >
              {cancel && 'Cancel & '}Close
            </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
