import { useSiteReport, useStudyAreaGrades } from '../../../api/requests';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BorneFlexContainer, BorneSmallButton, ContentContainer, PageContainer } from '../../generic/BorneComponents';
import { DisplayTable } from '../../generic/BorneDisplayTable';
import SideMenu2 from '../../generic/SideMenu2';
import { formatCurrency } from '../../../helpers';
import { AddressMap } from '../../pages/SiteReport/AddressMap';
import LineChart from '@bornellc/bach/LineChart';
import ModelExplanationPopup from '../../generic/ModelExplanationPopup';

import { Card, Divider, Loader, Tab, Table } from 'semantic-ui-react';
import { ChartHeader } from '../../styled/Typography';
import { Row } from '@bornellc/bach/Common';
import { Link } from 'react-router-dom';

import type Auth from '../../../auth/Auth';
import type { User } from '../../../api/models';
import BorneDataTable from '../../generic/BorneDataTableV2';
import { VictoryPie } from 'victory';
import { CellClassParams, CellClickedEvent } from 'ag-grid-community';
// import BorneDataTable from '../../generic/BorneDataTableV2';

interface Props {
  auth: Auth;
  user: User;
}

interface Params {
  id: string;
}

const SingleSiteReport: React.FC<Props> = ({ auth, user }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [revenuePopupOpen, setRevenuePopupOpen] = useState<boolean>(false);
  const [totalEstate, setTotalEstate] = useState<number>(0);
  const [estateData, setEstateData] = useState<Array<{ x: string; y: number; label: string; fill: string }>>();
  const [studyAreas, setStudyAreas] = useState<Array<any>>([]);
  const [conceptId, setConceptId] = useState<any>();
  const colors = ['#7cb0ba', '#bccd19', '#ffc400', '#C35924', '#AA88CC', '#E8B4DA', '#bbbfc1', '#2a4e55'];
  const siteReport = useSiteReport(auth, id);
  const [active, setActive] = useState<number>(0);

  const studyAreaGradesRes = useStudyAreaGrades(
    auth,
    studyAreas.map((x) => x.id),
    conceptId,
    false,
    {
      staleTime: 500000,
      enabled: !!id && studyAreas.length > 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      siteReport?.data?.report_data?.study_areas &&
      estateData == undefined &&
      siteReport?.data?.report_data?.real_estate_use
    ) {
      setTotalEstate(
        Object.keys(siteReport.data.report_data.real_estate_use).reduce(
          (a: any, c: any) => (siteReport.data.report_data.real_estate_use[`${c}`] as number) + a,
          0
        )
      );
      setEstateData(
        Object.keys(siteReport.data.report_data.real_estate_use)
          .filter((check) => siteReport.data.report_data.real_estate_use[`${check}`] !== null)
          .map((item, index) => {
            return {
              x: item.split('_').slice(2, item.split('_').length).join(' '),
              y: siteReport.data.report_data.real_estate_use[`${item}`],
              label: ' ',
              fill: colors[index % 8],
            };
          })
          .sort((a: any, b: any) => (a.y > b.y ? -1 : a.y < b.y ? 1 : 0))
      );
      setStudyAreas(
        siteReport.data.report_data.study_areas.map((x: Array<any>) => ({
          id: x[1] as string,
          name: x[0],
        }))
      );
      setConceptId(siteReport.data.report_data.concept_id);
    }
  }, [siteReport]);

  let precipMonthly: Row[];
  let maxTempMonthly: Row[];
  let minTempMonthly: Row[];
  let weatherData: Array<any>;
  if (siteReport.isSuccess && siteReport.data.report_data.monthly_weather) {
    precipMonthly = siteReport.data.report_data.monthly_weather.map((x: any, index: number) => ({
      precip: x.precip,
      month: index,
    }));
    maxTempMonthly = siteReport.data.report_data.monthly_weather.map((x: any, index: number) => ({
      maxTemp: x.max_temp,
      month: index,
    }));
    minTempMonthly = siteReport.data.report_data.monthly_weather.map((x: any, index: number) => ({
      minTemp: x.min_temp,
      month: index,
    }));
    weatherData = siteReport.data.report_data.monthly_weather.map((x: any) => ({
      text: x.month,
      text2: x.precip,
      text3: x.max_temp,
      text4: x.min_temp,
    }));
    weatherData.unshift({
      text: 'Month',
      text2: 'Precipitation',
      text3: 'Max Temperature',
      text4: 'Min Temperature',
    });
  }

  const panes = [
    {
      menuItem: 'Weather',
      render: () => (
        <BorneFlexContainer className="tw-flex-col">
          <Card.Group className="tw-m-auto tw-justify-center tw-mt-2" itemsPerRow={12}>
            {siteReport.data.report_data.monthly_weather.map((month_weather: any, index: number) => (
              <Card key={index} className="tw-w-[217]px">
                <Card.Content>
                  <Card.Header>{month_weather.month}</Card.Header>
                  <Card.Content>
                    <br />
                    <Table basic="very">
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>Max Temp</Table.Cell>
                          <Table.Cell>{month_weather.max_temp}&deg;F</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Min Temp</Table.Cell>
                          <Table.Cell>{month_weather.min_temp}&deg;F</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Precip</Table.Cell>
                          <Table.Cell>{month_weather.precip} in</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Card.Content>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
          <BorneFlexContainer className="tw-my-4">
            <div className="tw-w-[33%]">
              <ChartHeader as="h3" className="tw-text-center">
                Monthly Precipitation
              </ChartHeader>
              <Divider fitted />
              <LineChart
                data={precipMonthly}
                dataKey="precipMonthly"
                x="month"
                y="precip"
                orientation="h"
                height={400}
                isCurve={true}
                margin={{
                  top: 30,
                  bottom: 30,
                  left: 100,
                  right: 0,
                }}
                xScaleOptions={{
                  type: 'linear',
                  domain: [
                    Math.min(...precipMonthly.map((datum: any) => datum.month)),
                    Math.max(...precipMonthly.map((datum: any) => datum.month)),
                  ],
                  zero: false,
                }}
                xLabelFormatter={(month: string) => ` ${month}`}
                yLabelFormatter={(precip: number) => `${precip} in`}
              />
            </div>
            <div className="tw-w-[33%]">
              <ChartHeader as="h3" className="tw-text-center">
                Max Monthly Temperature
              </ChartHeader>
              <Divider fitted />
              <LineChart
                data={maxTempMonthly}
                dataKey="maxTempMonthly"
                x="month"
                y="maxTemp"
                orientation="h"
                height={400}
                isCurve={false}
                margin={{
                  top: 30,
                  bottom: 30,
                  left: 100,
                  right: 0,
                }}
                xScaleOptions={{
                  type: 'linear',
                  domain: [
                    Math.min(...maxTempMonthly.map((datum: any) => datum.month)),
                    Math.max(...maxTempMonthly.map((datum: any) => datum.month)),
                  ],
                  zero: false,
                }}
                xLabelFormatter={(month: string) => ` ${month}`}
                yLabelFormatter={(maxTemp: number) => `${maxTemp}\u00B0F`}
              />
            </div>
            <div className="tw-w-[33%]">
              <ChartHeader as="h3" className="tw-text-center">
                Min Monthly Temperature
              </ChartHeader>
              <Divider fitted />
              <LineChart
                data={minTempMonthly}
                dataKey="minTempMonthly"
                x="month"
                y="minTemp"
                orientation="h"
                height={400}
                isCurve={true}
                margin={{
                  top: 30,
                  bottom: 30,
                  left: 100,
                  right: 0,
                }}
                xScaleOptions={{
                  type: 'linear',
                  domain: [
                    Math.min(...minTempMonthly.map((datum: any) => datum.month)),
                    Math.max(...minTempMonthly.map((datum: any) => datum.month)),
                  ],
                  zero: false,
                }}
                xLabelFormatter={(month: string) => ` ${month}`}
                yLabelFormatter={(minTemp: number) => `${minTemp}\u00B0F`}
              />
            </div>
          </BorneFlexContainer>
        </BorneFlexContainer>
      ),
    },
    {
      menuItem: 'Tapestry',
      render: () => (
        <div>
          <BorneFlexContainer className="tw-justify-between tw-mt-4 lg:tw-flex-row md:tw-flex-col">
            <div style={{ width: '311px', marginBottom: '1.5rem', marginRight: '2rem' }}>
              <h3>Market Share</h3>
              <DisplayTable
                fill
                width={300}
                height={120}
                rowsPerPage={14}
                pagination={siteReport.data.report_data.market_share.restaurants.length > 5}
                headers={[
                  { field: 'text', width: 200 },
                  {
                    field: 'text2',
                    width: 100,
                    valueFormatter: (params) => `${Math.ceil(params.value) / 1000} km`,
                  },
                ]}
                rowData={siteReport.data.report_data.market_share.restaurants.map((rest: any) => ({
                  text: rest.name,
                  text2: rest.distanceMeters,
                }))}
              />
            </div>
            {siteReport.data.report_data.cuisine_counts && (
              <div style={{ width: '311px', marginBottom: '1.5rem' }}>
                <h3>Competition by Cuisine</h3>
                <DisplayTable
                  fill
                  width={300}
                  height={120}
                  rowsPerPage={14}
                  pagination={siteReport.data.report_data.cuisine_counts.length > 5}
                  headers={[
                    { field: 'text', width: 200 },
                    { field: 'text2', width: 100 },
                  ]}
                  rowData={siteReport.data.report_data.cuisine_counts.map((cuisine: any) => ({
                    text: cuisine.cuisine,
                    text2: cuisine.num_nearby_locations,
                  }))}
                />
              </div>
            )}
            {siteReport.data.report_data.market_share.restaurants && (
              <div style={{ width: '311px', marginBottom: '1.5rem' }}>
                <h3>Gap Analysis</h3>
                <DisplayTable
                  fill
                  width={300}
                  height={120}
                  rowsPerPage={14}
                  pagination={siteReport.data.report_data.market_share.restaurants.length > 5}
                  headers={[{ field: 'text', width: 300 }]}
                  rowData={siteReport.data.report_data.gap_analysis.map((itm: string, index: number) => ({
                    text: itm,
                  }))}
                />
              </div>
            )}
            {studyAreas.length > 0 &&
            conceptId != undefined &&
            studyAreaGradesRes.length > 0 &&
            studyAreaGradesRes[0].isSuccess &&
            studyAreaGradesRes[0].data != undefined ? (
              <BorneFlexContainer style={{ width: '500px', height: '300px' }} className="tw-mt-4 ">
                <BorneDataTable
                  fill
                  rowData={studyAreaGradesRes.map((x: any, index) => {
                    const safeForGrade = x.isSuccess && !x.isFetching;
                    if (safeForGrade) {
                      return {
                        grade: x.data[`${conceptId}`].grade,
                        name: `SA-${studyAreas[index].id as string} / ${studyAreas[index].name}`,
                        id: studyAreas[index].id,
                      };
                    }
                  })}
                  headers={[
                    { field: 'name', width: 400, headerName: 'Report Name' },
                    { field: 'grade', width: 100 },
                  ]}
                  onCellClick={(param: CellClickedEvent) => {
                    navigate(`/studyareas/${param.data.id}`);
                  }}
                />
              </BorneFlexContainer>
            ) : (
              <Loader size="big" />
            )}
          </BorneFlexContainer>
          <BorneFlexContainer className="tw-flex-col tw-items-start tw-mt-4">
            {siteReport.data.report_data.patron_prevalence != undefined &&
              siteReport.data.report_data.patron_prevalence.map((item: any, idx: number) => (
                <>
                  <BorneFlexContainer
                    onClick={() => setActive(idx)}
                    className={`tw-cursor-pointer ${
                      idx !== 2 && 'tw-border-b-2'
                    } tw-flex-col tw-items-start tw-pb-4 tw-mb-4`}
                  >
                    <h3>
                      {item[0]} : {Math.round(item[1] as number)}%
                    </h3>
                    <p className="tw-px-4">{active === idx ? `${item[2]}` : `${item[2].slice(0, 100)}...`}</p>
                  </BorneFlexContainer>
                </>
              ))}
          </BorneFlexContainer>
        </div>
      ),
    },
  ];

  return (
    <PageContainer>
      {siteReport.isSuccess ? (
        <ContentContainer className="tw-items-start">
          <SideMenu2 />
          <div className="tw-p-4 tw-w-full ">
            <BorneFlexContainer>
              <h2 className="tw-w-full">{siteReport.data.address}</h2>
              <BorneFlexContainer className="tw-justify-end">
                <Link to="/siteReports">
                  <BorneSmallButton active className="tw-ml-4">
                    <span>Back to Site Reports</span>
                  </BorneSmallButton>
                </Link>
              </BorneFlexContainer>
            </BorneFlexContainer>
            <BorneFlexContainer className="tw-items-end">
              <h3 className="tw-m-0 tw-mr-4">Concept: {siteReport.data.report_data.concept_name}</h3>
              <p className="tw-mr-4">Created by: {siteReport.data.created_by_username}</p>
              <p className="tw-mr-4">Created on: {siteReport.data.created.slice(0, 10)}</p>
            </BorneFlexContainer>
            <BorneFlexContainer className="tw-mt-16 tw-items-center tw-mb-8">
              <div className="lg:tw-w-[57%] md:tw-w-full tw-h-[25rem]" style={{ border: '1px solid black' }}>
                <AddressMap
                  address={siteReport.data.report_data.address}
                  latitude={siteReport.data.report_data.latitude}
                  longitude={siteReport.data.report_data.longitude}
                />
              </div>
              <div className="tw-ml-16 tw-w-[43%] lg:tw-block md:tw-hidden">
                <BorneFlexContainer>
                  <div className="tw-w-[50%]" style={{ borderRight: '1px solid gray' }}>
                    <p className="tw-mb-2">Projected Revenue</p>
                    <ModelExplanationPopup
                      header={<>About this Projection</>}
                      subtext={<>The projected revenue includes the following:</>}
                      open={revenuePopupOpen}
                      setOpen={setRevenuePopupOpen}
                      trigger={
                        <BorneFlexContainer className="sm:tw-flex-col lg:tw-flex-row tw-items-end">
                          <h1 className="tw-pr-2">{formatCurrency(siteReport.data.report_data.revenue_projection)}</h1>
                          <p className="smallText">/Annual</p>
                        </BorneFlexContainer>
                      }
                      user={user}
                    />
                    {
                      // The "false" condition to short circuit the explanation trigger was
                      // requested by Ken so that this does not show up in the demos that
                      // he is giving while the data is being mocked.
                      false && user.enterprise_id === 1 && (
                        <a
                          href="#"
                          // use visibility: hidden to keep the layout the same
                          className={`tw-block tw-underline tw-text-black ${revenuePopupOpen ? 'tw-invisible' : ''}`}
                          onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                            setRevenuePopupOpen(true);
                            e.preventDefault();
                          }}
                        >
                          What goes into this projection?
                        </a>
                      )
                    }
                  </div>
                  <div className="tw-w-[50%] tw-ml-16">
                    <p className="tw-mb-2">Occupancy Budget</p>
                    <BorneFlexContainer>
                      <h2 className="tw-pr-2">{formatCurrency(siteReport.data.report_data.occupancy_budget)}</h2>
                      <p className="smallText">/month</p>
                    </BorneFlexContainer>
                  </div>
                </BorneFlexContainer>
                <BorneFlexContainer style={{ borderTop: '1px solid gray' }} className="tw-mt-4 tw-pt-4">
                  {estateData && (
                    <>
                      <div style={{ width: '350px', height: '100%', textTransform: 'capitalize' }}>
                        <h2>Real Estate Use</h2>
                        <DisplayTable
                          fill
                          rowData={estateData.map((item) => ({
                            x: item.x,
                            pct: item.y,
                            color: (
                              <div
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  borderRadius: '50%',
                                  backgroundColor: item.fill,
                                }}
                              ></div>
                            ),
                          }))}
                          headers={[
                            { field: 'x' },
                            {
                              field: 'pct',
                              valueFormatter: (params) =>
                                `${
                                  Math.floor((params.value / totalEstate) * 100) > 0
                                    ? Math.floor((params.value / totalEstate) * 100)
                                    : '<1'
                                }%`,
                              width: 60,
                            },
                            { field: 'color', cellRenderer: (x: CellClassParams) => x.data.color, width: 60 },
                          ]}
                        />
                      </div>
                      <div style={{ width: '200px', height: '200px' }}>
                        <VictoryPie
                          // labelComponent={
                          //   <VictoryTooltip
                          //   flyoutComponent={}
                          //   />
                          // }
                          data={estateData}
                          style={{
                            data: {
                              fill: ({ datum }) => datum.fill,
                            },
                          }}
                        />
                      </div>
                    </>
                  )}
                </BorneFlexContainer>
              </div>
            </BorneFlexContainer>
            <div className=" tw-w-full lg:tw-hidden">
              <BorneFlexContainer>
                <div className="tw-w-[50%] tw-pl-8" style={{ borderRight: '1px solid gray' }}>
                  <p className="tw-mb-2">Projected Revenue</p>
                  <ModelExplanationPopup
                    header={<>About this Projection</>}
                    subtext={<>The projected revenue includes the following:</>}
                    open={revenuePopupOpen}
                    setOpen={setRevenuePopupOpen}
                    trigger={
                      <BorneFlexContainer className="tw-flex-col  tw-items-start">
                        <h1 className="tw-pr-2">{formatCurrency(siteReport.data.report_data.revenue_projection)}</h1>
                        <p className="smallText">/Annual</p>
                      </BorneFlexContainer>
                    }
                    user={user}
                  />
                  {
                    // The "false" condition to short circuit the explanation trigger was
                    // requested by Ken so that this does not show up in the demos that
                    // he is giving while the data is being mocked.
                    false && user.enterprise_id === 1 && (
                      <a
                        href="#"
                        // use visibility: hidden to keep the layout the same
                        className={`tw-block tw-underline tw-text-black ${revenuePopupOpen ? 'tw-invisible' : ''}`}
                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                          setRevenuePopupOpen(true);
                          e.preventDefault();
                        }}
                      >
                        What goes into this projection?
                      </a>
                    )
                  }
                </div>
                <div className="tw-w-[50%] tw-ml-16">
                  <p className="tw-mb-2">Occupancy Budget</p>
                  <BorneFlexContainer>
                    <h2 className="tw-pr-2">{formatCurrency(siteReport.data.report_data.occupancy_budget)}</h2>
                    <p className="smallText">/month</p>
                  </BorneFlexContainer>
                </div>
              </BorneFlexContainer>
              <BorneFlexContainer style={{ borderTop: '1px solid gray' }} className="tw-my-4 tw-pt-4 tw-pl-8">
                {estateData && (
                  <>
                    <div style={{ width: '350px', height: '100%', textTransform: 'capitalize', paddingRight: '25px' }}>
                      <h2>Real Estate Use</h2>
                      <DisplayTable
                        fill
                        rowData={estateData.map((item) => ({
                          x: item.x,
                          pct: item.y,
                          color: (
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: item.fill,
                              }}
                            ></div>
                          ),
                        }))}
                        headers={[
                          { field: 'x' },
                          {
                            field: 'pct',
                            valueFormatter: (params) =>
                              `${
                                Math.floor((params.value / totalEstate) * 100) > 0
                                  ? Math.floor((params.value / totalEstate) * 100)
                                  : '<1'
                              }%`,
                            width: 60,
                          },
                          { field: 'color', cellRenderer: (x: CellClassParams) => x.data.color, width: 60 },
                        ]}
                      />
                    </div>
                    <div style={{ width: '200px', height: '200px' }}>
                      <VictoryPie
                        // labelComponent={
                        //   <VictoryTooltip
                        //   flyoutComponent={}
                        //   />
                        // }
                        data={estateData}
                        style={{
                          data: {
                            fill: ({ datum }) => datum.fill,
                          },
                        }}
                      />
                    </div>
                  </>
                )}
              </BorneFlexContainer>
            </div>
            {siteReport.data.report_data.monthly_weather && <Tab className="tw-mb-8" panes={panes} />}
          </div>
        </ContentContainer>
      ) : (
        ''
      )}
    </PageContainer>
  );
};

export default SingleSiteReport;
