import React from 'react';
import { WrappedButtonGroupField } from './FormikUtil';

const options: Readonly<Record<number, string>> = {
  0: 'No',
  1: 'Yes',
};

interface Props {
  loc: string;
}

const ParkingLotQuestion = ({ loc }: Props) => {
  return <WrappedButtonGroupField label="Does your restaurant have a parking lot?" options={options} name={loc} />;
};

export default ParkingLotQuestion;
