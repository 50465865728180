import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Auth from '../../auth/Auth';
import { Concept } from '../concept/concept';
import withSideMenu from '../generic/MenuPage';

interface Props {
  auth: Auth;
}

const ConceptDetail: React.FC<Props> = (props: Props) => {
  const { auth } = props;
  const id = useParams<'id'>().id!;

  // Retrieve Concepts and store in hook
  const [concept, setConcept] = useState<Concept>();

  useEffect(() => {
    if (auth.hasCredentials()) {
      (async () => {
        const { data } = await auth.authenticatedAPICall({
          method: 'get',
          url: '/api/concepts/' + id + '/',
        });

        setConcept(data);
      })();
    }
  }, []);

  return (
    <>
      <p>Got ID: {concept && concept.address}</p>
    </>
  );
};

export default withSideMenu(ConceptDetail, { activeItem: 'concepts' });
