import { Header as BaseHeader, List } from 'semantic-ui-react';
import styled from '@emotion/styled';

export const Description = styled(BaseHeader)`
  &&& {
    font-family: 'Nunito Sans';
    margin-top: 0.33em;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;

export const Subheader = styled(BaseHeader)`
  &&& {
    font-family: 'Montserrat';
    font-weight: 400;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
    padding-top: 0em;
    margin-top: 0em;
    font-size: 1.3em;

    .icon:only-child {
      margin-right: 0.25rem;
    }

    > .icon {
      font-size: inherit;
      vertical-align: inherit;
    }
  }
`;

export const InlineListHeader = styled(List.Header)`
  && {
    display: inline !important;
  }
`;

export const InlineListDescription = styled(List.Description)`
  && {
    display: inline;
    float: right;
  }
`;

export const Header = styled(BaseHeader)`
  && {
    font-family: 'Roboto condensed';
    margin-bottom: 0em;
    display: inline;
    letter-spacing: 0.08rem;

    .icon:only-child {
      margin-right: 0.25rem;
    }

    > .icon {
      font-size: inherit;
      vertical-align: inherit;
    }
  }
`;

export const ChartHeader = styled(BaseHeader)`
  && {
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: 'Roboto condensed';
    letter-spacing: 0.15rem;
    text-align: left;
    color: #3d3f40;
  }
`;
