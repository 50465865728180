import React from 'react';
import { WrappedDropdownField } from './FormikUtil';
import { useChains } from '../../api/requests';

interface Props {
  loc: string;
  auth: any;
}

const BrandIdQuestion = ({ loc, auth }: Props) => {
  const chains = useChains(auth);

  return (
    <WrappedDropdownField
      label="Please find your brand on our list"
      name={loc}
      options={chains.data?.map(({ name }) => name) || []}
      placeholder="Select Brand"
      fluid
      search
      selection
      clearable
    />
  );
};

export default BrandIdQuestion;
