import React from 'react';
import { Header, Placeholder, Grid } from 'semantic-ui-react';
import { ConceptTable } from '../../generic/ConceptDefinition';

export const ConceptAndScoringDescriptionMenu: React.FC<{}> = () => {
  return (
    // Title of segment
    <>
      <Header>Concept and scoring</Header>
      {/* Description  */}
      <p>
        Etiam congue, arcu et dictum bibendum, dolor ex varius metus, et ornare ipsum turpis sed nisl. Nulla vulputate
        dignissim elit, et volutpat purus molestie eget. Vestibulum at lacus vel orci imperdiet auctor. Nam fermentum
        fringilla odio sit amet laoreet.
      </p>
    </>
    // Depending on segment: menu
  );
};

interface ConceptAndScoringProps {
  concept: any;
}

export const ConceptAndScoringDataSegment: React.FC<ConceptAndScoringProps> = (props) => {
  const { locationresults } = props.concept;

  return (
    <>
      <Grid attached="top">
        <Grid.Row>
          <Grid.Column width={12}>
            <Grid divided>
              <Grid.Column width={8}>
                <Header size="medium">
                  Borne Score
                  <Header.Subheader>
                    This concepts final score was{' '}
                    <b> {locationresults.totalPoints / locationresults.totalPossiblePoints}</b> compared to the average
                    score of <b> {locationresults.totalPoints}</b> for this address. Any score above 75 is considered a
                    strong fit.
                    <Placeholder>
                      <Placeholder.Image />
                    </Placeholder>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column width={8}>
                <Header size="medium">
                  Projection of Top Line Sales
                  <Header.Subheader>
                    Based on your check average, seats, hours of operation, an activity of nearby businesses, we
                    anticipate you will have
                    {' $2,096,640'}
                    <b>{''}</b> in sales per year.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column width={4}>
            <Placeholder>
              <Placeholder.Image />
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12}>
            <Grid divided>
              <Grid.Column width={8}>
                <Header size="medium">
                  Estimated Base Yelp Rating
                  <Header.Subheader>
                    We estimate that this concept will have a base Yelp Rating of <b>{' 3.70'}</b> stars.Keep in mind
                    that cleanliness, food-quality, and other operational factor profoundly impact your rating. The
                    average Yelp rating for your area is
                    <b>{' 3.58'}</b> stars.
                    <Placeholder>
                      <Placeholder.Image />
                    </Placeholder>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column width={8}>
                <Header size="medium">
                  Occupancy Budget
                  <Header.Subheader textAlign="right">
                    This concepts final score was <b>{' 78'}</b> compared to the average score of <b>{' 68'}</b> for
                    this address. Any score above 75 is considered a strong fit.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column width={4}>
            <Placeholder>
              <Placeholder.Image />
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
        <ConceptTable concept={locationresults} />
      </Grid>
    </>
  );
};
