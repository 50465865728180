import React from 'react';
import { ServiceQuality } from '../../data/categories';
import { WrappedButtonGroupField } from './FormikUtil';

const options: Readonly<Record<ServiceQuality, string>> = {
  fast_casual: 'Fast Casual',
  fine_dining: 'Fine Dining',
  casual: 'Casual',
};

interface Props {
  loc: string;
  useFormLabel?: boolean;
  fluid?: boolean;
}

const ServiceQualityQuestion = ({ loc, useFormLabel, fluid }: Props) => {
  return (
    <WrappedButtonGroupField
      label="Quality of service"
      options={options}
      name={loc}
      useFormLabel={useFormLabel}
      fluid={fluid}
    />
  );
};

export default ServiceQualityQuestion;
