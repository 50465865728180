import React from 'react';
import { XYChart, AnimatedGrid, AnimatedAxis, Tooltip } from '@visx/xychart';
import { LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { ParentSize } from '@visx/responsive';
import { GridType, Direction, BaseChartProps, Row, colors as baseColors, theme, Orientation } from './Common';

const getAnimatedGrid = (grid: GridType, numInfoTicks: number) => {
  switch (grid) {
    case 'v':
      return <AnimatedGrid rows={false} numTicks={numInfoTicks} />;
    case 'h':
      return <AnimatedGrid columns={false} numTicks={numInfoTicks} />;
    case 'full':
      return <AnimatedGrid />;
  }
  return <></>;
};

const getAxis = (axis: Direction, labelFormatter: ((value: any) => any) | undefined, numTicks?: number) => {
  return <AnimatedAxis key={`axis-${axis}`} orientation={axis} tickFormat={labelFormatter} numTicks={numTicks} />;
};

const getAxes = (
  axes: Direction[],
  xLabelFormatter: ((value: any) => any) | undefined,
  yLabelFormatter: ((value: any) => any) | undefined,
  orientation: Orientation,
  numInfoTicks: number,
  numDependentTicks: number | undefined
) => {
  return axes.map((axis: Direction) =>
    getAxis(
      axis,
      axis === 'left' || axis === 'right' ? yLabelFormatter : xLabelFormatter,
      (orientation === 'h' && (axis === 'top' || axis === 'bottom')) ||
        (orientation === 'v' && (axis === 'left' || axis === 'right'))
        ? numInfoTicks
        : numDependentTicks
    )
  );
};

const BaseChart: React.FC<BaseChartProps> = (props: BaseChartProps) => {
  const {
    tooltip,
    tooltipCrosshair,
    tooltipRenderer = (a, num, color, _) => <p style={{ color: color }}>{`${a}: ${num}`}</p>,
    xScaleOptions,
    yScaleOptions,
    grid,
    axes = ['left', 'bottom'],
    seriesArray,
    seriesLabels,
    xLabelFormatter,
    yLabelFormatter,
    numInfoTicks = 4,
    numDependentTicks,
    orientation,
    colors,
    role = 'chart',
    height,
    margin,
  } = props;

  const Grid = getAnimatedGrid(grid, numInfoTicks);
  const Axes = getAxes(axes, xLabelFormatter, yLabelFormatter, orientation, numInfoTicks, numDependentTicks);

  const colorScale = scaleOrdinal<string, string>({
    domain: seriesLabels,
    range: colors || baseColors,
  });

  const actualMargin = margin || {
    top: 25,
    right: 50,
    bottom: 50,
    left: 150,
  };

  return (
    <ParentSize>
      {(parent) => (
        <div role={role}>
          <XYChart
            margin={actualMargin}
            height={height || 75 + seriesArray.length * 32}
            width={parent.width}
            xScale={xScaleOptions}
            yScale={yScaleOptions}
            theme={theme}
          >
            {Grid}
            {Axes}
            {seriesArray}
            {tooltip && (
              <Tooltip<Row>
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair={tooltipCrosshair == 'v'}
                showHorizontalCrosshair={tooltipCrosshair == 'h'}
                renderTooltip={({ tooltipData, colorScale }) => {
                  const key = tooltipData?.nearestDatum?.key ?? '';
                  const value = tooltipData?.nearestDatum?.datum[key];
                  return tooltipRenderer(
                    key,
                    value,
                    colorScale && key ? colorScale(key) : undefined,
                    tooltipData?.nearestDatum?.datum
                  );
                }}
              />
            )}
          </XYChart>
          {seriesLabels && (
            <LegendOrdinal
              scale={colorScale}
              direction="column"
              shape="circle"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                height: `${1.8 * Math.ceil(seriesLabels.length / 2)}em`,
                fontFamily: 'Nunito Sans',
                lineHeight: '1.8em',
                marginBottom: '2em',
              }}
            />
          )}
        </div>
      )}
    </ParentSize>
  );
};

export default BaseChart;
