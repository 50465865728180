import React, { Component } from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeDark2 } from 'd3-scale-chromatic';
import { TrendsData } from '../../types';

interface Props {
  keywordTrendsData: TrendsData;
}

function propsToData(data: string): any {
  data = JSON.parse(data);

  const options = {
    year: '2-digit',
    month: 'short',
    day: 'numeric',
  } as const;

  return Object.keys(data).map((key: any, indx) => {
    return { name: new Date(parseInt(key)).toLocaleDateString('en-US', options), ...(data[key] as unknown as object) };
  });
}

class KeywordTrendsSegment extends Component<Props, {}> {
  constructor(props: Readonly<Props>) {
    super(props);
  }

  render(): JSX.Element {
    try {
      const data = propsToData(this.props.keywordTrendsData.data);
      const dataKeys = Object.keys(data[0]).filter((elem) => elem != 'name' && elem != 'isPartial');
      const colors = scaleOrdinal(schemeDark2).range();

      return (
        <Segment>
          <Header as="h3">Keyword Popularity</Header>
          <p>
            The Y-axis represents search interest relative to the other terms and the history of any given term. A value
            of 100 is the peak popularity for any given term. A value of 50 means that the term is half as popular,
            while a value of 0 means there was not enough data to properly analize this term.
          </p>
          {this.props.keywordTrendsData?.error ? (
            <p>this.props.keywordTrendsData.error</p>
          ) : (
            <Grid centered padded="vertically">
              <LineChart width={750} height={250} data={data} margin={{ top: 30, right: 30 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[0, 100]} />
                {dataKeys.map((entry, index) => (
                  <Line key={`line-${index}`} dataKey={entry} stroke={colors[index % colors.length]} />
                ))}
              </LineChart>
            </Grid>
          )}
        </Segment>
      );
    } catch (ex) {
      console.error('exception encountered when mapping keyword trend data... Dying gracefully');
      console.error(ex);
      return (
        <Segment>
          <p>Encountered error while formatting keyword data, failing gracefully</p>
        </Segment>
      );
    }
  }
}

export default KeywordTrendsSegment;
