import { ChartHeader, Header, Description } from '../../styled/Typography';
import React from 'react';
import { Divider, Grid } from 'semantic-ui-react';
import Auth from '../../../auth/Auth';
import { useStudyAreaTrends } from '../../../api/requests';
import LineChart from '@bornellc/bach/LineChart';

export const StudyAreaDetailTrendsDescription: React.FC = ({}) => {
  return (
    <>
      <Header as="h2">Trends</Header>
      <Description as="p">View how behavior has changed over time.</Description>
    </>
  );
};

interface DataProps {
  studyAreaId: string;
  auth: Auth;
}

export const StudyAreaDetailTrendsData: React.FC<DataProps> = ({ studyAreaId, auth }) => {
  const trendsResponse = useStudyAreaTrends(auth, studyAreaId);

  const spend =
    trendsResponse.data &&
    trendsResponse.data.spend.map((d) => ({
      week: new Date(d.week).getTime(),
      spend: d.spend,
    }));

  /*
  const trips =
    trendsResponse.data &&
    trendsResponse.data.trips.map((d) => ({
      week: new Date(d.week).getTime(),
      trips: d.trips,
    }));
    */

  return (
    <Grid attached="top" textAlign="center">
      <Grid.Row>
        <Grid.Column>
          {spend && (
            <>
              <ChartHeader as="h3" style={{ textAlign: 'center' }}>
                Weekly Restaurant Spend
              </ChartHeader>
              <Divider fitted />
              <LineChart
                data={spend}
                dataKey="spend"
                x="week"
                y="spend"
                orientation="h"
                height={250}
                isCurve={true}
                margin={{
                  top: 30,
                  bottom: 30,
                  left: 100,
                  right: 0,
                }}
                xScaleOptions={{
                  type: 'linear',
                  domain: [
                    Math.min(...spend.map((datum) => datum.week)),
                    Math.max(...spend.map((datum) => datum.week)),
                  ],
                  zero: false,
                }}
                xLabelFormatter={(date: number) => new Date(date).toISOString().split('T')[0]}
                yLabelFormatter={(spend: number) => `$${spend}`}
              />
            </>
          )}
        </Grid.Column>
      </Grid.Row>
      {/*
      <Grid.Row>
        <Grid.Column>
          {trips && (
            <>
              <ChartHeader as="h3" style={{ textAlign: 'center' }}>
                Weekly Restaurant Trips
              </ChartHeader>
              <Divider fitted />
              <LineChart
                data={trips}
                dataKey="trips"
                x="week"
                y="trips"
                orientation="h"
                height={250}
                isCurve={true}
                margin={{
                  top: 30,
                  bottom: 30,
                  left: 100,
                  right: 0,
                }}
                xScaleOptions={{
                  type: 'linear',
                  domain: [
                    Math.min(...trips.map((datum) => datum.week)),
                    Math.max(...trips.map((datum) => datum.week)),
                  ],
                  zero: false,
                }}
                xLabelFormatter={(date: number) => new Date(date).toISOString().split('T')[0]}
              />
            </>
          )}
        </Grid.Column>
      </Grid.Row>
      */}
    </Grid>
  );
};
