import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Divider, Image, Form, Input, Button } from 'semantic-ui-react';
import Results from '../Results';

const Report = () => {
  useEffect(() => {
    document.title = 'Borne Report\u00ae';
  }, []);

  const id = useParams<'id'>().id!;

  const [password, setPassword] = useState<string>('');
  const [submittedPassword, setSubmittedPassword] = useState<string>();

  return submittedPassword ? (
    <Results reportId={parseInt(id)} password={submittedPassword} />
  ) : (
    <div className="tw-w-full tw-h-full tw-bg-gradient-to-r tw-from-[#767676] tw-to-[#abcb54] tw-flex tw-items-center tw-justify-center">
      <div className="tw-w-96 tw-h-[56%] tw-bg-white tw-text-center tw-shadow-2xl tw-p-11">
        <div className="tw-w-3/4 tw-m-auto">
          <Image src="/static/B_Icon_transparent.png" />
        </div>
        <h1 className="tw-text-3xl tw-text-bold">
          Borne Report<sup>&#174;</sup>
        </h1>
        <p>Please input the password provided by your Borne representative to view your report</p>
        <Divider />
        <Form className="tw-mt-6" onSubmit={() => setSubmittedPassword(password)}>
          <Input
            type="password"
            name="password"
            placeholder="Password"
            fluid
            value={password}
            onChange={(_, { value }) => setPassword(value)}
          />
          <br />
          <Button type="submit" secondary fluid>
            View Report
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Report;
