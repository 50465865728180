const cuisines = [
  'Afghan',
  'African',
  'American',
  'Apres Ski Bar',
  'Arepa',
  'Argentinian',
  'Armenian',
  'Asian',
  'Australian',
  'Austrian',
  'Bangladeshi',
  'BBQ',
  'Beach Bar',
  'Beer Bar',
  'Beer Garden',
  'Belgian',
  'Bistro',
  'Bosnian',
  'Brazilian',
  'Bubble Tea Shop',
  'Buffet',
  'Bulgarian',
  'Burger',
  'Burmese',
  'Café',
  'Cajun / Creole',
  'Cambodian',
  'Caribbean',
  'Caucasian',
  'Chinese',
  'Cocktail Bar',
  'Coffee Shop',
  'Colombian',
  'Comfort Food',
  'Creperie',
  'Cuban',
  'Cupcake Shop',
  'Czech',
  'Deli',
  'Diner',
  'Dive Bar',
  'Donut Shop',
  'Dumpling',
  'Dutch',
  'Eastern European',
  'Egyptian',
  'Empanada',
  'English',
  'Ethiopian',
  'Falafel',
  'Fast Food',
  'Filipino',
  'Fish and Chips Shop',
  'Fondue',
  'French',
  'Fried Chicken',
  'Frozen Yogurt Shop',
  'Gastropub',
  'Gay Bar',
  'Gelato Shop',
  'German',
  'Greek',
  'Halal',
  'Hawaiian',
  'Himalayan',
  'Honduran',
  'Hookah Bar',
  'Hot Dog',
  'Hotel Bar',
  'Hotpot',
  'Hungarian',
  'Ice Bar',
  'Ice Cream Parlor',
  'Indian',
  'Indian Chinese',
  'Indonesian',
  'Iraqi',
  'Israeli',
  'Italian',
  'Japanese',
  'Jewish',
  'Karaoke Bar',
  'Kebab',
  'Korean',
  'Kurdish',
  'Latin American',
  'Lebanese',
  'Lounge',
  'Mac and Cheese',
  'Malay',
  'Mediterranean',
  'Mexican',
  'Middle Eastern',
  'Modern European',
  'Molecular Gastronomy',
  'Mongolian',
  'Moroccan',
  'New American',
  'Noodle',
  'Pakistani',
  'Pastry Shop',
  'Persian',
  'Peruvian',
  'Pet Café',
  'Piano Bar',
  'Pie Shop',
  'Pizzeria',
  'Polish',
  'Portuguese',
  'Poutine',
  'Pub',
  'Puerto Rican',
  'Romanian',
  'Rooftop Bar',
  'Russian',
  'Sake Bar',
  'Salad',
  'Salvadoran',
  'Sandwich',
  'Satay',
  'Scandinavian',
  'Scottish',
  'Seafood',
  'Shawarma',
  'Singaporean',
  'Slovak',
  'Soup',
  'South American',
  'Southern / Soul Food',
  'Spanish',
  'Speakeasy',
  'Sports Bar',
  'Sri Lankan',
  'Steakhouse',
  'Swiss',
  'Syrian',
  'Tea Room',
  'Thai',
  'Theme',
  'Tibetan',
  'Tiki Bar',
  'Turkish',
  'Ukrainian',
  'Vegan and Vegetarian',
  'Venezuelan',
  'Vietnamese',
  'Whisky Bar',
  'Wine Bar',
  'Wings',
  'Yemeni',
];

export default cuisines;

export type Cuisine = typeof cuisines[number];

export const yelpCuisines = [
  'Abruzzese',
  'Acai Bowls',
  'Afghan',
  'African',
  'Alentejo',
  'Algarve',
  'Alsatian',
  'Altoatesine',
  'American (New)',
  'American (Traditional)',
  'Andalusian',
  'Apulian',
  'Arab Pizza',
  'Arabian',
  'Argentine',
  'Armenian',
  'Arroceria / Paella',
  'Asian Fusion',
  'Asturian',
  'Australian',
  'Austrian',
  'Auvergnat',
  'Azores',
  'Backshop',
  'Baden',
  'Bagels',
  'Baguettes',
  'Bakeries',
  'Bangladeshi',
  'Barbeque',
  'Basque',
  'Bavarian',
  'Beer Garden',
  'Beer Hall',
  'Beer, Wine & Spirits',
  'Beira',
  'Beisl',
  'Belgian',
  'Bento',
  'Berrichon',
  'Beverage Store',
  'Bistros',
  'Black Sea',
  'Blowfish',
  'Bourguignon',
  'Brasseries',
  'Brazilian',
  'Brazilian Empanadas',
  'Breakfast & Brunch',
  'Breweries',
  'Brewpubs',
  'British',
  'Bubble Tea',
  'Buffets',
  'Bulgarian',
  'Burgers',
  'Burmese',
  'Butcher',
  'CSA',
  'Cafes',
  'Cafeteria',
  'Cajun/Creole',
  'Calabrian',
  'Cambodian',
  'Canadian (New)',
  'Candy Stores',
  'Canteen',
  'Cantonese',
  'Caribbean',
  'Catalan',
  'Central Brazilian',
  'Champagne Bars',
  'Chee Kufta',
  'Cheese Shops',
  'Cheesesteaks',
  'Chicken Shop',
  'Chicken Wings',
  'Chilean',
  'Chimney Cakes',
  'Chinese',
  'Chocolatiers & Shops',
  'Churros',
  'Cideries',
  'Coffee & Tea',
  'Coffee & Tea Supplies',
  'Coffee Roasteries',
  'Colombian',
  'Comfort Food',
  'Congee',
  'Convenience Stores',
  'Conveyor Belt Sushi',
  'Corsican',
  'Creperies',
  'Cuban',
  'Cucina campana',
  'Cupcakes',
  'Curry Sausage',
  'Custom Cakes',
  'Cypriot',
  'Czech',
  'Czech/Slovakian',
  'Dagashi',
  'Danish',
  'Delicatessen',
  'Delis',
  'Desserts',
  'Dim Sum',
  'Diners',
  'Dinner Theater',
  'Distilleries',
  'Do-It-Yourself Food',
  'Dominican',
  'Donairs',
  'Donburi',
  'Donuts',
  'Dried Fruit',
  'Dumplings',
  'Eastern European',
  'Eastern German',
  'Eastern Mexican',
  'Egyptian',
  'Emilian',
  'Empanadas',
  'Eritrean',
  'Ethical Grocery',
  'Ethiopian',
  'Fado Houses',
  'Falafel',
  'Farmers Market',
  'Fast Food',
  'Filipino',
  'Fischbroetchen',
  'Fish & Chips',
  'Fishmonger',
  'Flatbread',
  'Flemish',
  'Fondue',
  'Food Court',
  'Food Delivery Services',
  'Food Stands',
  'Food Trucks',
  'Franconian',
  'Freiduria',
  'French',
  'French Southwest',
  'Friterie',
  'Friulan',
  'Frozen Food',
  'Fruits & Veggies',
  'Fuzhou',
  'Galician',
  'Game Meat',
  'Gastropubs',
  'Gelato',
  'Georgian',
  'German',
  'Giblets',
  'Gluten-Free',
  'Gozleme',
  'Greek',
  'Grocery',
  'Guamanian',
  'Gyudon',
  'Hainan',
  'Haitian',
  'Hakka',
  'Halal',
  'Hand Rolls',
  'Hawaiian',
  'Hawker Centre',
  'Health Markets',
  'Henghwa',
  'Herbs & Spices',
  'Hessian',
  'Heuriger',
  'Himalayan/Nepalese',
  'Hokkien',
  'Homemade Food',
  'Honduran',
  'Honey',
  'Hong Kong Style Cafe',
  'Horumon',
  'Hot Dogs',
  'Hot Pot',
  'Hunan',
  'Hungarian',
  'Iberian',
  'Ice Cream & Frozen Yogurt',
  'Imported Food',
  'Indian',
  'Indonesian',
  'International',
  'International Grocery',
  'Internet Cafes',
  'Irish',
  'Island Pub',
  'Israeli',
  'Italian',
  'Izakaya',
  'Jaliscan',
  'Japanese',
  'Japanese Curry',
  'Japanese Sweets',
  'Jewish',
  'Juice Bars & Smoothies',
  'Kaiseki',
  'Kebab',
  'Kiosk',
  'Kombucha',
  'Kopitiam',
  'Korean',
  'Kosher',
  'Kurdish',
  'Kushikatsu',
  'Lahmacun',
  'Laos',
  'Laotian',
  'Latin American',
  'Lebanese',
  'Ligurian',
  'Live/Raw Food',
  'Lumbard',
  'Lyonnais',
  'Macarons',
  'Madeira',
  'Malaysian',
  'Mamak',
  'Mauritius',
  'Meaderies',
  'Meat Shops',
  'Meatballs',
  'Mediterranean',
  'Mexican',
  'Middle Eastern',
  'Milk Bars',
  'Milkshake Bars',
  'Minho',
  'Modern Australian',
  'Modern European',
  'Mongolian',
  'Moroccan',
  'Mulled Wine',
  'Napoletana',
  'Nasi Lemak',
  'New Mexican Cuisine',
  'New Zealand',
  'Nicaraguan',
  'Nicoise',
  'Night Food',
  'Nikkei',
  'Noodles',
  'Norcinerie',
  'Northeastern Brazilian',
  'Northern Brazilian',
  'Northern German',
  'Northern Mexican',
  'Nyonya',
  'Oaxacan',
  'Oden',
  'Okinawan',
  'Okonomiyaki',
  'Olive Oil',
  'Onigiri',
  'Open Sandwiches',
  'Organic Stores',
  'Oriental',
  'Ottoman Cuisine',
  'Oyakodon',
  'PF/Comercial',
  'Pakistani',
  'Palatine',
  'Pan Asian',
  'Pancakes',
  'Panzerotti',
  'Parent Cafes',
  'Parma',
  'Pasta Shops',
  'Patisserie/Cake Shop',
  'Pekinese',
  'Persian/Iranian',
  'Peruvian',
  'Piadina',
  'Piemonte',
  'Pierogis',
  'Pita',
  'Pizza',
  'Poke',
  'Polish',
  'Polynesian',
  'Pop-Up Restaurants',
  'Popcorn Shops',
  'Portuguese',
  'Potatoes',
  'Poutineries',
  'Pretzels',
  'Provencal',
  'Pub Food',
  'Pueblan',
  'Puerto Rican',
  'Ramen',
  'Reunion',
  'Rhinelandian',
  'Ribatejo',
  'Rice',
  'Robatayaki',
  'Rodizios',
  'Roman',
  'Romanian',
  'Rotisserie Chicken',
  'Russian',
  'Salad',
  'Salumerie',
  'Salvadoran',
  'Sandwiches',
  'Sardinian',
  'Scandinavian',
  'Schnitzel',
  'Scottish',
  'Seafood',
  'Seafood Markets',
  'Senegalese',
  'Serbo Croatian',
  'Shanghainese',
  'Shaved Ice',
  'Shaved Snow',
  'Sicilian',
  'Signature Cuisine',
  'Singaporean',
  'Slovakian',
  'Smokehouse',
  'Soba',
  'Somali',
  'Soul Food',
  'Soup',
  'South African',
  'Southern',
  'Spanish',
  'Specialty Food',
  'Sports Clubs',
  'Sri Lankan',
  'Steakhouses',
  'Street Vendors',
  'Sugar Shacks',
  'Sukiyaki',
  'Supper Clubs',
  'Sushi Bars',
  'Swabian',
  'Swedish',
  'Swiss Food',
  'Syrian',
  'Szechuan',
  'Tabernas',
  'Tacos',
  'Taiwanese',
  'Taiyaki',
  'Takoyaki',
  'Tamales',
  'Tapas Bars',
  'Tapas/Small Plates',
  'Tavola Calda',
  'Tea Rooms',
  'Tempura',
  'Teochew',
  'Teppanyaki',
  'Tex-Mex',
  'Thai',
  'Themed Cafes',
  'Tofu Shops',
  'Tonkatsu',
  'Torshi',
  'Tortillas',
  'Traditional Norwegian',
  'Traditional Swedish',
  'Tras-os-Montes',
  'Trattorie',
  'Trinidadian',
  'Turkish',
  'Turkish Ravioli',
  'Tuscan',
  'Udon',
  'Ukrainian',
  'Unagi',
  'Uzbek',
  'Vegan',
  'Vegetarian',
  'Venetian',
  'Venezuelan',
  'Venison',
  'Vietnamese',
  'Waffles',
  'Water Stores',
  'Western Style Japanese Food',
  'Wine Tasting Room',
  'Wineries',
  'Wok',
  'Wraps',
  'Yakiniku',
  'Yakitori',
  'Yucatan',
  'Yugoslav',
  'Zapiekanka',
] as const;

export type YelpCuisine = typeof yelpCuisines[number];
