import React from 'react';
import { WrappedInputField } from './FormikUtil';

interface Props {
  loc: string;
}

const NameQuestion = ({ loc }: Props) => {
  return <WrappedInputField label="Concept Name" fluid name={loc} />;
};

export default NameQuestion;
