import Auth from 'borne_ui/src/auth/Auth';
import React, { useMemo } from 'react';
import { LatLngBoundsExpression } from 'leaflet';
import { TileLayer, useMap, Pane, CircleMarker } from 'react-leaflet';
import { Loader } from 'semantic-ui-react';
import { useMarketGeo } from '../../api/requests';
import withMapContext from './WithMapContext';

interface Props {
  auth: Auth;
  marketId: string;
  coords: [number, number];
}

const SingleLocationMapComponent: React.FC<Props> = ({ auth, marketId, coords }: Props) => {
  const map = useMap();
  const [lng, lat] = coords;

  const marketGeoRes = useMarketGeo(auth, marketId, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      map.fitBounds([data.bbox!.slice(0, 2).reverse(), data.bbox!.slice(2).reverse()] as LatLngBoundsExpression);
      map.invalidateSize();
    },
  });

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
        zIndex={100}
      />
    ),
    []
  );

  return (
    <>
      {marketGeoRes.isSuccess && !marketGeoRes.isFetching ? (
        <>
          <Pane name="entLocPane" style={{ zIndex: 405 }}>
            <CircleMarker center={[lat, lng]} fillOpacity={1} fillColor="#bccd19" stroke={false} />
          </Pane>
          {Tiles}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export const SingleLocationMap = withMapContext(SingleLocationMapComponent, { height: '40vh' });
