import React, { Component } from 'react';
import { Header as BaseHeader, Segment } from 'semantic-ui-react';
import { MarketSegment } from '../../types';

interface Props {
  marketSegmentation: MarketSegment[];
}

class MarketSegmentationSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment>
        <BaseHeader as="h3">Market Segmentation</BaseHeader>
        {this.props.marketSegmentation.map((segment, i) => (
          <p key={i}>
            {segment.description}: {segment.value}
          </p>
        ))}
      </Segment>
    );
  }
}

export default MarketSegmentationSegment;
