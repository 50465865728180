import { useAllStudyAreas, useCurrentUser, useMarketSummary, useSubscribedMarketBounds } from '../../../api/requests';
import Auth from 'borne_ui/src/auth/Auth';
import React, { useEffect, useState } from 'react';
import { Grid, Icon, Tab, Message } from 'semantic-ui-react';
import {
  PageContainer,
  BorneFlexContainer,
  BorneDropDown,
  BorneSmallButton,
  ContentContainer,
  BorneTab,
} from '../../generic/BorneComponents';
import SideMenu2 from '../../generic/SideMenu2';
import MarketDemographicsContents from '../../pages/MarketList/MarketDemographicsContents';
import MarketRestaurantsContents from '../../pages/MarketList/MarketRestaurantLandscapeContents';
import { BorneSmallPlus, BorneSmallRightArrow, BorneWhiteClose } from '../../generic/BorneStyledcons';
import { MapContainer } from 'react-leaflet';
import { MarketMap2 } from './MarketMap2';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { commonActions } from '../../redux/testSlice';
import { Link } from 'react-router-dom';

interface Props {
  auth: Auth;
  currentMarket?: number;
  setCurrentMarket: (marketIndex: number) => void;
}
interface SaInMarket {
  name: string;
  id: string | undefined;
}

const Markets: React.FC<Props> = ({ auth, currentMarket, setCurrentMarket }: Props) => {
  const [saInMarket, setSAInMarket] = useState<SaInMarket>({ name: '', id: '-1' });
  const marketOptions = useSelector((state: RootState) => state.common.currentMarkets);
  const dispatch = useDispatch();
  const setMarketOptions = (x: string[]) => {
    dispatch(commonActions.setCurrentMarket(x));
  };
  // const adresses = [{ text: 'BroadWay', href: '/' }, { text: 'Avatar' }, { text: 'Nardia', href: '/' }];

  // const [marketOptions, setMarketOptions] = useState<Array<any>>([]);
  // const [filterSubscribed, setFilterSubscribed] = useState<boolean>(false);
  const [marketSelectionSearch, setMarketSelectionSearch] = useState('');
  const marketSummaryRes = useMarketSummary(auth, marketOptions);

  const studyAreas = useAllStudyAreas(auth, {
    refetchOnWindowFocus: false,
  });

  const meResponse = useCurrentUser(auth, { refetchOnWindowFocus: false });

  const marketsRes = useSubscribedMarketBounds(auth, {
    enabled: meResponse.isSuccess,
    onSuccess: (data) => {
      if (meResponse.data!.settings.preferredMarket && currentMarket === undefined) {
        const indexedMarkets = data
          .map((market, index) => ({ index: index, ...market }))
          .filter((market: any) => meResponse.data!.settings.preferredMarket === market.id);
        setCurrentMarket(indexedMarkets.length != 0 ? indexedMarkets[0]['index'] : 0);
        setMarketOptions([indexedMarkets[0].id]);
      } else if (currentMarket) {
        return;
      } else if (marketOptions[0] && currentMarket != undefined) {
        data.map((market, indx) => {
          if (marketOptions.some((x) => x == market.id)) {
            setCurrentMarket(indx);
            return;
          }
        });
      } else if (currentMarket === undefined) {
        setCurrentMarket(0);
        setMarketOptions([data[0].id]);
      }
    },
    refetchOnWindowFocus: false,
  });
  const availableSa = () => {
    if (studyAreas.isSuccess && studyAreas.data.length > 0) {
      studyAreas.data.map((x) => {
        if (marketOptions.includes(x.market)) {
          setSAInMarket({ name: x.name, id: x.id });
        }
      });
    }
  };

  useEffect(() => {
    availableSa();
  }, [setMarketOptions]);

  const panes = [
    {
      menuItem: 'POS Insights',
      render: () => (
        <Tab.Pane style={{ borderStyle: 'hidden', paddingTop: '4vh' }}>
          {marketOptions.length ? (
            marketSummaryRes.isSuccess ? (
              <MarketRestaurantsContents data={marketSummaryRes.data} />
            ) : (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  We are fetching market comparisons for you.
                </Message.Content>
              </Message>
            )
          ) : (
            <Message icon>
              <Icon name="search" />
              <Message.Content>
                <Message.Header>To get started</Message.Header>
                Select markets above to compare
              </Message.Content>
            </Message>
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Demographics',
      render: () => (
        <Tab.Pane style={{ borderStyle: 'hidden', paddingTop: '4vh' }}>
          {marketOptions.length ? (
            marketSummaryRes.isSuccess ? (
              <MarketDemographicsContents data={marketSummaryRes.data} />
            ) : (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  We are fetching market comparisons for you.
                </Message.Content>
              </Message>
            )
          ) : (
            <Message icon>
              <Icon name="search" />
              <Message.Content>
                <Message.Header>Choose some markets</Message.Header>
                We will compare those markets to each other.
              </Message.Content>
            </Message>
          )}
        </Tab.Pane>
      ),
    },
  ];

  return (
    <PageContainer>
      <ContentContainer>
        <SideMenu2 />
        <Grid className="tw-m-0 tw-h-full tw-w-full">
          <Grid.Row className="tw-z-[1003]">
            <BorneFlexContainer className="tw-px-4 tw-justify-between">
              <BorneFlexContainer className="sm:tw-flex-col lg:tw-flex-row tw-items-start">
                <BorneFlexContainer className="tw-w-max">
                  <h2 className="tw-m-0 tw-mr-6">Markets</h2>
                  {marketsRes.isSuccess && marketsRes.data.length > 0 ? (
                    <BorneFlexContainer style={{ minWidth: '15em', maxWidth: '15.2em' }}>
                      <p className="smallText">Market</p>
                      <BorneDropDown
                        selection
                        selectOnBlur={false}
                        search
                        placeholder="Select Market"
                        options={marketsRes.data.map((market, index) => ({
                          key: `marketsPage-${market.id as string}${index}`,
                          text: market.id,
                          value: market.id,
                        }))}
                        value={marketSelectionSearch}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, { value }: { value: any }) => {
                          setMarketSelectionSearch(value);
                        }}
                      />
                    </BorneFlexContainer>
                  ) : (
                    <BorneFlexContainer style={{ minWidth: '15em', maxWidth: '15.2em' }}>
                      <p className="smallText">Market</p>
                      <BorneDropDown selectOnBlur={false} search selection placeholder="City / DMA Name" />
                    </BorneFlexContainer>
                  )}
                  <BorneSmallButton
                    active={marketOptions.length <= 1}
                    onClick={() =>
                      marketSelectionSearch.length > 0 && marketOptions.length <= 1
                        ? setMarketOptions([marketSelectionSearch])
                        : ''
                    }
                    className="tw-ml0 tw-mr-4"
                  >
                    <span>View Market</span>
                    <BorneSmallPlus />
                  </BorneSmallButton>
                  <BorneSmallButton
                    active
                    onClick={() =>
                      marketSelectionSearch.length > 0 && marketOptions.length < 3
                        ? setMarketOptions(Array.from(new Set(marketOptions).add(marketSelectionSearch)))
                        : ''
                    }
                    className="tw-ml0 tw-mr-4"
                  >
                    <span>Compare Market</span>
                    <BorneSmallPlus />
                  </BorneSmallButton>
                </BorneFlexContainer>
                <BorneFlexContainer className="sm:tw-mt-2 lg:tw-mt-0">
                  {marketOptions != undefined && marketOptions[0]
                    ? marketOptions.map((x) => (
                        <BorneSmallButton className="tw-bg-[#7cb0ba] tw-ml0 tw-mr-4" active key={x}>
                          <span>{x}</span>
                          <BorneWhiteClose
                            onClick={(e) => {
                              const replace = new Set(marketOptions);
                              replace.delete(x);
                              setMarketOptions(Array.from(replace));
                            }}
                          />
                        </BorneSmallButton>
                      ))
                    : ''}
                </BorneFlexContainer>
              </BorneFlexContainer>
            </BorneFlexContainer>
          </Grid.Row>
          <Grid.Row>
            <BorneFlexContainer className="tw-px-4 tw-justify-between">
              {marketOptions != undefined &&
                marketOptions.length > 0 &&
                marketOptions[0].length > 0 &&
                marketOptions.map((x) => (
                  <BorneFlexContainer
                    className={`tw-border-[1px] tw-border-gray-900 tw-h-[40rem] tw-w-${98 / marketOptions.length}%`}
                  >
                    <MapContainer attributionControl={false} className="tw-h-full tw-w-full" tap={false}>
                      <MarketMap2 auth={auth} marketId={x} numberOfMarkets={Array.from(marketOptions).length} />
                    </MapContainer>
                  </BorneFlexContainer>
                ))}
            </BorneFlexContainer>
          </Grid.Row>
          <Grid.Row className="tw-pb-8 lg:tw-pr-0 md:tw-pr-8">
            <Grid.Column width={12}>
              <BorneTab panes={panes} menu={{ pointing: true, tabular: true }} renderActiveOnly={true} />
            </Grid.Column>
            <Grid.Column width={4}>
              <div style={{ position: 'sticky', top: 40 }}>
                <h3>View Study Area Report</h3>
                <p>
                  Take the time to dive into an in depth report of a randomly selected study area in one of the markets
                  currently selected.
                </p>
                {studyAreas.isSuccess && studyAreas.data.length > 0 ? (
                  <Link to={`/studyareas/${saInMarket.id == '-1' ? '' : saInMarket.id}`}>
                    <BorneSmallButton active className="tw-ml-0 tw-mb-4 tw-mt-4">
                      <span>{saInMarket.id == '-1' ? 'No Study Area' : saInMarket.name}</span>
                      <BorneSmallRightArrow />
                    </BorneSmallButton>
                  </Link>
                ) : (
                  <BorneSmallButton className="tw-ml-0 tw-mb-4 tw-mt-4" active={false}>
                    <span>No Market Selected</span>
                    <BorneSmallRightArrow className="lg:tw-block md:tw-hidden" />
                  </BorneSmallButton>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentContainer>
    </PageContainer>
  );
};

export default Markets;
