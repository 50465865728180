import { Header, Description } from '../../styled/Typography';
import React, { useState } from 'react';
import { Divider, Grid, Label, List, Loader, Menu } from 'semantic-ui-react';
import Auth from '../../../auth/Auth';
import { useStudyAreaTS } from '../../../api/requests';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { MarketTSMap } from '../../maps/MarketTSMap';

export const StudyAreaDetailTSDescription: React.FC = ({}) => {
  return (
    <>
      <Header as="h2">Tapestry Segmentation</Header>
      <Description as="p">
        The character of a study area depends on the psychographics of its core patrons. Here, we group patrons that
        visit restaurants in this area by their ESRI tapestry segments. ESRI’s Tapestry Segmentation is a geodemographic
        system that identifies distinctive markets in the U.S. based on socioeconomic and demographic characteristics to
        provide an accurate, comprehensive profile of U.S. consumers.
      </Description>
    </>
  );
};

interface DataProps {
  studyAreaId: string;
  auth: Auth;
  marketId: string;
}

export const StudyAreaDetailTSData: React.FC<DataProps> = ({ studyAreaId, auth, marketId }) => {
  const tapestryQueryResponse = useStudyAreaTS(auth, studyAreaId);
  const [tsIndex, setTsIndex] = useState<number>(0);

  return tapestryQueryResponse.isSuccess ? (
    <Grid attached="top" textAlign="center" columns="equal" padded="horizontally">
      <Grid.Row>
        <Grid.Column width="6">
          <Menu vertical secondary compact fluid style={{ height: '40vh', overflowY: 'scroll' }}>
            {tapestryQueryResponse.data.map((ts, idx) => (
              <Menu.Item
                link
                active={tsIndex === idx}
                key={idx}
                index={idx}
                onClick={(ev, data) => {
                  setTsIndex(idx);
                }}
                style={{
                  paddingLeft: 40,
                }}
              >
                <Label
                  attached="top left"
                  style={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    height: '100%',
                    width: '80px',
                    margin: 0,
                  }}
                >
                  <ResponsiveContainer height="100%" width="100%">
                    <PieChart width={50} height={50}>
                      <Pie
                        data={[
                          { name: 'Percent', value: ts.pct },
                          { name: 'Complement', value: 100 - ts.pct },
                        ]}
                        startAngle={180}
                        endAngle={-180}
                        innerRadius={15}
                        outerRadius={25}
                        fill="#8884d8"
                        paddingAngle={2}
                        dataKey="value"
                      >
                        <Cell key="cell-0" fill="#0C7C59" />
                        <Cell key="cell-1" fill="#BBBBBB" />
                      </Pie>
                      <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
                        {Math.round(ts.pct)}%
                      </text>
                    </PieChart>
                  </ResponsiveContainer>
                </Label>
                <Header as="h3">{ts.name}</Header>
                <Description as="p">{Math.round(ts.total)} trips</Description>
              </Menu.Item>
            ))}
          </Menu>
        </Grid.Column>
        <Grid.Column width="10">
          <MarketTSMap
            studyAreaId={studyAreaId}
            marketId={marketId}
            ts={tapestryQueryResponse.data[tsIndex].id}
            auth={auth}
          />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <Grid.Column textAlign="left">
          <>
            <Header as="h2">{tapestryQueryResponse.data[tsIndex].name}</Header>
            <Description as="p">{tapestryQueryResponse.data[tsIndex].description}</Description>
          </>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="left">
          <Header as="h2">Market Profile</Header>
          <List as="ul">
            {tapestryQueryResponse.data[tsIndex].marketProfile.map((item, idx) => (
              <List.Item key={idx} as="li" content={item} style={{ fontFamily: 'Montserrat' }} />
            ))}
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    <div className="tw-min-h-[5rem]">
      <Loader active size="massive" />
    </div>
  );
};
