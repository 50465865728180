/* Some helpful generics */
let cartesian_two_operands = (a: any, b: any) => [].concat(...a.map((a: any) => b.map((b: any) => [].concat(a, b))));
let cartesian: any = (a: any, b: any, ...c: any) => (b ? cartesian(cartesian_two_operands(a, b), ...c) : a);
let range = (start: any, end: any, step = 1) =>
  Array.from(Array(Math.floor((end - start + 1) / step)).keys()).map((x) => x * step + start);

const allTimes24Hr = cartesian(range(0, 23), range(0, 60, 15)).map(
  ([hour, minute]: any) =>
    `${String(hour).padStart(2, 0 as unknown as string)}:${String(minute).padStart(2, 0 as unknown as string)}`
);

const allTimes12Hr = cartesian(['am', 'pm'], range(0, 11), range(0, 60, 15)).map(
  ([period, hour, minute]: any) =>
    `${hour == 0 ? 12 : hour}:${String(minute).padStart(2, '0')}${hour != 12 ? period : period == 'am' ? 'pm' : 'am'}`
);

export const formattedTime = () => {
  return allTimes24Hr.map((x: any, i: any) => ({ value: x, text: allTimes12Hr[i], key: x }));
};

export const endShiftValues = () => {
  let temp = formattedTime();
  temp.push({ value: '24:00', text: '12:00am', key: '24:00' });
  return temp.slice(1);
};
