import Auth from 'borne_ui/src/auth/Auth';
import React, { useMemo, useState } from 'react';
import { Layer as LeafletLayer } from 'leaflet';
import { TileLayer, useMap, Pane, AttributionControl } from 'react-leaflet';
import GeoJSON from '../generic/GeoJSON';
import { Dropdown, Loader } from 'semantic-ui-react';
import { useStudyAreaGeo, useStudyAreaHomeDGeo, useStudyAreaODGeo } from '../../api/requests';
import withMapContext from './WithMapContext';
import Chroma from 'chroma-js';
import { DayOfWeek } from 'borne_ui/src/types';

interface Props {
  auth: Auth;
  studyAreaId: string;
  dayOfWeek: DayOfWeek;
}

type ODSeries = 'od' | 'home';

const StudyAreaODMapComponent: React.FC<Props> = ({ auth, studyAreaId, dayOfWeek }: Props) => {
  const map = useMap();
  const [colorScale, setColorScale] = useState<Chroma.Scale>();
  const [currentSeries, setSeries] = useState<ODSeries>('od');
  const onEachFeatureDetail = (feature: any, layer: LeafletLayer) => {
    if (feature.properties && feature.properties.volume) {
      layer.bindTooltip(`Trip Count: ${feature.properties.volume}`, { sticky: true, opacity: 0.9 });
    }
  };

  const studyAreaGeoRes = useStudyAreaGeo(auth, studyAreaId, {
    refetchOnWindowFocus: false,
  });

  const studyAreaODRes = useStudyAreaODGeo(auth, studyAreaId, dayOfWeek, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      map.invalidateSize();
      const volumes = [...data.features.map(({ properties }) => properties!.volume)];
      const chroma = Chroma.scale('YlGn').classes(Chroma.limits(volumes, 'l', 7));
      setColorScale(() => chroma);
    },
  });

  const studyAreaHomeDRes = useStudyAreaHomeDGeo(auth, studyAreaId, dayOfWeek, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      map.invalidateSize();
      const volumes = [...data.features.map(({ properties }) => properties!.volume)];
      const chroma = Chroma.scale('YlGn').classes(Chroma.limits(volumes, 'l', 7));
      setColorScale(() => chroma);
    },
  });

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a>'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
        zIndex={100}
      />
    ),
    []
  );
  const ReplicaTiles = useMemo(
    () => (
      <TileLayer
        attribution='<div style="display:inline-flex;align-items:baseline;"><img width="8" height="9" src="https://replicahq.com/wp-content/uploads/2022/09/Logo-R.svg"/><a href="https://replicahq.com/">Powered by Replica</a></div>'
        url="https://replicahq.com/wp-content/uploads/2022/09/Logo-R.svg"
      />
    ),
    []
  );

  return (
    <>
      {studyAreaGeoRes.isSuccess && !studyAreaGeoRes.isFetching ? (
        <>
          <Pane name="data" style={{ zIndex: 200 }}>
            <AttributionControl position="bottomright" />
            {studyAreaODRes.isSuccess && !studyAreaODRes.isFetching && colorScale && currentSeries === 'od' && (
              <GeoJSON
                data={studyAreaODRes.data}
                onEachFeature={onEachFeatureDetail}
                style={(record) => ({
                  color: 'rgba(0,0,0,.04)',
                  weight: 1,
                  fillOpacity: 0.7,
                  zIndex: 10,
                  fillColor: colorScale(record?.properties.volume as number).hex(),
                })}
              />
            )}
            {studyAreaHomeDRes.isSuccess && !studyAreaHomeDRes.isFetching && colorScale && currentSeries === 'home' && (
              <GeoJSON
                data={studyAreaHomeDRes.data}
                onEachFeature={onEachFeatureDetail}
                style={(record) => ({
                  color: 'rgba(0,0,0,.04)',
                  weight: 1,
                  fillOpacity: 0.7,
                  zIndex: 10,
                  fillColor: colorScale(record?.properties.volume as number).hex(),
                })}
              />
            )}
          </Pane>
          {Tiles}
          {ReplicaTiles}
          <Pane name="boundary" style={{ zIndex: 1000 }}>
            <GeoJSON
              data={studyAreaGeoRes.data}
              style={(_) => ({
                color: 'rgba(0,0,0,.4)',
                fillColor: 'rgba(0,0,0,0.01)',
              })}
            />
          </Pane>
          <div className="leaflet-top leaflet-right leaflet-control">
            <Dropdown
              onOpen={() => {
                map.dragging.disable();
                map.touchZoom.disable();
                map.doubleClickZoom.disable();
                map.scrollWheelZoom.disable();
                map.boxZoom.disable();
                map.keyboard.disable();
                if (map.tap) map.tap.disable();
              }}
              onClose={() => {
                map.dragging.enable();
                map.touchZoom.enable();
                map.doubleClickZoom.enable();
                map.scrollWheelZoom.enable();
                map.boxZoom.enable();
                map.keyboard.enable();
                if (map.tap) map.tap.enable();
              }}
              className="icon leaflet-control"
              labeled
              button
              icon="caret right"
              placeholder={currentSeries == 'od' ? 'Trip Origin' : 'Home Location'}
            >
              <Dropdown.Menu>
                <Dropdown.Item value="od" content="Trip Origin" onClick={() => setSeries('od')} />
                <Dropdown.Item value="home" content="Home Location" onClick={() => setSeries('home')} />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export const StudyAreaODMap = withMapContext(StudyAreaODMapComponent, { height: '60vh' });
