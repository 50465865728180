import React, { useState } from 'react';
import { Divider, Dropdown, Grid } from 'semantic-ui-react';

interface Props {
  children: any;
  defaultNormalize?: boolean;
}

const BarChartToggleNormalize: React.FC<Props> = (props: Props) => {
  const { children, defaultNormalize } = props;
  const notChart = React.Children.toArray(children).filter(
    (x) => React.isValidElement(x) && (!('role' in x.props) || x.props['role'] !== 'chart')
  );
  const chart = React.Children.toArray(children).filter(
    (x) => React.isValidElement(x) && 'role' in x.props && x.props['role'] === 'chart'
  )[0];
  const [normalize, setNormalize] = useState<boolean>(defaultNormalize ? true : false);

  return (
    <>
      <Grid verticalAlign="middle" textAlign="left">
        <Grid.Column width={10} style={{ paddingRight: 0 }}>
          {notChart}
        </Grid.Column>
        <Grid.Column width={5} textAlign="right">
          <Dropdown
            defaultValue={defaultNormalize ? 'pct' : 'counts'}
            onChange={(_, { value }) => {
              value === 'counts' ? setNormalize(false) : setNormalize(true);
            }}
            options={[
              { key: 'counts', value: 'counts', text: 'Count' },
              { key: 'pct', value: 'pct', text: 'Percentage' },
            ]}
          />
        </Grid.Column>
      </Grid>
      <Divider fitted />
      {React.isValidElement(chart) && React.cloneElement(chart as any, { normalize: normalize })}
    </>
  );
};

export default BarChartToggleNormalize;
