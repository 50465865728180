import React, { Component } from 'react';
import { Header as BaseHeader, Table, Segment } from 'semantic-ui-react';

interface Props {
  totalPoints: number;
  totalPossiblePoints: number;
}

class TotalScoreSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment>
        <BaseHeader as="h3">Total</BaseHeader>
        <h4>
          The concept&apos;s final score:{' '}
          <b>{Math.round((this.props.totalPoints / this.props.totalPossiblePoints) * 100)}% </b>
        </h4>
        <p>
          The Borne Report<sup>&#174;</sup> score evaluates the probability of a concept&apos;s success beyond 3 years
          of operation. Of course, success is highly dependent on sound operations, marketing, and financial acumen, so
          our report assumes these factors are in place.
        </p>
        <p>Here is the key for understanding the score:</p>
        <Table>
          <Table.Row>
            <Table.Cell textAlign="right">85+</Table.Cell>
            <Table.Cell>High probability of success</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="right">80 - 84</Table.Cell>
            <Table.Cell>Strong with some challenges to success</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="right">75 - 79</Table.Cell>
            <Table.Cell>Average and may struggle to realize success</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="right">0 - 74</Table.Cell>
            <Table.Cell>Risky with a low probability of success</Table.Cell>
          </Table.Row>
        </Table>
      </Segment>
    );
  }
}

export default TotalScoreSegment;
