import React, { Component } from 'react';
import { Header as BaseHeader, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { formatCurrencyWithStandardPrecision } from '../../helpers';
import { MinimumWageInfo } from '../../types';

interface Props {
  color: SemanticCOLORS;
  minWageInfo: MinimumWageInfo;
}

class MinimumWageSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">Minimum Wage</BaseHeader>
        <p>
          It is very important to account for minimum wage laws in the area that you are intending to open a concept. If
          your restaurant is in a location with a high minimum wage (and/or in a location which pays tipped workers
          minimum wage), it is crucial that you do the math to ensure that the concept will survive with the number of
          employees you expect to need to have.
        </p>
        <p>
          {this.props.minWageInfo ? (
            <span>
              The minimum wage for this area is{' '}
              <b>{formatCurrencyWithStandardPrecision(this.props.minWageInfo.minWageGeneral)}</b>. The minimum wage for
              tipped employees in this area is{' '}
              <b>{formatCurrencyWithStandardPrecision(this.props.minWageInfo.minWageTipped)}</b>.
            </span>
          ) : (
            'Minimum wage information unavailable for this location.'
          )}
        </p>
      </Segment>
    );
  }
}

export default MinimumWageSegment;
