import React from 'react';
import { Grid, Header, Placeholder } from 'semantic-ui-react';
import styled from '@emotion/styled';

const SiteStlyedColumn0 = styled(Grid.Column)`
  border-left: 4px solid #00c4c4;
`;
const SiteStlyedColumn1 = styled(Grid.Column)`
  border-left: 4px solid #c70000;
`;
export const SiteAndDetailsnMenu: React.FC<{}> = () => {
  return (
    // Title of segment
    <>
      <Header>Site and Details</Header>
      {/* Description  */}
      <p>
        Etiam congue, arcu et dictum bibendum, dolor ex varius metus, et ornare ipsum turpis sed nisl. Nulla vulputate
        dignissim elit, et volutpat purus molestie eget. Vestibulum at lacus vel orci imperdiet auctor. Nam fermentum
        fringilla odio sit amet laoreet.
      </p>
    </>
    // Depending on segment: menu
  );
};

export const SiteAndDetailsnDataSegment: React.FC<{}> = () => {
  return (
    <>
      <Grid columns="equal" relaxed padded>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header>Walk, Bike, and Transit Scores</Header>
            <p>
              Neighborhood restuarants need to be in a place that is accessible. Walk, Bike, and Transit scores measure
              how easy it is to get to nearyb amenities on foot, by bike, or by public transportaion
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Placeholder>
              <Placeholder.Image />
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <SiteStlyedColumn0 width={8}>
            <Header>Nearby Parking</Header>
            <p>
              Considering Parking is absolutely necessary when trying to decide where to plant a concept. If parking is
              unavailable, then few factors can make up for this unavailability.
            </p>
            <p>There is a parking structure within 300 meters of the concepts proposed locations.</p>
          </SiteStlyedColumn0>
          <SiteStlyedColumn1 width={8}>
            <Header>Reservations</Header>
            <p>
              Deciding whether or not to take reservations can be difficult if the restuarant is trying to cater towards
              the impropmtu crowd. It may be perfectly viable to not accept reservations.
            </p>
            <p>Your restuarant does not need to accept reservations.</p>
          </SiteStlyedColumn1>
        </Grid.Row>
      </Grid>
    </>
  );
};
