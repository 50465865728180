import React from 'react';
import { join } from '../../reactHelpers';
import { Form, Header as BaseHeader, DropdownItemProps, Button, Container } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { useField } from 'formik';

const Header = styled(BaseHeader)`
  &&&& {
    margin: 0 0 20px;
  }
`;

const ErrorMessage = styled.div`
  color: #8b0000;
  font-weight: bold;
  font-size: 0.85em;
  margin: 0 0 10px;
`;

interface WrappedFieldProps {
  label?: string;
  name: string;
  useFormLabel?: boolean;
  fluid?: boolean;
}

interface WrappedInputFieldProps extends WrappedFieldProps {
  type?: string;
}

const WrappedInputField = ({ label, type = 'string', useFormLabel, ...props }: WrappedInputFieldProps) => {
  const [field, meta] = useField(props);
  return (
    <>
      {!useFormLabel && label && <Header as="h3">{label}</Header>}
      {!useFormLabel && meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
      <Form.Input
        label={useFormLabel ? label : undefined}
        error={useFormLabel && meta.touched && meta.error}
        type={type}
        {...field}
        {...props}
      />
    </>
  );
};

type WrappedDropdownOption = string | DropdownItemProps;

interface WrappedDropdownFieldProps extends WrappedFieldProps {
  options: readonly WrappedDropdownOption[];
  [key: string]: any;
}

const WrappedDropdownField = ({ label, options, useFormLabel, ...props }: WrappedDropdownFieldProps) => {
  const [field, meta, helpers] = useField(props);

  function toDropdownOption(category: string): DropdownItemProps {
    return {
      text: category,
      value: category,
    };
  }

  function isString(option: WrappedDropdownOption): option is string {
    return typeof option === 'string';
  }

  return (
    <>
      {!useFormLabel && label && <Header as="h3">{label}</Header>}
      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
      <Form.Dropdown
        label={useFormLabel ? label : undefined}
        onChange={(_, v) => {
          helpers.setValue(v.value);
          helpers.setTouched(true, false);
        }}
        options={options.map((option) => (isString(option) ? toDropdownOption(option) : option))}
        value={field.value}
        {...props}
      />
    </>
  );
};

interface WrappedCheckboxFieldProps extends WrappedFieldProps {
  radio?: boolean;
}

/**
 * Checkboxes in Semantic UI are a superset of radio selects
 *
 * Formik handles them specially, therefore, as we need to pass
 * type: checkbox to useField in order to get the correct behavior
 */
const WrappedCheckboxField = ({ label, name, ...props }: WrappedCheckboxFieldProps) => {
  // const [field, meta, helpers] = useField({ name, type: 'checkbox' })
};

interface WrappedButtonGroupProps extends WrappedFieldProps {
  options: Record<string | number, string>;
}

const WrappedButtonGroupField = ({ label, options, useFormLabel, fluid, ...props }: WrappedButtonGroupProps) => {
  const [field, meta, helpers] = useField(props);

  return (
    <>
      {!useFormLabel && label && <Header as="h3">{label}</Header>}
      <Container fluid={fluid} textAlign="center">
        {meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
        <Form.Field control={Button.Group} label={useFormLabel ? label : undefined}>
          {join(
            Object.keys(options).map((option) => (
              <Button
                key={option}
                type="button" // Prevent submit from being called by Formik
                active={field.value == option}
                onClick={() => {
                  helpers.setValue(option === '0' || option === '1' ? option === '1' : option);
                  helpers.setTouched(true, false);
                }}
                color={field.value == option ? 'grey' : undefined}
              >
                {options[option]}
              </Button>
            )),
            <Button.Or />
          )}
        </Form.Field>
      </Container>
    </>
  );
};

export { WrappedInputField, WrappedDropdownField, WrappedCheckboxField, WrappedButtonGroupField };
