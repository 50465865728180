import BarChart from '@bornellc/bach/BarChart';
import { Header, ChartHeader, Description } from '../../styled/Typography';
import React from 'react';
import { Dropdown, Grid, Segment } from 'semantic-ui-react';
import ObjectPieChart from '../../generic/ObjectPieChart';
import { isSeriesValid, buildSeries } from '../../../reactHelpers';
import BarChartToggleNormalize from '../../generic/BarChartToggleNormalize';
import { DayOfWeek } from '../../../types';

interface DescriptionProps {
  dayOfWeek: DayOfWeek;
  setDayOfWeek: (dow: DayOfWeek) => void;
}

export const StudyAreaDetailDemographicDescription: React.FC<DescriptionProps> = ({ dayOfWeek, setDayOfWeek }) => {
  return (
    <>
      <Header as="h2">Trips and Trip Takers</Header>
      <Description as="p">
        These figures show demographics and travelling info for patrons who visited restaurants within this study area
        on an average{' '}
        <Dropdown
          inline
          value={dayOfWeek}
          options={[
            { key: 1, text: 'weekday', value: 'thu' },
            { key: 2, text: 'weekend', value: 'sat' },
          ]}
          onChange={(e, data) => setDayOfWeek((data.value as DayOfWeek) ?? 'thu')}
        />
      </Description>
    </>
  );
};

interface DataProps {
  studyAreaSummaryData: any;
}

export const StudyAreaDetailDemographicData: React.FC<DataProps> = ({ studyAreaSummaryData }) => {
  return (
    <>
      {studyAreaSummaryData ? (
        <Grid padded relaxed attached="top" columns="2" textAlign="center">
          {isSeriesValid(studyAreaSummaryData, 'age_') && (
            <Grid.Column>
              <BarChartToggleNormalize>
                <ChartHeader as="h3">Age</ChartHeader>
                <BarChart
                  orientation="h"
                  x="key"
                  y="value"
                  grid="v"
                  data={buildSeries(studyAreaSummaryData, 'age_', [
                    '≤ 4',
                    '5 to 14',
                    '15 to 17',
                    '18 to 24',
                    '25 to 34',
                    '35 to 64',
                    '65+',
                  ])}
                  dataKey="age_bar_chart"
                  height={300}
                  role="chart"
                />
              </BarChartToggleNormalize>
            </Grid.Column>
          )}
          {isSeriesValid(studyAreaSummaryData, 'employment_') && (
            <Grid.Column>
              <ChartHeader as="h3">Employment</ChartHeader>
              <ObjectPieChart
                width="100%"
                height={300}
                data={studyAreaSummaryData}
                otherThreshold={0}
                normalize
                keys={['employment_employed', 'employment_unemployed', 'employment_notinlf', 'employment_under_16']}
                labels={['Employed', 'Unemployed', 'Not in Labor Force', 'Under 16']}
              />
            </Grid.Column>
          )}
          {isSeriesValid(studyAreaSummaryData, 'race_') && (
            <Grid.Column>
              <ChartHeader as="h3">Race</ChartHeader>
              <ObjectPieChart
                width="100%"
                height={300}
                data={studyAreaSummaryData}
                normalize
                keys={[
                  'race_white',
                  'race_two_or_more_races',
                  'race_other_race_alone',
                  'race_black_african_american',
                  'race_american_indian_alaska_native',
                  'race_hawaiian_pacific',
                  'race_asian',
                  'race_hispanic_or_latino',
                ]}
                labels={[
                  'White',
                  'Two or More',
                  'Other',
                  'Black',
                  'American Indian',
                  'Hawaiian / PI',
                  'Asian',
                  'Hispanic or Latino',
                ]}
              />
            </Grid.Column>
          )}
          {isSeriesValid(studyAreaSummaryData, 'household_income_') && (
            <Grid.Column>
              <BarChartToggleNormalize>
                <ChartHeader as="h3">Household Income</ChartHeader>
                <BarChart
                  orientation="h"
                  x="key"
                  y="value"
                  grid="v"
                  data={buildSeries(studyAreaSummaryData, 'household_income_', [
                    '≤ 10k',
                    '10k to 40k',
                    '40k to 75k',
                    '75k to 125k',
                    '125k+',
                  ])}
                  dataKey="household_income_bar_chart"
                  height={300}
                  role="chart"
                />
              </BarChartToggleNormalize>
            </Grid.Column>
          )}

          {isSeriesValid(studyAreaSummaryData, 'education_') && (
            <Grid.Column>
              <BarChartToggleNormalize>
                <ChartHeader as="h3">Education</ChartHeader>
                <BarChart
                  orientation="h"
                  x="key"
                  y="value"
                  grid="v"
                  data={buildSeries(studyAreaSummaryData, 'education_', [
                    'Bachelors',
                    'Some College',
                    'High School',
                    'K-12',
                    '< 3',
                  ])}
                  dataKey="education_bar_chart"
                  height={300}
                  role="chart"
                />
              </BarChartToggleNormalize>
            </Grid.Column>
          )}
          {isSeriesValid(studyAreaSummaryData, 'movedin_') && (
            <Grid.Column>
              <BarChartToggleNormalize>
                <ChartHeader as="h3">Residency Term</ChartHeader>
                <BarChart
                  orientation="h"
                  x="key"
                  y="value"
                  grid="v"
                  data={buildSeries(studyAreaSummaryData, 'movedin_', [
                    '< 1 Year',
                    '1 to 2 Years',
                    '2 to 4 Years',
                    '5 to 9 Years',
                    '10 to 19 Years',
                    '20 to 29 Years',
                    '30+ Years',
                    'Group Quarters',
                  ])}
                  dataKey="movedin_bar_chart"
                  height={300}
                  role="chart"
                />
              </BarChartToggleNormalize>
            </Grid.Column>
          )}
          {isSeriesValid(studyAreaSummaryData, 'tenure_') && (
            <Grid.Column>
              <ChartHeader as="h3">Tenure</ChartHeader>
              <ObjectPieChart
                width="100%"
                height={300}
                data={studyAreaSummaryData}
                otherThreshold={0}
                normalize
                keys={['tenure_owner', 'tenure_renter', 'tenure_group_quarters']}
                labels={['Owner', 'Renter', 'Group Quarters']}
              />
            </Grid.Column>
          )}
          {isSeriesValid(studyAreaSummaryData, 'mode_') && (
            <Grid.Column>
              <ChartHeader as="h3">Mode of Transportation</ChartHeader>
              <ObjectPieChart
                width="100%"
                height={300}
                data={studyAreaSummaryData}
                normalize
                keys={[
                  'mode_carpool',
                  'mode_commercial',
                  'mode_public_transit',
                  'mode_biking',
                  'mode_other',
                  'mode_private_auto',
                  'mode_walking',
                  'mode_on_demand_auto',
                ]}
                labels={[
                  'Carpool',
                  'Commercial',
                  'Public Transit',
                  'Biking',
                  'Other',
                  'Private Auto',
                  'Walking',
                  'On-Demand Auto',
                ]}
              />
            </Grid.Column>
          )}
          {isSeriesValid(studyAreaSummaryData, 'previous_activity_') && (
            <Grid.Column>
              <ChartHeader as="h3">Previous Activity</ChartHeader>
              <ObjectPieChart
                width="100%"
                height={300}
                data={studyAreaSummaryData}
                normalize
                keys={[
                  'previous_activity_recreation',
                  'previous_activity_other',
                  'previous_activity_school',
                  'previous_activity_lodging',
                  'previous_activity_eat',
                  'previous_activity_social',
                  'previous_activity_shop',
                  'previous_activity_home',
                  'previous_activity_work',
                ]}
                labels={['Recreation', 'Other', 'School', 'Lodging', 'Eat', 'Social', 'Shop', 'Home', 'Work']}
              />
            </Grid.Column>
          )}
          {isSeriesValid(studyAreaSummaryData, 'family_structure_') && (
            <Grid.Column>
              <ChartHeader as="h3">Household Type</ChartHeader>
              <ObjectPieChart
                width="100%"
                height={300}
                data={studyAreaSummaryData}
                normalize
                otherThreshold={0}
                keys={[
                  'family_structure_living_alone',
                  'family_structure_family_single',
                  'family_structure_nonfamily_single',
                  'family_structure_married_couple',
                  'family_structure_group_quarters',
                ]}
                labels={['Living Alone', 'Single, Family', 'Single, Non-family', 'Married', 'Group Quarters']}
              />
            </Grid.Column>
          )}
          {isSeriesValid(studyAreaSummaryData, 'duration_') && (
            <Grid.Column>
              <BarChartToggleNormalize>
                <ChartHeader as="h3">Trip Duration</ChartHeader>
                <BarChart
                  orientation="h"
                  x="key"
                  y="value"
                  grid="v"
                  data={buildSeries(studyAreaSummaryData, 'duration_', [
                    '< 5 minutes',
                    '5 to 10 min',
                    '10 to 20 min',
                    '20 to 40 min',
                    '40 to 80 min',
                    '> 80 minutes',
                  ])}
                  dataKey="duration_bar_chart"
                  height={300}
                  role="chart"
                />
              </BarChartToggleNormalize>
            </Grid.Column>
          )}
          {isSeriesValid(studyAreaSummaryData, 'distance_') && (
            <Grid.Column>
              <BarChartToggleNormalize>
                <ChartHeader as="h3">Trip Distance</ChartHeader>
                <BarChart
                  orientation="h"
                  x="key"
                  y="value"
                  grid="v"
                  data={buildSeries(studyAreaSummaryData, 'distance_', [
                    '> .5 miles',
                    '.5 to 1 mi',
                    '1 to 2 mi',
                    '2 to 4 mi',
                    '4 to 8 mi',
                    '8 to 16 mi',
                    '16 to 32 mi',
                    '32 to 64 mi',
                    '< 64 miles',
                  ])}
                  dataKey="duration_bar_chart"
                  height={300}
                  role="chart"
                />
              </BarChartToggleNormalize>
            </Grid.Column>
          )}
        </Grid>
      ) : (
        <Segment placeholder />
      )}
    </>
  );
};
