import React from 'react';
import { AnimatedBarSeries, AnimatedBarStack } from '@visx/xychart';
import BaseChart from './BaseChart';
import { ChartProps, Row } from './Common';

interface SeriesMetadata {
  key: string;
  label: string;
}

interface BarChartProps extends ChartProps {
  seriesOptions?: {
    barPadding?: number;
  };
  x: string;
  y: string | Array<SeriesMetadata>;
  normalize?: boolean;
  valueLabelFormatter?: (value: any) => any;
  numInfoTicks?: number;
  colors?: string[];
  role?: string;
}

const BarChart: React.FC<BarChartProps> = (props: BarChartProps) => {
  const {
    data,
    dataKey,
    height,
    width,
    grid,
    orientation = 'v',
    chartOptions = {},
    numInfoTicks = 3,
    normalize,
    tooltip,
    tooltipRenderer,
    colors,
  } = props;

  let { x, y, valueLabelFormatter } = props;
  const { barPadding = 0.5 } = chartOptions;
  const isVertical = orientation === 'v';
  const isSeriesData = y instanceof Array;
  const labelAccessor = (d: Row) => d[x];
  const singleValueAccessor = normalize
    ? (d: Row) =>
        ((d[y as string] as number) * 100) / data.map((d) => d[y as string] as number).reduce((sum, d) => sum + d, 0)
    : (d: Row) => d[y as string];

  const seriesArray = isSeriesData
    ? data.map((row: Row, rowIdx) => {
        return (
          <AnimatedBarStack key={`stack-${rowIdx}`}>
            {(y as Array<SeriesMetadata>).map(({ key, label }, idx) => {
              const valueAccessor = (d: Row) =>
                normalize
                  ? ((d[key] as number) /
                      (y as Array<SeriesMetadata>).reduce((sum, { key, label }) => (d[key] as number) + sum, 0)) *
                    100
                  : d[key];
              return (
                <AnimatedBarSeries
                  data={data}
                  key={`${dataKey}-series${idx}`}
                  dataKey={key}
                  xAccessor={isVertical ? labelAccessor : valueAccessor}
                  yAccessor={isVertical ? valueAccessor : labelAccessor}
                />
              );
            })}
          </AnimatedBarStack>
        );
      })
    : data.map((row: Row, idx: number) => (
        <AnimatedBarSeries
          data={data}
          key={`${dataKey}-series-${idx}`}
          dataKey={y as string}
          xAccessor={isVertical ? labelAccessor : singleValueAccessor}
          yAccessor={isVertical ? singleValueAccessor : labelAccessor}
        />
      ));

  const seriesLabels = isSeriesData ? (y as Array<SeriesMetadata>).map(({ key, label }) => label) : undefined;

  if (!valueLabelFormatter && normalize) {
    valueLabelFormatter = (value: number) => value + '%';
  }

  return (
    <BaseChart
      data={data}
      dataKey={dataKey}
      numInfoTicks={numInfoTicks}
      grid={grid ?? 'full'}
      xScaleOptions={isVertical ? { type: 'band', padding: barPadding } : { type: 'linear' }}
      yScaleOptions={isVertical ? { type: 'linear' } : { type: 'band', padding: barPadding }}
      height={height}
      width={width}
      seriesArray={seriesArray}
      seriesLabels={seriesLabels}
      tooltip={tooltip}
      orientation={orientation}
      tooltipRenderer={tooltipRenderer}
      xLabelFormatter={!isVertical ? valueLabelFormatter : undefined}
      yLabelFormatter={isVertical ? valueLabelFormatter : undefined}
      colors={colors}
    />
  );
};

export default BarChart;
