import React, { SyntheticEvent, useState } from 'react';
import {
  Form,
  Header as BaseHeader,
  Search,
  SearchProps,
  SearchResultData,
  SearchResultProps,
} from 'semantic-ui-react';
import { useField } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';
import { unauthenticatedAPICall } from '../../auth/Auth';

const MIN_CHARACTERS = 5;
const Header = styled(BaseHeader)`
  &&&& {
    margin: 0px 0 20px;
  }
`;

interface Props {
  loc: string;
  noLabel?: boolean;
  errorMessagePlaceholder?: boolean;
}

const AddressQuestion = ({ loc, noLabel, errorMessagePlaceholder }: Props) => {
  const [session] = useState(uuidv4());
  const [autoComplete, setAutoComplete] = useState([]);
  const [field, meta, helpers] = useField({ name: loc });

  const runAutoComplete = async (address: string) => {
    try {
      const response = await unauthenticatedAPICall({
        method: 'GET',
        url: `/api/addresses?address=${encodeURIComponent(address)}&session=${session}`,
      });

      setAutoComplete(
        response.data.map((datum: any) => {
          return { title: datum.description };
        })
      );
    } catch (ex) {
      console.error(`Auto complete call failed with: ${ex}`);
    }
  };

  const updateAddress = (address?: string): void => {
    helpers.setValue(address);
    helpers.setTouched(true, false);
    if (address && address.length >= MIN_CHARACTERS) {
      runAutoComplete(address);
    }
  };

  return (
    <>
      {!noLabel && <Header as="h3">Please enter an address for your restaurant:</Header>}
      <Form.Field
        control={Search}
        placeholder={(!noLabel && 'Address') || (errorMessagePlaceholder && meta.touched && meta.error)}
        fluid
        minCharacters={MIN_CHARACTERS}
        value={field.value}
        onResultSelect={(_: SyntheticEvent, { result }: SearchResultData) => updateAddress(result.title)}
        onSearchChange={(_: SyntheticEvent, { value }: SearchProps) => updateAddress(value)}
        resultRenderer={({ title }: SearchResultProps) => <div>{title}</div>}
        results={autoComplete}
        error={
          meta.touched && meta.error
            ? errorMessagePlaceholder
              ? true
              : {
                  content: meta.error,
                  pointing: 'below',
                }
            : undefined
        }
      />
    </>
  );
};

export default AddressQuestion;
