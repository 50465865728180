import React, { useEffect } from 'react';
import _ from 'lodash';
import { Checkbox, Grid, Pagination, Search, SemanticWIDTHS, Table } from 'semantic-ui-react';
import TableData from './DataTable';
import { Header } from '../../styled/Typography';

type SortingOrders = 'asc' | 'desc' | 'none';

interface Column {
  name: string;
  sortOrder: SortingOrders;
  tooltip: string;
}

interface TableState {
  data: Array<any>;
  getData: () => Array<Array<any>>;
  columns: Array<Column>;
  filterKey: string;
  currentPage: number;
  resultsPerPage: number;
}

interface TableAction {
  column?: string;
  filterKey?: string;
  page?: number;
  resultsPerPage?: number;
  type:
    | 'CHANGE_SORT'
    | 'CHANGE_FILTER'
    | 'NEXT_PAGE'
    | 'PREVIOUS_PAGE'
    | 'CHANGE_PAGE'
    | 'CHANGE_RESULTS_PER_PAGE'
    | 'UPDATE_DATA';
}

interface Props {
  data: Array<TableData>;

  // The column which contains the ID of the object
  idColumn?: string;

  // The base path for detail routes of elements displayed in this table
  basePath?: string;

  // Define whether or not each row is clickable to open a detail page (using ID and Basepath for redirection)
  detailPage?: boolean;

  // Whether or not each row has a selection checkbox
  selectable?: boolean;
  selectedIndex?: string;

  // What to do on select change
  onSelectChange?: any;

  tableProps?: {
    size?: 'large' | 'small';
    compact?: boolean;
    fixed?: boolean;
    stackable?: boolean;
    celled?: boolean;
    basic?: boolean | 'very';
    selectable?: boolean;
    style?: any;
  };

  // Title to include for table
  title?: string;

  // Rows to be hidden
  hiddenRows?: string[];

  // Columns to be hidden
  hiddenCols?: string[];

  // Action react elements to be applied on each row
  rowActionButtonComponent?: (rowData: any) => any;

  onRowClick?: (rowData: any) => void;

  filterKey?: string;

  customFilters?: JSX.Element;

  selected?: Set<string>;
  selectionDisabled?: string;

  // Disable pagination and enable internal scrolling
  scrolling?: boolean;
  search?: boolean;
  resultsPerPage?: number;
  height?: number | string;

  rowActionWidth?: SemanticWIDTHS;
}

const DataTable: React.FC<Props> = ({
  data,
  idColumn,
  basePath,
  detailPage,
  selectable,
  selectedIndex,
  onSelectChange,
  tableProps,
  title,
  hiddenRows,
  hiddenCols = [],
  rowActionButtonComponent,
  filterKey,
  selected,
  selectionDisabled,
  scrolling,
  search,
  resultsPerPage = 5,
  height,
  customFilters,
  onRowClick = (rowData: any) => {},
  rowActionWidth = 5,
}: Props) => {
  const hiddenSet = new Set(hiddenRows);

  const nextOrder = (sortOrder: SortingOrders): SortingOrders => {
    if (sortOrder === 'none') return 'asc';
    else if (sortOrder === 'asc') return 'desc';
    else return 'none';
  };

  const tableReducer = (state: TableState, action: TableAction): TableState => {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.columns.map((col) => col.name).includes(action?.column ?? '')) {
          const curCol = state.columns.find((col) => col.name === action.column);
          const sortOrder = nextOrder(curCol?.sortOrder ?? 'none');
          return {
            ...state,
            data:
              sortOrder === 'none'
                ? state.data
                : _.orderBy(
                    state.data,
                    [
                      (i) =>
                        // This needs to handle strings case-insensitively to make sense
                        typeof i[action.column!] === 'string' ? i[action.column!].toLowerCase() : i[action.column!],
                    ],
                    [sortOrder]
                  ),
            columns: state.columns.map(
              (col): Column => ({
                name: col.name,
                sortOrder: col.name === action.column ? sortOrder : 'none',
                tooltip: col.tooltip,
              })
            ),
          };
        } else {
          console.error('Attempted to change sort on unknown column');
          return state;
        }
      case 'CHANGE_FILTER':
        // TODO: we need to figure out how to best handle being orphaned when you are on a later page before filtering
        return {
          ...state,
          filterKey: action.filterKey ?? '',
          getData: () =>
            _.chunk(
              _.filter(state.data, (elem: TableData) =>
                _.reduce(
                  Object.values(elem),
                  (acc, value) => {
                    return (
                      value
                        .toString()
                        .toLowerCase()
                        .includes(action.filterKey?.toLowerCase() ?? '') || acc
                    );
                  },
                  false as boolean
                )
              ),
              scrolling ? state.data.length : state.resultsPerPage
            ),
        };
      case 'CHANGE_PAGE':
        return {
          ...state,
          currentPage: action.page!,
        };
      case 'CHANGE_RESULTS_PER_PAGE':
        return {
          ...state,
          resultsPerPage: _.min([1, action.resultsPerPage]) ?? resultsPerPage,
        };
      case 'UPDATE_DATA':
        // TODO: Need to maintain pagination and sorting somehow.
        return {
          ...state,
          data,
          getData: () => (scrolling ? [data] : _.chunk(data, resultsPerPage)),
        };
      default:
        throw new Error();
    }
  };

  const initialState = {
    data,
    getData: () => (scrolling ? [data] : _.chunk(data, resultsPerPage)),
    columns: data.length
      ? Object.keys(data[0])
          .filter((key) => !hiddenCols.includes(key))
          .map((key): Column => ({ name: key, sortOrder: 'none', tooltip: '' }))
      : [],
    filterKey: '',
    currentPage: 1,
    resultsPerPage: resultsPerPage,
  };

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  useEffect(() => {
    dispatch({ type: 'UPDATE_DATA' });
  }, [data]);

  const headers = state.columns
    .filter((col) => col.name !== idColumn && !hiddenSet.has(col.name))
    .map((col) => (
      <Table.HeaderCell
        style={{ borderStyle: 'hidden hidden solid hidden', borderWidth: '2px' }}
        key={col.name}
        sorted={col.sortOrder === 'asc' ? 'ascending' : col.sortOrder === 'desc' ? 'descending' : undefined}
        onClick={() => {
          // Note: This is needed because we always need to update getData, it also makes sense if you think hard enough, I promise.
          dispatch({ type: 'CHANGE_SORT', column: col.name });
          dispatch({ type: 'CHANGE_FILTER', filterKey: state.filterKey });
        }}
      >
        <span style={{ textTransform: 'capitalize' }}>{col.name}</span>
      </Table.HeaderCell>
    ));

  const tableBody = state.getData()[state.currentPage - 1]?.map((row: TableData, index) => (
    <Table.Row
      style={scrolling ? { display: 'table', width: '100%', tableLayout: 'fixed' } : {}}
      key={index.toString()}
      onClick={() => {
        onRowClick(row);
      }}
    >
      {selectable && onSelectChange && selected && selectedIndex && selectionDisabled && (
        <Table.Cell collapsing>
          <Checkbox
            disabled={row[selectionDisabled] as boolean}
            onChange={onSelectChange(row)}
            checked={selected?.has(row[selectedIndex!] as string)}
          />
        </Table.Cell>
      )}
      {Object.entries(row)
        .filter((entry) => !hiddenCols.includes(entry[0]))
        .filter((entry) => entry[0] !== idColumn && !hiddenSet.has(entry[0]))
        .map((entry) => (
          <Table.Cell singleLine key={index + entry[0].toString()}>
            {entry[1]}
          </Table.Cell>
        ))}
      {rowActionButtonComponent && (
        <Table.Cell key={index + 'action_button'} width={rowActionWidth} content={rowActionButtonComponent(row)} />
      )}
    </Table.Row>
  ));

  return (
    <>
      {(((title || search) && data.length) || customFilters) && (
        <Grid padded="vertically" verticalAlign="middle" style={{ minHeight: '66px' }}>
          {(title || search) && data.length ? (
            <Grid.Row>
              {title && (
                <Grid.Column width={search ? '11' : '16'}>
                  <Header as="h2">{title}</Header>
                </Grid.Column>
              )}
              {search && (
                <Grid.Column width={title ? '5' : '16'}>
                  <Search
                    input={{
                      fluid: true,
                    }}
                    open={false}
                    placeholder="Search..."
                    onSearchChange={(_, { value }) => dispatch({ type: 'CHANGE_FILTER', filterKey: value ?? '' })}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          ) : (
            <></>
          )}
          {customFilters && (
            <Grid.Row>
              <Grid.Column width="16">{customFilters}</Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}
      {data.length ? (
        <div style={scrolling ? { height: 'inherit' } : {}} className="container__table">
          <Table sortable selectable={detailPage} style={scrolling ? { height: 'inherit' } : {}} {...tableProps}>
            <Table.Header
              style={scrolling ? { display: 'table', width: '100%', tableLayout: 'fixed', paddingRight: '10px' } : {}}
            >
              <Table.Row>
                {selectable && <Table.HeaderCell />}
                {headers}
                {rowActionButtonComponent && (
                  <Table.HeaderCell
                    width={rowActionWidth}
                    style={{ borderStyle: 'hidden hidden solid hidden', borderWidth: '2px' }}
                  />
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body
              style={
                scrolling
                  ? { display: 'block', maxHeight: height ? height : `${3.07 * resultsPerPage}em`, overflow: 'auto' }
                  : {}
              }
            >
              {tableBody}
            </Table.Body>
          </Table>
          {!scrolling && (
            <Pagination
              defaultActivePage={1}
              totalPages={state.getData().length}
              onPageChange={(event, data) => dispatch({ type: 'CHANGE_PAGE', page: data.activePage as number })}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DataTable;
