import React, { Component } from 'react';
import { Grid, Header, Segment, Statistic } from 'semantic-ui-react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { CountyMonthlyUnemployment, CountyUnemployment } from '../../types';

interface Props {
  countyUnemployment: CountyUnemployment;
}

interface ChartData {
  name: string;
  rate: number;
}

function propsToData(data: CountyMonthlyUnemployment[]): ChartData[] {
  return data.map((point) => ({
    name: point.monthYear.replace('-', ' '),
    rate: point.rate,
  }));
}

function getUnemployedLabel(datum: CountyMonthlyUnemployment): string {
  return datum.unemployed + ' / ' + datum.civilianLaborForce;
}

class CountyUnemploymentSegment extends Component<Props, {}> {
  constructor(props: Readonly<Props>) {
    super(props);
  }

  render(): JSX.Element {
    return (
      <Segment>
        <Header as="h3">County Unemployment</Header>
        {this.props.countyUnemployment.error ? (
          <p>this.props.countyUnemployment.error</p>
        ) : (
          <div>
            <Grid centered padded="vertically">
              <LineChart
                width={500}
                height={200}
                data={propsToData(this.props.countyUnemployment.data)}
                margin={{ top: 30, right: 30 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[0, 8]} />
                <Line dataKey="rate" fill="#8884d8" />
              </LineChart>
              <br />
            </Grid>
            <Grid centered>
              <Statistic
                label="Unemployed"
                size="tiny"
                value={getUnemployedLabel(
                  this.props.countyUnemployment.data[this.props.countyUnemployment.data.length - 1]
                )}
              />
            </Grid>
          </div>
        )}
      </Segment>
    );
  }
}

export default CountyUnemploymentSegment;
