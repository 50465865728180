import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import chroma from 'chroma-js';

export interface BrandStateStuff {
  name?: string;
  itemColor?: string;
}

export type StuffState = {
  currentFold: number;
  borneLocation: boolean;
  userLocation: boolean;
  brandSearchKey: string;
  satelliteEnabled: boolean;
  visibleMapLayers: Set<string>;
  borneStudyAreas: boolean;
  customStudyAreas: boolean;
  brands: Array<BrandStateStuff>;
  conceptId: number;
  amenitiyCategorySet: Set<string>;
  studyAreaId: string | undefined;
  newUI: boolean;
  showConceptId: boolean;
};

export type BooleanState = { state: boolean };

export type StringState = { state: string };

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    currentMarkets: [''],
  },
  reducers: {
    setCurrentMarket: (state, action: PayloadAction<Array<string>>) => {
      state.currentMarkets = action.payload;
      return state;
    },
  },
});

const initialState: StuffState = {
  currentFold: 2,
  borneLocation: true,
  userLocation: true,
  brandSearchKey: '',
  satelliteEnabled: false,
  visibleMapLayers: new Set(['studyAreas']),
  borneStudyAreas: true,
  customStudyAreas: true,
  brands: [],
  conceptId: 0,
  amenitiyCategorySet: new Set(),
  studyAreaId: undefined,
  newUI: true,
  showConceptId: false,
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    changeCurrentFold: (state, action: PayloadAction<number>) => {
      state.currentFold = action.payload;
      return state;
    },
    changeBorneLocationShow: (state, action: PayloadAction<boolean>) => {
      state.borneLocation = action.payload;
      return state;
    },
    changeUserLocationShow: (state, action: PayloadAction<boolean>) => {
      state.userLocation = action.payload;
      return state;
    },
    changeBrandKey: (state, action: PayloadAction<string>) => {
      state.brandSearchKey = action.payload;
      return state;
    },
    changeSatelliteEnabled: (state, action: PayloadAction<boolean>) => {
      state.satelliteEnabled = action.payload;
      return state;
    },
    changeBorneStudyAreas: (state, action: PayloadAction<boolean>) => {
      state.borneStudyAreas = action.payload;
      return state;
    },
    toggleShowCustomStudyAreas: (state, action: PayloadAction<boolean>) => {
      state.customStudyAreas = action.payload;
      return state;
    },
    toggleVisibleMapLayer: (state, action: PayloadAction<{ set: Set<string>; type: string; value: string }>) => {
      const selectionVisibleReducer = (
        selectionSet: Set<string>,
        action: PayloadAction<{ type: string; value: string }>
      ) => {
        const newSelectionSet = new Set(selectionSet);
        switch (action.payload.type) {
          case 'add':
            newSelectionSet.add(action.payload.value);
            break;
          case 'remove':
            newSelectionSet.delete(action.payload.value);
            break;
          case 'toggle':
            if (selectionSet.has(action.payload.value)) {
              newSelectionSet.delete(action.payload.value);
            } else {
              newSelectionSet.add(action.payload.value);
            }
            break;
          case 'clear':
            newSelectionSet.clear();
            break;
          default:
            throw Error('Invalid selection action on useSelectionSet');
        }
        return newSelectionSet;
      };
      state.visibleMapLayers = selectionVisibleReducer(action.payload.set, action);
      return state;
    },
    changeAmenityCategorySelection: (
      state,
      action: PayloadAction<{ set: Set<string>; type: string; value: string }>
    ) => {
      const selectionReducer = (selectionSet: Set<string>, action: PayloadAction<{ type: string; value: string }>) => {
        const newSelectionSet = new Set(selectionSet);
        switch (action.payload.type) {
          case 'add':
            newSelectionSet.add(action.payload.value);
            break;
          case 'remove':
            newSelectionSet.delete(action.payload.value);
            break;
          case 'toggle':
            if (selectionSet.has(action.payload.value)) {
              newSelectionSet.delete(action.payload.value);
            } else {
              newSelectionSet.add(action.payload.value);
            }
            break;
          case 'clear':
            newSelectionSet.clear();
            break;
          default:
            throw Error('Invalid selection action on useSelectionSet');
        }
        return newSelectionSet;
      };
      state.amenitiyCategorySet = selectionReducer(action.payload.set, action);
      return state;
    },
    changeSelectedBrand: (state, action: PayloadAction<string>) => {
      const allPossibleColors = chroma.scale('Set1').colors(16);
      let setColor;
      const temp = state.brands.map((x) => x);
      if (temp.some((o) => o.name == action.payload)) {
        state.brands = temp.filter((x) => x.name !== action.payload);
        return state;
      } else {
        setColor = allPossibleColors.filter(
          (color) =>
            !new Set(
              temp.map((data) => {
                return data.itemColor;
              })
            )
        )[0];
        temp.push({ name: action.payload, itemColor: setColor });
        state.brands = temp;
        return state;
      }
      // if (state.brands.has(action.payload)) {
      //   temp.delete(action.payload);
      //   state.brands = new Map(temp);
      //   return state;
      // } else {
      //   color = allPossibleColors.filter((color) => !new Set(temp.values()).has(color))[0];
      //   temp.set(action.payload, color);
      //   state.brands = new Map(temp);
      //   return state;
      // }
    },
    selectConceptId: (state, action: PayloadAction<number>) => {
      state.conceptId = action.payload;
      return state;
    },
    selectStudyAreId: (state, action: PayloadAction<string | undefined>) => {
      state.studyAreaId = action.payload;
      return state;
    },
    toggleNewUi: (state, action: PayloadAction<boolean>) => {
      state.newUI = action.payload;
      return state;
    },
    toggleConceptId: (state, action: PayloadAction<boolean>) => {
      state.showConceptId = action.payload;
      return state;
    },
  },
});

export const mainActions = mainSlice.actions;

export const commonActions = commonSlice.actions;
