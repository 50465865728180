import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Menu, Dropdown, Button, Grid, Loader, Dimmer, Container } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Auth from '../../../auth/Auth';
import withSideMenu from '../../generic/MenuPage';

import { ReportRowLayout } from '../../generic/ReportLayout';
import {
  MarketComparisonDataSegment,
  MarketComparisonDescriptionSegment,
} from './MarketComparisonSections/MarketComparisonSection';

const Date = styled.div`
  text-transform: uppercase;
`;

const Brand = styled.div`
  font-size: 1.5em;
  font-weight: bold;
`;

interface Params {
  reportId: string;
}

interface Props {
  auth: Auth;
}

const MarketComparisonPage: React.FC<Props> = (props: Props) => {
  const { auth } = props;
  const { reportId } = useParams<keyof Params>() as Params;
  const navigate = useNavigate();

  const [report, setReport] = useState<any>();
  const [selectedMarketGoTo, setSelectedMarketGoTo] = useState<string>('');

  // Market Selection
  const marketSelectionReducer = (selectedMarkets: Set<string>, event: { action: string; marketName: string }) => {
    switch (event.action) {
      case 'add':
        selectedMarkets.add(event.marketName);
        break;
      case 'remove':
        selectedMarkets.delete(event.marketName);
        break;
      default:
        throw Error(`Invalid event action: ${event.action}`);
    }
    return new Set(selectedMarkets);
  };
  const [selectedMarkets, dispatchMarketSelection] = useReducer(marketSelectionReducer, new Set([]));

  useEffect(() => {
    (async () => {
      try {
        const response = await auth.authenticatedAPICall({
          method: 'GET',
          url: `/api/reports/${reportId}/`,
        });

        setReport(response.data);
        dispatchMarketSelection({ action: 'add', marketName: response.data.data.market_detail[0].post_office_city });
        if (response.data.data.market_detail[1]) {
          dispatchMarketSelection({ action: 'add', marketName: response.data.data.market_detail[1].post_office_city });
        }
      } catch (err) {
        console.log(err);
        window.alert('An error occurred retrieving your report');
      }
    })();
  }, []);

  return report ? (
    <>
      <Menu fixed="bottom">
        <Menu.Item as="div">
          <Dropdown
            placeholder="Go to market detail"
            search
            selection
            options={report.data.market_detail.map((market: any, indx: number) => ({
              text: market.post_office_city,
              value: indx,
              key: indx,
            }))}
            onChange={(_, data) => setSelectedMarketGoTo(data.value as string)}
          />
          <Button
            attached="right"
            color="green"
            onClick={() => navigate(`/scaleReport/${report.id}/${selectedMarketGoTo}`)}
          >
            Go
          </Button>
        </Menu.Item>

        <Menu.Item as="a" header position="right">
          <Menu.Header>
            <Brand>{report.concept.name || "Carl's Jr."}</Brand>
            <Date>{moment(report.concept.updated).format('MMM D, YYYY')}</Date>
          </Menu.Header>
        </Menu.Item>
      </Menu>
      <Grid style={{ paddingBottom: '10em' }}>
        <ReportRowLayout
          DescriptionComponent={
            <MarketComparisonDescriptionSegment
              marketOptions={report?.data.market_detail.map((market: any) => market.post_office_city)}
              selectedMarkets={selectedMarkets}
              dispatchMarketSelection={dispatchMarketSelection}
            />
          }
          DataComponent={
            <MarketComparisonDataSegment
              marketOptions={report?.data.market_detail.map((market: any) => market.post_office_city)}
              selectedMarkets={selectedMarkets}
              report={report}
            />
          }
        />
      </Grid>
    </>
  ) : (
    <Container fluid style={{ paddingTop: '50vh' }}>
      <Dimmer active inverted>
        <Loader size="big">Loading Report</Loader>
      </Dimmer>
    </Container>
  );
};

export default withSideMenu(MarketComparisonPage, { activeItem: 'reports' });
