import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Divider } from 'semantic-ui-react';
import OneTimePaymentForm from '../payments/OneTimePaymentForm';
import FormPage from './FormPage';
import PriceDisclaimer from './PriceDisclaimer';

const REPORT_PRICE = '749.95';

const PurchaseForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <FormPage currentStep="payment">
      <div className="tw-flex">
        <div className="tw-w-[40%]">
          <header className="tw-text-3xl tw-text-borneblue-darkest tw-mb-4">Payment Information</header>
          <PriceDisclaimer />
          <div className="tw-text-bornegray-darkest tw-mb-12">
            Supply your payment information, and agree to the terms of service.
          </div>
          <a
            href="#"
            onClick={(e) => {
              navigate(-1);
              e.preventDefault();
            }}
            className="tw-mt-12 tw-pt-[12px] tw-pb-[10px] tw-px-[15px] tw-border tw-border-gray-300 tw-uppercase tw-text-[0.8em] tw-text-[#707070] hover:tw-bg-[#bccd19] hover:tw-text-[#2f2e2b] tw-transition-colors tw-duration-300"
          >
            ← Previous Step
          </a>
        </div>
        <div className="tw-w-[60%] tw-pl-[3em] tw-pr-[6em]">
          <br />
          <div className="tw-flex tw-mb-2">
            <div className="tw-grow tw-text-bornegray-darkest tw-text-lg tw-uppercase">
              Borne Report<sup>&#174;</sup> for <b>{state.reportRequest.address}</b>
            </div>
            <div className="tw-grow-0 tw-font-bold">${REPORT_PRICE}</div>
          </div>
          <Divider section />
          <OneTimePaymentForm
            price={REPORT_PRICE}
            reportRequestId={state.reportRequest.id}
            address={state.reportRequest.address}
            orderNumber={state.reportRequest.user_order_number}
          />
        </div>
      </div>
    </FormPage>
  );
};

export default PurchaseForm;
