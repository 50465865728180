import React, { useEffect } from 'react';
import AccessMap from '@bornellc/bach/AccessMap';
import { Header } from 'semantic-ui-react';

interface DescriptionProps {
  auth: any;
  setAccessMap: any;
  post_office_city: string;
}

export const AccessMapDescriptionSegment: React.FC<DescriptionProps> = ({ auth, setAccessMap, post_office_city }) => {
  useEffect(() => {
    (async () => {
      try {
        const response = await auth.authenticatedAPICall({
          method: 'GET',
          url: `/api/accessmap/`,
          params: { post_office_city: post_office_city },
        });

        setAccessMap(response.data);
        console.log(response.data);
      } catch (err) {
        console.log(err);
        window.alert('An error occurred retrieving your report');
      }
    })();
  }, []);
  return (
    <>
      <Header as="h1">Access</Header>
      <p>Understand market gaps by looking at how long it takes to drive to the nearest restaurant.</p>
    </>
  );
};

interface DataProps {
  accessMap: any;
  mapData: {
    center: [number, number];
    zoom: number;
  };
}

export const AccessMapDataSegment: React.FC<DataProps> = (props: DataProps) => {
  return props.accessMap ? (
    <AccessMap
      geojson={JSON.parse(props.accessMap)}
      width={650}
      zoom={props.mapData.zoom - 1}
      center={props.mapData.center}
    />
  ) : (
    <></>
  );
};
