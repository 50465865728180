import { Header, Description, ChartHeader } from '../../styled/Typography';
import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import Auth from '../../../auth/Auth';
import { useStudyAreaPricingSummary } from '../../../api/requests';
import BarChart from '@bornellc/bach/BarChart';
import { buildSeries } from '../../../reactHelpers';
import { formatCurrency, formatNumber } from '../../../helpers';

export const StudyAreaDetailPricingInsightsDescription: React.FC = ({}) => {
  return (
    <>
      <Header as="h2">Pricing Insights</Header>
      <Description as="p">
        View revenue and check average distribution across this market and similar markets.
      </Description>
    </>
  );
};

interface DataProps {
  studyAreaId: string;
  auth: Auth;
}

export const StudyAreaDetailPricingInsightsData: React.FC<DataProps> = ({ studyAreaId, auth }) => {
  const studyAreaPricingSummaryResponse = useStudyAreaPricingSummary(auth, studyAreaId);

  return studyAreaPricingSummaryResponse.isSuccess ? (
    <Grid attached="top" textAlign="center" columns="equal" padded="horizontally">
      <Grid.Row>
        <Grid.Column>
          <ChartHeader as="h3">Revenue Distribution</ChartHeader>
          <BarChart
            orientation="v"
            x="key"
            y="value"
            grid="h"
            data={buildSeries(studyAreaPricingSummaryResponse.data, 'rev_', [
              '> 1M',
              '1-2M',
              '2-3M',
              '3-4M',
              '4-5M',
              '> 5M',
            ]).reverse()}
            valueLabelFormatter={formatNumber}
            chartOptions={{ barPadding: 0.2 }}
            dataKey="rev_bar_chart"
            height={300}
            role="chart"
            normalize
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ChartHeader as="h3">Check Avg. by Meal Period</ChartHeader>
          <BarChart
            orientation="h"
            x="key"
            y="value"
            grid="h"
            data={buildSeries(studyAreaPricingSummaryResponse.data, 'mp_check_average_', [
              'Breakfast',
              'Lunch',
              'Dinner',
              'Late Night',
            ])}
            valueLabelFormatter={(datum) => formatCurrency(datum, 0)}
            tooltip
            tooltipRenderer={(_, datum) => <p>Check avg.: {formatCurrency(datum as number)}</p>}
            dataKey="mp_ca_bar_chart"
            height={300}
            role="chart"
          />
        </Grid.Column>
        <Grid.Column>
          <ChartHeader as="h3">Check Avg. by Service Type</ChartHeader>
          <BarChart
            orientation="h"
            x="key"
            y="value"
            grid="h"
            data={buildSeries(studyAreaPricingSummaryResponse.data, 'sq_check_average_', [
              'Quick Serve',
              'Fast Casual',
              'Casual Dining',
            ])}
            valueLabelFormatter={(datum) => formatCurrency(datum, 0)}
            tooltip
            tooltipRenderer={(_, datum) => <p>Check avg.: {formatCurrency(datum as number, 2)}</p>}
            dataKey="sq_ca_bar_chart"
            height={300}
            role="chart"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    <div className="tw-min-h-[5rem]">
      <Loader active size="massive" />
    </div>
  );
};
