import React from 'react';
import cuisines, { yelpCuisines } from '../../data/cuisines';
import { WrappedDropdownField } from './FormikUtil';

interface Props {
  loc: string;
  useFormLabel?: boolean;
  useYelp?: boolean;
}

const CuisineQuestion = ({ loc, useFormLabel, useYelp }: Props) => {
  return (
    <WrappedDropdownField
      label="Cuisine"
      name={loc}
      options={useYelp ? yelpCuisines : cuisines}
      placeholder="Select Cuisine"
      fluid
      search
      selection
      useFormLabel={useFormLabel}
    />
  );
};

export default CuisineQuestion;
