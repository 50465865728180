import React from 'react';
import { WrappedDropdownField } from './FormikUtil';

interface Props {
  loc: string;
  label: string;
}

const RestaurantCategoryQuestion = ({ loc, label }: Props) => {
  return (
    <WrappedDropdownField
      label={label}
      name={loc}
      options={[
        { text: 'Bar', value: 'bar' },
        { text: 'Casual Dining', value: 'casual_dining' },
        { text: 'Family Restaurant', value: 'family_restaurant' },
        { text: 'Fast Casual', value: 'fast_casual' },
        { text: 'Fine Dining', value: 'fine_dining' },
        { text: 'Pizza', value: 'pizza' },
        { text: 'Quick Serve', value: 'quick_serve' },
        { text: 'Sports Bar', value: 'sports_bar' },
      ]}
      placeholder="Select Restaurant Category"
      fluid
      search
      selection
    />
  );
};

export default RestaurantCategoryQuestion;
