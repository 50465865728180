import React from 'react';

export default (
  <>
    <p>
      This Software Services Agreement ("Agreement") is entered into between Borne, LLC, a California Limited Liability
      Borne ("Borne"), and the customer ("Customer") upon digital completion and submission by the Customer of the
      appropriate form requesting setup of the Services for Customer. The date of submission is the Effective Date of
      the Agreement.
    </p>
    <p>
      THIS IS A LEGAL AND ENFORCEABLE CONTRACT BETWEEN BORNE AND CUSTOMER. CUSTOMER IS RESPONSIBLE FOR CAREFULLY READING
      ALL TERMS AND CONDITIONS OF THIS AGREEMENT BEFORE SIGNING AN ORDER FORM, CLICKING "ACCEPT," OR ACCESSING OR USING
      ANY BORNE SERVICES. BY SIGNING AN ORDER FORM, OR ACCESSING OR USING ANY BORNE SERVICES, CUSTOMER CONFIRMS THAT
      CUSTOMER HAS ACCESSED ONLINE AND/OR BEEN PROVIDED A COPY OF THIS AGREEMENT AND HAS READ AND ACCEPTS THIS AGREEMENT
      IN ITS ENTIRETY. NOTWITHSTANDING ANY DIFFERENT OR ADDITIONAL TERMS CUSTOMER MAY REFERENCE OR PROVIDE, BORNE'S
      OFFER OR ACCEPTANCE TO ENTER INTO AN AGREEMENT WITH CUSTOMER WITH RESPECT TO ANY BORNE SERVICES IS EXPRESSLY
      LIMITED TO THE TERMS OF THIS AGREEMENT AND CONDITIONED ON CUSTOMER'S CONSENT TO THIS AGREEMENT.
    </p>
    <p>
      <b>1. SOFTWARE SERVICES</b>
    </p>
    <p>
      1.1 <u>Services</u>. Subject to the terms of this Agreement and the applicable Fees, Borne will use commercially
      reasonable efforts to provide Customer a limited license to access and use Borne's proprietary restaurant concept
      location intelligence services (the "Services"). The Pricing Terms of the Services are further described in
      Exhibit A, attached hereto, and the Services shall be hosted by or on behalf of Borne, in accordance with the
      terms of this Agreement. Borne shall provide Customer with any additional specific services, that may be set forth
      in Exhibit A.
    </p>
    <p>
      1.2 <u>Service Access</u>. Subject to the terms and conditions of this Agreement, Borne hereby grants to Customer
      the non-exclusive, non-transferable (except as specified in this Agreement), royalty-free right to access and use
      the Services in accordance with the terms of this Agreement and all applicable order form(s) and/or statements of
      work (e.g., any usage volume terms and limitations to particular Customer legal entities, business units, projects
      and/or services set forth therein). If Customer's use of the Services exceeds the entitlements applicable to its
      then subscription, Borne may (i) require Customer to pay any additional Fees associated with continuing the
      Services; or (ii) terminate the Agreement for cause in accordance with Section 6 (Termination).
    </p>
    <p>
      1.3 <u>Reservation of Rights</u>. Borne reserves the right to refuse registration of or cancel passwords it deems
      inappropriate.
    </p>
    <p>
      <b>2. OWNERSHIP OF SERVICES, PRIVACY, SECURITY, AND CUSTOMER DATA</b>
    </p>
    <p>
      2.1 <u>Ownership of the Services</u>. The Services are the property of Borne, and is protected by copyright,
      patent, trade secret and other intellectual property laws. Borne and its licensors retain any and all rights,
      title and interest in and to the Services (including, without limitation, all intellectual property rights),
      including all copies, modifications, extensions and derivative works thereof. Customer's right to use the Services
      are limited to the rights expressly granted in this Agreement. All rights not expressly granted to Customer are
      reserved and retained by Borne and its licensors.
    </p>
    <p>
      2.2 <u>Customer Data and Storage</u>. As between Customer and Borne, Customer shall own all right, title and
      interest in and to the Customer data. Any data that is based on or derived from the Customer data and provided to
      Customer as part of the Services provided by Borne shall be owned by Borne and retain all right, title and
      interest in and to the Services and software, all improvements, enhancements or modifications thereto, any
      software, applications, inventions or other technology developed in connection with implementation services or
      support, and all intellectual property rights related to any of the foregoing. Borne retains any and all rights,
      title and interest in and to Customer communications with Borne, including all copies, modifications, extensions
      and derivative works thereof. Borne will store Customer data and inputs, including restaurant concept details with
      complete edit and modification history in order to test, build and improve Borne's Services and potential future
      services that Borne may offer to Customers.
    </p>
    <p>
      2.3 <u>Data Sharing</u>. Borne will not disclose, distribute, sell, share, rent or otherwise transfer sensitive
      Customer personal information to any third-party, except as expressly required to perform our obligations in this
      Agreement. We may share and disclose Customer information as described at the time information is collected or as
      follows:
    </p>
    <p>
      (a) To Perform Services. We may disclose Customer personal information to third parties in order to perform the
      Services requested or functions initiated by you, but only with your consent. In addition, we may disclose
      Customer personal information in order to identify a user in connection with communications sent through the
      Services;
    </p>
    <p>
      (b) With Third Party Service Providers Performing Services on Our Behalf. We share information, not including
      sensitive personal information, with our service providers to perform the functions for which we engage them (such
      as hosting and data analyses);
    </p>
    <p>
      (c) For Legal Purposes. We also may share information that we collect from users, as needed, to enforce our
      rights, protect our property or protect the rights, property or safety of others, or as needed to support external
      auditing, compliance and corporate governance functions. We will disclose Customer personal information as we deem
      necessary to respond to a subpoena, regulation, binding order of a data protection agency, legal process,
      governmental request or other legal or regulatory process. We may also share Customer personal information as
      required to pursue available remedies or limit damages we may sustain; and
    </p>
    <p>
      (d) Corporate Changes. We may transfer information, including your customer personal information, in connection
      with a merger, sale, acquisition or other change of ownership or control by or of us or any affiliated company (in
      each case whether in whole or in part). When one of these events occurs, we will use reasonable efforts to notify
      you.
    </p>
    <p>
      2.4 <u>Compliance with Privacy Laws</u>. Borne and Customer will at all times comply with all privacy laws. Borne
      will only use Customer data in the manner permitted by this Agreement and all privacy laws; provided, however,
      that:
    </p>
    <p>
      (a) Customer hereby authorizes Borne and its affiliates to use Customer data solely to provide the Services to
      Customer and otherwise meet Borne's obligations under this Agreement, including engaging sub processors and
      contractors to provide the Services to Customer in accordance with this Agreement (Borne remains liable for such
      Affiliates, sub processors and contractors' compliance with this Agreement);
    </p>
    <p>
      (b) Customer hereby warrants that, to the extent required by privacy laws applicable to Borne's provision of the
      Services to Customer and the parties' respective obligations under this Agreement, Customer has provided all
      proper notices under privacy laws and obtained from its personnel, customers and all legally required third
      parties all rights and permissions legally required in order to grant the authorizations and to use the Services
      in the manner contemplated by this Agreement;
    </p>
    <p>
      (c) As applicable, Borne will serve as Customer's Service Provider (as such term is defined under California
      Consumer Privacy Act) solely with respect to processing of personal data, and as such, Borne will not (i) sell
      personal data; or (ii) collect, retain, use or disclose personal data for any purpose other than to provide the
      Services and otherwise perform its obligations to Customer as set forth in the Agreement. Borne acknowledges that
      it does not receive personal data in exchange for providing the Services to Customer.
    </p>
    <p>
      Notwithstanding anything to the contrary in this Agreement, if (i) complying with any privacy laws would
      materially change Borne's costs or risks in providing the Services (including, without limitation, by requiring
      that any Borne data centers be located outside the U.S., or requiring Borne to operate in violation of any U.S.
      laws), and (ii) after the Parties' respective legal counsel meet to discuss the problem, Borne provides written
      notice that it does not wish to incur such costs or risks, then each Party will have the right to terminate this
      Agreement (including all order forms) in writing within thirty (30) days after Borne provides such written notice
      &ndash; in which case the termination will be effective thirty (30) days thereafter. In the event of such a
      termination, Customer's sole right and Borne's sole obligation (except to the extent otherwise expressly stated in
      this Agreement) will be for Borne to promptly refund to Customer, any Fees paid under all order forms and/or
      statements of work then in effect that are unused as of the termination effective date.
    </p>
    <p>
      2.5 <u>Hosting</u>. Unless otherwise mutually agreed to in writing by the Parties, Customer's data will be hosted
      in Borne's United States Data Center(s). Customer's data may be accessed remotely for support and technical
      operations purposes from outside of the United States.
    </p>
    <p>
      2.6 <u>Security of the Service, PCI DSS Compliance and Personal Data Breach</u>. Throughout the term of this
      Agreement, Borne will maintain a data security program for the Services that will:
    </p>
    <p>
      (a) include reasonable administrative, physical, technical, organizational and other security measures to protect
      against unauthorized access to, or destruction, loss, unavailability or alteration of, any Customer data processed
      or stored by Borne;
    </p>
    <p>(b) comply with the PCI DSS (or prevailing successor industry standard, if replaced); and</p>
    <p>(c) include reasonable and appropriate controls.</p>
    <p>
      Borne will be responsible for unauthorized access and damage to, and for unauthorized deletion, destruction and
      loss of, Customer data solely to the extent arising from Borne's breach of its obligations under this Agreement.
    </p>
    <p>
      2.7 <u>Data Retention</u>. Regardless of the basis for expiration or termination of this Agreement, Borne will not
      be obligated to retain any Customer data for longer than thirty (30) days after any such expiration or
      termination, unless otherwise agreed in advance by the Parties in writing.
    </p>
    <p>
      2.8 <u>Business Continuity &amp; Disaster Recovery</u>. Borne will implement and maintain throughout the term of
      this Agreement reasonable business continuity and disaster recovery plans to help ensure availability of the
      Customer data following any significant interruption or failure of critical business processes or systems
      affecting the Service.
    </p>
    <p>
      2.9 <u>Deletion of Financial Account Data &amp; Other Customer Data</u>. Customer will need assistance from Borne,
      however, to export credit card account information or other Customer data (e.g., ACH direct debit account
      information) that is subject to the PCI DSS or other financial industry rules or regulations regarding account
      information (collectively, "Financial Account Data"). Borne will provide Financial Account Data only to recipients
      that have the required certifications and qualifications (e.g., certification to receive information that is
      subject to the PCI DSS).
    </p>
    <p>
      2.10 <u>Support &amp; Service Level Agreement</u>. Borne may provide technical support for the Services if
      detailed in Exhibit A, as long as Customer is entitled to receive support under the applicable order form, and/or
      ant statements of work and this Agreement.
    </p>
    <p>
      <b>3. RESTRICTIONS AND RESPONSIBILITIES</b>
    </p>
    <p>
      3.1 <u>General Restrictions</u>. Customer must not, without Borne's prior written consent, cause or permit the:
    </p>
    <p>
      (a) use, copying, modification, rental, lease, sublease, sublicense, transfer or other commercial exploitation of,
      or other third-party access to, any element of the Services, except to the extent expressly permitted by this
      Agreement;
    </p>
    <p>(b) creation of any modifications or derivative works of the Services;</p>
    <p>(c) reverse engineering of the Services;</p>
    <p>(d) gaining of unauthorized access to the Services or its related systems or networks;</p>
    <p>
      (e) interference with or disruption of the integrity or performance of the Services or the data contained therein
      (for example, via unauthorized benchmark testing or penetration testing);
    </p>
    <p>
      (f) sending, storing or use of any Customer Data in connection with the Services for which Customer lacks
      sufficient ownership or other rights; or
    </p>
    <p>
      (g) sending, storing or use of any infringing, obscene, threatening, libelous or otherwise unlawful or tortious
      material in connection with the Services. Customer also must use reasonable security measures to access the
      Services, and must not knowingly send, store or use any material containing any viruses, worms, Trojan horses or
      other malicious or harmful computer code, files, scripts, agents or programs in connection with the Services.
      Borne also reserves the right to take all steps reasonably necessary to protect the security, integrity or
      availability of the Services (e.g., by temporarily suspending access by anyone who introduces malicious code or
      attempts to do so), notwithstanding anything to the contrary in this Agreement.
    </p>
    <p>
      3.2 <u>Export Restrictions</u>. Further, Customer may not remove or export from the United States or allow the
      export or re-export of the Services, Software or anything related thereto, or any direct product thereof in
      violation of any restrictions, laws or regulations of the United States Department of Commerce, the United States
      Department of Treasury Office of Foreign Assets Control, or any other United States or foreign agency or
      authority. As defined in FAR section 2.101, the Software and documentation are "commercial items" and according to
      DFAR section 252.227-7014(a)(1) and (5) are deemed to be "commercial computer software" and "commercial computer
      software documentation." Consistent with DFAR section 227.7202 and FAR section 12.212, any use modification,
      reproduction, release, performance, display, or disclosure of such commercial software or commercial software
      documentation by the U.S. Government will be governed solely by the terms of this Agreement and will be prohibited
      except to the extent expressly permitted by the terms of this Agreement.
    </p>
    <p>
      3.3 <u>Customer Representations</u>. Customer represents, covenants, and warrants that Customer will use the
      Services only in compliance with Borne's standard published terms and conditions and all applicable laws and
      regulations. Customer hereby agrees to indemnify and hold harmless Borne against any damages, losses, liabilities,
      settlements and expenses (including without limitation costs and attorneys' fees) in connection with any claim or
      action that arises from an alleged violation of the foregoing or otherwise from Customer's use of Services.
      Although Borne has no obligation to monitor Customer's use of the Services, Borne may do so and may prohibit any
      use of the Services it believes may be (or alleged to be) in violation of the foregoing.
    </p>
    <p>
      3.4 <u>Customer Responsibilities</u>. Customer shall be responsible for obtaining and maintaining any equipment
      and ancillary services needed to connect to, access or otherwise use the Services, including, without limitation,
      modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively,
      "Equipment"). Customer shall also be responsible for maintaining the security of the Equipment, Customer account,
      passwords (including but not limited to administrative and user passwords) and files, and for all uses of Customer
      account or the Equipment with or without Customer's knowledge or consent.
    </p>
    <p>
      3.5 <u>Customer Responsible for User Accounts</u>. Customer is responsible for all activity occurring under
      Customer's user accounts (except to the extent any such activity is caused by Borne), and for complying with all
      laws and regulations applicable to Customer's use of the Services. Customer also must:
    </p>
    <p>
      (a) notify Borne promptly upon becoming aware of any unauthorized use of any Customer password or account (or any
      other breach of security of the Services);
    </p>
    <p>
      (b) notify Borne promptly upon becoming aware of, and make a reasonable effort to stop, any unauthorized copying,
      distribution or other misuse of any aspect of the Services; and
    </p>
    <p>
      (c) input accurate and complete transaction records and/or data into the Services in the established standard
      format and in accordance with the specifications set forth in the documentation therefor.
    </p>
    <p>
      3.6 <u>Customer PCI Responsibilities</u>. If Customer or its third-party service provider processes any credit
      card information using the Services, Customer (and/or such service provider, as applicable) will:
    </p>
    <p>(a) comply with their responsibilities under the Payment Card Industry Data Security Standard ("PCI DSS");</p>
    <p>
      (b) implement and maintain reasonable security measures to protect all cardholder data in their possession or
      control; and
    </p>
    <p>
      (c) not take any action in connection with using the Services that places Borne in non-compliance with the PCI DSS
      (for example, storing any cardholder data in any custom fields of the Services).
    </p>
    <p>
      <b>4. CONFIDENTIALITY; PROPRIETARY RIGHTS</b>
    </p>
    <p>
      4.1 <u>Confidentiality</u>. Each party (the "Receiving Party") understands that the other party (the "Disclosing
      Party") has disclosed or may disclose business, technical or financial information relating to the Disclosing
      Party's business (hereinafter referred to as "Proprietary Information" of the Disclosing Party). Proprietary
      Information of Borne includes non-public information regarding features, functionality and performance of the
      Services. Proprietary Information of Customer includes non-public data provided by Customer to Borne to enable the
      provision of the Services ("Customer Data"). The Receiving Party agrees: (i) to take reasonable precautions to
      protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise
      permitted herein) or divulge to any third person any such Proprietary Information. The Disclosing Party agrees
      that the foregoing shall not apply with respect to any information following the disclosure thereof or any
      information that the Receiving Party can document (a) is or becomes generally available to the public, or (b) was
      in its possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully disclosed to it
      without restriction by a third party, or (d) was independently developed without use of any Proprietary
      Information of the Disclosing Party or (e) is required to be disclosed by law.
    </p>
    <p>
      4.2 Exclusions. Receiving Party's obligations under this section will not apply to any Disclosing Party of
      Confidential Information that Recipient can prove:
    </p>
    <p>(a) is or becomes part of in the public domain through no fault of Receiving Party;</p>
    <p>(b) is rightfully in Receiving Party's possession free of any confidentiality obligation; or</p>
    <p>
      (c) was independently developed by Receiving Party without use of any Disclosing Party Confidential Information.
    </p>
    <p>
      A disclosure by Receiving Party of any Confidential Information (i) in response to a valid order or other legal
      process issued by a court or other governmental body having jurisdiction, (ii) as otherwise required by law, or
      (iii) necessary to establish the rights of either Party under this Agreement will not be a breach of this
      Agreement if, to the extent legally permitted, Receiving Party gives Discloser prompt notice and reasonable
      cooperation so Disclosing Party may seek to prevent or limit such disclosure.
    </p>
    <p>
      4.3 <u>Exceptions</u>. Notwithstanding anything to the contrary, Borne shall have the right collect and analyze
      data and other information relating to the provision, use and performance of various aspects of the Services and
      related systems and technologies (including, without limitation, information concerning Customer data and data
      derived therefrom), and Borne will be free (during and after the term hereof) to (i) use such information and data
      to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection
      with the Services and other Borne offerings, and (ii) disclose such data solely in aggregate or other
      de-identified form in connection with its business.
    </p>
    <p>
      4.4 <u>Confidentiality Period</u>. Receiving Party's obligations with respect to Disclosing Party's Confidential
      Information under this section will remain in effect for the term of this Agreement and for three (3) years after
      any expiration or termination of this Agreement. Notwithstanding the foregoing, Receiving Party's obligations
      under this Agreement will continue to apply to Confidential Information that qualifies as a trade secret or
      personal data under applicable law for as long as it so qualifies.
    </p>
    <p>
      <b>5. FEES</b>
    </p>
    <p>
      5.1 <u>Subscription Fees</u>. Customer will pay Borne the then applicable subscription Fees described in the
      Pricing Terms section in Exhibit A for the Services and any technical support Services in accordance with the
      terms therein (the "Fees"). If Customer's use of the Services exceeds the Services capacity set forth on the
      Pricing Terms section or otherwise requires the payment of additional fees (per the terms of this Agreement),
      Customer shall be billed for such usage and Customer agrees to pay the additional fees in the manner provided
      herein. Borne reserves the right to change the Fees or applicable charges and to institute new charges and Fees at
      the end of the Initial Service Term or then-current renewal term, upon thirty (30) days prior notice to Customer
      (which may be sent by email). If Customer believes that Borne has billed Customer incorrectly, Customer must
      contact Borne no later than 60 days after the closing date on the first billing statement in which the error or
      problem appeared, in order to receive an adjustment or credit.
    </p>
    <p>
      5.2 <u>Processing of Fees</u>. For payment via credit card or electronic money transfer (e.g., Wire, Direct
      Debit), Borne is permitted to process such payment on the date of entering into the initial Services Agreement
      ("Initial Service Term") and/or Borne's renewal invoice ("Renewal Service Term"). Borne charges and collects in
      advance the Fees for use of the Service based on Customer's contracted usage for the Service (after the Initial
      Service Term, Borne will invoice Customer for such fees prior to the start of each Renewal Service Term); and (ii)
      if Customer's use of the Service exceeds the contracted usage, Customer will pay the additional usage-based Fees
      as described in the Pricing Terms section in Exhibit A.
    </p>
    <p>
      5.3 <u>Customer Contact Information</u>. Customer agrees to provide Borne accurate billing and other contact
      information at all times, including the name of Customer's applicable legal entity, and the street address, e-mail
      address, name and telephone number of an authorized billing contact.
    </p>
    <p>
      5.4 <u>Consequences of Non-Payment</u>. If Customer fails to make any payments required under this Agreement, then
      in addition to any other rights Borne may have under this Agreement or applicable law:
    </p>
    <p>
      (a) Customer will owe Borne an interest penalty of one and one-half percent (1.5%) per month on any outstanding
      balance under each delinquent invoice, or the maximum permitted by law (whichever is less);
    </p>
    <p>
      (b) Borne will be entitled to recover its reasonable attorneys' fees, other legal expenses (including expert
      witness fees and expenses on appeal) and other reasonable costs to collect such amounts; and
    </p>
    <p>
      (c) If Customer's account remains delinquent (with respect to payment of a valid invoice) for thirty (30) days
      after receipt of a delinquency notice from Borne, which may be provided via email to Customer's designated billing
      contact, Borne may temporarily suspend Customer's access to the Services for up to ninety (90) days to pursue good
      faith negotiations before pursuing termination. Customer will continue to incur and owe all applicable Fees
      irrespective of any such suspension of the Services based on such Customer delinquency.
    </p>
    <p>
      5.5 <u>Taxes</u>. Borne's Fees are exclusive of all taxes, levies, or duties imposed by taxing authorities in
      connection with this Agreement. Customer is responsible for paying all such taxes, levies, or duties except any
      taxes based solely on Borne's income or which do not arise from this Agreement. If Borne has the legal obligation
      to pay or collect taxes for which Customer is responsible, the appropriate amount will be invoiced to and paid by
      Customer unless Customer provides Borne a valid tax exemption certificate authorized by the appropriate taxing
      authority. Unless prohibited by the applicable taxing jurisdiction, the tax situs will be Customer's admin user
      address as set forth in the information provided by Customer when entering into this Agreement. Customer may
      update such address by providing written notice to Borne and taxes will be updated on a prospective basis.
    </p>
    <p>
      <b>6. TERM AND TERMINATION</b>
    </p>
    <p>
      6.1 <u>Term</u>. Subject to earlier termination as provided below, this Agreement is for the Initial Service Term
      and any Renewal Service Term, and shall be automatically renewed for additional Services selected by Customer as
      specified in the Pricing Terms of Exhibit A.
    </p>
    <p>
      6.2 <u>Termination of Agreement</u>. This Agreement will automatically terminate in accordance with the terms set
      forth herein.
    </p>
    <p>
      6.3 <u>Effect of Expiration or Termination of Agreement</u>. All ownership restrictions, intellectual property
      protections and confidentiality restrictions of this Agreement will survive any expiration or termination of this
      Agreement.
    </p>
    <p>6.4 Effect of Termination. Subject to the exclusive remedy provisions in this Agreement:</p>
    <p>
      (a) if Customer terminates this Agreement for uncured material breach in accordance with this Agreement, Customer
      will be entitled to a refund of any Fees paid thereunder that are unused as of the termination effective date; and
    </p>
    <p>
      (b) if Borne terminates this Agreement for uncured material breach in accordance with this Section, all amounts
      owed by Customer thereunder will become due and payable.
    </p>
    <p>
      <b>7. THIRD PARTY INTERACTIONS</b>
    </p>
    <p>
      To the extent Customer requires use of any third-party products or services not made available by Borne (e.g.,
      Oracle Java, Adobe Acrobat, Salesforce CRM, Amazon Web Services and/or a Web browser), Customer may be required to
      separately purchase/license such products or services directly from the applicable third party. In addition, in
      connection with using the Services, Customer may choose to purchase/license certain other third-party products
      and/or services identified by Borne. Any third-party products and services and any terms associated therewith are
      between Customer and the relevant third parties. Borne does not, license, support, control, endorse or otherwise
      make any representations or warranties regarding any third-party products or services under this section, and in
      no event will Borne have any liability whatsoever in connection therewith.
    </p>
    <p>
      <b>8. WARRANTIES &amp; DISCLAIMERS</b>
    </p>
    <p>
      8.1 <u>Mutual Warranties</u>. Each Party represents and warrants to the other that it has the legal power and
      authority to enter into this Agreement, and that this Agreement has been duly authorized, executed and delivered
      and constitutes a valid and binding agreement enforceable against such Party in accordance with its terms.
    </p>
    <p>
      8.2 <u>Reasonable Efforts</u>. Borne shall use reasonable efforts consistent with prevailing industry standards to
      maintain the Services in a manner which minimizes errors and interruptions in the Services and shall perform the
      Services in a professional and workmanlike manner. Services may be temporarily unavailable for scheduled
      maintenance or for unscheduled emergency maintenance, either by Borne or by third-party providers, or because of
      other causes beyond Borne's reasonable control, but Borne shall use reasonable efforts to provide advance notice
      in writing or by e-mail of any scheduled service disruption. However, Borne does not warrant that the Services
      will be uninterrupted or error free; nor does it make any warranty as to the results that may be obtained from use
      of the Services.
    </p>
    <p>
      8.3 <u>Disclaimer of Other Representations</u>. BORNE MAKES NO REPRESENTATION AS TO THE ACCURACY, COMPLETENESS OR
      USEFULNESS OF THE INFORMATION AND GUIDANCE PROVIDED BY THE SERVICES, OR THAT ANY PARTICULAR SUGGESTION OR STRATEGY
      DERIVED FROM CUSTOMER'S PERSONALIZED INPUT(S) AND DATA ARE EITHER COMPLETE, APPROPRIATE OR SUITABLE. ANY
      PREDICTION, PROJECTION, CALCULATION, OR OTHER INFORMATION REGARDING THE LIKELIHOOD OF VARIOUS OUTCOMES IS
      HYPOTHETICAL IN NATURE, IS NOT GUARANTEED FOR SUCCESS, ACCURACY OR COMPLETENESS, DOES NOT REFLECT ACTUAL RESULTS
      AND IS NOT A GUARANTEE OF FUTURE RESULTS. CUSTOMER DATA AND PERSONALIZED INPUT(S) AND SELECTION CRITERIA WHEN
      USING ANY INTERACTIVE TOOLS ARE AT CUSTOMER'S SOLE DISCRETION. CUSTOMER ALONE IS RESPONSIBLE FOR DETERMINING
      WHETHER ANY SUGGESTION OR GUIDANCE IS SUITABLE FOR CUSTOMER, WHETHER SUCH GUIDANCE REQUIRES FURTHER CUSTOMIZATION
      BY CUSTOMER AND FOR EVALUATING THE MERITS AND RISKS ASSOCIATED WITH THE USE OF ANY INTERACTIVE TOOL, BEFORE MAKING
      ANY STRATEGIC DECISIONS. BORNE AND ITS AFFILIATES, THIRD-PARTY PROVIDERS, LICENSORS, OFFICERS, DIRECTORS OR
      EMPLOYEES SHALL NOT BE LIABLE IN ANY WAY FOR (A) ANY INACCURACY OF, ERROR OR DELAY IN, OR OMISSION OF THE
      SERVICES; OR (B) ANY LOSS OR DAMAGE ARISING FROM I) CUSTOMER'S INVESTMENT DECISIONS; II) CUSTOMER'S USE OF THE
      SERVICES, INCLUSIVE OF ANY INTERACTIVE TOOLS THAT MAY BE A PART OF THE SERVICES; III) ANY ERROR OR DELAY IN THE
      TRANSMISSION OR FUNCTIONALITY OF SUCH SERVICES; IV) ANY CAUSE BEYOND THE CONTROL OF BORNE, ITS AFFILIATES AND
      THIRD-PARTY PROVIDERS, OR V) NON-PERFORMANCE.
    </p>
    <p>
      8.4 Warranty Disclaimers. EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT OR IN <u>EXHIBIT A</u> HERETO, THE
      SERVICES ARE PROVIDED "AS IS" AND BORNE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
      TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. BORNE AND ITS
      LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, STATUTORY OR IMPLIED (IN FACT OR BY
      OPERATION OF LAW), REGARDING THE SERVICES OR ANY MATTER WHATSOEVER; AND BORNE AND ITS LICENSORS DO NOT WARRANT
      THAT THE SERVICES ARE OR WILL BE ERROR-FREE, MEET CUSTOMER'S REQUIREMENTS, ACHIEVE ANY PARTICULAR RESULTS, OR BE
      TIMELY OR SECURE. BORNE AND ITS LICENSORS EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
      FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT WITH RESPECT TO THE SERVICES, AND CUSTOMER HAS NO RIGHT TO MAKE OR
      PASS ON TO ANY THIRD PARTY ANY REPRESENTATION OR WARRANTY BY BORNE.
    </p>
    <p>
      THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET OR
      ELECTRONIC COMMUNICATIONS. BORNE IS NOT RESPONSIBLE FOR DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE, LOSS OR
      LIABILITY RESULTING FROM SUCH PROBLEMS NOT CAUSED BY BORNE.
    </p>
    <p>
      CUSTOMER AGREES THAT ITS SUBSCRIPTION TO THE SERVICES AND FEES DUE OR PAID UNDER THIS AGREEMENT ARE NEITHER
      CONTINGENT ON THE DELIVERY OF ANY FUTURE FUNCTIONALITY OR FEATURES, NOR BASED ON ANY ORAL OR WRITTEN COMMENTS
      REGARDING ANY FUTURE FUNCTIONALITY OR FEATURES. MORE GENERALLY, IN ENTERING INTO THIS AGREEMENT, NEITHER PARTY IS
      RELYING ON ANY OTHER COMMITMENTS, STATEMENTS OR OTHER MATTERS NOT EXPRESSLY ADDRESSED IN THIS AGREEMENT.
    </p>
    <p>
      <b>9. INDEMNITY</b>
    </p>
    <p>
      9.1 <u>Indemnity by Borne</u>. Borne shall hold Customer harmless from liability to third parties resulting from
      infringement by the Services of any United States patent or any copyright or misappropriation of any trade secret,
      provided Borne is promptly notified of any and all threats, claims and proceedings related thereto and given
      reasonable assistance and the opportunity to assume sole control over defense and settlement; Borne will not be
      responsible for any settlement it does not approve in writing. The foregoing obligations do not apply with respect
      to portions or components of the Services (i) not supplied by Borne, (ii) made in whole or in part by Customer,
      (iii) that are modified after delivery by Borne, (iv) combined with other products, processes or materials where
      the alleged infringement relates to such combination, (v) where Customer continues allegedly infringing activity
      after being notified thereof or after being informed of modifications that would have avoided the alleged
      infringement, or (vi) where Customer's use of the Services are not strictly in accordance with this Agreement. If,
      due to a claim of infringement, the Services are held by a court of competent jurisdiction to be or are believed
      by Borne to be infringing, Borne may, at its option and expense (a) replace or modify the Services to be
      non-infringing provided that such modification or replacement contains substantially similar features and
      functionality, (b) obtain for Customer a license to continue using the Services, or (c) if neither of the
      foregoing is commercially practicable, terminate this Agreement and Customer's rights hereunder and provide
      Customer a refund of any prepaid, unused Fees for the Services.
    </p>
    <p>
      9.2 <u>Indemnity by Customer</u>. Customer will defend Borne, its affiliates, officers, directors and employees
      from and against any claims asserted by a third party based on a breach by Customer of Customer responsibilities
      of this Agreement. Customer will also indemnify Borne and its affiliates, officers, directors and employees by
      paying all damages, costs and expenses (including reasonable legal fees and costs) awarded by a court of competent
      jurisdiction in a final, non-appealable judgment, or agreed in a written settlement agreement agreed by Customer
      in writing, arising out of the third-party claims described in this section.
    </p>
    <p>
      9.3 <u>Requirements for Indemnification</u>. Each Party's respective defense and indemnity obligations under this
      Agreement are contingent upon the indemnified Party:
    </p>
    <p>
      (a) promptly giving notice of the third-party claim to the defending/indemnifying Party once the claim is known;
    </p>
    <p>
      (b) giving the defending/indemnifying Party sole control of the defense and settlement of the claim and not
      compromising or settling the claim without the defending/indemnifying Party's approval (though the
      defending/indemnifying Party must not settle such claim unless the settlement unconditionally releases the other
      Party of all liability and does not adversely affect the other Party's business or service in a material manner);
      and
    </p>
    <p>
      (c) providing appropriate information and reasonable cooperation to the defending/indemnifying Party in connection
      with the claim.
    </p>
    <p>
      THE FOREGOING ARE THE DEFENDING/INDEMNIFYING PARTY'S SOLE OBLIGATIONS, AND THE INDEMNIFIED PARTY'S EXCLUSIVE
      REMEDIES WITH RESPECT TO INDEMNIFICATION AND THE MATTERS ADDRESSED IN THIS SECTION.
    </p>
    <p>
      <b>10. LIMITATION OF LIABILITY</b>
    </p>
    <p>
      10.1 <u>Limitation of Liability</u>. NOTWITHSTANDING ANYTHING TO THE CONTRARY, BORNE AND ITS SUPPLIERS (INCLUDING
      BUT NOT LIMITED TO ALL TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES
      SHALL NOT BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND CONDITIONS
      RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF
      USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR
      TECHNOLOGY OR LOSS OF BUSINESS, OPPORTUNITIES, ECONOMIC ADVANTAGE, GOODWILL, DATA OR USE; (B) FOR ANY INDIRECT,
      EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND BORNE'S REASONABLE CONTROL; OR
      (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CUSTOMER
      TO BORNE FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY,
      IN EACH CASE, WHETHER OR NOT BORNE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
    </p>
    <p>
      10.2 <u>No Investment or Legal Advice</u>. Borne does not provide investment or legal advice and does not make
      assurances of any kind. The Services may provide general information and guidance and may generate and present
      various strategy alternatives, based upon your personalized inputs and data. Customer is solely responsible for
      inputs and data, including evaluating the merits and risks associated with the use of any content provided through
      the Service, before making any decisions based on such service content. Customer is solely responsible for
      determining whether any investment, strategy or related transaction is appropriate based on the Customer's
      objectives, financial circumstances and risk tolerance. Any projections or other information regarding the
      likelihood of various outcomes are hypothetical in nature and are not guaranteed for accuracy or completeness.
      Borne's interactive tools, data and past performance data should not be construed as being indicative of future
      results and do not guarantee future results or returns.
    </p>
    <p>
      YOU AGREE NOT TO HOLD BORNE ITS AFFILIATES OR THIRD-PARTY PROVIDERS LIABLE FOR ANY POSSIBLE CLAIM FOR DAMAGES OR
      LOSSES, ARISING FROM ANY INVESTMENT OR STRATEGY DECISION CUSTOMER MAKES, WHETHER OR NOT BASED UPON THE SERVICES OR
      FROM OTHER INFORMATION MADE AVAILABLE TO CUSTOMER THOUGH THE SERVICES.
    </p>
    <p>
      <b>11. GENERAL</b>
    </p>
    <p>
      11.1 <u>Governing Law</u>. This Agreement is governed by California law and controlling United States federal law,
      without regard to conflicts of law provisions of any jurisdiction. The Services are a service, not a good, and is
      not subject to the Uniform Commercial Code, the Uniform Computer Information Transactions Act, or the United
      Nations Convention on the International Sale of Goods.
    </p>
    <p>
      11.2 <u>Dispute Resolution</u>. The Parties will first attempt in good faith to resolve each controversy or claim
      within sixty (60) days by negotiations between executives of the parties who have settlement authority. The
      disputing Party will give the other Party written notice of the controversy or claim in accordance with the notice
      provision of this Agreement. The other Party will submit a response within twenty (20) days after receiving said
      notice. If the controversy or claim has not been resolved within ninety (90) days of the disputing Party's notice,
      the controversy or claim arising in any way from Client's use of the Services, except for disputes relating to the
      infringement of Borne's intellectual property rights or the access or use of the Services in violation of this
      Agreement, shall be resolved by binding arbitration, rather than in court, under the following guidelines:
    </p>
    <p>
      (a) No Judge or Jury. There is no judge or jury in arbitration, and court review of an arbitration award is
      limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including
      injunctive and declaratory relief or statutory damages), and must follow the terms of this Agreement as a court
      would.
    </p>
    <p>
      (b) Arbitrator and Rules. The arbitration will be conducted before a neutral single arbitrator, whose decision
      will be final and binding, and the arbitral proceedings shall be governed by the AAA Commercial Arbitration Rules,
      Consumer Due Process Protocol, and Supplementary Procedures for Resolution of Consumer Related Disputes. These
      rules can be found on the AAA website at www.adr.org.
    </p>
    <p>
      (c) Starting an Arbitration. To begin an arbitration proceeding, Customer must send Borne a notice of dispute, in
      writing, setting forth Customer's name, address and contact information, the facts of the dispute and relief
      requested. Customer must send Customer's notice of legal dispute to Borne at the following address: Borne LLC, 29
      16<sup>th</sup> Avenue, San Mateo California, 94402.
    </p>
    <p>
      (d) Format of Proceedings. The arbitration shall be conducted, at the option of the Party seeking relief, by
      telephone, online, or based solely on written submissions. Otherwise, the exclusive venue for any arbitration will
      be San Mateo County, California, USA.
    </p>
    <p>
      (e) Fees. If Customer initiates arbitration, Customer's arbitration fees will initially be limited to the filing
      fee set forth in the AAA's Consumer Arbitration Rules. Unless the arbitrator finds the arbitration was frivolous
      or brought for an improper purpose, each Party shall pay its own AAA and arbitrator's fees and expenses. If the
      arbitrator finds the arbitration to be frivolous or brought for an improper purpose, then Customer agrees to pay
      all costs and expenses connected to the arbitration.
    </p>
    <p>
      11.3 <u>Assignment &amp; Other Transfers</u>. Customer may assign, sublicense or otherwise transfer (by operation
      of law or otherwise) this Agreement, or Customer's rights or obligations under this Agreement, to any third party
      with Borne's prior written consent, which consent must not be unreasonably withheld, delayed or conditioned;
      provided, however, that upon written notice, either Party may assign or otherwise transfer this Agreement (and all
      its rights and obligations thereunder), (a) to a successor-in-interest in connection with a merger, acquisition,
      reorganization, a sale of most or all of its assets, or other change of control, or (b) to its affiliate. In the
      event of a transfer by Customer that is permitted under this section, the rights granted under this Agreement
      shall continue to be subject to the same usage limitations that applied under applicable order forms prior to the
      transfer (e.g., any transaction volume terms, and any limitations to particular Customer legal entities, business
      units, projects, brands, products and/or services set forth therein). Any purported assignment or other transfer
      in violation of this section is void. Subject to the terms of this section, this Agreement will bind and inure to
      the benefit of the Parties and their respective permitted successors and transferees.
    </p>
    <p>
      11.4 <u>Force Majeure</u>. If either Party is prevented from performing, or is unable to perform, any of its
      obligations under this Agreement (other than payment obligations) due to any cause beyond its reasonable control,
      e.g., war, riots, labor unrest, fire, earthquake, flood, hurricane, other natural disasters and acts of God,
      Internet service failures or delays, and denial of service attacks (collectively, "Force Majeure"), the affected
      Party's performance will be excused for the resulting period of delay or inability to perform.
    </p>
    <p>
      11.5 <u>Marketing</u>. Borne is permitted to identify Customer as a Borne customer on Borne's website and
      marketing materials and, within thirty (30) days after Customer goes live on the Service, Customer and Borne may
      also issue a mutually agreed joint public announcement relating thereto.
    </p>
    <p>
      11.6 <u>Independent Contractors</u>. The Parties are independent contracting parties. Neither Party has, or will
      hold itself out as having, any right or authority to incur any obligation on behalf of the other Party. The
      Parties' relationship in connection with this Agreement will not be construed as a joint venture, partnership,
      franchise, employment, or agency relationship, or as imposing any liability upon either Party that otherwise might
      result from such a relationship.
    </p>
    <p>
      11.7 <u>Notices</u>. All legal notices (e.g., notice of termination of this Agreement or a noticed based on an
      alleged material breach) required under this Agreement must be delivered to the other Party in writing (a) in
      person, (b) by nationally recognized overnight delivery service, or (c) by certified U.S. mail (requiring
      signature) to the other Party's corporate headquarters. With respect to all other notices, Customer may email
      Borne at g.nasser@bornellc.com and Borne may email Customer's billing contact identified on the applicable order
      form(s). Either Party may change its notice address by giving written notice to the other Party.
    </p>
    <p>
      11.8 <u>Anti-Corruption</u>. Customer acknowledges it has not received or been offered any illegal or otherwise
      improper bribe, kickback, payment, gift or other thing of value by any Borne employee, representative or agent in
      connection with this Agreement. Customer will use reasonable efforts to promptly notify Borne if Customer becomes
      aware of any circumstances that are contrary to this acknowledgment.
    </p>
    <p>
      11.9 <u>Government Users</u>. Each Party agrees to comply with all applicable laws, regulations, orders and
      sanctions relating to prohibitions or limitations on relationships or transactions with prohibited countries or
      individuals (e.g., those administered by the U.S. Commerce or Treasury Departments). Customer shall not make the
      Service available to any individual or entity that is (i) located in a country that is subject to a United States
      government embargo, or (ii) is listed on any United States government list of prohibited or restricted parties.
    </p>
    <p>
      11.10 <u>Execution</u>. This Agreement may be signed electronically and in counterparts, in which case each signed
      copy will be deemed an original as though both signatures appeared on the same document.
    </p>
    <p>
      11.11 <u>Severability</u>. In the event that any provision of this Agreement is held to be invalid or
      unenforceable, then:
    </p>
    <p>
      (a) such provision shall be deemed reformed to the extent strictly necessary to render such provision valid and
      enforceable, or if not capable of such reformation shall be deemed severed from this Agreement; and
    </p>
    <p>
      (b) the validity and enforceability of all of the other provisions hereof, shall in no way be affected or impaired
      thereby.
    </p>
    <p>
      11.12 <u>Waiver</u>. The failure to exercise, or delay in exercising, a right, power or remedy provided in this
      Agreement or by law shall not constitute a waiver of that right, power or remedy. Borne's waiver of any obligation
      or breach of this Agreement shall not operate as a waiver of any other obligation or subsequent breach of this
      Agreement.
    </p>
    <p>
      11.13 <u>Construction</u>. The section headings are for convenience only and will not be deemed part of this
      Agreement. The language of this Agreement will be construed according to its fair meaning. Any rule of
      construction resolving ambiguities against the drafting party will not apply in the interpretation of this
      Agreement.
    </p>
    <p>
      11.14 <u>Entire Agreement</u>. This Agreement, together with any applicable order forms (including any other terms
      referenced in any of those documents), comprises the entire agreement between Customer and Borne regarding the
      subject matter of this Agreement, supersedes all prior or contemporaneous negotiations, discussions or agreements
      (including but not limited to any non-disclosure or other agreement governing the sharing of confidential
      information by and between Borne and Customer), whether written or oral, between the Parties regarding such
      subject matter, and may only be modified by a document signed by authorized representatives of both Parties. Each
      term and provision of this Agreement is valid and enforceable to the fullest extent permitted by law, and any
      invalid, illegal or unenforceable term or provision shall be deemed replaced by a term or provision that is valid
      and enforceable and that most effectively accomplishes the Parties' shared goals and intent, determined from the
      perspective of an objective, reasonable person.
    </p>
    <p className="tw-text-center">
      <b>
        <u>EXHIBIT A</u>
      </b>
    </p>
    <p className="tw-text-center">
      <b>Pricing Terms</b>
    </p>
    <p>
      Customer shall pay Borne the respective Fees for the following services provided by Borne in accordance with the
      terms of the Agreement: All Sales are final.
    </p>
    <p className="tw-text-center">
      I. $749.95 (Seven hundred Forty Nine United States Dollars and Ninety Five Cents) - for the Borne Report
      <sup>&#174;</sup>
      Link.
    </p>
  </>
);
