import React from 'react';
import { Form } from 'semantic-ui-react';
import { useField } from 'formik';

interface Props {
  loc: string;
}

const SharedWithEnterpriseQuestion = ({ loc }: Props) => {
  const [field, _, helpers] = useField({ name: loc });

  const toggleCheckbox = () => {
    helpers.setValue(!field.value);
    helpers.setTouched(true, false);
  };

  return <Form.Radio label="Share with Enterprise" checked={field.value} onChange={toggleCheckbox} slider />;
};

export default SharedWithEnterpriseQuestion;
