import Auth from '../auth/Auth';
import { User } from './models';

export default class Users {
  auth: Auth;

  /**
   * Initialize a new Users API object
   */
  constructor(auth: Auth) {
    this.auth = auth;
  }

  /**
   * Retrieves the user that is currently logged in, or a 401 (completes exceptionally) if credentials are expired
   * If user has not logged in, immediately rejects the promise
   */
  async getCurrentUser(): Promise<User> {
    const response = await this.auth.authenticatedAPICall({
      method: 'get',
      url: '/api/users/me/',
    });

    return response.data;
  }
}
