import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import type { InfiniteQueryObserverOptions, QueryKey, QueryFunction } from '@tanstack/query-core';
import type { Page } from '../api/models';

export type UseStreamQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Pick<
  InfiniteQueryObserverOptions<Page<TQueryFnData>, TError, Page<TData>, Page<TQueryData>, TQueryKey>,
  'enabled' | 'refetchOnWindowFocus'
>;

interface StreamQuerySuccessResult<TData> {
  isSuccess: true;
  data: TData[];
}

interface StreamQueryLoadingErrorResult {
  isSuccess: false;
  data: undefined;
}

type UseStreamQueryResult<TData> = StreamQuerySuccessResult<TData> | StreamQueryLoadingErrorResult;

export default function useStreamQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<Page<TQueryFnData>, TQueryKey>,
  options?: UseStreamQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>
): UseStreamQueryResult<TData> {
  const { data, hasNextPage, fetchNextPage } = useInfiniteQuery<Page<TQueryFnData>, TError, Page<TData>, TQueryKey>(
    queryKey,
    queryFn,
    {
      getNextPageParam: (lastPage) => lastPage.next,
      ...options,
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [data?.pages.length]);

  return data !== undefined
    ? { isSuccess: true, data: useMemo(() => data.pages.flatMap((page) => page.results), [data.pages]) }
    : { isSuccess: false, data: useMemo(() => undefined, [undefined]) };
}
