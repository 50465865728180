import React from 'react';
import { WrappedButtonGroupField } from './FormikUtil';

const options: Readonly<Record<number, string>> = {
  0: 'No',
  1: 'Yes',
};

interface Props {
  loc: string;
}

const ContractQuestion = ({ loc }: Props) => {
  return (
    <WrappedButtonGroupField
      label="Is contract service or a management contract involved?"
      options={options}
      name={loc}
    />
  );
};

export default ContractQuestion;
