import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';

interface Point {
  label?: string;
  value?: string | number;
  icon?: any;
}

interface Props {
  points: Array<Point>;
  currentPoint?: string;
}

interface CheckPointProps {
  complete?: boolean;
  inProgress?: boolean;
}

interface ColorBarProps {
  width: number;
}

const ProgressBar: React.FC<Props> = ({ points, currentPoint }: Props) => {
  const Bar = styled.div`
    height: 8px;
    width: 100%;
    background-color: rgb(243 244 245);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  `;
  const ColorBar: any = styled.div`
    height: 8px;
    width: ${(props: ColorBarProps) => props.width}%;
    background-color: rgb(164 218 81);
    position: absolute;
  `;
  const CheckPoint: any = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${(props: CheckPointProps) => (props.complete ? 'rgb(164 218 81)' : 'rgb(243 244 245)')};
    border: ${(props: CheckPointProps) => (props.inProgress ? '2px solid rgb(164 218 81)' : '')};
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const CheckPointLabel = styled.span`
    position: absolute;
    right: -18px;
  `;
  return (
    <Bar>
      <ColorBar width={(points?.findIndex((x) => x.value === currentPoint) / (points?.length - 1)) * 100} />
      {points?.map((point, i) => (
        <div className="tw-relative">
          <CheckPoint
            complete={
              points?.findIndex((x) => x.value === currentPoint) === points.length - 1 ||
              points?.findIndex((x) => x.value === currentPoint) > i
            }
            inProgress={points?.findIndex((x) => x.value === currentPoint) == i}
          >
            {points?.findIndex((x) => x.value === currentPoint) === points.length - 1 ||
              (points?.findIndex((x) => x.value === currentPoint) > i && (
                <Icon inverted color="grey" className="tw-m-0 tw-mb-1" name="check" />
              ))}
          </CheckPoint>
          <CheckPointLabel>{point.label}</CheckPointLabel>
        </div>
      ))}
    </Bar>
  );
};

export default ProgressBar;
