import React, { ReactNode } from 'react';
import Header from './Header';
import Steps from './Steps';

interface Props {
  children: ReactNode;
  currentStep: string;
}

const FormPage = ({ children, currentStep }: Props) => {
  return (
    <>
      <Header cancel={currentStep !== 'complete'} />
      <div className="tw-w-4/5 tw-max-w-screen-xl tw-mx-auto">
        <div className="tw-mb-12">
          <header className="tw-text-4xl tw-text-borneblue-darkest tw-mb-4">Purchase a Borne Report</header>
          <p>
            NOTE: The Borne Report<sup>&#174;</sup> is for existing restaurants looking to evaluate the viability of
            their concept at a specific street address in the United States mainland. This report does not indicate what
            type of concepts will work at a location, nor does it list the Borne Score for top concepts. If you're
            interested in identifying the right concept for a location or a real estate report,{' '}
            <a href="https://bornereport.com/discuss-reporting">contact us to discuss the details</a>.
          </p>
        </div>
        <header className="tw-mb-12">
          <Steps current={currentStep}>
            {[
              { name: 'location', text: '01. Location' },
              { name: 'concept', text: '02. Concept' },
              { name: 'contact', text: '03. Contact' },
              { name: 'payment', text: '04. Payment' },
              { name: 'complete', text: 'Complete' },
            ]}
          </Steps>
        </header>
        {children}
      </div>
    </>
  );
};

export default FormPage;
