import { configureStore } from '@reduxjs/toolkit';
import { mainSlice, commonSlice } from './testSlice';

export const store = configureStore({
  reducer: {
    main: mainSlice.reducer,
    // market: marketSlice.reducer,
    // studyArea: studyAreaSlice.reducer,
    // studyAreaComparison: studyAreaComparisonSlice.reducer,
    common: commonSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
