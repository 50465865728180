import { useConcepts, useStudyAreaGrades, useStudyAreaSummary } from '../../../api/requests';
import Auth from 'borne_ui/src/auth/Auth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Grid, Icon, Message, Tab } from 'semantic-ui-react';
import {
  PageContainer,
  ContentContainer,
  BorneFlexContainer,
  BorneSmallButton,
  BorneTab,
  BorneLargeButton,
  BorneDropDown,
} from '../../generic/BorneComponents';
import SideMenu2 from '../../generic/SideMenu2';
import { StudyAreaSingleMapComparison } from './StudyAreasComparisonMap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import PatronIdentityContents from '../../pages/StudyAreaList/PatronIdentityContents';
import TripCharacteristicsContents from '../../pages/StudyAreaList/TripCharacteristicsContents';
import SelectSAModal from '../../modals/SelectStudyAreas';
import { StudyArea } from 'borne_ui/src/types';
import CreateEditCustomStudyAreaModal from '../../modals/CreateEditCustomStudyAreaModal';
import { mainActions } from '../../redux/testSlice';
import { BorneSmallPlus, BorneSmallRightArrow } from '../../generic/BorneStyledcons';

interface Props {
  auth: Auth;
  currentMarket?: number;
  setCurrentMarket: (marketIndex: number) => void;
}

export const StudyAreasComparison: React.FC<Props> = ({ auth, currentMarket, setCurrentMarket }: Props) => {
  const concepts = useConcepts(auth);
  const navigate = useNavigate();
  const studyAreaId = useSelector((state: RootState) => state.main.studyAreaId);
  const [selectedStudyAreas, setSelectedStudyAreas] = useState<Set<string>>(new Set());
  const studyAreaSummaryRes = useStudyAreaSummary(auth, Array.from(selectedStudyAreas));
  const [currentMutateStudyArea, setCurrentMutateStudyArea] = useState<StudyArea | undefined>(undefined);
  const [studyModalOpen, setStudyModalOpen] = useState(false);
  const selectedConceptId = useSelector((state: RootState) => state.main.conceptId);
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const selectConceptId = (x: number) => {
    dispatch(mainActions.selectConceptId(x));
  };

  const studyAreaGradesRes = useStudyAreaGrades(auth, Array.from(selectedStudyAreas), selectedConceptId, false, {
    staleTime: 500000,
    enabled: !!selectedConceptId && selectedStudyAreas.size > 0,
    refetchOnWindowFocus: false,
  });
  const startMessage = (
    <Message icon>
      <Icon name="search" />
      <Message.Content>
        <Message.Header>To get started</Message.Header>
        Select study areas from the left to compare
      </Message.Content>
    </Message>
  );
  const updateStudyAreaSelection = (event: { action: string; studyAreaIds: string[] }) => {
    switch (event.action) {
      case 'add':
        setSelectedStudyAreas((currentSelectedMarkets) => {
          const newSelectedBrands = new Set(currentSelectedMarkets);
          for (let brand of event.studyAreaIds) {
            newSelectedBrands.add(brand);
          }
          return newSelectedBrands;
        });
        break;
      case 'remove':
        setSelectedStudyAreas((currentSelectedMarkets) => {
          const newSelectedBrands = new Set(currentSelectedMarkets);
          for (let brand of event.studyAreaIds) {
            newSelectedBrands.delete(brand);
          }
          return newSelectedBrands;
        });
        break;
      default:
        throw Error(`Invalid event action: ${event.action}`);
    }
  };

  const panes = [
    {
      menuItem: 'Patron Identity',
      render: () => (
        <Tab.Pane className="tw-pt-[4vh] tw-border-0">
          {Array.from(selectedStudyAreas).length ? (
            studyAreaSummaryRes.isSuccess ? (
              <PatronIdentityContents data={studyAreaSummaryRes.data} />
            ) : (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  We are fetching study area comparisons for you.
                </Message.Content>
              </Message>
            )
          ) : (
            startMessage
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Trip Characteristics',
      render: () => (
        <Tab.Pane className="tw-pt-[4vh] tw-border-0">
          {Array.from(selectedStudyAreas).length ? (
            studyAreaSummaryRes.isSuccess ? (
              <TripCharacteristicsContents data={studyAreaSummaryRes.data} />
            ) : (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  We are fetching study area comparisons for you.
                </Message.Content>
              </Message>
            )
          ) : (
            startMessage
          )}
        </Tab.Pane>
      ),
    },
  ];
  useEffect(() => {
    studyAreaId && updateStudyAreaSelection({ action: 'add', studyAreaIds: [studyAreaId] });
  }, []);
  return (
    <PageContainer>
      <ContentContainer>
        <SideMenu2 />
        <CreateEditCustomStudyAreaModal
          auth={auth}
          setOpen={setStudyModalOpen}
          isOpen={studyModalOpen}
          currentMutateStudyArea={currentMutateStudyArea}
          setCurrentMutateStudyArea={setCurrentMutateStudyArea}
          newUi
        />
        <SelectSAModal
          setStudyModalOpen={setStudyModalOpen}
          auth={auth}
          isOpen={open}
          setOpen={setOpen}
          currentMarket={currentMarket}
          setCurrentMarket={setCurrentMarket}
          updateStudyArea={updateStudyAreaSelection}
        />
        <Grid className="tw-m-0 tw-h-full tw-w-full">
          <Grid.Row className="tw-w-full">
            <BorneFlexContainer className="tw-pl-4 tw-pr-4 tw-justify-between tw-h-fit">
              <BorneFlexContainer>
                <h2 className="tw-m-0 tw-mr-6">Study Areas</h2>
                {concepts.isSuccess ? (
                  <>
                    <p className="smallText">Concept</p>
                    <BorneDropDown
                      search
                      selectOnBlur={false}
                      selection
                      placeholder="Concept Name"
                      options={concepts.data
                        .sort((a: any, b: any) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                        .map((co: any) => ({
                          key: co.id,
                          text: co.name,
                          value: co.id,
                        }))}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
                        // TODO: tni: This seems wrong! This should be updating Concept name, not concept ID.
                        // Need to figure out what happens because of this. The "as number" is just to allow
                        // the code to compile for now.
                        selectConceptId(value as unknown as number);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <p className="smallText">Concept</p>
                    <BorneDropDown
                      selectOnBlur={false}
                      search
                      selection
                      placeholder="Concept Name"
                      noResultsMessage="Loading"
                    />
                  </>
                )}
              </BorneFlexContainer>
              <BorneFlexContainer className="tw-justify-end">
                <BorneSmallButton active onClick={() => navigate(`/StudyAreas`)}>
                  <span>Back to Study Areas</span>
                  <BorneSmallRightArrow />
                </BorneSmallButton>
              </BorneFlexContainer>
            </BorneFlexContainer>
          </Grid.Row>
          <Grid.Row>
            <BorneFlexContainer className="tw-pl-4 tw-pr-4 tw-justify-between tw-h-fit tw-w-full">
              {selectedStudyAreas.size === 0 && (
                <>
                  <div
                    className="tw-h-[60vh] tw-w-[98%] tw-flex tw-justify-center tw-items-center"
                    style={{
                      border: 'gray solid 1px',
                      backgroundColor: '#e8e8e8',
                    }}
                  >
                    <BorneLargeButton onClick={() => setOpen && setOpen(true)}>
                      <span>Add Study Area</span>
                    </BorneLargeButton>
                  </div>
                  <div
                    className="tw-h-[60vh] tw-w-[98%] tw-flex tw-justify-center tw-items-center"
                    style={{
                      border: 'gray solid 1px',
                      backgroundColor: '#e8e8e8',
                    }}
                  ></div>
                </>
              )}
              {selectedStudyAreas.size === 1 && (
                <>
                  <StudyAreaSingleMapComparison
                    currentMarket={currentMarket}
                    studyAreaId={Array.from(selectedStudyAreas)[0]}
                    studyAreaData={studyAreaGradesRes[0]}
                    auth={auth}
                  />
                  <div
                    className="tw-h-[60vh] tw-w-[98%] tw-flex tw-justify-center tw-items-center"
                    style={{
                      border: 'gray solid 1px',
                      backgroundColor: '#e8e8e8',
                    }}
                  >
                    <BorneLargeButton onClick={() => setOpen && setOpen(true)}>
                      <span>Add Study Area</span>
                    </BorneLargeButton>
                  </div>
                </>
              )}
              {selectedStudyAreas.size === 2 &&
                Array.from(selectedStudyAreas).map((x, i) => (
                  <StudyAreaSingleMapComparison
                    currentMarket={currentMarket}
                    studyAreaId={x}
                    studyAreaData={studyAreaGradesRes[i]}
                    auth={auth}
                  />
                ))}
            </BorneFlexContainer>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <BorneTab panes={panes} menu={{ pointing: true, tabular: true }} renderActiveOnly={true} />
            </Grid.Column>
            <Grid.Column width={4}>
              <Grid.Row className="tw-mt-4">
                <h3>Create Custom Study Areas</h3>
                <p className="smallText">Create custom study areas based on the markets in your comparison</p>
                <div></div>
                <div>
                  <BorneSmallButton onClick={() => setStudyModalOpen(true)} active className="tw-mt-2 tw-ml-0 tw-mb-4">
                    <span>New Study Area</span>
                    <BorneSmallPlus className="sm:tw-hidden" />
                  </BorneSmallButton>
                </div>
                <p className="smallText">
                  Markets in not your subscription are locked please contact support to unlock them
                </p>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentContainer>
    </PageContainer>
  );
};
