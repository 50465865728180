import React, { useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { Button, Form, Segment } from 'semantic-ui-react';
import Auth from '../../auth/Auth';

declare const Plaid: any;

interface Props {
  auth: Auth;
}

const Ach: React.FunctionComponent<Props> = (props: Props) => {
  const { auth } = props;

  const [isCustomer, setIsCustomer] = useState<boolean>();

  const [numCredits, setNumCredits] = useState<number>();

  const connectBankAccount = async () => {
    const response = await auth.authenticatedAPICall({
      method: 'post',
      url: '/api/payments/ach/',
    });

    const { data } = response;
    initializePlaidLink(data.link_token);
  };

  const initializePlaidLink = (linkToken: string) => {
    const configs = {
      token: linkToken,
      onLoad: function () {
        // The Link module finished loading.
      },
      onSuccess: (public_token: string, metadata: any) => {
        // The onSuccess function is called when the user has
        // successfully authenticated and selected an account to
        // use.
        //
        // When called, you will send the public_token
        // and the selected account ID, metadata.accounts,
        // to your backend app server.
        //
        // sendDataToBackendServer({
        //   public_token: public_token,
        //   account_id: metadata.accounts[0].id
        // });
        console.log('Public Token: ' + public_token);
        switch (metadata.accounts.length) {
          case 0:
            // Select Account is disabled: https://dashboard.plaid.com/link/account-select
            break;
          case 1:
            connectWithStripe(public_token, metadata.accounts[0].id);
            break;
          default:
            // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
            break;
        }
      },
      onExit: async (err: any, metadata: any) => {
        // The user exited the Link flow.
        if (err != null) {
          // The user encountered a Plaid API error
          // prior to exiting.
        }
        // metadata contains information about the institution
        // that the user selected and the most recent
        // API request IDs.
        // Storing this information can be helpful for support.
      },
    };

    Plaid.create(configs).open();
  };

  const connectWithStripe = async (public_token: string, account_id: string) => {
    await auth.authenticatedAPICall({
      method: 'post',
      url: '/api/payments/ach/',
      data: { public_token, account_id },
    });

    await fetchNumCredits();

    setIsCustomer(true);
  };

  const purchaseCredits = async (credits: string) => {
    await auth.authenticatedAPICall({
      method: 'post',
      url: '/api/payments/purchase/',
      data: { credits: parseInt(credits) },
    });

    await fetchNumCredits();
  };

  const fetchNumCredits = async () => {
    const response = await auth.authenticatedAPICall({
      method: 'get',
      url: '/api/users/me/',
    });

    setNumCredits(response.data.credits);
  };

  return !isCustomer ? (
    <Segment padded textAlign="center" style={{ margin: '50px auto', width: '50%' }}>
      <Button primary onClick={connectBankAccount}>
        Connect bank account
      </Button>
    </Segment>
  ) : (
    <>
      <Segment padded textAlign="center" style={{ margin: '50px auto 0', width: '50%' }}>
        Number of credits: {numCredits || 0}
      </Segment>
      <Segment padded textAlign="center" style={{ margin: '10px auto', width: '50%' }}>
        <Formik initialValues={{ credits: '' }} onSubmit={({ credits }) => purchaseCredits(credits)}>
          {(formikBag) => (
            <Form as={FormikForm} onSubmit={formikBag.handleSubmit}>
              <Form.Input
                label="Purchase Credits"
                name="credits"
                value={formikBag.values.credits}
                onChange={formikBag.handleChange}
                placeholder="0"
              />
              <Button type="submit">Purchase</Button>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
};

export default Ach;
