import React, { useRef } from 'react';
import { Grid, Ref, Segment, Sticky } from 'semantic-ui-react';

// TODO: Build out report layout for the new style of report pages here in a generic way for re-use in both
//    the individual report page and the market / market detail report pages.

// withReportRow will return a grid row fragment with the desired column layout from two provided component parameters
interface Props {
  DescriptionComponent: JSX.Element;
  DataComponent: JSX.Element;
}

export const ReportRowLayout: React.FunctionComponent<Props> = (props: Props) => {
  const { DescriptionComponent, DataComponent } = props;
  const menuRef: any = useRef();

  return (
    <>
      <Grid
        padded
        style={{
          paddingTop: '10vh',
          width: 'inherit',
        }}
      >
        <Ref innerRef={menuRef}>
          <Grid.Row>
            <Grid.Column width={4} style={{ paddingLeft: '1em' }}>
              <Sticky
                context={menuRef}
                offset={100}
                styleElement={{ zIndex: 100, borderLeft: '1px solid rgba(0,0,0,.1)', paddingLeft: '1em' }}
              >
                {DescriptionComponent}
              </Sticky>
            </Grid.Column>
            <Grid.Column width={12}>
              <Segment padded>{DataComponent}</Segment>
            </Grid.Column>
          </Grid.Row>
        </Ref>
      </Grid>
    </>
  );
};
