import { Description, Header } from '../../styled/Typography';
import React, { useState } from 'react';
import { Container, Grid, List, Loader, Search, Image, Radio } from 'semantic-ui-react';
import Auth from '../../../auth/Auth';
import { useMarketChains } from '../../../api/requests';
import { MarketTerritoryMap } from '../../maps/MarketTerritoryMap';

interface DescriptionProps {
  studyAreaRes: any;
  auth: Auth;
  setSelectedBrand: React.Dispatch<string>;
  selectedBrand?: string;
  setFetching: React.Dispatch<boolean>;
  isFetching: boolean;
}

export const StudyAreaDetailTerritoryPlanningDescription: React.FC<DescriptionProps> = ({
  auth,
  studyAreaRes,
  setSelectedBrand,
  selectedBrand,
  setFetching,
  isFetching,
}) => {
  const [brandSearchKey, setBrandSearchKey] = useState<string>('');
  const marketBrands = useMarketChains(auth, studyAreaRes.data?.market, {
    enabled: studyAreaRes.isSuccess,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <>
        <Header as="h2">Territory Planning</Header>
        <Description as="p">
          To help select sites, here we show the territory of the closest location within a five minute drive.
        </Description>
        <Search
          input={{
            fluid: true,
          }}
          style={{
            paddingBottom: '1em',
          }}
          open={false}
          placeholder="Search..."
          onSearchChange={(_, { value }) => setBrandSearchKey(value ?? '')}
        />
        <Container fluid style={{ height: '30vh', overflowY: 'scroll' }}>
          <List verticalAlign="middle">
            {marketBrands.isSuccess &&
              !marketBrands.isFetching &&
              marketBrands.data
                .filter((brand) => !!brand.name && brand.name.toLowerCase().includes(brandSearchKey.toLowerCase()))
                .map((marketBrand: any) => (
                  <List.Item key={marketBrand.name}>
                    <List.Content floated="left">
                      {!isFetching || selectedBrand != marketBrand.cid ? (
                        <Image
                          src={`/static/icons/${marketBrand.cid}.png`}
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                            marginRight: '8px',
                          }}
                          height="32px"
                          width="32px"
                          circular
                          inline
                        />
                      ) : (
                        <Loader
                          active
                          inline
                          size="small"
                          style={{
                            marginLeft: '-2px',
                            marginRight: '10px',
                            height: '32px',
                            width: '32px',
                          }}
                        />
                      )}
                      {marketBrand.name} ({marketBrand.total})
                    </List.Content>
                    <List.Content
                      style={{
                        marginTop: '6px',
                        marginRight: '5px',
                      }}
                      floated="right"
                    >
                      <Radio
                        checked={selectedBrand == marketBrand.cid}
                        onClick={() => {
                          setSelectedBrand(marketBrand.cid);
                          setFetching(true);
                        }}
                      />
                    </List.Content>
                  </List.Item>
                ))}
          </List>
        </Container>
      </>
    </>
  );
};

interface DataProps {
  studyAreaRes: any;
  auth: Auth;
  selectedBrand?: string;
  setFetching: React.Dispatch<boolean>;
  entLocId: string;
}

export const StudyAreaDetailTerritoryPlanningData: React.FC<DataProps> = ({
  studyAreaRes,
  auth,
  selectedBrand,
  setFetching,
  entLocId,
}) => {
  return studyAreaRes.isSuccess ? (
    <Grid attached="top" textAlign="center">
      <Grid.Row>
        <Grid.Column style={{ height: '60vh' }}>
          <MarketTerritoryMap
            auth={auth}
            marketId={studyAreaRes.data.market}
            chainId={selectedBrand}
            setFetching={setFetching}
            entLocId={entLocId}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    <div className="tw-min-h-[5rem]">
      <Loader active size="massive" />
    </div>
  );
};
