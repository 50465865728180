import React, { Component } from 'react';
import { Header as BaseHeader, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { formatNumber } from '../../helpers';
import { Concept, Walkscore } from '../../types';

interface Props {
  color: SemanticCOLORS;
  concept: Concept;
  walkScoreDict: Walkscore;
}

class WalkTransitScoreSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">
          {this.props.concept.restaurant_type === 'destination' ? 'Transit Score' : 'Walk Score'}
        </BaseHeader>
        {this.props.concept.restaurant_type === 'destination' ? (
          <span>
            <p>Destination restaurants need to be in a place that is easily reached by transit.</p>
            <p>
              {this.props.walkScoreDict.error ? (
                this.props.walkScoreDict.error
              ) : (
                <span>
                  The transit score for this area is <b>{formatNumber(this.props.walkScoreDict['transitScore']!)}</b>.{' '}
                </span>
              )}
              {this.props.walkScoreDict['otherScore'] ? (
                <span>
                  {' '}
                  For comparison, the walk score for this area is{' '}
                  {formatNumber(this.props.walkScoreDict['otherScore']!)}.
                </span>
              ) : (
                <span> No walk score for this area was found.</span>
              )}
            </p>
          </span>
        ) : (
          <span>
            <p>Neighborhood restaurants need to be in a place that is easily walkable.</p>
            <p>
              {this.props.walkScoreDict.error ? (
                this.props.walkScoreDict.error
              ) : (
                <span>
                  The walk score for this area is <b>{formatNumber(this.props.walkScoreDict['walkScore']!)}</b>.
                </span>
              )}
              {this.props.walkScoreDict['otherScore'] ? (
                <span>
                  {' '}
                  For comparison, the transit score for this area is{' '}
                  {formatNumber(this.props.walkScoreDict['otherScore']!)}.
                </span>
              ) : (
                <span> No transit score for this area was found.</span>
              )}
            </p>
          </span>
        )}
      </Segment>
    );
  }
}

export default WalkTransitScoreSegment;
