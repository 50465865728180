import React, { Component } from 'react';
import { Icon } from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Header as BaseHeader, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { BusinessesNearby, Concept } from '../../types';

interface Props {
  businessesNearbyDict: BusinessesNearby;
  color: SemanticCOLORS;
  concept: Concept;
  conceptLatLng: [number, number];
  limit?: number;
}

// The default bundler configuration will not work with the way leaflet loads
// its images, so doing this avoids seeing broken image icons.
const markerIcon = new Icon({ iconUrl: markerIconPng });

class NearbyBusinessesSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">Nearby Businesses</BaseHeader>
        {this.props.concept.is_near_office_park_or_tourism ? (
          <p>
            Because the restaurant is in an office park or a tourist destination, it does well if there are nearby
            businesses to drive traffic to the restaurant.
          </p>
        ) : (
          <p>
            Since the restaurant is not near an office park or a tourist destination, it is important to consider the
            types of business that the nearby businesses will attract.
          </p>
        )}
        {this.props.businessesNearbyDict.businesses && (
          <MapContainer center={this.props.conceptLatLng} zoom={15} style={{ height: 320 }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {this.props.businessesNearbyDict.businesses.slice(0, this.props.limit).map((restaurant, id) => (
              <Marker key={id} position={[restaurant.latitude, restaurant.longitude]} icon={markerIcon}>
                <Popup key={id}>
                  <h3 id="firstHeading" className="firstHeading">
                    {restaurant.name}
                  </h3>
                  <div id="bodyContent">
                    <p>
                      <ul>
                        <li>Distance from Concept: {restaurant.distanceMeters.toFixed(2)} meters</li>
                      </ul>
                    </p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        )}
      </Segment>
    );
  }
}

export default NearbyBusinessesSegment;
