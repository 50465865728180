import React, { Component } from 'react';
import { Header as BaseHeader, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { formatNumber } from '../../helpers';
import { Parking } from '../../types';

interface Props {
  color: SemanticCOLORS;
  parkingDict: Parking;
}

class NearbyParkingSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">Nearby Parking</BaseHeader>
        <p>
          Considering parking is absolutely necessary when trying to decide where to plant a concept. If parking is
          unavailable, then few factors can make up for this unavailability.
        </p>
        <p>
          There is a parking structure within <b>{formatNumber(this.props.parkingDict['radiusMeters'])} meters </b> of
          the concept&apos;s proposed location.
        </p>
      </Segment>
    );
  }
}

export default NearbyParkingSegment;
