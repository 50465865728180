import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Auth from '../../../auth/Auth';
import LoginForm from './LoginForm';

declare var VERSION: string;

const LoginSplashscreen = styled('div')`
  background-image: url('/static/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  margin: 0 0 0 0;
`;

const FullHeightWidthGrid = styled(Container)`
  height: 100vh;
  width: 100vw;
`;

interface Props {
  auth: Auth;
  rerender: () => void;
}

const LandingPage: React.FunctionComponent<Props> = ({ auth, rerender }: Props) => {
  console.log('Version: ', VERSION);

  return (
    <LoginSplashscreen>
      <FullHeightWidthGrid container verticalAlign="middle">
        <Grid.Row style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid.Column>
            <Segment raised>
              <LoginForm auth={auth} rerender={rerender} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </FullHeightWidthGrid>
    </LoginSplashscreen>
  );
};

export default LandingPage;
