import Auth from 'borne_ui/src/auth/Auth';
import React, { useMemo } from 'react';
import { LatLngBoundsExpression, Layer as LeafletLayer } from 'leaflet';
import { TileLayer, useMap, Pane, Marker } from 'react-leaflet';
import GeoJSON from '../generic/GeoJSON';
import { Loader } from 'semantic-ui-react';
import { useMarketGeo, useMarketTerritory } from '../../api/requests';
import withMapContext from './WithMapContext';
import { divIcon } from 'leaflet';

interface Props {
  auth: Auth;
  marketId: string;
  chainId?: string;
  entLocId: string;
  setFetching: React.Dispatch<boolean>;
}

const MarketTerritoryMapComponent: React.FC<Props> = ({ auth, marketId, chainId, setFetching, entLocId }: Props) => {
  const map = useMap();

  const territoryRes = useMarketTerritory(auth, marketId, chainId, entLocId, {
    enabled: !!chainId,
    refetchOnWindowFocus: false,
    onSuccess: (_) => {
      setFetching(false);
      map.invalidateSize();
    },
  });

  const onEachFeatureDetail = (feature: any, layer: LeafletLayer) => {
    if (feature.properties && feature.properties.address) {
      layer.bindTooltip(
        `${feature.properties.address === 'entloc' ? 'Proposed Location' : feature.properties.address}`,
        { sticky: true, opacity: 0.9 }
      );
    }
  };

  const marketGeoRes = useMarketGeo(auth, marketId, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      map.fitBounds([data.bbox!.slice(0, 2).reverse(), data.bbox!.slice(2).reverse()] as LatLngBoundsExpression);
      map.invalidateSize();
    },
  });

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
        zIndex={100}
      />
    ),
    []
  );

  return (
    <>
      {marketGeoRes.isSuccess && !marketGeoRes.isFetching ? (
        <>
          <Pane name="data" style={{ zIndex: 200 }}>
            {territoryRes.isSuccess && !territoryRes.isFetching && (
              <GeoJSON
                data={territoryRes.data.isochrones}
                onEachFeature={onEachFeatureDetail}
                style={(datum) => ({
                  color: datum?.properties.address == 'entloc' ? 'rgba(0,0,255,.8)' : 'rgba(0,0,0,.8)',
                  weight: 1,
                  fillOpacity: 0.7,
                  zIndex: 10,
                  fillColor: datum?.properties.address == 'entloc' ? 'rgba(0,0,255,.4)' : 'rgba(0,0,0,.4)',
                })}
              />
            )}
          </Pane>
          <Pane name="BrandsPane" style={{ zIndex: 405 }}>
            {territoryRes.isSuccess &&
              !territoryRes.isFetching &&
              Object.entries(territoryRes.data.locations)
                .filter(([fsq_id, _]) => fsq_id === 'entloc')
                .map(([fsq_id, [lng, lat]]: any) => {
                  return (
                    <Marker
                      position={[lat, lng]}
                      icon={divIcon({
                        html: `
            <div>
            <img style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; background-color: white;" 
            src="/static/icons/${chainId}.png"
            aria-hidden="true"
            class="ui circular image brand-icon"
            data-chain-id="${chainId}" data-location-id="${fsq_id}" />
            </div>`,
                        className: '',
                        iconSize: [32, 32],
                      })}
                    />
                  );
                })}
          </Pane>
          {Tiles}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export const MarketTerritoryMap = withMapContext(MarketTerritoryMapComponent, { height: '60vh' });
