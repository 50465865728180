import Auth from 'borne_ui/src/auth/Auth';
import React from 'react';

interface Props {
  auth: Auth;
}

export const Sites: React.FC<Props> = ({ auth }: Props) => {
  return <></>;
};
