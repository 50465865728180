import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Card, Grid, Header } from 'semantic-ui-react';
import Auth from '../../auth/Auth';
import { Concept } from '../concept/concept';
import ConceptCard from '../concept/ConceptCard';
import DataTable from '../generic/DataTable';
import TableData from '../generic/DataTable/DataTable';
import withSideMenu from '../generic/MenuPage';

interface Props {
  auth: Auth;
}

const Concepts: React.FunctionComponent<Props> = (props: Props) => {
  const { auth } = props;

  // Retrieve Concepts and store in hook
  const [concepts, setConcepts] = useState<Concept[]>();

  const navigate = useNavigate();

  useEffect(() => {
    if (auth.hasCredentials()) {
      (async () => {
        const { data } = await auth.authenticatedAPICall({
          method: 'get',
          url: '/api/concepts/?ordering=-updated',
        });

        setConcepts(data);
      })();
    }
  }, []);

  const enumerateConcepts = () => {
    return concepts
      ?.map((concept, index: number) => {
        return <ConceptCard key={index} concept={concept} />;
      })
      .slice(0, 3);
  };

  const preprocessConcepts = (): Array<TableData> => {
    return concepts!.map((concept) => {
      return {
        id: concept.id ?? '',
        address: concept.address ?? '',
        cuisine: concept.cuisine,
        price: concept.price_level,
      };
    });
  };

  return (
    <Grid style={{ paddingTop: '4vh', paddingLeft: '4vw', paddingRight: '4vw' }} verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={12}>
          <Header as="h1">Concepts</Header>
        </Grid.Column>
        <Grid.Column width={4} floated="right">
          <Button
            color="black"
            fluid
            onClick={() => {
              navigate('/concept');
            }}
          >
            New Concept
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Card.Group itemsPerRow={3} style={{ marginBottom: '4px' }}>
          {concepts && enumerateConcepts()}
        </Card.Group>
        {concepts && (
          <DataTable
            data={preprocessConcepts()}
            idColumn="id"
            basePath="/concept/"
            tableProps={{ size: 'small', compact: true, stackable: true, celled: false }}
            detailPage
          />
        )}
      </Grid.Row>
    </Grid>
  );
};

export default withSideMenu(Concepts, { activeItem: 'concepts' });
