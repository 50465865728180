import BarChart from '@bornellc/bach/BarChart';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { ChartHeader } from '../../styled/Typography';
import BarChartToggleNormalize from '../../generic/BarChartToggleNormalize';

interface Props {
  data: any;
}

const MarketDemographicsContents: React.FC<Props> = (props: Props) => {
  function computeRaceOther(record: any) {
    return (
      (record['race_american_indian_alaska_native'] || 0) +
      (record['race_hawaiian_pacific'] || 0) +
      (record['race_other_race_alone'] || 0) +
      (record['race_two_or_more_races'] || 0)
    );
  }

  function computeUnder18(record: any) {
    return (record['age_lte_4'] || 0) + (record['age_5_14'] || 0) + (record['age_15_17'] || 0);
  }

  const { data } = props;

  return (
    <Grid columns={2} doubling>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Age</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="market"
            y={[
              { key: 'age_lt_18', label: '< 18' },
              { key: 'age_18_24', label: '18 to 24' },
              { key: 'age_25_34', label: '25 to 34' },
              { key: 'age_35_64', label: '35 to 64' },
              { key: 'age_65_plus', label: '65+' },
            ]}
            data={data.map((record: any) => ({
              ...record,
              age_lt_18: computeUnder18(record),
            }))}
            dataKey="age_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Household Income</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="market"
            y={[
              { key: 'household_income_group_lte_10000', label: '≤ 10k' },
              { key: 'household_income_group_10000_40000', label: '10k to 40k' },
              { key: 'household_income_group_40000_75000', label: '40k to 75k' },
              { key: 'household_income_group_75000_125000', label: '75k to 125k' },
              { key: 'household_income_group_125000_plus', label: '125k+' },
            ]}
            data={data}
            dataKey="hi_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Race</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="market"
            y={[
              { key: 'race_white', label: 'White' },
              { key: 'race_black_african_american', label: 'Black' },
              { key: 'race_asian', label: 'Asian' },
              { key: 'race_hispanic_or_latino', label: 'Hispanic or Latino' },
              { key: 'race_other', label: 'Other' },
            ]}
            data={data.map((record: any) => ({
              ...record,
              race_other: computeRaceOther(record),
            }))}
            dataKey="race_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Education</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="market"
            y={[
              { key: 'education_bachelors_degree', label: 'Bachelors' },
              { key: 'education_some_college', label: 'Some College' },
              { key: 'education_high_school', label: 'High School' },
              { key: 'education_k_12', label: 'K-12' },
            ]}
            data={data}
            dataKey="education_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Family Structure</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="market"
            y={[
              { key: 'family_structure_living_alone', label: 'Living Alone' },
              { key: 'family_structure_family_single', label: 'Single, Family' },
              { key: 'family_structure_nonfamily_single', label: 'Single, Non-family' },
              { key: 'family_structure_married_couple', label: 'Married' },
              { key: 'family_structure_group_quarters', label: 'Group Quarters' },
            ]}
            data={data}
            dataKey="age_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Tenure</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="market"
            y={[
              { key: 'tenure_owner', label: 'Owner' },
              { key: 'tenure_renter', label: 'Renter' },
              { key: 'tenure_group_quarters', label: 'Group Quarters' },
            ]}
            data={data}
            dataKey="tenure_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Employment</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="market"
            y={[
              { key: 'employment_employed', label: 'Employed' },
              { key: 'employment_unemployed', label: 'Unemployed' },
              { key: 'employment_notinlf', label: 'Not in Labor Force' },
            ]}
            data={data}
            dataKey="employment_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
    </Grid>
  );
};

export default MarketDemographicsContents;
