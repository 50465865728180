import Auth from 'borne_ui/src/auth/Auth';
import React, { useMemo } from 'react';
import { TileLayer } from 'react-leaflet';
import GeoJSON from '../generic/GeoJSON';
import { Loader } from 'semantic-ui-react';
import { useMarketGeo } from '../../api/requests';
import withMapContext from './WithMapContext';

interface Props {
  auth: Auth;
  marketId: string;
}

const MarketSingleMapComponent: React.FC<Props> = ({ auth, marketId }: Props) => {
  const marketGeoRes = useMarketGeo(auth, marketId, {
    refetchOnWindowFocus: false,
  });

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
    ),
    []
  );

  return (
    <>
      {marketGeoRes.isSuccess ? (
        <>
          {Tiles}
          <GeoJSON
            data={marketGeoRes.data}
            style={(_) => ({
              color: '#0c7c59',
            })}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export const MarketSingleMap = withMapContext(MarketSingleMapComponent, { height: '100%' });
