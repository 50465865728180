import React from 'react';
import yelpCategories from '../../data/yelpCategories';
import { WrappedDropdownField } from './FormikUtil';

interface Props {
  loc: string;
  label: string;
}

const CategoriesQuestion = ({ loc, label }: Props) => {
  return (
    <WrappedDropdownField
      label={label}
      name={loc}
      options={yelpCategories}
      placeholder="Select Categories"
      fluid
      multiple
      search
      selection
    />
  );
};

export default CategoriesQuestion;
