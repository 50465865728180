import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Auth from '../../auth/Auth';

interface Props {
  auth: Auth;
  rerender: () => void;
}

const Logout: React.FC<Props> = (props: Props) => {
  const { auth, rerender } = props;
  const navigate = useNavigate();

  useEffect(() => {
    auth.deleteCredentials();
    navigate('/');
    rerender();
  });

  return <p>Logging you out!</p>;
};

export default Logout;
