import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, Icon, IconProps, Modal, ModalProps, SemanticShorthandItem, Step } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import styled from '@emotion/styled';
import { useUpdateConcept } from '../../api/requests';
import Auth from '../../auth/Auth';
import AlcoholQuestion from '../questions/AlcoholQuestion';
import PriceQuestion from '../questions/PriceQuestion';
import RevenueMultiplierQuestion from '../questions/RevenueMultiplierQuestion';
import NameQuestion from '../questions/NameQuestion';
import CuisineQuestion from '../questions/CuisineQuestion';
import HoursOfOperationQuestion from '../questions/HoursOfOperationQuestion';
import BrandIdQuestion from '../questions/BrandIdQuestion';
import RestaurantCategoryQuestion from '../questions/RestaurantCategoryQuestion';
import { ConceptType } from '../../types';

interface Props extends ModalProps {
  auth: Auth;
  handleClose: () => void;
  concept?: any;
}

const NewConceptModalHeader = styled(Modal.Header)`
  &&&& {
    padding: 0;
  }
`;

type NewConceptStep = 'Basics' | 'Details';

interface NewConceptStepTemplate {
  title: NewConceptStep;
  icon: SemanticShorthandItem<IconProps>;
  description: string;
}

const NEW_CONCEPT_STEP_TEMPLATES: NewConceptStepTemplate[] = [
  {
    title: 'Basics',
    icon: 'info circle',
    description: 'Get started',
  },
  {
    title: 'Details',
    icon: 'utensils',
    description: 'Tell us about your concept',
  },
];

interface BasicsProps {
  auth: Auth;
}

const Basics = ({ auth }: BasicsProps) => (
  <>
    <NameQuestion loc="name" />
    <br />
    {auth ? <BrandIdQuestion loc="brand_id" auth={auth} /> : <></>}
    <br />
    <CuisineQuestion loc="cuisine" />
    <br />
    <PriceQuestion loc="price_level" />
    <br />
    <RevenueMultiplierQuestion loc="revenue_multiplier" />
  </>
);

const Details = (props: any) => (
  <>
    <RestaurantCategoryQuestion loc="restaurant_category" label="What type of restaurant are you?" />
    <br />
    <AlcoholQuestion loc="alcohol_service" />
    <br />
    <HoursOfOperationQuestion loc="hours_of_operation" formikBag={props.formikBag} />
  </>
);

const Submit = () => {
  const { submitForm } = useFormikContext();

  return (
    <Button onClick={() => submitForm()} primary>
      Save
    </Button>
  );
};

const NewConceptModal = (props: Props) => {
  const { auth, handleClose, concept, ...modalProps } = props;

  const [activeStep, setActiveStep] = useState<NewConceptStep>('Basics');

  const updateConcept = useUpdateConcept(auth);

  function internalHandleClose() {
    setActiveStep('Basics');
    handleClose();
  }

  function getStepContent(step: NewConceptStep, formikBag: any) {
    switch (step) {
      case 'Basics':
        return <Basics auth={auth} />;
      case 'Details':
        return <Details formikBag={formikBag} />;
    }
  }

  function getModalActions(step: NewConceptStep) {
    switch (step) {
      case 'Basics':
        return (
          <Modal.Actions>
            <Button onClick={internalHandleClose} secondary>
              Cancel
            </Button>
            <Button onClick={() => setActiveStep('Details')} primary>
              Next <Icon name="chevron right" />
            </Button>
          </Modal.Actions>
        );
      case 'Details':
        return (
          <Modal.Actions>
            <Button onClick={() => setActiveStep('Basics')} primary>
              <Icon name="chevron left" /> Previous
            </Button>
            <Button onClick={internalHandleClose} secondary>
              Cancel
            </Button>
            <Submit />
          </Modal.Actions>
        );
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        concept_type: 'scale_brand' as ConceptType,
        name: concept?.name || '',
        address: concept?.addess || '',
        cuisine: concept?.cuisine || '',
        hours_of_operation: concept?.hours_of_operation || null,
        brand_id: concept?.brand_id || '',
        service_type: concept?.service_type || '',
        restaurant_category: concept?.restaurant_category || '',
        states: new Set<string>(),
        size: null,
        price_level: concept?.price_level || '',
        alcohol_service: concept?.alcohol_service || 'none',
        revenue_multiplier: concept?.revenue_multiplier,
      }}
      validationSchema={Yup.object().shape({
        project: Yup.string(),
        name: Yup.string().label('Concept name').required(),
        cuisine: Yup.string().label('Cuisine').required(),
        price_level: Yup.string().label('Price level').required(),
      })}
      onSubmit={async (values) => {
        await updateConcept.mutateAsync({
          id: concept?.id,
          ...values,
        });

        internalHandleClose();
      }}
    >
      {(bag) => (
        <Modal {...modalProps}>
          <NewConceptModalHeader>
            <Step.Group attached>
              {NEW_CONCEPT_STEP_TEMPLATES.map((template) => (
                <Step
                  active={activeStep === template.title}
                  icon={template.icon}
                  link
                  onClick={() => setActiveStep(template.title)}
                  title={template.title}
                  key={template.title}
                  description={template.description}
                />
              ))}
            </Step.Group>
          </NewConceptModalHeader>
          <Modal.Content scrolling>{getStepContent(activeStep, bag)}</Modal.Content>
          {getModalActions(activeStep)}
        </Modal>
      )}
    </Formik>
  );
};

export default NewConceptModal;
