import { Dropdown, Tab } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { Checkbox, Switch } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';

interface Props {
  active: boolean;
}

export const jLLRed = '#e30311';
export const jLLLightRed = `#ffcccc`;
export const borneBlue = '#2a4e55';
export const borneLightBlue = `#edf7f8`;

export const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  input {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    line-height: 1.21428571em;
    padding: 0.67857143em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color 0.1s ease, border-color 0.1s ease;
  }
  input:focus {
    border-color: rgb(144 182 214);
  }
  margin-bottom: 5rem;
  .helpIcon {
    color: #2a4e55 !important;
  }
`;

export const BorneFlexContainer = styled.div`
  height: max-content;
  display: flex;
  align-items: center;
  width: 100%;
  p {
    margin: 0;
  }
  & .menu::-webkit-scrollbar-track {
    display: none;
  }
`;

export const ReportPage = styled(PageContainer)`
  background-image: url('/static/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  scroll-behavior: smooth;
  padding: 4rem 7% 3rem 7%;
  align-items: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 16px;
  .searchAddress {
    .input {
      width: 100%;
    }
    input {
      border-radius: 0.28571429rem !important;
    }
  }

  .errored {
    border-color: red;
    input {
      border-color: red !important;
    }
    .input {
      border-color: red !important;
    }
    border-color: red !important;
  }

  .bar {
    background-color: rgb(184 217 71) !important;
  }
`;

export const ContentContainer = styled(BorneFlexContainer)`
  overflow-y: scroll;
  align-items: flex-start;
  height: 100%;
  .ui.menu {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
  }
`;

export const SidebarIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const BorneGrid = styled.div`
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  align-items: stretch;
  padding: 0;
`;

export const BorneSmallButton: any = styled.button`
  & span,
  a {
    font-size: 14px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 2px;
  }
  white-space: nowrap;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito Sans', sans-serif;
  border-radius: 0.28571429rem;
  border: 0;
  background-color: ${(props: Props) => (props.active ? borneBlue : '#BBBfc1')};
  color: ${(props: Props) => (props.active ? 'white' : 'black')};
  size: 75%;
  cursor: pointer;
  &:hover {
    background-color: ${(props: Props) => (props.active ? '#bccd19' : '#BBBfc1')};
  }
`;

export const BorneLargeButton = styled.button`
  span,
  a {
    font-size: 18px;
    padding-left: 10px;
    color: white !important;
    font-family: 'Roboto Condensed', sans-serif;
    padding-right: 10px;
  }
  font-size: 18px;
  padding-left: 10px;
  color: white !important;
  font-family: 'Roboto Condensed', sans-serif;
  padding-right: 10px;
  display: flex;
  width: fit-content;
  height: 50px;
  font-family: 'Roboto Condensed', sans-serif;
  margin-left: 3.5%;
  border: 0;
  border-radius: 0.28571429rem;
  background-color: ${borneBlue};
  color: white;
  size: 75%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #bccd19;
  }
`;

export const BorneDropDown: any = styled(Dropdown)`
  font-size: 10px !important;
  margin-left: 5%;
  z-index: 1000 !important;
`;

export const BorneCheckbox = styled(Checkbox)`
  .pretty.p-default input:checked ~ .state label:after {
    background-color: green !important;
  }
`;

export const BorneToggle = styled(Switch)`
  & .pretty.p-switch input:checked ~ .state label:after {
    background-color: red !important;
    left: 1em;
  }
`;

export const BorneTab = styled(Tab)`
  .item {
    color: #7cb0ba !important;
  }
  .active {
    color: #bccd19 !important;
  }
`;
