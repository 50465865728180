import React from 'react';
import { Grid, Header, Segment, Table } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { days, meals } from '../../data/categories';
import { formatNumber } from '../../helpers';

interface Props {
  concept: any;
}

// TODO: Break down concept definition from current results page into re-usable component here.
const ConceptQuestionCell = styled(Grid.Column)`
  font-weight: bold;
`;

const ConceptTableCell = styled(Table.Cell)`
  width: 20px;
`;

export const ConceptTable: React.FunctionComponent<Props> = (props: Props) => {
  const { concept } = props;
  return (
    <>
      <Segment attached>
        <Header textAlign="center">Your Concept</Header>
        <Grid celled columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="6">Address</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.address}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="8">Community Type</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.communityType}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="6">Cuisine</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.cuisine}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="8">Office Park or Tourist Spot</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.officeParkTourism}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="6">Catergories</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.categories}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="8">Restaurant Type</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.restaurantType}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="6">SEO terms</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.threeWords}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="8">Service Type</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.serviceType}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="6">Restaurant Style</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.restaurantStyles}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="8">Service Quality</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.serviceQuality}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="6">Restuarant Size</ConceptQuestionCell>
                  <Grid.Column>
                    {concept.concept.restaurantSize.squareFootage ? (
                      <span>{formatNumber(concept.concept.restaurantSize.squareFootage)} sq. ft.</span>
                    ) : (
                      <span>
                        {formatNumber(concept.concept.restaurantSize.size || 0)} {concept.concept.restaurantSize.units}
                      </span>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="8">Private Dining</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.privateDining ? 'Yes' : 'No'}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="6">Third Party Delivery</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.thirdPartyDelivery ? 'Yes' : 'No'}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="8">Price Level</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.price}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="6">Alcohol</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.alcohol}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="8">Scale</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.scale ? 'Yes' : 'No'}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="6">Contract Service</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.conctract ? 'Yes' : 'No'}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="8">Reservation</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.reservation ? 'Yes' : 'No'}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="6">Affiliation</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.affiliations}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell width="8">Parking Lot</ConceptQuestionCell>
                  <Grid.Column>{concept.concept.parkingLot ? 'Yes' : 'No'}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns="equal">
                <Grid.Row>
                  <ConceptQuestionCell textAlign="center">Hours of Operation</ConceptQuestionCell>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Table>
                      <Table.Row>
                        <ConceptTableCell />
                        <ConceptTableCell>Sun</ConceptTableCell>
                        <ConceptTableCell>Mon</ConceptTableCell>
                        <ConceptTableCell>Tue</ConceptTableCell>
                        <ConceptTableCell>Wed</ConceptTableCell>
                        <ConceptTableCell>Thurs</ConceptTableCell>
                        <ConceptTableCell>Fri</ConceptTableCell>
                        <ConceptTableCell>Sat</ConceptTableCell>
                      </Table.Row>
                      {meals.map((meal, i) => (
                        <Table.Row key={i}>
                          <ConceptTableCell>{meal}</ConceptTableCell>
                          {days.map((day, j) =>
                            concept.concept.hoursOfOperation[day][meal] ? (
                              <ConceptTableCell key={j} positive></ConceptTableCell>
                            ) : (
                              <ConceptTableCell key={j} negative></ConceptTableCell>
                            )
                          )}
                        </Table.Row>
                      ))}
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};
