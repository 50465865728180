import React, { Component } from 'react';
import { Icon } from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Header as BaseHeader, Segment } from 'semantic-ui-react';
import { BarsNearby } from '../../types';

interface Props {
  conceptLatLng: [number, number];
  barsNearby: BarsNearby;
}

// The default bundler configuration will not work with the way leaflet loads
// its images, so doing this avoids seeing broken image icons.
const markerIcon = new Icon({ iconUrl: markerIconPng });

class NearbyBarsSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment>
        <BaseHeader as="h3">Nearby Bars</BaseHeader>
        <p>
          If you have a full bar, it is important to take into account the surrounding bars. Even if you only serve
          beer/wine, you should still be aware of other alcohol-serving establishments in the surrounding few miles.
        </p>
        <MapContainer center={this.props.conceptLatLng} zoom={15} style={{ height: 320 }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {this.props.barsNearby['bars'].slice(0, 10).map((restaurant, id) => (
            <Marker key={id} position={[restaurant.latitude, restaurant.longitude]} icon={markerIcon}>
              <Popup key={id}>
                <h3 id="firstHeading" className="firstHeading">
                  {restaurant.name}
                </h3>
                <div id="bodyContent">
                  <p>
                    <ul>
                      <li>Distance from Concept: {restaurant.distanceMeters.toFixed(2)} meters</li>
                    </ul>
                  </p>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </Segment>
    );
  }
}

export default NearbyBarsSegment;
