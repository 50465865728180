import React, { Component, Fragment } from 'react';
import { DropdownItemProps, Form, Grid, Header as BaseHeader, Select } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { RestaurantSize, RestaurantSizeUnit, restaurantSizeUnits } from '../../data/categories';
import { DropdownValue } from '../../types';
import { FormikProps } from 'formik';

const Header = styled(BaseHeader)`
  &&&& {
    margin: 50px 0 20px;
  }
`;

function toDropdownOption(unit: RestaurantSizeUnit): DropdownItemProps {
  return {
    text: unit,
    value: unit,
  };
}

function createDefaultRestaurantSize(): RestaurantSize {
  return {
    units: 'seats',
  };
}

interface Props {
  loc: string;
  formikBag: FormikProps<any>;
}

interface State {
  restaurantSize: RestaurantSize;
}

class RestaurantSizeQuestion extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      restaurantSize:
        (props.formikBag.getFieldMeta(props.loc).initialValue as RestaurantSize) || createDefaultRestaurantSize(),
    };
  }

  updateSize = (size: string): void => {
    const { loc, formikBag } = this.props;
    const helpers = formikBag.getFieldHelpers(loc);
    helpers.setTouched(true, false);

    this.setState(
      ({ restaurantSize: prevRestaurantSize }) => ({
        restaurantSize: {
          ...prevRestaurantSize,
          size: parseInt(size),
        },
      }),
      () => helpers.setValue(this.state.restaurantSize)
    );
  };

  updateUnits = (value: DropdownValue): void => {
    const { loc, formikBag } = this.props;
    const helpers = formikBag.getFieldHelpers(loc);
    helpers.setTouched(true, false);

    const unit = value as RestaurantSizeUnit;
    if (!restaurantSizeUnits.includes(unit)) {
      throw new Error(`Unexpected dropdown value ${value}.`);
    }
    this.setState(
      ({ restaurantSize: prevRestaurantSize }) => ({
        restaurantSize: {
          ...prevRestaurantSize,
          units: unit,
        },
      }),
      () => helpers.setValue(this.state.restaurantSize)
    );
  };

  updateSquareFootage = (squareFootage: string): void => {
    const { loc, formikBag } = this.props;
    const helpers = formikBag.getFieldHelpers(loc);
    helpers.setTouched(true, false);

    this.setState(
      ({ restaurantSize: prevRestaurantSize }) => ({
        restaurantSize: {
          ...prevRestaurantSize,
          squareFootage: parseInt(squareFootage),
        },
      }),
      () => helpers.setValue(this.state.restaurantSize)
    );
  };

  render(): JSX.Element {
    return (
      <Fragment>
        <Header as="h3">What is the desired size of your restaurant?</Header>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                onChange={(_, { value }) => this.updateSize(value)}
                value={this.state.restaurantSize.size || ''}
                action
                fluid
              >
                <input />
                <Select
                  options={restaurantSizeUnits.map(toDropdownOption)}
                  onChange={(_, { value }) => this.updateUnits(value)}
                  value={this.state.restaurantSize.units}
                />
              </Form.Input>
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                onChange={(_, { value }) => this.updateSquareFootage(value)}
                value={this.state.restaurantSize.squareFootage || ''}
                placeholder="sq. ft."
                fluid
              ></Form.Input>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

export default RestaurantSizeQuestion;
