import { COLORS } from '@bornellc/bach/Colors';
import { ParentSize } from '@visx/responsive';
import Pie from '@visx/shape/lib/shapes/Pie';
import React from 'react';
import { scaleOrdinal } from '@visx/scale';
import { LegendOrdinal } from '@visx/legend';
import { Divider } from 'semantic-ui-react';

interface Props {
  height: number;
  width: number | string;
  pieProps?: { [key: string]: any };
  data: { [key: string]: any };
  normalize?: boolean;
  keys: Array<string>;
  labels: Array<string>;
  filterZeros?: boolean;
  otherThreshold?: number;
}

const objectToArray = (
  object: { [key: string]: any },
  keys: Array<string>,
  labels: Array<string>,
  filterZeros: boolean,
  normalize: boolean,
  otherThreshold: number
) => {
  const total = keys.reduce((sum, key) => (object[key] as number) + sum, 0);
  const array = keys.map((key, idx) => ({
    name: labels[idx],
    value: (normalize ? object[key] / total : object[key]) as number,
    key: key,
  }));

  const otherKeys = new Set(
    keys.filter(
      (key) =>
        (normalize ? object[key] / total < otherThreshold : object[key] < otherThreshold * total) ||
        key.endsWith('other')
    )
  );
  const filteredArray = array.filter(({ key }) => !otherKeys.has(key));

  filteredArray.push({
    name: 'Other',
    value: array.filter(({ key }) => otherKeys.has(key)).reduce((sum, { value }) => value + sum, 0),
    key: 'other',
  });
  return filteredArray
    .filter(({ value }) => !filterZeros || value)
    .sort((a, b) => b.value - a.value)
    .map((obj, idx) => ({
      ...obj,
      fill: COLORS[idx],
    }));
};

const ObjectPieChart: React.FC<Props> = ({
  height,
  width,
  pieProps,
  data,
  normalize = false,
  keys,
  labels,
  filterZeros = true,
  otherThreshold = 0.03,
}: Props) => {
  const dataArr = objectToArray(data, keys, labels, normalize, filterZeros, otherThreshold);
  const colorScale = scaleOrdinal<string, string>({
    domain: dataArr.map((d) => `${d.name}  ${Math.round(d.value * 100)}%`),
    range: COLORS,
  });
  return (
    <ParentSize>
      {(parent) => (
        <div role="chart">
          <Divider fitted />
          <svg width={parent.width} height={(parent.width / 24) * 9}>
            <Pie
              data={dataArr}
              left={parent.width * 0.5}
              top={(parent.width / 24) * 9 * 0.5}
              outerRadius={parent.width * 0.13}
              fill={(d) => d.data.fill}
              pieValue={(d) => d.value}
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
              }}
            />
          </svg>
          <LegendOrdinal
            scale={colorScale}
            direction="column"
            shape="circle"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              height: `${1.8 * Math.ceil(dataArr.length / 2)}em`,
              fontFamily: 'Nunito Sans',
              lineHeight: '1.8em',
              marginBottom: '2em',
            }}
          />
        </div>
      )}
    </ParentSize>
  );
};

export default ObjectPieChart;
