import React from 'react';
import { MapContainer } from 'react-leaflet';

interface ChildProps {
  [key: string]: any;
}

interface ParentProps {
  height?: string | number;
}

const withMapContext = (ChildComponent: React.FC<any>, pProps: ParentProps) => {
  return (props: ChildProps) => {
    // const { height } = pProps;
    return (
      // Until Leaflet 1.8 is released, with fix to this bug:
      //
      //   https://github.com/Leaflet/Leaflet/issues/7255
      //
      // we need to disable tap.
      //
      <MapContainer attributionControl={false} style={{ height: '100%', width: '100%' }} tap={false}>
        <ChildComponent {...props} />
      </MapContainer>
    );
  };
};

export default withMapContext;
