import React from 'react';

interface Props {
  active?: boolean;
  size?: number;
  className?: string;
  onClick?: () => void;
}

export const HelpSvg = ({ active = false, size = 32, onClick, className }: Props) => {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <path
        fill={active ? '#bccd19' : '#2a4e55'}
        d="M19.98,0.04C8.94,0.04-0.01,8.99-0.01,20.03c0,11.05,8.95,20,19.99,20s19.99-8.95,19.99-20
		C39.97,8.99,31.02,0.04,19.98,0.04z M21.94,30.48h-3.97v-3.91h3.97V30.48z M25.56,17.52c-0.48,0.73-1.15,1.54-2.04,2.43
		c-0.77,0.83-1.35,1.51-1.74,2.04s-0.63,1.13-0.72,1.81l-0.12,0.76h-2.09v-1.13c0-1.04,0.19-1.91,0.57-2.63
		c0.37-0.72,0.92-1.5,1.64-2.35c0.54-0.66,0.95-1.22,1.21-1.67c0.27-0.45,0.4-0.96,0.4-1.53c0-0.64-0.23-1.14-0.68-1.51
		s-1.08-0.55-1.89-0.55c-0.82,0-1.67,0.18-2.56,0.54c-0.9,0.35-1.78,0.86-2.65,1.52l-1.19-2.66c0.78-0.69,1.77-1.25,3-1.66
		c1.23-0.42,2.45-0.63,3.68-0.63c1.15,0,2.17,0.2,3.05,0.58c0.89,0.39,1.58,0.94,2.08,1.64c0.5,0.71,0.75,1.52,0.75,2.42
		C26.26,15.92,26.03,16.78,25.56,17.52z"
      />
    </svg>
  );
};
