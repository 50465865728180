import {
  useConcepts,
  useStudyArea,
  useStudyAreaLocations,
  useStudyAreaMarketPotential,
  useStudyAreaTrends,
  useStudyAreaTS,
} from '../../../api/requests';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import {
  PageContainer,
  ContentContainer,
  BorneFlexContainer,
  BorneSmallButton,
  BorneTab,
  BorneDropDown,
} from '../../generic/BorneComponents';
import SideMenu2 from '../../generic/SideMenu2';
import { DisplayTable } from '../../generic/BorneDisplayTable';
import { formatCurrency, formatNumber } from '../../../helpers';
import { StudyAreasOpenCloseTablesV2 } from './StudyAreasOpenCloseTablesV2';
import { StudyAreaTripTakersV2 } from './StudyAreaTripTakersV2';
import { StudyAreasConsumerSpending } from './StudyAreasConsumerSpending';
import { useStudyAreaConsumerSpending } from '../../../api/requests';
import { StudyAreasMarketSegmentationV2 } from './StudyAreasMarketSegmentationV2';
import { StudyAreasMarketPotentialV2 } from './StudyAreasMarketPotentialV2';
import { StudyAreasTripSourcesV2 } from './StudyAreasTripSourcesV2';
import { StudyAreasTrendingV2 } from './StudyAreasTrendingV2';
import { StudyAreaSingleLocationMap } from '../../maps/StudyAreaSingleLocationMap';
import { BorneSmallPlus } from '../../generic/BorneStyledcons';
import NewSiteReportModal from '../../pages/SiteReportList/NewSiteReportModal';
import { mainActions } from '../../redux/testSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';
import GradeStatistic from './GradeStatistic';

import type Auth from 'borne_ui/src/auth/Auth';
import type { User } from '../../../api/models';
import { DayOfWeek } from '../../../types';

interface Params {
  id: string;
}

interface Props {
  auth: Auth;
  currentMarket?: number;
  user: User;
}

export const StudyAreasDetails: React.FC<Props> = ({ auth, currentMarket, user }: Props) => {
  const [open, setOpen] = useState(false);
  const selectedConceptId = useSelector((state: RootState) => state.main.conceptId);
  const [dayOfWeek, setDayOfWeek] = useState<DayOfWeek>('thu');

  const { id } = useParams<keyof Params>() as Params;
  const studyAreaRes = useStudyArea(auth, id, 'sat', {
    refetchOnWindowFocus: false,
  });

  const dispatch = useDispatch();

  const selectConceptId = (x: number) => {
    dispatch(mainActions.selectConceptId(x));
  };
  const openLocations = useStudyAreaLocations(auth, id, 'open', {
    refetchOnWindowFocus: false,
  });
  const closedLocations = useStudyAreaLocations(auth, id, 'closed', {
    refetchOnWindowFocus: false,
  });
  const consumerSpendingQueryResponse = useStudyAreaConsumerSpending(auth, id);
  const marketQueryResponse = useStudyAreaTS(auth, id);
  const marketPotentialQueryResponse = useStudyAreaMarketPotential(auth, id);
  const trendsResponse = useStudyAreaTrends(auth, id);

  let topLineTableData: Array<{ text: string; text2: string }> = [];
  if (studyAreaRes.isSuccess && studyAreaRes.data[`selected_sa_summaries`]) {
    topLineTableData = [
      {
        text: 'No of Rest. Locations',
        text2: `${formatNumber(studyAreaRes.data[`selected_sa_summaries`][0].number_of_locations)}`,
      },
      {
        text: 'Avg Daily Trips',
        text2: `${formatNumber(studyAreaRes.data[`selected_sa_summaries`][0].trips)}`,
      },
      {
        text: 'Median TT-HHI',
        text2: `${formatCurrency(studyAreaRes.data[`selected_sa_summaries`][0].household_income_median)}`,
      },
      {
        text: 'Median Age',
        text2: `${formatNumber(studyAreaRes.data[`selected_sa_summaries`][0].age_median)}`,
      },
    ];
  }
  const concepts = useConcepts(auth);

  const panes = [
    {
      menuItem: 'Landscape',
      render: () => (
        <StudyAreasOpenCloseTablesV2
          openLocations={openLocations.data!}
          closedLocations={closedLocations.data!}
          // TODO: tni: Handle when data is undefined and get rid of the "!"
          areaName={studyAreaRes.data!.market}
          studyAreaId={id}
          auth={auth}
        />
      ),
    },
    {
      menuItem: 'Trip Takers',
      // TODO: tni: Handle when data is undefined and get rid of the "!"
      render: () => (
        <StudyAreaTripTakersV2
          dayOfWeek={dayOfWeek}
          setDayOfWeek={setDayOfWeek}
          studyAreaSummaryData={
            studyAreaRes.data && studyAreaRes.data.selected_sa_summaries
              ? studyAreaRes.data.selected_sa_summaries[0]
              : ''
          }
        />
      ),
    },
    {
      menuItem: 'Market Segmentation',
      render: () => (
        <StudyAreasMarketSegmentationV2
          auth={auth}
          studyAreaId={id}
          marketQuery={marketQueryResponse}
          // TODO: tni: Handle when data is undefined and get rid of the "!"
          marketId={studyAreaRes.data!.market}
        />
      ),
    },
    {
      menuItem: 'Consumer Spending',
      render: () => <StudyAreasConsumerSpending consumerSpending={consumerSpendingQueryResponse} />,
    },
    {
      menuItem: 'Market Potential',
      render: () => <StudyAreasMarketPotentialV2 marketPotentialQueryResponse={marketPotentialQueryResponse} />,
    },
    {
      menuItem: 'Trip Sources',
      render: () => (
        <StudyAreasTripSourcesV2 dayOfWeek={dayOfWeek} setDayOfWeek={setDayOfWeek} auth={auth} studyAreaId={id} />
      ),
    },
    { menuItem: 'Trends', render: () => <StudyAreasTrendingV2 trendsResponse={trendsResponse} /> },
  ];
  return (
    <>
      <PageContainer>
        <ContentContainer>
          <SideMenu2 />
          {studyAreaRes.isSuccess && openLocations.isSuccess && closedLocations.isSuccess ? (
            <BorneFlexContainer className="tw-items-start tw-justify-start tw-flex-col tw-p-4">
              <NewSiteReportModal auth={auth} handleClose={() => setOpen(false)} open={open} closeOnEscape={false} />
              <BorneFlexContainer className="sm:tw-flex-col lg:tw-flex-row">
                <BorneFlexContainer>
                  <div className="tw-whitespace-nowrap">
                    <h2>
                      SA-{id} / {studyAreaRes.isSuccess ? studyAreaRes.data.name : ''}
                    </h2>
                  </div>
                  {concepts.isSuccess ? (
                    <>
                      <p className="smallText tw-ml-4">Concept</p>
                      <BorneDropDown
                        className="tw-ml-2"
                        search
                        selectOnBlur={false}
                        selection
                        placeholder={`${
                          concepts.data.filter((x: any) => x.id == selectedConceptId)[0] != undefined
                            ? concepts.data.filter((x: any) => x.id == selectedConceptId)[0].name
                            : 'Select concept'
                        }`}
                        options={concepts.data.map((co: any) => ({
                          key: co.id,
                          text: co.name,
                          value: co.id,
                        }))}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, { value }: { value: number }) => {
                          // TODO: tni: This seems wrong! This should be updating Concept name, not concept ID.
                          // Need to figure out what happens because of this. The "as number" is just to allow
                          // the code to compile for now.
                          selectConceptId(value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p className="smallText">Concept</p>
                      <BorneDropDown
                        selectOnBlur={false}
                        search
                        selection
                        placeholder="Brand Name"
                        noResultsMessage="Loading"
                      />
                    </>
                  )}
                </BorneFlexContainer>
                <BorneFlexContainer className="sm:tw-justify-start lg:tw-justify-end sm:tw-my-2 lg:tw-my-0">
                  <BorneSmallButton className="tw-mr-4" active onClick={() => setOpen(true)}>
                    <BorneSmallPlus />
                    <span>Create New Site Report</span>
                  </BorneSmallButton>
                  <Link to="/studyareas">
                    <BorneSmallButton active>
                      <span>Back to Study Areas</span>
                    </BorneSmallButton>
                  </Link>
                </BorneFlexContainer>
              </BorneFlexContainer>
              <BorneFlexContainer className="tw-justify-between tw-items-start">
                <BorneFlexContainer className="tw-w-[48%] tw-items-start">
                  <BorneFlexContainer className="sm:tw-flex-col">
                    <BorneFlexContainer className="tw-justify-between">
                      <h3 className="tw-m-0">Market: {studyAreaRes.data.market}</h3>
                      {concepts.data && selectedConceptId !== 0 && (
                        <>
                          <h3 className="tw-m-0">
                            Concept: {concepts.data.filter((x: any) => x.id == selectedConceptId)[0].name}
                          </h3>
                          <p className="smallText">
                            Created on:{' '}
                            {new Date(
                              concepts.data.filter((x: any) => x.id == selectedConceptId)[0].created!
                            ).toLocaleDateString()}
                          </p>
                        </>
                      )}
                    </BorneFlexContainer>
                    <BorneFlexContainer className="tw-items-start sm:tw-flex-col lg:tw-flex-row tw-pt-12">
                      <div className="tw-flex tw-justify-center tw-mt-10 lg:tw-w-[35%] sm:tw-w-full">
                        <GradeStatistic
                          auth={auth}
                          studyAreaId={id}
                          selectedConceptId={selectedConceptId}
                          considerImpact={false}
                          user={user}
                        />
                      </div>
                      <div className="lg:tw-w-[65%] sm:tw-w-full">
                        <h3>Top Line Data</h3>
                        <DisplayTable
                          width={400}
                          height={400}
                          fill
                          rowData={topLineTableData}
                          headers={[{ field: 'text' }, { field: 'text2' }]}
                        />
                      </div>
                    </BorneFlexContainer>
                  </BorneFlexContainer>
                </BorneFlexContainer>
                <BorneFlexContainer className="tw-w-[48%] tw-h-[45vh]">
                  <div className="tw-h-[96%] tw-w-full" style={{ border: 'solid 1px' }}>
                    <StudyAreaSingleLocationMap studyAreaId={id} auth={auth} status={'open'} />
                  </div>
                </BorneFlexContainer>
              </BorneFlexContainer>
              <BorneTab panes={panes} className="tw-w-full" />
            </BorneFlexContainer>
          ) : (
            ''
          )}
        </ContentContainer>
      </PageContainer>
    </>
  );
};
