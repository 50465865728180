import {
  deleteStudyAreaMutation,
  useAllStudyAreas,
  useCurrentUser,
  useMarketStudyAreaGeos,
  useSubscribedMarketBounds,
} from '../../../api/requests';
import Auth from 'borne_ui/src/auth/Auth';
import React, { useState } from 'react';
import { Button, Confirm, Grid, Icon, Loader, Modal } from 'semantic-ui-react';
import {
  PageContainer,
  BorneFlexContainer,
  BorneDropDown,
  BorneSmallButton,
  ContentContainer,
} from '../../generic/BorneComponents';
import SideBarMenu from '../../generic/SideBarExtend';
import SideMenu2 from '../../generic/SideMenu2';
import { BorneCompare, BorneExpand, BorneSmallEye, BorneSmallPlus } from '../../generic/BorneStyledcons';
import { useNavigate } from 'react-router';
import BorneDataTable from '../../generic/BorneDataTableV2';
import { CellClassParams, CellClickedEvent } from 'ag-grid-community';
import CreateEditCustomStudyAreaModal from '../../modals/CreateEditCustomStudyAreaModal';
import { StudyArea } from 'borne_ui/src/types';
import NewSiteReportModal from '../../pages/SiteReportList/NewSiteReportModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { commonActions, mainActions } from '../../redux/testSlice';
import { StudyAreasMap } from './StudyAreasMap';
import { Link } from 'react-router-dom';

interface Props {
  auth: Auth;
  currentMarket?: number;
  setCurrentMarket: (marketIndex: number) => void;
}

const StudyAreas: React.FC<Props> = ({ auth, currentMarket, setCurrentMarket }: Props) => {
  const navigate = useNavigate();
  const [studyModalOpen, setStudyModalOpen] = useState(false);
  // const [studyAreaId, setStudyAreaId] = useState<number>()
  const [currentMutateStudyArea, setCurrentMutateStudyArea] = useState<StudyArea | undefined>(undefined);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [siteModalOpen, setSiteModalOpen] = useState(false);
  const studyAreaId = useSelector((state: RootState) => state.main.studyAreaId);
  const marketOptions = useSelector((state: RootState) => state.common.currentMarkets);
  const [sideBarVisibility, setSideBarVisibility] = useState(false);
  const dispatch = useDispatch();
  const setMarketOptions = (x: string[]) => {
    dispatch(commonActions.setCurrentMarket(x));
  };
  const setStudyAreaId = (x: string | undefined) => {
    dispatch(mainActions.selectStudyAreId(x));
  };

  const idEval = /[a-zA-Z]+/;
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const meResponse = useCurrentUser(auth, { refetchOnWindowFocus: false });

  const studyAreas = useAllStudyAreas(auth, {
    refetchOnWindowFocus: false,
  });

  const marketsRes = useSubscribedMarketBounds(auth, {
    enabled: meResponse.isSuccess,
    onSuccess: (data) => {
      if (meResponse.data!.settings.preferredMarket && currentMarket === undefined) {
        const indexedMarkets = data
          .map((market, index) => ({ index: index, ...market }))
          .filter((market: any) => meResponse.data!.settings.preferredMarket === market.id);
        setCurrentMarket(indexedMarkets.length != 0 ? indexedMarkets[0]['index'] : 0);
        setMarketOptions([indexedMarkets[0].id]);
      } else if (currentMarket) {
        return;
      } else if (marketOptions[0]) {
        data.map((market, indx) => {
          if (marketOptions.some((x) => x == market.id)) {
            setCurrentMarket(indx);
            return;
          }
        });
      } else if (currentMarket === undefined) {
        setCurrentMarket(0);
        setMarketOptions([data[0].id]);
      }
    },
    refetchOnWindowFocus: false,
  });

  const studyAreasRes = useMarketStudyAreaGeos(
    auth,
    marketsRes.isSuccess && currentMarket !== undefined ? marketsRes.data[currentMarket].id : undefined,
    {
      enabled: marketsRes.isSuccess && currentMarket !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const deleteStudyArea = deleteStudyAreaMutation(auth);

  return (
    <PageContainer>
      <ContentContainer>
        <SideMenu2 />
        <CreateEditCustomStudyAreaModal
          auth={auth}
          setOpen={setEditOpen}
          isOpen={editOpen}
          currentMutateStudyArea={currentMutateStudyArea}
          setCurrentMutateStudyArea={setCurrentMutateStudyArea}
        />
        <Confirm
          open={deleteConfirmOpen}
          header={`Delete ${currentMutateStudyArea?.name}?`}
          content={
            <Modal.Content>
              Are you sure you would like to delete <em>{currentMutateStudyArea?.name}</em>? This action cannot be
              reversed.
            </Modal.Content>
          }
          confirmButton={<Button icon="trash" loading={deleteStudyArea.isLoading} content={'Delete'} negative />}
          cancelButton={<Button icon="times" content="Cancel" />}
          onCancel={() => setDeleteConfirmOpen(false)}
          onConfirm={async () => {
            await deleteStudyArea.mutateAsync(currentMutateStudyArea!);
            setDeleteConfirmOpen(false);
            setCurrentMutateStudyArea(undefined);
          }}
          dimmer="blurring"
        />
        <SideBarMenu
          auth={auth}
          currentMarket={currentMarket}
          setCurrentMarket={setCurrentMarket}
          sideBarVisibility={sideBarVisibility}
          setSideBarVisibility={setSideBarVisibility}
        />
        <CreateEditCustomStudyAreaModal
          auth={auth}
          setOpen={setStudyModalOpen}
          isOpen={studyModalOpen}
          currentMarket={currentMarket}
          currentMutateStudyArea={currentMutateStudyArea}
          setCurrentMutateStudyArea={setCurrentMutateStudyArea}
          newUi
        />
        <NewSiteReportModal
          auth={auth}
          handleClose={() => setSiteModalOpen(false)}
          open={siteModalOpen}
          closeOnEscape={false}
        />
        <Grid className="tw-m-0 tw-h-full tw-w-full">
          <Grid.Row className="tw-w-full">
            <BorneFlexContainer className="sm:tw-flex-col lg:tw-flex-row tw-pl-4 tw-pr-4">
              <BorneFlexContainer>
                <BorneExpand
                  className="tw-cursor-pointer tw-mr-4 tw-min-w-[25px]"
                  onClick={() => setSideBarVisibility(!sideBarVisibility)}
                />
                <h2 className="tw-m-0 tw-mr-6">Study Areas</h2>
                {marketsRes.isSuccess && marketsRes.data.length > 0 && currentMarket != undefined ? (
                  <BorneFlexContainer style={{ minWidth: '15em', maxWidth: '15.2em' }}>
                    <p className="smallText">Market</p>
                    <BorneDropDown
                      selectOnBlur={false}
                      search
                      selection
                      placeholder={marketsRes.data[currentMarket].id}
                      options={marketsRes.data.map((market, index) => ({
                        key: `studyArea-dropdown-${market.id as string}${index}-some-unique-string`,
                        text: market.id,
                        value: index,
                      }))}
                      selectOnNavigation={false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>, { value }: { value: number }) => {
                        setStudyAreaId(undefined);
                        setCurrentMarket(value);
                      }}
                    />
                  </BorneFlexContainer>
                ) : (
                  <BorneFlexContainer style={{ minWidth: '15em', maxWidth: '15.2em' }}>
                    <p className="smallText">Market</p>
                    <BorneDropDown selectOnBlur={false} search selection placeholder="Select Market" />
                  </BorneFlexContainer>
                )}
                {studyAreasRes.isSuccess && studyAreasRes.data ? (
                  <BorneFlexContainer style={{ minWidth: '15em', maxWidth: '15.2em' }}>
                    <p className="smallText">Study Area</p>
                    <BorneDropDown
                      selectOnBlur={false}
                      clearable
                      search
                      selection
                      placeholder="Select Study Area"
                      options={studyAreasRes.data.features.map((studyArea, index) => ({
                        key: `studyArea-dropdown-${
                          studyArea.properties && (studyArea.properties.name as string)
                        }${index}-some-unique-string`,
                        text: studyArea.properties && studyArea.properties.name,
                        value: studyArea.id,
                      }))}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
                        if (typeof value == 'string') {
                          setStudyAreaId(undefined);
                          return;
                        }
                        setStudyAreaId(value);
                      }}
                      selectOnNavigation={false}
                    />
                  </BorneFlexContainer>
                ) : (
                  <BorneFlexContainer style={{ minWidth: '15em', maxWidth: '15.2em' }}>
                    <p className="smallText">Study Areas</p>
                    <BorneDropDown selectOnBlur={false} search selection placeholder="Select Study Area" />
                  </BorneFlexContainer>
                )}
              </BorneFlexContainer>
              <BorneFlexContainer className="lg:tw-justify-end sm:tw-justify-start sm:tw-mt-4">
                <BorneSmallButton className="tw-mr-3" onClick={() => setStudyModalOpen(!studyModalOpen)} active>
                  <span>New Study Area</span>
                  <BorneSmallPlus />
                </BorneSmallButton>
                <Link to={'/studyareascomparison'}>
                  <BorneSmallButton>
                    <span>Compare Study Area</span>
                    <BorneCompare />
                  </BorneSmallButton>
                </Link>
              </BorneFlexContainer>
            </BorneFlexContainer>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <BorneFlexContainer className="tw-justify-between">
                <div className="tw-w-full">
                  <div className="tw-h-[80vh] tw-w-full" style={{ border: 'gray solid 1px' }}>
                    <StudyAreasMap
                      selectedStudyArea={studyAreaId}
                      auth={auth}
                      setCurrentMarket={setCurrentMarket}
                      currentMarket={currentMarket}
                    />
                  </div>
                </div>
              </BorneFlexContainer>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="tw-pb-8">
            <Grid.Column width={12}>
              {studyAreas.data ? (
                <BorneDataTable
                  fill
                  headers={[
                    {
                      field: 'report',
                      headerName: 'Report Id',
                      sortable: true,
                      width: 100,
                      valueFormatter: (params) => `SA-${params.value as string}`,
                      cellStyle: { cursor: 'pointer' },
                      sort: 'desc',
                    },
                    { field: 'name', sortable: true, width: 230 },
                    { field: 'market', sortable: true, width: 280 },
                    {
                      field: 'edit',
                      headerName: '',
                      width: 60,
                      cellRenderer: (x: CellClassParams) => x.data.edit,
                    },
                    {
                      field: 'delete',
                      headerName: '',
                      width: 60,
                      cellRenderer: (x: CellClassParams) => x.data.delete,
                    },
                    {
                      field: 'compare',
                      headerName: '',
                      width: 60,
                      cellRenderer: (x: CellClassParams) => x.data.compare,
                    },
                    {
                      field: 'sAReport',
                      headerName: '',
                      width: 60,
                      cellRenderer: (x: CellClassParams) => x.data.sAReport,
                    },
                  ]}
                  onCellClick={(params: CellClickedEvent) => {
                    if (!idEval.test(params.value)) {
                      navigate(`/studyAreas/${params.value}`);
                    }
                  }}
                  rowData={studyAreas.data.map((sa) => ({
                    report: sa.id,
                    name: sa.name,
                    market: sa.market,
                    compare: (
                      <Link to={'/studyareascomparison'}>
                        <BorneSmallButton
                          onClick={() => {
                            setStudyAreaId(sa.id);
                          }}
                          active
                        >
                          <BorneCompare color="white" />
                        </BorneSmallButton>
                      </Link>
                    ),
                    sAReport: (
                      <Link to={`/studyAreas/${sa.id as string}`}>
                        <BorneSmallButton active>
                          <BorneSmallEye />
                        </BorneSmallButton>
                      </Link>
                    ),
                    edit: sa.is_author && (
                      <BorneSmallButton
                        active
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                          e.stopPropagation();
                          setCurrentMutateStudyArea(sa);
                          setEditOpen(true);
                        }}
                      >
                        <Icon name="edit" className="tw-m-0" link />
                      </BorneSmallButton>
                    ),
                    delete: sa.is_author && (
                      <BorneSmallButton
                        active
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                          e.stopPropagation();
                          setDeleteConfirmOpen(true);
                          setCurrentMutateStudyArea(sa);
                        }}
                      >
                        <Icon name="trash" className="tw-m-0" link />
                      </BorneSmallButton>
                    ),
                  }))}
                  filterText={search}
                />
              ) : (
                <div className="tw-w-[29rem] tw-h-[29rem]">
                  <Loader active size="medium" />
                </div>
              )}
            </Grid.Column>
            <Grid.Column width={4}>
              <Grid.Row className="tw-mt-4">
                <h3>Search Area Study Reports</h3>
                <p>Enter keywords or report code to find the Study Area Repot you wish to review. </p>
                <div className="tw-flex-col tw-flex tw-mt-4">
                  <input placeholder="Keywords/Report Code" value={search} onChange={handleSearchChange} />
                </div>
              </Grid.Row>
              <Grid.Row className="tw-mt-8">
                <h3>Run Site Reports</h3>
                <BorneSmallButton onClick={() => setSiteModalOpen(!siteModalOpen)} className="tw-ml-0" active>
                  <span>Run Report</span>
                  <BorneSmallPlus />
                </BorneSmallButton>
                <p className="tw-mt-4">
                  Note: Pull the addresses from the commercial real estate service of your choice, or use those supplied
                  by your real estate agent
                </p>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentContainer>
    </PageContainer>
  );
};

export default StudyAreas;
