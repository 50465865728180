import React from 'react';
import { ServiceType } from '../../data/categories';
import { WrappedButtonGroupField } from './FormikUtil';

const options: Readonly<Record<ServiceType, string>> = {
  full_service: 'Full Service',
  counter_service: 'Counter Service',
  quick_serve: 'Quick Serve',
  virtual_service: 'Virtual Service',
};

interface Props {
  loc: string;
}

const ServiceTypeQuestion = ({ loc }: Props) => {
  return <WrappedButtonGroupField label="Please choose the type of service:" options={options} name={loc} />;
};

export default ServiceTypeQuestion;
