const yelpCategories = [
  'Abruzzese',
  'Acai Bowls',
  'Afghan',
  'African',
  'Alentejo',
  'Algarve',
  'Alsatian',
  'Altoatesine',
  'Andalusian',
  'Apulian',
  'Arabian',
  'Arab Pizza',
  'Argentine',
  'Armenian',
  'Arroceria / Paella',
  'Asian Fusion',
  'Asturian',
  'Australian',
  'Austrian',
  'Auvergnat',
  'Azores',
  'Backshop',
  'Baden',
  'Bagels',
  'Baguettes',
  'Bakeries',
  'Bangladeshi',
  'Basque',
  'Bavarian',
  'Barbeque',
  'Beer, Wine & Spirits',
  'Beer Garden',
  'Beer Hall',
  'Beira',
  'Beisl',
  'Belgian',
  'Bento',
  'Berrichon',
  'Beverage Store',
  'Bistros',
  'Black Sea',
  'Blowfish',
  'Bourguignon',
  'Brasseries',
  'Brazilian',
  'Brazilian Empanadas',
  'Breakfast & Brunch',
  'Breweries',
  'Brewpubs',
  'British',
  'Bubble Tea',
  'Buffets',
  'Bulgarian',
  'Burgers',
  'Burmese',
  'Butcher',
  'Cafes',
  'Cafeteria',
  'Cajun/Creole',
  'Patisserie/Cake Shop',
  'Calabrian',
  'Cambodian',
  'Candy Stores',
  'Canteen',
  'Cantonese',
  'Caribbean',
  'Catalan',
  'Central Brazilian',
  'Chee Kufta',
  'Cheese Shops',
  'Cheesesteaks',
  'Chicken Wings',
  'Chicken Shop',
  'Chilean',
  'Chimney Cakes',
  'Chinese',
  'Chocolatiers & Shops',
  'Churros',
  'Cideries',
  'Coffee & Tea',
  'Coffee Roasteries',
  'Coffee & Tea Supplies',
  'Colombian',
  'Comfort Food',
  'Congee',
  'Convenience Stores',
  'Conveyor Belt Sushi',
  'Corsican',
  'Creperies',
  'CSA',
  'Cuban',
  'Cucina campana',
  'Cupcakes',
  'Curry Sausage',
  'Custom Cakes',
  'Cypriot',
  'Czech',
  'Czech/Slovakian',
  'Dagashi',
  'Danish',
  'Delicatessen',
  'Delis',
  'Desserts',
  'Dim Sum',
  'Diners',
  'Dinner Theater',
  'Distilleries',
  'Do-It-Yourself Food',
  'Dominican',
  'Donairs',
  'Donburi',
  'Donuts',
  'Dried Fruit',
  'Dumplings',
  'Eastern European',
  'Eastern German',
  'Eastern Mexican',
  'Egyptian',
  'Parent Cafes',
  'Emilian',
  'Empanadas',
  'Eritrean',
  'Ethical Grocery',
  'Ethiopian',
  'Fado Houses',
  'Falafel',
  'Farmers Market',
  'Filipino',
  'Fischbroetchen',
  'Fishmonger',
  'Fish & Chips',
  'Flatbread',
  'Flemish',
  'Fondue',
  'Food Court',
  'Food Delivery Services',
  'Food Stands',
  'Food Trucks',
  'Franconian',
  'Freiduria',
  'French',
  'Friterie',
  'Friulan',
  'Frozen Food',
  'Fuzhou',
  'Galician',
  'Game Meat',
  'Gastropubs',
  'Gelato',
  'Georgian',
  'German',
  'Giblets',
  'Mulled Wine',
  'Gluten-Free',
  'Specialty Food',
  'Gozleme',
  'Greek',
  'Grocery',
  'Guamanian',
  'Gyudon',
  'Hainan',
  'Haitian',
  'Hakka',
  'Halal',
  'Hand Rolls',
  'Hawaiian',
  'Hawker Centre',
  'Health Markets',
  'Henghwa',
  'Herbs & Spices',
  'Hessian',
  'Heuriger',
  'Himalayan/Nepalese',
  'Hong Kong Style Cafe',
  'Hokkien',
  'Homemade Food',
  'Honduran',
  'Honey',
  'Horumon',
  'Hot Dogs',
  'Fast Food',
  'Hot Pot',
  'Hunan',
  'Hungarian',
  'Iberian',
  'Ice Cream & Frozen Yogurt',
  'Imported Food',
  'Indonesian',
  'Indian',
  'International',
  'Internet Cafes',
  'International Grocery',
  'Irish',
  'Island Pub',
  'Israeli',
  'Italian',
  'Izakaya',
  'Jaliscan',
  'Japanese Curry',
  'Japanese',
  'Jewish',
  'Japanese Sweets',
  'Juice Bars & Smoothies',
  'Kaiseki',
  'Kebab',
  'Kiosk',
  'Kombucha',
  'Kopitiam',
  'Korean',
  'Kosher',
  'Kurdish',
  'Kushikatsu',
  'Lahmacun',
  'Laos',
  'Laotian',
  'Latin American',
  'Lebanese',
  'Ligurian',
  'Lumbard',
  'Lyonnais',
  'Macarons',
  'Madeira',
  'Malaysian',
  'Mamak',
  'Fruits & Veggies',
  'Mauritius',
  'Meaderies',
  'Meatballs',
  'Meat Shops',
  'Mediterranean',
  'Mexican',
  'Middle Eastern',
  'Milk Bars',
  'Milkshake Bars',
  'Minho',
  'Modern Australian',
  'Modern European',
  'Mongolian',
  'Moroccan',
  'Napoletana',
  'Nasi Lemak',
  'American (New)',
  'Canadian (New)',
  'New Mexican Cuisine',
  'New Zealand',
  'Nicaraguan',
  'Nicoise',
  'Night Food',
  'Nikkei',
  'Noodles',
  'Norcinerie',
  'Northeastern Brazilian',
  'Northern Brazilian',
  'Northern German',
  'Northern Mexican',
  'Traditional Norwegian',
  'Nyonya',
  'Oaxacan',
  'Oden',
  'Okinawan',
  'Okonomiyaki',
  'Olive Oil',
  'Onigiri',
  'Open Sandwiches',
  'Organic Stores',
  'Oriental',
  'Ottoman Cuisine',
  'Oyakodon',
  'Pakistani',
  'Palatine',
  'Pan Asian',
  'Pancakes',
  'Panzerotti',
  'Parma',
  'Pasta Shops',
  'Pekinese',
  'Persian/Iranian',
  'Peruvian',
  'PF/Comercial',
  'Piadina',
  'Piemonte',
  'Pierogis',
  'Pita',
  'Pizza',
  'Poke',
  'Polish',
  'Polynesian',
  'Popcorn Shops',
  'Pop-Up Restaurants',
  'Portuguese',
  'Potatoes',
  'Poutineries',
  'Pretzels',
  'Provencal',
  'Pub Food',
  'Pueblan',
  'Puerto Rican',
  'Ramen',
  'Live/Raw Food',
  'Reunion',
  'Rhinelandian',
  'Ribatejo',
  'Rice',
  'Robatayaki',
  'Rodizios',
  'Roman',
  'Romanian',
  'Rotisserie Chicken',
  'Russian',
  'Salad',
  'Salumerie',
  'Salvadoran',
  'Sandwiches',
  'Sardinian',
  'Scandinavian',
  'Schnitzel',
  'Scottish',
  'Seafood',
  'Seafood Markets',
  'Senegalese',
  'Serbo Croatian',
  'Shanghainese',
  'Shaved Ice',
  'Shaved Snow',
  'Sicilian',
  'Signature Cuisine',
  'Singaporean',
  'Slovakian',
  'Smokehouse',
  'Soba',
  'Somali',
  'Soul Food',
  'Soup',
  'South African',
  'Southern',
  'Spanish',
  'Sri Lankan',
  'Steakhouses',
  'Street Vendors',
  'French Southwest',
  'Sugar Shacks',
  'Sukiyaki',
  'Supper Clubs',
  'Sushi Bars',
  'Swabian',
  'Swedish',
  'Swiss Food',
  'Syrian',
  'Szechuan',
  'Tabernas',
  'Tacos',
  'Taiwanese',
  'Taiyaki',
  'Takoyaki',
  'Tamales',
  'Tapas Bars',
  'Tapas/Small Plates',
  'Tavola Calda',
  'Tea Rooms',
  'Tempura',
  'Teochew',
  'Teppanyaki',
  'Tex-Mex',
  'Thai',
  'Themed Cafes',
  'Tofu Shops',
  'Tonkatsu',
  'Torshi',
  'Tortillas',
  'American (Traditional)',
  'Traditional Swedish',
  'Tras-os-Montes',
  'Trattorie',
  'Trinidadian',
  'Turkish',
  'Turkish Ravioli',
  'Tuscan',
  'Udon',
  'Ukrainian',
  'Unagi',
  'Uzbek',
  'Vegan',
  'Vegetarian',
  'Venetian',
  'Venezuelan',
  'Venison',
  'Vietnamese',
  'Waffles',
  'Water Stores',
  'Western Style Japanese Food',
  'Wineries',
  'Wine Tasting Room',
  'Wok',
  'Wraps',
  'Yakiniku',
  'Yakitori',
  'Yucatan',
  'Yugoslav',
  'Zapiekanka',
];

export default yelpCategories;

export type YelpCategory = typeof yelpCategories[number];
