import BarChart from '@bornellc/bach/BarChart';
import { ChartHeader } from '../../styled/Typography';
import React, { useState } from 'react';
import { Dropdown, Grid } from 'semantic-ui-react';
import ObjectPieChart from '../../generic/ObjectPieChart';
import { isSeriesValid, buildSeries } from '../../../reactHelpers';
import BarChartToggleNormalize from '../../generic/BarChartToggleNormalize';
import { BorneCheckbox, BorneFlexContainer } from '../../generic/BorneComponents';
import { DayOfWeek } from 'borne_ui/src/types';

interface DataProps {
  studyAreaSummaryData: any;
  dayOfWeek: DayOfWeek;
  setDayOfWeek: (dow: DayOfWeek) => void;
}

export const StudyAreaTripTakersV2: React.FC<DataProps> = ({
  studyAreaSummaryData,
  dayOfWeek,
  setDayOfWeek,
}: DataProps) => {
  const [age, setAge] = useState(true);
  const [employment, setEmployment] = useState(true);
  const [race, setRace] = useState(true);
  const [education, setEducation] = useState(true);
  const [income, setIncome] = useState(true);
  const [tenure, setTenure] = useState(true);
  const [type, setType] = useState(true);
  const [trasportation, setTransportation] = useState(true);
  const [activity, setActivity] = useState(true);
  const [duration, setDuration] = useState(true);
  const [distance, setDistance] = useState(true);
  const options = [
    {
      state: age,
      method: () => {
        setAge(!age);
      },
      text: 'Age',
    },
    {
      state: employment,
      method: () => {
        setEmployment(!employment);
      },
      text: 'Employment',
    },
    {
      state: race,
      method: () => {
        setRace(!race);
      },
      text: 'Race',
    },
    {
      state: education,
      method: () => {
        setEducation(!education);
      },
      text: 'Educations',
    },
    {
      state: income,
      method: () => {
        setIncome(!income);
      },
      text: 'Household Income',
    },
    {
      state: tenure,
      method: () => {
        setTenure(!tenure);
      },
      text: 'Tenure',
    },
    {
      state: type,
      method: () => {
        setType(!type);
      },
      text: 'Household Type',
    },
    {
      state: trasportation,
      method: () => {
        setTransportation(!trasportation);
      },
      text: 'Mode of Transportation',
    },
    {
      state: activity,
      method: () => {
        setActivity(!activity);
      },
      text: 'Previous Activity',
    },
    {
      state: duration,
      method: () => {
        setDuration(!duration);
      },
      text: 'Trip Duration',
    },
    {
      state: distance,
      method: () => {
        setDistance(!distance);
      },
      text: 'Trip Distance',
    },
  ];
  return (
    <>
      {studyAreaSummaryData ? (
        <div className="tw-mt-4 tw-relative">
          <Grid.Row>
            {/* Perosnell Employment age race education */}
            <BorneFlexContainer style={{ alignItems: 'inherit' }}>
              <div className="tw-w-[30%] tw-h-full tw-sticky tw-top-0 tw-pt-8">
                <p>
                  These figures show demographics and travelling info for patrons who visited restaurants within this
                  study area on an average{' '}
                  <Dropdown
                    inline
                    value={dayOfWeek}
                    options={[
                      { key: 1, text: 'weekday', value: 'thu' },
                      { key: 2, text: 'weekend', value: 'sat' },
                    ]}
                    onChange={(e, data) => setDayOfWeek((data.value as DayOfWeek) ?? 'thu')}
                  />
                </p>
                <div>
                  {options.map((graph: any) => (
                    <BorneFlexContainer key={graph.text} onClick={graph.method}>
                      <BorneCheckbox bigger checked={graph.state} className="tw-mt-2">
                        {graph.text}
                      </BorneCheckbox>
                    </BorneFlexContainer>
                  ))}
                  <div className="tw-mt-4 " style={{ display: 'inline-flex', alignItems: 'baseline' }}>
                    <a href="https://replicahq.com/">Powered by Replica</a>
                  </div>
                </div>
              </div>
              <div className="tw-w-[70%] tw-h-full">
                <Grid padded relaxed attached="top" columns="2" textAlign="center">
                  {isSeriesValid(studyAreaSummaryData, 'age_') && (
                    <Grid.Column style={{ display: `${age ? '' : 'none'}` }}>
                      <BarChartToggleNormalize>
                        <ChartHeader as="h3">Age</ChartHeader>
                        <BarChart
                          orientation="h"
                          x="key"
                          y="value"
                          grid="v"
                          data={buildSeries(studyAreaSummaryData, 'age_', [
                            '< 4',
                            '5 to 14',
                            '15 to 17',
                            '18 to 24',
                            '25 to 34',
                            '35 to 64',
                            '65+',
                          ])}
                          dataKey="age_bar_chart"
                          height={300}
                          role="chart"
                        />
                      </BarChartToggleNormalize>
                    </Grid.Column>
                  )}
                  {isSeriesValid(studyAreaSummaryData, 'employment_') && (
                    <Grid.Column style={{ display: `${employment ? '' : 'none'}` }}>
                      <ChartHeader as="h3">Employment</ChartHeader>
                      <ObjectPieChart
                        width="100%"
                        height={300}
                        data={studyAreaSummaryData}
                        otherThreshold={0}
                        normalize
                        keys={[
                          'employment_employed',
                          'employment_unemployed',
                          'employment_notinlf',
                          'employment_under_16',
                        ]}
                        labels={['Employed', 'Unemployed', 'Not in Labor Force', 'Under 16']}
                      />
                    </Grid.Column>
                  )}
                  {isSeriesValid(studyAreaSummaryData, 'race_') && (
                    <Grid.Column style={{ display: `${race ? '' : 'none'}` }}>
                      <ChartHeader as="h3">Race</ChartHeader>
                      <ObjectPieChart
                        width="100%"
                        height={300}
                        data={studyAreaSummaryData}
                        normalize
                        keys={[
                          'race_white',
                          'race_two_or_more_races',
                          'race_other_race_alone',
                          'race_black_african_american',
                          'race_american_indian_alaska_native',
                          'race_hawaiian_pacific',
                          'race_asian',
                          'race_hispanic_or_latino',
                        ]}
                        labels={[
                          'White',
                          'Two or More',
                          'Other',
                          'Black',
                          'American Indian',
                          'Hawaiian / PI',
                          'Asian',
                          'Hispanic or Latino',
                        ]}
                      />
                    </Grid.Column>
                  )}
                  {isSeriesValid(studyAreaSummaryData, 'education_') && (
                    <Grid.Column style={{ display: `${education ? '' : 'none'}` }}>
                      <BarChartToggleNormalize>
                        <ChartHeader as="h3">Education</ChartHeader>
                        <BarChart
                          orientation="h"
                          x="key"
                          y="value"
                          grid="v"
                          data={buildSeries(studyAreaSummaryData, 'education_', [
                            'Bachelors',
                            'Some College',
                            'High School',
                            'K-12',
                            '< 3',
                          ])}
                          dataKey="education_bar_chart"
                          height={300}
                          role="chart"
                        />
                      </BarChartToggleNormalize>
                    </Grid.Column>
                  )}
                  {isSeriesValid(studyAreaSummaryData, 'household_income_') && (
                    <Grid.Column style={{ display: `${income ? '' : 'none'}` }}>
                      <BarChartToggleNormalize>
                        <ChartHeader as="h3">Household Income</ChartHeader>
                        <BarChart
                          orientation="h"
                          x="key"
                          y="value"
                          grid="v"
                          data={buildSeries(studyAreaSummaryData, 'household_income_', [
                            '< 10k',
                            '10k to 40k',
                            '40k to 75k',
                            '75k to 125k',
                            '125k+',
                          ])}
                          dataKey="household_income_bar_chart"
                          height={300}
                          role="chart"
                        />
                      </BarChartToggleNormalize>
                    </Grid.Column>
                  )}
                  {isSeriesValid(studyAreaSummaryData, 'tenure_') && (
                    <Grid.Column style={{ display: `${tenure ? '' : 'none'}` }}>
                      <ChartHeader as="h3">Tenure</ChartHeader>
                      <ObjectPieChart
                        width="100%"
                        height={300}
                        data={studyAreaSummaryData}
                        otherThreshold={0}
                        normalize
                        keys={['tenure_owner', 'tenure_renter', 'tenure_group_quarters']}
                        labels={['Owner', 'Renter', 'Group Quarters']}
                      />
                    </Grid.Column>
                  )}
                  {isSeriesValid(studyAreaSummaryData, 'family_structure_') && (
                    <Grid.Column style={{ display: `${type ? '' : 'none'}` }}>
                      <ChartHeader as="h3">Household Type</ChartHeader>
                      <ObjectPieChart
                        width="100%"
                        height={300}
                        data={studyAreaSummaryData}
                        normalize
                        otherThreshold={0}
                        keys={[
                          'family_structure_living_alone',
                          'family_structure_family_single',
                          'family_structure_nonfamily_single',
                          'family_structure_married_couple',
                          'family_structure_group_quarters',
                        ]}
                        labels={['Living Alone', 'Single, Family', 'Single, Non-family', 'Married', 'Group Quarters']}
                      />
                    </Grid.Column>
                  )}
                  {isSeriesValid(studyAreaSummaryData, 'mode_') && (
                    <Grid.Column style={{ display: `${trasportation ? '' : 'none'}` }}>
                      <ChartHeader as="h3">Mode of Transportation</ChartHeader>
                      <ObjectPieChart
                        width="100%"
                        height={300}
                        data={studyAreaSummaryData}
                        normalize
                        keys={[
                          'mode_carpool',
                          'mode_commercial',
                          'mode_public_transit',
                          'mode_biking',
                          'mode_other',
                          'mode_private_auto',
                          'mode_walking',
                          'mode_on_demand_auto',
                        ]}
                        labels={[
                          'Carpool',
                          'Commercial',
                          'Public Transit',
                          'Biking',
                          'Other',
                          'Private Auto',
                          'Walking',
                          'On-Demand Auto',
                        ]}
                      />
                    </Grid.Column>
                  )}
                  {isSeriesValid(studyAreaSummaryData, 'previous_activity_') && (
                    <Grid.Column style={{ display: `${activity ? '' : 'none'}` }}>
                      <ChartHeader as="h3">Previous Activity</ChartHeader>
                      <ObjectPieChart
                        width="100%"
                        height={300}
                        data={studyAreaSummaryData}
                        normalize
                        keys={[
                          'previous_activity_recreation',
                          'previous_activity_other',
                          'previous_activity_school',
                          'previous_activity_lodging',
                          'previous_activity_eat',
                          'previous_activity_social',
                          'previous_activity_shop',
                          'previous_activity_home',
                          'previous_activity_work',
                        ]}
                        labels={['Recreation', 'Other', 'School', 'Lodging', 'Eat', 'Social', 'Shop', 'Home', 'Work']}
                      />
                    </Grid.Column>
                  )}
                  {isSeriesValid(studyAreaSummaryData, 'duration_') && (
                    <Grid.Column style={{ display: `${duration ? '' : 'none'}` }}>
                      <BarChartToggleNormalize>
                        <ChartHeader as="h3">Trip Duration</ChartHeader>
                        <BarChart
                          orientation="h"
                          x="key"
                          y="value"
                          grid="v"
                          data={buildSeries(studyAreaSummaryData, 'duration_', [
                            '< 5 minutes',
                            '5 to 10 min',
                            '10 to 20 min',
                            '20 to 40 min',
                            '40 to 80 min',
                            '> 80 minutes',
                          ])}
                          dataKey="duration_bar_chart"
                          height={300}
                          role="chart"
                        />
                      </BarChartToggleNormalize>
                    </Grid.Column>
                  )}
                  {isSeriesValid(studyAreaSummaryData, 'distance_') && (
                    <Grid.Column style={{ display: `${distance ? '' : 'none'}` }}>
                      <BarChartToggleNormalize>
                        <ChartHeader as="h3">Trip Distance</ChartHeader>
                        <BarChart
                          orientation="h"
                          x="key"
                          y="value"
                          grid="v"
                          data={buildSeries(studyAreaSummaryData, 'distance_', [
                            '> .5 miles',
                            '.5 to 1 mi',
                            '1 to 2 mi',
                            '2 to 4 mi',
                            '4 to 8 mi',
                            '8 to 16 mi',
                            '16 to 32 mi',
                            '32 to 64 mi',
                            '< 64 miles',
                          ])}
                          dataKey="duration_bar_chart"
                          height={300}
                          role="chart"
                        />
                      </BarChartToggleNormalize>
                    </Grid.Column>
                  )}
                </Grid>
              </div>
            </BorneFlexContainer>
          </Grid.Row>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
