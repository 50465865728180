import React from 'react';
import { Divider, Grid } from 'semantic-ui-react';
import { ChartHeader } from '../../styled/Typography';
import LineChart from '@bornellc/bach/LineChart';
import { BorneFlexContainer } from '../../generic/BorneComponents';

interface Props {
  trendsResponse: any;
}

export const StudyAreasTrendingV2: React.FC<Props> = ({ trendsResponse }: Props) => {
  const spend =
    trendsResponse.data &&
    trendsResponse.data.spend.map((d: any) => ({
      week: new Date(d.week).getTime(),
      spend: d.spend,
    }));
  return (
    <Grid.Row>
      <BorneFlexContainer className="tw-h-[35rem] tw-items-start tw-mt-8">
        <div className="tw-h-fit tw-w-[30%] tw-sticky tw-top-0">View how behavior has changed over time</div>
        <div className="tw-w-[70%]">
          <Grid.Column>
            {spend && (
              <>
                <ChartHeader as="h3" className="tw-items-center">
                  Weekly Restaurant Spend
                </ChartHeader>
                <Divider fitted />
                <LineChart
                  data={spend}
                  dataKey="spend"
                  x="week"
                  y="spend"
                  orientation="h"
                  height={250}
                  isCurve={true}
                  margin={{
                    top: 30,
                    bottom: 30,
                    left: 100,
                    right: 0,
                  }}
                  xScaleOptions={{
                    type: 'linear',
                    domain: [
                      Math.min(...spend.map((datum: any) => datum.week)),
                      Math.max(...spend.map((datum: any) => datum.week)),
                    ],
                    zero: false,
                  }}
                  xLabelFormatter={(date: number) => new Date(date).toISOString().split('T')[0]}
                  yLabelFormatter={(spend: number) => `$${spend}`}
                />
              </>
            )}
          </Grid.Column>
        </div>
      </BorneFlexContainer>
    </Grid.Row>
  );
};
