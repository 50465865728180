import { toggleScoreMutation, useConcepts, useCurrentUser, useDeleteConcept } from '../../../api/requests';
import Auth from 'borne_ui/src/auth/Auth';
import React, { useState } from 'react';
import {
  BorneFlexContainer,
  BorneSmallButton,
  BorneToggle,
  ContentContainer,
  PageContainer,
} from '../../generic/BorneComponents';
import SideMenu2 from '../../generic/SideMenu2';
import { User } from '../../../api/models';
import NewConceptModal from '../../concept/NewConceptModal';
import { CellClassParams } from 'ag-grid-community';
import { DisplayTable } from '../../generic/BorneDisplayTable';

interface Props {
  auth: Auth;
  user: User;
}

export const Profile: React.FC<Props> = ({ auth, user }: Props) => {
  const toggleGrades = toggleScoreMutation(auth);
  const meResponse = useCurrentUser(auth, { refetchOnWindowFocus: false });
  const [score, setScore] = useState<boolean | undefined>(user.settings.GRADE_SCORES);
  const [newConceptModalOpen, setNewConceptModalOpen] = useState<boolean>(false);
  const [conceptToEdit, setConceptToEdit] = useState<any>();
  const concepts = useConcepts(auth);
  const deleteConcept = useDeleteConcept(auth);

  function handleModalClose() {
    setNewConceptModalOpen(false);
    setConceptToEdit(undefined);
  }

  return (
    <PageContainer>
      <ContentContainer>
        <SideMenu2 />
        <BorneFlexContainer className="tw-flex-col tw-items-start tw-m-2">
          <NewConceptModal
            auth={auth}
            open={newConceptModalOpen}
            concept={conceptToEdit}
            handleClose={handleModalClose}
            onClose={() => handleModalClose()}
            closeOnEscape={false}
            dimmer="blurring"
          />
          {/* {meResponse.isSuccess ? (
            <div>
              <h2>Your Profile</h2>
              <h3>Username: {meResponse.data.username}</h3>
              <h3>Email: {meResponse.data.email}</h3>
              <h3>Credits: {meResponse.data.credits}</h3>
            </div>
          ) : (
            ''
          )} */}
          <h2>Borne Employee Options</h2>
          <BorneFlexContainer>
            <BorneToggle
              color="success"
              onChange={async () => {
                await toggleGrades.mutateAsync();
                setScore(!score);
              }}
              checked={score}
            />
            <p className="tw-ml-4">{score ? 'Score' : 'Grade'}</p>
          </BorneFlexContainer>
          <BorneFlexContainer
            className="tw-flex-col tw-w-[500px] tw-h-[300px]
          ] tw-items-start tw-pt-4"
          >
            {meResponse.isSuccess && concepts.isSuccess ? (
              <>
                <h3>Conepts</h3>
                <DisplayTable
                  fill
                  height={120}
                  headers={[
                    { field: 'name', sortable: true, headerName: '' },
                    { field: 'edit', width: 100, headerName: '', cellRenderer: (x: CellClassParams) => x.data.edit },
                    {
                      field: 'delete',
                      width: 110,
                      headerName: '',
                      cellRenderer: (x: CellClassParams) => x.data.delete,
                    },
                  ]}
                  rowData={concepts.data
                    .sort((a: any, b: any) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                    .map((co: any, index: number) => ({
                      name: co.name,
                      edit: (
                        <BorneSmallButton
                          onClick={() => {
                            if (meResponse.data.settings.CAN_EDIT_CONCEPTS) {
                              setNewConceptModalOpen(true);
                              setConceptToEdit(co);
                            }
                          }}
                          active={meResponse.data.settings.CAN_EDIT_CONCEPTS}
                        >
                          edit
                        </BorneSmallButton>
                      ),
                      delete: (
                        <BorneSmallButton
                          active={meResponse.data.settings.CAN_EDIT_CONCEPTS}
                          onClick={() => {
                            if (meResponse.data.settings.CAN_EDIT_CONCEPTS) {
                              deleteConcept.mutate(co.id);
                            }
                          }}
                        >
                          delete
                        </BorneSmallButton>
                      ),
                    }))}
                />
                <BorneSmallButton active onClick={() => setNewConceptModalOpen(true)}>
                  Create New Concept
                </BorneSmallButton>
              </>
            ) : (
              ''
            )}
          </BorneFlexContainer>
        </BorneFlexContainer>
      </ContentContainer>
    </PageContainer>
  );
};
