import React from 'react';
import { WrappedButtonGroupField } from './FormikUtil';
import { Price } from '../../data/categories';

const options: Readonly<Record<Price, string>> = {
  $: '$',
  $$: '$$',
  $$$: '$$$',
  $$$$: '$$$$',
};

interface Props {
  loc: string;
  useFormLabel?: boolean;
}

const PriceQuestion = ({ loc, useFormLabel }: Props) => {
  return <WrappedButtonGroupField label="Price level" options={options} name={loc} useFormLabel={useFormLabel} />;
};

export default PriceQuestion;
