import React, { useEffect } from 'react';
import { AddressMap } from '../../pages/SiteReport/AddressMap';
import Auth from '../../../auth/Auth';
import {
  useAddressGeocode,
  useFetchBorneReport,
  useFetchBorneReportLimit,
  useFetchGoogleDriveLinks,
} from '../../../api/requests';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../AppContext';
import { BorneSmallButton, ReportPage } from '../../generic/BorneComponents';
import ProgressBar from './ProgressBar';
import { Params, useNavigate, useParams } from 'react-router';
import { Icon, Label, Loader, Progress } from 'semantic-ui-react';
import { IconMenu2 } from '@tabler/icons-react';
import BorneUiMobileMenu from './Menu';

interface Props {
  auth: Auth;
}

const BorneReportStatus: React.FC<Props> = ({ auth }: Props) => {
  const [session] = React.useState(uuidv4());
  const [report, setReport] = React.useState<any>();
  const [opened, setOpened] = React.useState(false);
  const { id } = useParams<keyof Params>() as Params;

  const navigate = useNavigate();
  const borneReports = useFetchBorneReport(auth, {
    onSuccess: (data) => {
      setReport(data.filter((item: any) => item.id == id)[0]);
    },
  });
  useEffect(() => {
    console.log(id);
    if (id != undefined) {
      setReport(borneReports.data?.filter((item: any) => item.id == id)[0]);
    }
  }, [id]);
  const address = report != undefined && report.name;

  const geocode = useAddressGeocode(auth, address!, session, {
    enabled: address != undefined && report != undefined,
    refetchOnWindowFocus: false,
  });
  const borneReportLimit = useFetchBorneReportLimit(auth);

  const viewLink = useFetchGoogleDriveLinks(auth, id!, {
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <BorneUiMobileMenu opened={opened} setOpened={setOpened} />
      <ReportPage>
        <div className="tw-flex tw-flex-col lg:tw-flex-row md:tw-flex-row tw-justify-between">
          <div className="tw-mb-4 lg:tw-hidden md:tw-hidden">
            <IconMenu2 size={40} color="#2a4e55" onClick={() => setOpened(true)} />
          </div>
          <div className="tw-w-[100%] lg:tw-w-[70%] md:tw-w-[70%] tw-rounded-lg tw-bg-white tw-p-6 tw-shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
            {report && (
              <>
                <div className="tw-w-full tw-h-[40vh] tw-mb-4">
                  {geocode.isFetched && !geocode.isFetching && geocode.isSuccess && (
                    <AddressMap
                      address={address}
                      latitude={geocode.data[0].geometry.location.lat}
                      longitude={geocode.data[0].geometry.location.lng}
                    />
                  )}
                </div>
                <div>
                  <h3>Your Borne Report® is on its way</h3>
                  <AppContext.Consumer>
                    {(appContext) => (
                      <div className="tw-px-6 tw-mt-12">
                        <ProgressBar
                          currentPoint={report.state_type}
                          points={[
                            { label: 'Submitted', value: 'default' },
                            { label: 'Pending', value: 'PENDING' },
                            { label: 'Complete', value: 'COMPLETED' },
                          ]}
                        />
                        <div className="tw-mt-12">
                          {report.state_type === 'COMPLETED'
                            ? `An email should have been sent to ${appContext.user?.email} with your report attached.`
                            : report.state_type === 'PENDING'
                            ? `You'll recieve an email at ${appContext.user?.email} when your report is ready.`
                            : `We are currently working on adressing this report and will notify you of updates at ${appContext.user?.email}.`}
                        </div>
                        <div
                          className="tw-my-8 tw-text-[#0000EE] tw-cursor-pointer tw-underline"
                          onClick={() => {
                            navigate('/');
                          }}
                        >
                          Run another report
                        </div>
                        {viewLink.isSuccess &&
                          viewLink.isFetched &&
                          !viewLink.isFetching &&
                          viewLink.data != undefined &&
                          report.state_type === 'COMPLETED' && (
                            <div className="tw-hidden lg:tw-flex md:tw-flex tw-justify-between tw-mt-6">
                              <BorneSmallButton active={report.state_type === 'COMPLETED'}>
                                <a className="tw-text-white" href={viewLink.data.google_drive_download_link}>
                                  Download as pdf
                                </a>
                              </BorneSmallButton>
                              <BorneSmallButton active={report.state_type === 'COMPLETED'}>
                                <a className="tw-text-white" href={viewLink.data.google_drive_link} target="_blank">
                                  View on Google Drive
                                </a>
                              </BorneSmallButton>
                            </div>
                          )}
                        {viewLink.isSuccess &&
                          viewLink.isFetched &&
                          !viewLink.isFetching &&
                          viewLink.data != undefined &&
                          report.state_type === 'COMPLETED' && (
                            <div className="lg:tw-hidden md:tw-hidden tw-justify-between tw-mt-6">
                              <BorneSmallButton
                                className="tw-mb-4 tw-w-full"
                                active={report.state_type === 'COMPLETED'}
                              >
                                <a className="tw-text-white" href={viewLink.data.google_drive_download_link}>
                                  Download as pdf
                                </a>
                              </BorneSmallButton>
                              <BorneSmallButton active={report.state_type === 'COMPLETED'}>
                                <a className="tw-text-white" href={viewLink.data.google_drive_link} target="_blank">
                                  View on Google Drive
                                </a>
                              </BorneSmallButton>
                            </div>
                          )}
                      </div>
                    )}
                  </AppContext.Consumer>
                </div>
              </>
            )}
          </div>
          <div className="lg:tw-flex tw-flex-col tw-w-[28%] tw-hidden">
            <div className="tw-w-full tw-pb-3 tw-mb-4 tw-rounded-lg tw-bg-white tw-shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
              {borneReportLimit.isSuccess ? (
                <>
                  <Progress
                    className="tw-h-4"
                    color="green"
                    indicating
                    percent={(1 - borneReportLimit.data.remaining / borneReportLimit.data.limit) * 100}
                    attached="top"
                    size="large"
                  />
                  <h2 className="tw-mt-3 tw-mb-0 tw-ml-6">
                    {borneReportLimit.data.remaining} {borneReportLimit.data.remaining == 1 ? 'report' : 'reports'}{' '}
                    remaining
                  </h2>
                  <span className="tw-ml-6">Out of {borneReportLimit.data.limit} for the day</span>
                </>
              ) : (
                <div className="tw-pt-3">
                  <Loader active inline="centered" />
                </div>
              )}
            </div>
            <div className="tw-w-full tw-rounded-lg tw-max-h-[70vh] tw-overflow-y-scroll tw-bg-white tw-shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
              <h3 className="tw-px-4 tw-mb-0 tw-pt-6 tw-pb-2 tw-bg-white tw-w-[100%] tw-border-b-[1px] tw-sticky tw-top-0">
                Your Reports
              </h3>
              <div className="tw-p-6">
                {borneReports.isSuccess ? (
                  borneReports.data.map((report: any, i: any) => {
                    return (
                      <div
                        className="tw-grid tw-grid-cols-2 tw-mb-6 tw-gap-1 tw-cursor-pointer"
                        onClick={() => navigate(`/reportstatus/${report.id}`)}
                      >
                        <h5 className="tw-mb-1">
                          {report.state_type === 'COMPLETED' ? (
                            <Label className="tw-flex tw-w-min" color="green" horizontal>
                              <Icon className="tw-mr-0 lg:tw-mr-1" name="check" />
                              <span className="tw-hidden lg:tw-flex">Completed</span>
                            </Label>
                          ) : report.state_type === 'PENDING' ? (
                            <Label className="tw-flex sm:tw-pb-4 tw-w-min" color="yellow" horizontal>
                              <span className="tw-hidden lg:tw-flex">Pending</span>
                            </Label>
                          ) : (
                            <Label className="lg:tw-flex tw-w-min" color="grey" horizontal>
                              <span className="tw-hidden lg:tw-flex">Delayed </span>
                            </Label>
                          )}
                        </h5>
                        <div className="tw-justify-self-end tw-text-gray-400">{report.created}</div>
                        <div className="tw-mb-0 tw-pb-0 tw-col-span-2">{report.name}</div>
                      </div>
                    );
                  })
                ) : (
                  <div className="tw-pt-3">
                    <Loader active inline="centered" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ReportPage>
    </>
  );
};

export default BorneReportStatus;
