import React from 'react';
import { useNavigate } from 'react-router';
import { Button, Card } from 'semantic-ui-react';
import { Concept } from './concept';

interface Props {
  concept: Concept;
}

const ConceptCard = ({ concept }: Props) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Card.Content>
        <Card.Header>{concept.name || concept.address}</Card.Header>
        {concept.name && <Card.Meta>{concept.address}</Card.Meta>}
        <Card.Description>
          Cuisine: <strong>{concept.cuisine}</strong> | Price: <strong>{concept.price_level}</strong> | Quality:{' '}
          <strong>{concept.service_quality}</strong> | Service Type: <strong>{concept.service_type}</strong>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button basic color="green" onClick={() => navigate('/concept', { state: { concept } })}>
            Configure
          </Button>
        </div>
      </Card.Content>
    </Card>
  );
};

export default ConceptCard;
