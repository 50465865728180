export const affiliations = [
  'restaurant_group_corporation',
  'celebrity_known_chef',
  'celebrity_known_bartender',
  'opened_0_concepts',
  'opened_1_2_concepts',
  'opened_3_4_concepts',
  'opened_5_concepts',
  'famous_known_architect_designer',
  'own_10_year_concept_legacy',
  'none',
] as const;

// TODO: This deserves a better name.
export const alcohols = ['full_bar', 'wine_or_beer', 'none'] as const;
export const communityTypes = ['urban', 'suburban', 'rural'] as const;
export const restaurantTypes = ['neighborhood', 'destination'] as const;
export const serviceQualities = ['fine_dining', 'fast_casual', 'casual'] as const;
export const serviceTypes = ['full_service', 'counter_service', 'quick_serve', 'virtual_service'] as const;

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

export const meals = ['Breakfast', 'Brunch', 'Lunch', 'Dinner', 'Late Night'] as const;

// TODO: This deserves a better name.
export const prices = ['$', '$$', '$$$', '$$$$'] as const;

export type Affiliation = typeof affiliations[number];
export type Alcohol = typeof alcohols[number];
export type CommunityType = typeof communityTypes[number];
export type RestaurantType = typeof restaurantTypes[number];
export type ServiceQuality = typeof serviceQualities[number];
export type ServiceType = typeof serviceTypes[number];
export type Day = typeof days[number];
export type Meal = typeof meals[number];
export type HoursOfOperation = Readonly<Record<Day, Record<Meal, boolean>>>;
export type Price = typeof prices[number];

export const restaurantSizeUnits = ['seats', 'transactions'] as const;
export type RestaurantSizeUnit = typeof restaurantSizeUnits[number];
interface WritableRestaurantSize {
  size?: number;
  units: RestaurantSizeUnit;
  squareFootage?: number;
}
export type RestaurantSize = Readonly<WritableRestaurantSize>;
