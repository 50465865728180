import React, { useState } from 'react';
import Auth from 'borne_ui/src/auth/Auth';
import { BorneFlexContainer } from '../../generic/BorneComponents';
import { MarketTSMap } from '../../maps/MarketTSMap';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Grid, Label, List, Menu } from 'semantic-ui-react';
import { Description, Header } from '../../styled/Typography';

interface Props {
  marketQuery: any;
  marketId: string;
  auth: Auth;
  studyAreaId: string;
}

export const StudyAreasMarketSegmentationV2: React.FC<Props> = ({
  marketQuery,
  marketId,
  studyAreaId,
  auth,
}: Props) => {
  const [tsIndex, setTsIndex] = useState<number>(0);

  return (
    <>
      <Grid.Row>
        <BorneFlexContainer className="tw-items-start tw-relative">
          <div className="tw-mr-4 tw-w-[30%] tw-sticky tw-top-0 tw-pt-8">
            {marketQuery.isSuccess && (
              <Menu vertical secondary compact fluid className="tw-h-[62vh]" style={{ overflowY: 'scroll' }}>
                {marketQuery.data.map((ts: any, idx: number) => (
                  <Menu.Item
                    link
                    active={tsIndex === idx}
                    key={idx}
                    index={idx}
                    onClick={(ev, data) => {
                      setTsIndex(idx);
                    }}
                    style={{
                      paddingLeft: 40,
                    }}
                  >
                    <Label
                      attached="top left"
                      className="tw-h-full tw-w-20"
                      style={{
                        backgroundColor: 'rgba(0,0,0,0)',
                        margin: 0,
                      }}
                    >
                      <ResponsiveContainer height="100%" width="100%">
                        <PieChart width={50} height={50}>
                          <Pie
                            data={[
                              { name: 'Percent', value: ts.pct },
                              { name: 'Complement', value: 100 - ts.pct },
                            ]}
                            startAngle={180}
                            endAngle={-180}
                            innerRadius={15}
                            outerRadius={25}
                            fill="#8884d8"
                            paddingAngle={2}
                            dataKey="value"
                          >
                            <Cell key="cell-0" fill="#0C7C59" />
                            <Cell key="cell-1" fill="#BBBBBB" />
                          </Pie>
                          <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
                            {Math.round(ts.pct)}%
                          </text>
                        </PieChart>
                      </ResponsiveContainer>
                    </Label>
                    <Header className="tw-ml-[40px]" as="h3">
                      {ts.name}
                    </Header>
                    <Description className="tw-ml-[40px]" as="p">
                      {Math.round(ts.total)} trips
                    </Description>
                  </Menu.Item>
                ))}
              </Menu>
            )}
            <p className="tw-mt-8 tw-mr-4">
              The character of a study area depends on the psychographics of its core patrons. Here, we group patrons
              that visit restaurants in this area by their ESRI tapestry segments. ESRI’s Tapestry Segmentation is a
              geodemographic system that identifies distinctive markets in the U.S. based on socioeconomic and
              demographic characteristics to provide an accurate, comprehensive profile of U.S. consumers.
            </p>
          </div>
          <div className="tw-w-[70%] tw-h-full tw-pb-8 tw-pt-8">
            <Grid.Row>
              <Grid.Column textAlign="left">
                <div className="tw-w-full tw-h-[62vh] tw-mb-4 " style={{ border: 'solid 1px' }}>
                  <MarketTSMap
                    studyAreaId={studyAreaId}
                    marketId={marketId}
                    ts={marketQuery.data[tsIndex].id}
                    auth={auth}
                  />
                </div>
                {marketQuery.isSuccess && (
                  <>
                    <Header className="tw-mb-4" as="h2">
                      {marketQuery.data[tsIndex].name}
                    </Header>
                    <Description as="p">{marketQuery.data[tsIndex].description}</Description>
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="tw-mt-4">
              <Grid.Column textAlign="left">
                <Header as="h2">Market Profile</Header>
                {marketQuery.isSuccess && (
                  <List as="ul">
                    {marketQuery.data[tsIndex].marketProfile.map((item: any, idx: number) => (
                      <List.Item key={idx} as="li" content={item} style={{ fontFamily: 'Montserrat' }} />
                    ))}
                  </List>
                )}
              </Grid.Column>
            </Grid.Row>
          </div>
        </BorneFlexContainer>
      </Grid.Row>
    </>
  );
};
