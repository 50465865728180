import React from 'react';
import { WrappedInputField } from './FormikUtil';

interface Props {
  loc: string;
}

const RevenueMultiplierQuestion = ({ loc }: Props) => {
  return <WrappedInputField type="number" label="Revenue multiplier" name={loc} />;
};

export default RevenueMultiplierQuestion;
