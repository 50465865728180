import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import Auth from '../../../auth/Auth';
import withSideMenu from '../../generic/MenuPage';
import { ReportRowLayout } from '../../generic/ReportLayout';
import { ConceptAndScoringDataSegment, ConceptAndScoringDescriptionMenu } from './ConceptAndScoringSection';
import { SearchEngineData, SearchEngineDescription } from './SearchEngineInfo';
import { SiteAndDetailsnMenu, SiteAndDetailsnDataSegment } from './SiteDefinition';
import { VisitorTrafficData, VisitorTrafficDescription } from './VisitorTrafficdefintion';

interface Props {
  auth: Auth;
}

const IndividualReportPage: React.FunctionComponent<Props> = (props: Props) => {
  const [concept, setConcept] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await props.auth.authenticatedAPICall({
          method: 'POST',
          url: '/api/locationsearch/',
        });

        const data = response.data;
        console.log(data);
        setConcept({
          tableData: [
            {
              attribute: 'Market Share',
              score: data['cuisinesNearby']['points'],
              reason: '',
            },
            {
              attribute: 'Population Density',
              score: data['populationDensity']['points'],
              reason: '',
            },
            {
              attribute: 'Median Income',
              score: data['medianIncome']['points'],
              reason: data['medianIncome']['reason'],
            },
            {
              attribute: 'Avg Yelp Rating',
              score: data['averageYelpRating']['points'],
              reason: '',
            },
            {
              attribute: 'Walk Score',
              score: data['walkscore']['points'],
              reason: data['walkscore']['pointsReason'],
            },
            {
              attribute: 'Parking',
              score: data['parking']['points'],
              reason: data['parking']['pointsReason'],
            },
            {
              attribute: 'Reservations',
              score: data['reservation']['points'],
              reason: '',
            },
            {
              attribute: 'Grand Total',
              score: data['totalPoints'],
              reason: '',
            },
            {
              attribute: 'Maximum Points',
              score: data['totalPossiblePoints'],
              reason: '',
            },
          ],
          locationresults: data,
        });
      } catch (err) {
        console.log(err);
        alert("An error occurred. Sorry 'bout that.");
      }
    })();
  }, []);

  return (
    <>
      <Grid
        relaxed
        style={{
          paddingTop: '10vh',
        }}
      >
        {concept ? (
          <>
            <ReportRowLayout
              DataComponent={<ConceptAndScoringDataSegment concept={concept} />}
              DescriptionComponent={<ConceptAndScoringDescriptionMenu />}
            />
            <ReportRowLayout
              DataComponent={<SiteAndDetailsnDataSegment />}
              DescriptionComponent={<SiteAndDetailsnMenu />}
            />
            <ReportRowLayout DataComponent={<SearchEngineData />} DescriptionComponent={<SearchEngineDescription />} />
            <ReportRowLayout
              DataComponent={<VisitorTrafficData />}
              DescriptionComponent={<VisitorTrafficDescription />}
            />
          </>
        ) : (
          <p>Loading...</p>
        )}
      </Grid>
    </>
  );
};

export default withSideMenu(IndividualReportPage, { activeItem: 'reports' });
