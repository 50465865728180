import React, { useEffect, useRef } from 'react';
import { Layer as LeafletLayer } from 'leaflet';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  layer: LeafletLayer;
  permanent: boolean;
  className?: string;
}

const FeatureTooltip = ({ children, layer, permanent, className }: Props) => {
  const tooltipElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    layer.bindTooltip(tooltipElement.current!, { permanent, className, interactive: true });
    return () => {
      layer.unbindTooltip();
    };
  }, [layer]);

  return (
    // The outer <div> is controlled by React while the inner <div> can be
    // taken over and moved by Leaflet when showing the tooltip.
    <div style={{ display: 'none' }}>
      <div ref={tooltipElement}>{children}</div>
    </div>
  );
};

export default FeatureTooltip;
