import { useCurrentUser, useMarketStudyAreaGeos, useSubscribedMarketBounds } from '../../api/requests';
import Auth from '../../auth/Auth';
import React, { useState } from 'react';
import { Grid, Header, Modal } from 'semantic-ui-react';
import { BorneDropDown, BorneFlexContainer, BorneSmallButton } from '../generic/BorneComponents';
import { StudyAreasMap } from '../pages2/StudyAreas/StudyAreasMap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { commonActions } from '../redux/testSlice';

interface Props {
  auth: Auth;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  currentMarket?: number;
  setCurrentMarket: (marketIndex: number) => void;
  updateStudyArea: any;
  setStudyModalOpen: (value: boolean) => void;
}

const SelectSAModal = ({
  auth,
  isOpen,
  setOpen,
  currentMarket,
  setCurrentMarket,
  updateStudyArea,
  setStudyModalOpen,
}: Props) => {
  const [selection, setSelection] = useState<string>();
  const meResponse = useCurrentUser(auth, { refetchOnWindowFocus: false });
  const marketOptions = useSelector((state: RootState) => state.common.currentMarkets);

  const dispatch = useDispatch();
  const setMarketOptions = (x: string[]) => {
    dispatch(commonActions.setCurrentMarket(x));
  };

  const marketsRes = useSubscribedMarketBounds(auth, {
    enabled: meResponse.isSuccess,
    onSuccess: (data) => {
      if (meResponse.data!.settings.preferredMarket && currentMarket === undefined) {
        const indexedMarkets = data
          .map((market, index) => ({ index: index, ...market }))
          .filter((market: any) => meResponse.data!.settings.preferredMarket === market.id);
        setCurrentMarket(indexedMarkets.length != 0 ? indexedMarkets[0]['index'] : 0);
        setMarketOptions([indexedMarkets[0].id]);
      } else if (currentMarket) {
        return;
      } else if (marketOptions[0]) {
        data.map((market, indx) => {
          if (marketOptions.some((x) => x == market.id)) {
            setCurrentMarket(indx);
            return;
          }
        });
      } else if (currentMarket === undefined) {
        setCurrentMarket(0);
        setMarketOptions([data[0].id]);
      }
    },
    refetchOnWindowFocus: false,
  });

  const studyAreasRes = useMarketStudyAreaGeos(
    auth,
    marketsRes.isSuccess && currentMarket !== undefined ? marketsRes.data[currentMarket].id : undefined,
    {
      enabled: marketsRes.isSuccess && currentMarket !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={isOpen}
      dimmer="blurring"
      style={{ width: '75vw', minWidth: '700px', height: '80vh' }}
    >
      <Modal.Header>
        <Header as="h1">Select Study Area</Header>
      </Modal.Header>
      <Modal.Content style={{ height: '100%' }}>
        <Grid style={{ height: '100%' }}>
          <Grid.Column width={11}>
            <StudyAreasMap
              selectedStudyArea={selection}
              auth={auth}
              setCurrentMarket={setCurrentMarket}
              currentMarket={currentMarket}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <BorneFlexContainer className="sm:tw-flex-col lg:tw-flex-row" style={{ justifyContent: 'flex-start' }}>
              {marketsRes.isSuccess && marketsRes.data.length > 0 ? (
                <BorneDropDown
                  selectOnBlur={false}
                  style={{ marginLeft: 0 }}
                  search
                  selection
                  placeholder="Select Market"
                  options={marketsRes.data.map((market, index) => ({
                    key: `studyArea-dropdown-${market.id as string}${index}-some-unique-string`,
                    text: market.id,
                    value: index,
                  }))}
                  selectOnNavigation={false}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, { value }: { value: number }) => {
                    setCurrentMarket(value);
                  }}
                />
              ) : (
                <BorneDropDown selectOnBlur={false} search selection placeholder="City / DMA Name" />
              )}
              {studyAreasRes.isSuccess && studyAreasRes.data ? (
                <BorneDropDown
                  className="sm:tw-ml-0 lg:tw-ml-2 sm:tw-mt-4 lg:tw-mt-0"
                  selectOnBlur={false}
                  search
                  selection
                  placeholder="City / DMA Name"
                  options={studyAreasRes.data.features.map((studyArea, index) => ({
                    key: `studyArea-dropdown-${
                      studyArea.properties && (studyArea.properties.name as string)
                    }${index}-some-unique-string`,
                    text: studyArea.properties && studyArea.properties.name,
                    value: studyArea.id,
                  }))}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
                    setSelection(value);
                  }}
                  selectOnNavigation={false}
                />
              ) : (
                <BorneDropDown selectOnBlur={false} search selection placeholder="City / DMA Name" />
              )}
            </BorneFlexContainer>
            <BorneFlexContainer style={{ height: '100%', alignItems: 'flex-end' }}>
              <BorneSmallButton
                active
                onClick={() => {
                  setStudyModalOpen(true);
                  setOpen(false);
                }}
              >
                <span>Custom Study Area</span>
              </BorneSmallButton>
              <BorneSmallButton
                className="tw-mr-2"
                active
                onClick={() => {
                  updateStudyArea({ action: 'add', studyAreaIds: [selection] });
                  setOpen(false);
                }}
              >
                <span>Confirm</span>
              </BorneSmallButton>
            </BorneFlexContainer>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default SelectSAModal;
