import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Layer as LeafletLayer } from 'leaflet';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  layer: LeafletLayer;
  onOpen?: () => void;
  onClose?: () => void;
}

interface InternalLeafletLayer extends LeafletLayer {
  _popup: {
    _contentNode: HTMLDivElement;
  };
}

const FeaturePopup = ({ children, layer, onOpen, onClose }: Props) => {
  const popupElement = useRef<HTMLDivElement>(null);
  const [popupContainerElement, setPopupContainerElement] = useState<HTMLDivElement>();

  useEffect(() => {
    layer.bindPopup('', { pane: 'popupPane' });

    layer.on('click', () => {
      // When the layer is clicked is when Leaflet creates the container node
      // for the popup. We need to save it so that we can render the contents
      // of the popup into it using a React portal. If we do not use a portal,
      // React will not know where the contents are and will not be able to do
      // normal things like handle event listeners.

      setPopupContainerElement((layer as InternalLeafletLayer)._popup._contentNode);
    });

    if (onOpen) {
      layer.on('popupopen', onOpen);
    }

    if (onClose) {
      layer.on('popupclose', onClose);
    }

    return () => {
      layer.closePopup();

      if (onOpen) {
        layer.off('popupopen', onOpen);
      }

      if (onClose) {
        layer.off('popupclose', onClose);
      }

      setPopupContainerElement(undefined);
      layer.unbindPopup();
    };
  }, [layer]);

  useEffect(() => {
    if (popupContainerElement) {
      layer.setPopupContent(popupElement.current!);
    }
  }, [popupContainerElement]);

  return popupContainerElement ? (
    ReactDOM.createPortal(<div ref={popupElement}>{children}</div>, popupContainerElement)
  ) : (
    <></>
  );
};

export default FeaturePopup;
