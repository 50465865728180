import React from 'react';
import { WrappedInputField } from './FormikUtil';

interface Props {
  loc: string;
}

const ThreeWordsQuestion = ({ loc }: Props) => {
  return <WrappedInputField label="Please describe your restaurant in 3 words:" name={loc} />;
};

export default ThreeWordsQuestion;
