import React, { Component } from 'react';
import { Header as BaseHeader, Segment, SemanticCOLORS, Table } from 'semantic-ui-react';
import { CuisineNearby } from '../../types';

interface Props {
  color: SemanticCOLORS;
  similarRestaurants: CuisineNearby[];
}

class NearbyCuisinesSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">Market Share</BaseHeader>
        <p>
          The market share is calculated by retrieving nearby, similar restaurants and seeing how many are within
          walking distance of the proposed concept.
        </p>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Distance</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.similarRestaurants.slice(0, 10).map((restaurant, id) => {
              return (
                <Table.Row key={id}>
                  <Table.Cell>{restaurant.distanceMeters.toFixed(2)} Meters</Table.Cell>
                  <Table.Cell>{restaurant.name}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default NearbyCuisinesSegment;
