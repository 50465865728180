import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Divider, Form, Grid, Image, Message } from 'semantic-ui-react';
import Auth from '../../../auth/Auth';

interface Props {
  auth: Auth;
  rerender: () => void;
}

interface LocationState {
  path: string | null;
}

const LoginForm = ({ auth, rerender }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [invalid, setInvalid] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const navigate = useNavigate();
  const state = useLocation().state as LocationState;

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    // Dont submit standard form post
    event.preventDefault();
    setLoading(true);
    auth
      .exchangeCredentials(username, password)
      .then(() => {
        if (state && state.path !== null) {
          navigate(state.path);
        } else {
          navigate('/');
        }
        rerender();
      })
      .catch((error) => {
        setLoading(false);
        if (error.status <= 401) setInvalid(true);
        else {
          setError(true);
        }
      });
  };

  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column>
        <Image src="/static/Login_Logo.png" size="medium" inline />
        <Divider hidden />
        {invalid ? (
          <Message negative>
            <Message.Header>Error Logging In</Message.Header>
            <p>Unrecognized username/password combination.</p>
          </Message>
        ) : null}
        {error ? (
          <Message negative>
            <Message.Header>Error Logging In</Message.Header>
            <p>An unknown error was encountered. Please try again later.</p>
          </Message>
        ) : null}
        <Form size="medium" loading={loading} onSubmit={handleSubmit}>
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Username"
            value={username}
            onChange={handleUsernameChange}
          />
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />

          <Button animated fluid type="submit" color="black" size="medium">
            Login
          </Button>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
