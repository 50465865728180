import React from 'react';

const PriceDisclaimer = () => (
  <div className="tw-text-bornegray-darkest tw-mb-4">
    Each report is $749.95 and is billable prior to beginning work. This amount will be charged to the credit card
    entered on the last page of this form.
  </div>
);

export default PriceDisclaimer;
