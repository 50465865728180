import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Card, Divider, Grid, Header as BaseHeader, Message, Table } from 'semantic-ui-react';
import withSideMenu from '../../generic/MenuPage';
import { Header } from '../../styled/Typography';
import { useSiteReport } from '../../../api/requests';
import { AddressMap } from './AddressMap';
import DataTable from '../../generic/DataTable';
import ModelExplanationPopup from '../../generic/ModelExplanationPopup';

import type Auth from '../../../auth/Auth';
import type { User } from '../../../api/models';

interface Params {
  id: string;
}

interface Props {
  auth: Auth;
  user: User;
}

function formatCurrency(value: number) {
  return Math.round(value).toLocaleString('en-US');
}

function formatDistance(meters: number) {
  return (meters / 1609).toFixed(2);
}

const SiteReport: React.FC<Props> = ({ auth, user }: Props) => {
  const { id } = useParams<keyof Params>() as Params;
  const [revenuePopupOpen, setRevenuePopupOpen] = useState<boolean>(false);

  const siteReport = useSiteReport(auth, id);

  return (
    <>
      <Grid padded="horizontally" style={{ padding: '1em', marginTop: '36px' }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h1">Site Report</Header>
          </Grid.Column>
        </Grid.Row>
        {siteReport.isSuccess && (
          <>
            <Grid.Row>
              <Grid.Column width={8}>
                <AddressMap
                  address={siteReport.data.report_data.address}
                  latitude={siteReport.data.report_data.latitude}
                  longitude={siteReport.data.report_data.longitude}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as="h2">{siteReport.data.report_data.address}</Header>
                <BaseHeader>Concept: {siteReport.data.report_data.concept_name}</BaseHeader>
                <Divider />
                <BaseHeader>Projected Annual Revenue Opportunities</BaseHeader>
                <div className="tw-text-5xl tw-text-bornegreen-dark tw-py-4">
                  <ModelExplanationPopup
                    header={<>About this Projection</>}
                    subtext={<>The projected revenue includes the following:</>}
                    open={revenuePopupOpen}
                    setOpen={setRevenuePopupOpen}
                    trigger={<span>${formatCurrency(siteReport.data.report_data.revenue_projection)}</span>}
                    user={user}
                  />
                </div>
                {
                  // The "false" condition to short circuit the explanation trigger was
                  // requested by Ken so that this does not show up in the demos that
                  // he is giving while the data is being mocked.
                  false && user.enterprise_id === 1 && (
                    <a
                      href="#"
                      // use visibility: hidden to keep the layout the same
                      className={`tw-block tw-underline tw-text-black ${revenuePopupOpen ? 'tw-invisible' : ''}`}
                      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                        setRevenuePopupOpen(true);
                        e.preventDefault();
                      }}
                    >
                      What goes into this projection?
                    </a>
                  )
                }
                <Divider />
                <BaseHeader>Occupancy Budget</BaseHeader>
                <div style={{ fontSize: '1.6em', padding: '1rem 0' }}>
                  ${formatCurrency(siteReport.data.report_data.occupancy_budget)} / month
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginTop: '2em' }}>
              <Grid.Column width={8}>
                <Header as="h2">Market Share</Header>
                <br />
                <br />
                {siteReport.data.report_data.market_share.restaurants.length ? (
                  <DataTable
                    scrolling
                    height="40vh"
                    resultsPerPage={8}
                    tableProps={{ basic: 'very', fixed: true, style: { marginBottom: '1em' } }}
                    idColumn="id"
                    data={siteReport.data.report_data.market_share.restaurants.map((restaurant: any) => ({
                      id: restaurant.fsq_id,
                      name: restaurant.name,
                      'distance (miles)': formatDistance(restaurant.distanceMeters),
                    }))}
                  />
                ) : (
                  <Message>No similar restaurants found.</Message>
                )}
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as="h2">Gap Analysis</Header>
                <br />
                <br />
                {siteReport.data.report_data.gap_analysis.length ? (
                  <DataTable
                    scrolling
                    height="40vh"
                    resultsPerPage={8}
                    tableProps={{ basic: 'very', fixed: true, style: { marginBottom: '1em' } }}
                    idColumn="id"
                    data={siteReport.data.report_data.gap_analysis.map((cuisine: string) => ({
                      cuisine: cuisine,
                    }))}
                  />
                ) : (
                  <Message>No gaps found.</Message>
                )}
              </Grid.Column>
              {siteReport.data.report_data.monthly_weather && (
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h2">Monthly Weather</Header>
                    <br />
                    <br />
                    <Card.Group itemsPerRow={12}>
                      {siteReport.data.report_data.monthly_weather.map((month_weather: any) => (
                        <Card style={{ width: '240px' }}>
                          <Card.Content>
                            <Card.Header>{month_weather.month}</Card.Header>
                            <Card.Content>
                              <br />
                              <Table basic="very">
                                <Table.Body>
                                  {month_weather.avg_temp && (
                                    <Table.Row>
                                      <Table.Cell>Avg Temp</Table.Cell>
                                      <Table.Cell>{month_weather.avg_temp}&deg;F</Table.Cell>
                                    </Table.Row>
                                  )}
                                  <Table.Row>
                                    <Table.Cell>Max Temp</Table.Cell>
                                    <Table.Cell>{month_weather.max_temp}&deg;F</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>Min Temp</Table.Cell>
                                    <Table.Cell>{month_weather.min_temp}&deg;F</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>Precip</Table.Cell>
                                    <Table.Cell>{month_weather.precip} in</Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                            </Card.Content>
                          </Card.Content>
                        </Card>
                      ))}
                    </Card.Group>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid.Row>
          </>
        )}
      </Grid>
    </>
  );
};

export default withSideMenu(SiteReport, { activeItem: 'site reports' });
