import React, { SyntheticEvent, useState } from 'react';
import { Form, Search, SearchProps, SearchResultData, SearchResultProps } from 'semantic-ui-react';
import { useField } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { unauthenticatedAPICall } from '../../auth/Auth';

const MIN_CHARACTERS = 5;

const STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

const CorporateStructuredAddressQuestion = () => {
  const [session] = useState(uuidv4());
  const [autoComplete, setAutoComplete] = useState([]);
  const [addressField, addressMeta, addressHelpers] = useField({ name: 'organization_street_address' });
  const [addressLine2Field, addressLine2Meta] = useField({ name: 'organization_address_line2' });
  const [cityField, cityMeta, cityHelpers] = useField({ name: 'organization_city' });
  const [stateField, stateMeta, stateHelpers] = useField({ name: 'organization_state' });
  const [postalCodeField, postalCodeMeta, postalCodeHelpers] = useField({ name: 'organization_postal_code' });

  const runAutoComplete = async (address: string) => {
    try {
      const response = await unauthenticatedAPICall({
        method: 'GET',
        url: `/api/addresses?address=${encodeURIComponent(address)}&session=${session}`,
      });

      setAutoComplete(
        response.data.map((datum: any) => {
          return { title: datum.description, placeId: datum.place_id };
        })
      );
    } catch (ex) {
      console.error(`Auto complete call failed with: ${ex}`);
    }
  };

  const updateAddress = (address?: string): void => {
    addressHelpers.setValue(address);
    addressHelpers.setTouched(true, false);
    if (address && address.length >= MIN_CHARACTERS) {
      runAutoComplete(address);
    }
  };

  const selectAddress = async (placeId: string) => {
    try {
      const response = await unauthenticatedAPICall({
        method: 'GET',
        url: `/api/addressComponents?place_id=${placeId}&session=${session}`,
      });

      addressHelpers.setValue(response.data.street_address);
      addressHelpers.setTouched(true, false);

      cityHelpers.setValue(response.data.city);
      cityHelpers.setTouched(true, false);

      stateHelpers.setValue(response.data.state);
      stateHelpers.setTouched(true, false);

      postalCodeHelpers.setValue(response.data.postal_code);
      postalCodeHelpers.setTouched(true, false);
    } catch (ex) {
      console.error(`Place detail call failed with: ${ex}`);
    }
  };

  return (
    <>
      <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">Street address</label>
      <Form.Field
        control={Search}
        placeholder={addressMeta.touched && addressMeta.error}
        fluid
        minCharacters={MIN_CHARACTERS}
        onResultSelect={(_: SyntheticEvent, { result }: SearchResultData) => selectAddress(result.placeId)}
        onSearchChange={(_: SyntheticEvent, { value }: SearchProps) => updateAddress(value)}
        resultRenderer={({ title }: SearchResultProps) => <div>{title}</div>}
        results={autoComplete}
        error={addressMeta.touched && !!addressMeta.error}
        {...addressField}
      />
      <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
        Address line two
      </label>
      <Form.Input
        {...addressLine2Field}
        error={addressLine2Meta.touched && !!addressLine2Meta.error}
        placeholder={addressLine2Meta.touched && addressLine2Meta.error}
      />
      <div className="tw-flex tw-gap-2 tw-mt-8">
        <div className="tw-flex-auto">
          <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">City</label>
          <Form.Input
            {...cityField}
            error={cityMeta.touched && !!cityMeta.error}
            placeholder={cityMeta.touched && cityMeta.error}
          />
        </div>
        <div className="tw-flex-auto">
          <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">State</label>
          <Form.Dropdown
            name="state"
            onChange={(_, v) => {
              stateHelpers.setValue(v.value);
              stateHelpers.setTouched(true, false);
            }}
            options={STATES.map((state) => ({ text: state, value: state }))}
            value={stateField.value}
            error={stateMeta.touched && !!stateMeta.error}
            placeholder={(stateMeta.touched && stateMeta.error) || undefined}
            fluid
            search
            selection
          />
        </div>
        <div>
          <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">Zip Code</label>
          <Form.Input
            {...postalCodeField}
            error={postalCodeMeta.touched && !!postalCodeMeta.error}
            placeholder={postalCodeMeta.touched && postalCodeMeta.error}
          />
        </div>
      </div>
    </>
  );
};

export default CorporateStructuredAddressQuestion;
