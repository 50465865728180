import React from 'react';
import { Header as BaseHeader, Segment, Table } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { days, meals } from '../../data/categories';
import { formatNumber } from '../../helpers';
import { Concept } from '../../types';
import { Affiliation, Alcohol, ServiceType, ServiceQuality } from '../../data/categories';

const ConceptQuestionCell = styled(Table.Cell)`
  font-weight: bold;
  background: #f9fafb;
`;

function capitalize(str: string): string {
  if (str === '') {
    return '';
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

function printServiceType(serviceType: ServiceType): string {
  switch (serviceType) {
    case 'full_service':
      return 'Full Service';
    case 'counter_service':
      return 'Counter Service';
    case 'quick_serve':
      return 'Quick Serve';
    case 'virtual_service':
      return 'Virtual Service';
    default:
      return serviceType;
  }
}

function printServiceQuality(serviceQuality: ServiceQuality | 'fine_casual'): string {
  switch (serviceQuality) {
    case 'fine_dining':
      return 'Fine Dining';
    case 'fast_casual':
      return 'Fast Casual';
    case 'fine_casual':
      return 'Fine Casual';
    case 'casual':
      return 'Casual';
    default:
      return serviceQuality;
  }
}

function printAlcoholService(alcoholService: Alcohol): string {
  switch (alcoholService) {
    case 'full_bar':
      return 'Full Bar';
    case 'wine_or_beer':
      return 'Wine or Beer';
    case 'none':
      return 'None';
    default:
      return alcoholService;
  }
}

function printAffiliations(affiliations: Affiliation): string {
  switch (affiliations) {
    case 'restaurant_group_corporation':
      return 'Restaurant Group or Corporation';
    case 'celebrity_known_chef':
      return 'Celebrity or Known Chef';
    case 'celebrity_known_bartender':
      return 'Celebrity or Known Bartender';
    case 'opened_0_concepts':
      return 'Opened 0 Concepts';
    case 'opened_1_2_concepts':
      return 'Opened 1-2 Concepts';
    case 'opened_3_4_concepts':
      return 'Opened 3-4 Concepts';
    case 'opened_5_concepts':
      return 'Opened 5+ Concepts';
    case 'famous_known_architect_designer':
      return 'Famous or Known Architect or Designer';
    case 'own_10_year_concept_legacy':
      return 'Own a 10 Year+ Concept/Legacy';
    case 'none':
      return 'None';
    default:
      return affiliations;
  }
}

interface Props {
  concept: Concept;
  isOnlineReport: boolean;
}

const ConceptInfoSegment = ({ concept, isOnlineReport }: Props) => {
  return (
    <Segment color="green">
      <BaseHeader as="h3">Concept</BaseHeader>
      <Table celled structured>
        <Table.Body>
          <Table.Row>
            <ConceptQuestionCell>Address</ConceptQuestionCell>
            <Table.Cell colSpan="8">{concept.address}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Cuisine</ConceptQuestionCell>
            <Table.Cell colSpan="8">{concept.cuisine}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Categories</ConceptQuestionCell>
            <Table.Cell colSpan="8">{(concept.categories || []).join(', ')}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>3 Words</ConceptQuestionCell>
            <Table.Cell colSpan="8">{concept.trending_words}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Restaurant Style</ConceptQuestionCell>
            <Table.Cell colSpan="8">{(concept.styles || []).join(', ')}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell rowSpan="6" verticalAlign="top">
              Hours of Operation
            </ConceptQuestionCell>
            <Table.Cell />
            <Table.Cell>Sun</Table.Cell>
            <Table.Cell>Mon</Table.Cell>
            <Table.Cell>Tue</Table.Cell>
            <Table.Cell>Wed</Table.Cell>
            <Table.Cell>Thurs</Table.Cell>
            <Table.Cell>Fri</Table.Cell>
            <Table.Cell>Sat</Table.Cell>
          </Table.Row>
          {meals.map((meal, i) => (
            <Table.Row key={i}>
              <Table.Cell>{meal}</Table.Cell>
              {days.map((day, j) =>
                concept.hours_of_operation[day][meal] ? (
                  <Table.Cell
                    key={j}
                    positive
                    className="[print-color-adjust:exact] [-webkit-print-color-adjust:exact]"
                  ></Table.Cell>
                ) : (
                  <Table.Cell
                    key={j}
                    negative
                    className="[print-color-adjust:exact] [-webkit-print-color-adjust:exact]"
                  ></Table.Cell>
                )
              )}
            </Table.Row>
          ))}
          <Table.Row>
            <ConceptQuestionCell>Community Type</ConceptQuestionCell>
            <Table.Cell colSpan="8">{capitalize(concept.community_type)}</Table.Cell>
          </Table.Row>
          {!isOnlineReport && (
            <Table.Row>
              <ConceptQuestionCell>Office Park or Tourist Spot</ConceptQuestionCell>
              <Table.Cell colSpan="8">{concept.is_near_office_park_or_tourism ? 'Yes' : 'No'}</Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <ConceptQuestionCell>Restaurant Type</ConceptQuestionCell>
            <Table.Cell colSpan="8">{capitalize(concept.restaurant_type)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Service Type</ConceptQuestionCell>
            <Table.Cell colSpan="8">{printServiceType(concept.service_type)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Service Quality</ConceptQuestionCell>
            <Table.Cell colSpan="8">{printServiceQuality(concept.service_quality)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Restaurant Size</ConceptQuestionCell>
            <Table.Cell colSpan="8">
              {concept.size.squareFootage ? (
                <span>{formatNumber(concept.size.squareFootage)} sq. ft.</span>
              ) : (
                <span>
                  {formatNumber(concept.size.size || 0)} {concept.size.units}
                </span>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Private Dining</ConceptQuestionCell>
            <Table.Cell colSpan="8">{concept.has_private_dining ? 'Yes' : 'No'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Third-party Delivery</ConceptQuestionCell>
            <Table.Cell colSpan="8">{concept.has_third_party_delivery ? 'Yes' : 'No'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Price</ConceptQuestionCell>
            <Table.Cell colSpan="8">{concept.price_level}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Alcohol</ConceptQuestionCell>
            <Table.Cell colSpan="8">{printAlcoholService(concept.alcohol_service)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Reservation System</ConceptQuestionCell>
            <Table.Cell colSpan="8">{concept.accepts_reservations ? 'Yes' : 'No'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Parking Lot</ConceptQuestionCell>
            <Table.Cell colSpan="8">{concept.has_parking_lot ? 'Yes' : 'No'}</Table.Cell>
          </Table.Row>
          {!isOnlineReport && (
            <Table.Row>
              <ConceptQuestionCell>Contract Service</ConceptQuestionCell>
              <Table.Cell colSpan="8">{concept.has_management_contract ? 'Yes' : 'No'}</Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <ConceptQuestionCell>Affiliations</ConceptQuestionCell>
            <Table.Cell colSpan="8">{printAffiliations(concept.affiliations)}</Table.Cell>
          </Table.Row>
          {!isOnlineReport && (
            <Table.Row>
              <ConceptQuestionCell>Scale</ConceptQuestionCell>
              <Table.Cell colSpan="8">{concept.intent_to_scale ? 'Yes' : 'No'}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  );
};

export default ConceptInfoSegment;
