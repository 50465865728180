import Auth from 'borne_ui/src/auth/Auth';
import React, { useEffect, useMemo, useState } from 'react';
import { TileLayer, CircleMarker, Tooltip } from 'react-leaflet';
import GeoJSON from '../generic/GeoJSON';
import { Checkbox, Loader } from 'semantic-ui-react';
import { useStudyAreaGeo, useStudyAreaLocations } from '../../api/requests';
import withMapContext from './WithMapContext';

interface Props {
  auth: Auth;
  studyAreaId: string;
  status: 'open' | 'closed';
  color?: string;
  currentCuisine?: string;
  dayOfWeek?: string;
  hour?: number;
}

function parseStringHour(stringHour: string): number {
  // looks like "00:00"
  return parseInt(stringHour.substring(0, stringHour.indexOf(':')));
}

const StudyAreaSingleLocationMapComponent: React.FC<Props> = ({
  auth,
  studyAreaId,
  status,
  color = '#364C4D',
  currentCuisine,
  dayOfWeek,
  hour,
}: Props) => {
  const [locationsLayer, setLocationsLayer] = useState<any>(<></>);
  const currentDate = new Date();
  const [closedOnlyRecent, setClosedOnlyRecent] = useState<boolean>(false);

  const getLocationType = (hours: any, hoursPopular: any) => {
    if (!dayOfWeek || !hour) {
      return 'none';
    }

    if (!hours || !hours[dayOfWeek]) {
      return 'unknown';
    }

    if (hoursPopular) {
      for (let i = 0; i < (hoursPopular[dayOfWeek] || []).length; i++) {
        for (let j = 0; j < hoursPopular[dayOfWeek].length; j++) {
          const [start, end] = hoursPopular[dayOfWeek][j];

          if (parseStringHour(start) <= hour && parseStringHour(end) > hour) {
            return 'popular';
          }
        }
      }
    }

    for (let i = 0; i < hours[dayOfWeek].length; i++) {
      for (let j = 0; j < hours[dayOfWeek].length; j++) {
        const [start, end] = hours[dayOfWeek][j];

        if (parseStringHour(start) <= hour && parseStringHour(end) > hour) {
          return 'open';
        }
      }
    }

    return 'closed';
  };

  const studyAreaGeoRes = useStudyAreaGeo(auth, studyAreaId, {
    refetchOnWindowFocus: false,
  });

  const locations = useStudyAreaLocations(auth, studyAreaId, status, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (locations.isSuccess) {
      setLocationsLayer(
        <>
          {locations.data
            .filter(
              ({ cuisines }) =>
                !currentCuisine || (!!currentCuisine && !cuisines.map((x) => x.cuisine).includes(currentCuisine))
            )
            .map(({ coords, name, hours, hours_popular, address, date_closed }, idx) => {
              let fillColor = color;
              let radius = 4;
              let locDesc;

              const locType = getLocationType(hours, hours_popular);

              switch (locType) {
                case 'popular':
                  fillColor = '#FFD700';
                  locDesc = 'Popular';
                  break;
                case 'open':
                  fillColor = '#364C4D';
                  locDesc = 'Open';
                  break;
                case 'closed':
                  fillColor = '#C3743A';
                  locDesc = 'Closed';
                  break;
                default:
                  fillColor = 'gray';
                  break;
              }
              return (
                <>
                  {(status === 'open' ||
                    !closedOnlyRecent ||
                    (currentDate.getTime() - new Date(date_closed!).getTime() < 60 * 24 * 60 * 60 * 1000 &&
                      !!date_closed)) && (
                    <CircleMarker
                      key={`${idx}-${fillColor}-${radius}`}
                      center={coords}
                      stroke={false}
                      fillOpacity={1}
                      fillColor={fillColor}
                      radius={radius}
                    >
                      <Tooltip>
                        <div style={{ textAlign: 'left' }}>
                          <b>{name}</b>
                          <br />
                          {address}
                          <br />
                          {status !== 'open' && (date_closed ? `Closed since ${date_closed}` : 'Close date unknown')}
                          <span style={{ color: fillColor }}>{locDesc}</span>
                        </div>
                      </Tooltip>
                    </CircleMarker>
                  )}
                </>
              );
            })}

          {locations.data
            .filter(({ cuisines }) => !!currentCuisine && cuisines.map((x) => x.cuisine).includes(currentCuisine))
            .map(({ coords, name, address, date_closed }, idx) => {
              return (
                <>
                  {
                    <CircleMarker
                      key={idx}
                      center={coords}
                      stroke={false}
                      fillOpacity={1}
                      fillColor="#BFD251"
                      radius={4}
                    >
                      <Tooltip>
                        <b>{name}</b>
                        <br />
                        {address}
                        <br />
                        {status !== 'open' && (date_closed ? `Closed since ${date_closed}` : 'Close date unknown')}
                      </Tooltip>
                    </CircleMarker>
                  }
                </>
              );
            })}
        </>
      );
    }
  }, [currentCuisine, locations.isFetching, locations.isSuccess, hour, dayOfWeek, closedOnlyRecent, status]);

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
    ),
    []
  );

  return (
    <>
      {studyAreaGeoRes.isSuccess && !studyAreaGeoRes.isFetching && locations.isSuccess ? (
        <>
          {Tiles}
          <GeoJSON
            data={studyAreaGeoRes.data}
            style={(_) => ({
              color: 'rgba(0,0,0,.4)',
              fillColor: 'rgba(0,0,0,0.01)',
            })}
          />
          {locations.data.reduce((p, x) => !!x.date_closed || p, false) && (
            <div
              className="leaflet-top leaflet-right leaflet-control"
              style={{
                backgroundColor: '#FFF',
                marginTop: 10,
                marginRight: 10,
                borderStyle: 'solid',
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                borderColor: 'rgba(0,0,0,.2)',
                borderWidth: 2,
              }}
            >
              <Checkbox
                slider
                className="leaflet-control"
                label="Recently closed"
                style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }}
                checked={closedOnlyRecent}
                onChange={(e, _) => {
                  e.stopPropagation();
                  setClosedOnlyRecent(!closedOnlyRecent);
                }}
              />
            </div>
          )}
          {locationsLayer}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export const StudyAreaSingleLocationMap = withMapContext(StudyAreaSingleLocationMapComponent, { height: '60vh' });
