import { deleteSiteReport, useSiteReports } from '../../../api/requests';
import Auth from 'borne_ui/src/auth/Auth';
import React, { useState } from 'react';
import { BorneFlexContainer, BorneSmallButton, PageContainer } from '../../generic/BorneComponents';
import SideMenu2 from '../../generic/SideMenu2';
import { Grid, Icon } from 'semantic-ui-react';
import { BorneSmallEye, BorneSmallPlus } from '../../generic/BorneStyledcons';
import BorneDataTable from '../../generic/BorneDataTableV2';
import { CellClassParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import NewSiteReportModal from '../../pages/SiteReportList/NewSiteReportModal';

interface Props {
  auth: Auth;
}

const SiteReports: React.FC<Props> = ({ auth }: Props) => {
  const [search, setSearch] = useState<string>();
  const [siteModalOpen, setSiteModalOpen] = useState(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const deleteSiteReportRequest = deleteSiteReport(auth);

  const siteReports = useSiteReports(auth);
  return (
    <PageContainer>
      <SideMenu2 />
      <NewSiteReportModal
        className="tw-z-[1100]"
        auth={auth}
        handleClose={() => setSiteModalOpen(false)}
        open={siteModalOpen}
        closeOnEscape={false}
      />
      <Grid className="tw-m-0 tw-h-full tw-w-full">
        <BorneFlexContainer className="tw-items-start tw-flex-col tw-h-screen tw-pt-4">
          <BorneFlexContainer className="tw-m-4 tw-justify-between">
            <h2 className="tw-m-0">Site Reports</h2>
          </BorneFlexContainer>
          <BorneFlexContainer className="tw-pb-4">
            <BorneFlexContainer className="tw-items-center tw-justify-start">
              <BorneFlexContainer className="tw-justify-start">
                <p className="tw-mb-0">Search Reports</p>
                <div>
                  <input
                    className="tw-w-full tw-p-1 tw-ml-4 tw-pl-2"
                    placeholder="Keywords/Report Codes"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
              </BorneFlexContainer>
            </BorneFlexContainer>
          </BorneFlexContainer>
          <BorneDataTable
            className="sm:tw-hidden lg:tw-block"
            width={1400}
            fill
            height={600}
            headers={[
              { field: 'id', headerName: 'Report No', width: 100, sortable: true, filter: true },
              { field: 'address', headerName: 'Address', width: 350, sortable: true },
              { field: 'concept_name', headerName: 'Concept', width: 200, sortable: true },
              { field: 'created_by_username', headerName: 'Created by', width: 150, sortable: true },
              { field: 'created', headerName: 'Created On', width: 150, sortable: true },
              {
                field: 'siteReportsButton',
                width: 60,
                cellRenderer: (x: CellClassParams) => x.data.siteReportsButton,
                headerName: '',
              },
              {
                field: 'deleteButton',
                width: 60,
                cellRenderer: (x: CellClassParams) => x.data.deleteButton,
                headerName: '',
              },
            ]}
            rowData={
              siteReports.isSuccess &&
              siteReports.data.map((x: any) => ({
                id: x.id,
                address: x.address,
                concept_name: x.report_data.concept_name,
                created_by_username: x.created_by_username,
                created: new Date(x.created).toLocaleDateString(),
                siteReportsButton: (
                  <Link to={`/sitereports/${x.id}`}>
                    <BorneSmallButton active>
                      <BorneSmallEye />
                    </BorneSmallButton>
                  </Link>
                ),
                deleteButton: x.is_editable && (
                  <BorneSmallButton active>
                    <Icon
                      className="tw-m-0"
                      name="trash"
                      link
                      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        e.stopPropagation();
                        deleteSiteReportRequest.mutate(x.id);
                      }}
                    />
                  </BorneSmallButton>
                ),
              }))
            }
            filterText={search}
          />
          <BorneDataTable
            className="sm:tw-block lg:tw-hidden"
            width={1400}
            fill
            height={600}
            headers={[
              { field: 'id', headerName: 'Report No', width: 90, sortable: true, filter: true },
              { field: 'concept_name', headerName: 'Concept', width: 180, sortable: true },
              { field: 'created_by_username', headerName: 'Created by', width: 130, sortable: true },
              { field: 'created', headerName: 'Created On', width: 130, sortable: true },
              {
                field: 'siteReportsButton',
                width: 60,
                cellRenderer: (x: CellClassParams) => x.data.siteReportsButton,
                headerName: '',
              },
              {
                field: 'deleteButton',
                width: 60,
                cellRenderer: (x: CellClassParams) => x.data.deleteButton,
                headerName: '',
              },
            ]}
            rowData={
              siteReports.isSuccess &&
              siteReports.data.map((x: any) => ({
                id: x.id,
                address: x.address,
                concept_name: x.report_data.concept_name,
                created_by_username: x.created_by_username,
                created: new Date(x.created).toLocaleDateString(),
                siteReportsButton: (
                  <Link to={`/sitereports/${x.id}`}>
                    <BorneSmallButton active>
                      <BorneSmallEye />
                    </BorneSmallButton>
                  </Link>
                ),
                deleteButton: x.is_editable && (
                  <BorneSmallButton active>
                    <Icon
                      className="tw-m-0"
                      name="trash"
                      link
                      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        e.stopPropagation();
                        deleteSiteReportRequest.mutate(x.id);
                      }}
                    />
                  </BorneSmallButton>
                ),
              }))
            }
            filterText={search}
          />
          <BorneSmallButton className="tw-mt-4" onClick={() => setSiteModalOpen(!siteModalOpen)} active>
            <span>New Site Report</span>
            <BorneSmallPlus />
          </BorneSmallButton>
        </BorneFlexContainer>
      </Grid>
    </PageContainer>
  );
};

export default SiteReports;
