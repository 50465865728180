import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthErrors from './AuthErrors';

function getCsrfToken(): string | undefined {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith('csrftoken='))
    ?.split('=')[1];
}
class Auth {
  accessTokenConstant = 'at';
  refreshTokenConstant = 'rt';

  /**
   * Returns whether or not the user is authenticated in this browser
   */
  hasCredentials(): boolean {
    return this.hasAccessToken() && this.hasRefreshToken();
  }

  /**
   * Remove credentials from browser
   */
  deleteCredentials(): void {
    window.sessionStorage.removeItem(this.accessTokenConstant);
    window.localStorage.removeItem(this.refreshTokenConstant);
  }

  /**
   * This function checks if the current browser has auth credentials.
   * Even if true is returned, the credentials may be invalid / expired.
   */
  hasAccessToken(): boolean {
    // This is intentionally a loose-check. If it's anything *like* null, then return false.
    return window.sessionStorage.getItem(this.accessTokenConstant) != null;
  }

  /**
   * Retrieves the access token from session storage if it exists
   */
  getAccessToken(): string | null {
    return window.sessionStorage.getItem(this.accessTokenConstant);
  }

  /**
   * Saves passed access token into sessionStorage
   */
  setAccessToken(accessToken: string): void {
    window.sessionStorage.setItem(this.accessTokenConstant, accessToken);
  }

  /**
   * This function checks if the local storage has a refresh token
   */
  hasRefreshToken(): boolean {
    // This is intentionally a loose-check. If it's anything *like* null, then return false.
    return window.localStorage.getItem(this.refreshTokenConstant) != null;
  }

  /**
   * Retrieves the refresh token from local storage if it exists
   */
  getRefreshToken(): string | null {
    return window.localStorage.getItem(this.refreshTokenConstant);
  }

  /**
   * Saves passed refresh token into localStorage
   */
  setRefreshToken(refreshToken: string): void {
    window.localStorage.setItem(this.refreshTokenConstant, refreshToken);
  }

  exchangeRefreshToken(): Promise<AxiosResponse> {
    return new Promise((accept, reject) => {
      if (this.hasRefreshToken()) {
        axios
          .post('/api/token/refresh/', {
            refresh: this.getRefreshToken(),
          })
          .then((result) => {
            this.setAccessToken(result.data['access']);
            accept(result);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject(AuthErrors.NO_REFRESH_TOKEN);
      }
    });
  }

  /**
   * Exchange a user-entered username and password for a new AT RT pair
   * Returned promise completes happily if successful and exceptionally if unsuccessful,
   * but no authentication data is passed back to the caller, it's maintained in session/local storage
   */
  exchangeCredentials(username = '', password = ''): Promise<AxiosResponse> {
    return new Promise((accept, reject) => {
      axios
        .post('/api/token/', {
          username: username,
          password: password,
        })
        .then((result) => {
          this.setAccessToken(result.data['access']);
          this.setRefreshToken(result.data['refresh']);
          accept(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Pass in an Axios configuration object (but remember authentication is taken care of for you)
   * If access token has expired, attempts to use refresh token to get a new access token.
   * If the refresh token is expired, an error is returned
   */
  async authenticatedAPICall(axiosSettings: AxiosRequestConfig): Promise<AxiosResponse> {
    return new Promise((accept, reject) => {
      if (axiosSettings.headers !== undefined) {
        axiosSettings.headers['Authorization'] = 'Bearer ' + this.getAccessToken();
        axiosSettings.headers['Content-Type'] = 'application/json';
        axiosSettings.headers['X-CSRFToken'] = getCsrfToken();
      } else {
        axiosSettings.headers = {
          Authorization: 'Bearer ' + this.getAccessToken(),
          'Content-Type': 'application/json',
          'X-CSRFToken': getCsrfToken(),
        };
      }
      axios(axiosSettings)
        .then((result) => {
          accept(result);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.exchangeRefreshToken()
              // TODO: Provide a real type.
              .then((refreshRes: any) => {
                if (refreshRes.status === 200) {
                  axios(axiosSettings)
                    .then((secondTryRes) => {
                      accept(secondTryRes);
                    })
                    .catch((secondTryError) => {
                      reject(secondTryError);
                    });
                } else {
                  reject(AuthErrors.INVALID_REFRESH_TOKEN);
                }
              })
              .catch((refreshError) => {
                reject(refreshError);
              });
          } else {
            reject(err);
          }
        });
    });
  }
}

export default Auth;

export async function unauthenticatedAPICall(axiosSettings: AxiosRequestConfig): Promise<AxiosResponse> {
  return new Promise((accept, reject) => {
    if (axiosSettings.headers !== undefined) {
      axiosSettings.headers['Content-Type'] = 'application/json';
      axiosSettings.headers['X-CSRFToken'] = getCsrfToken();
    } else {
      axiosSettings.headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(),
      };
    }
    axios(axiosSettings)
      .then((result) => {
        accept(result);
      })
      .catch((err) => reject(err));
  });
}
