import React from 'react';
import { Container, Header, Segment, Tab } from 'semantic-ui-react';
import { Elements } from '@stripe/react-stripe-js';
import { AppContext } from '../AppContext';
import Auth from '../../auth/Auth';
import withSideMenu from '../generic/MenuPage';
import Ach from '../payments/Ach';
import CreditCard from '../payments/CreditCard';

interface Props {
  auth: Auth;
}

const Settings: React.FC<Props> = (props: Props) => {
  const panes = [
    {
      menuItem: 'Credit card',
      render: () => (
        <Tab.Pane attached={false} as={Container}>
          <CreditCard auth={props.auth} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Bank account',
      render: () => (
        <Tab.Pane attached={false} as={Container}>
          <Ach auth={props.auth} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Container style={{ paddingTop: '2vh' }}>
      <h1>Settings</h1>
      <Header as="h2" attached="top">
        Billing
      </Header>
      <Segment attached>
        <AppContext.Consumer>
          {(appContext) => (
            <Elements stripe={appContext.stripe!}>
              <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </Elements>
          )}
        </AppContext.Consumer>
      </Segment>
    </Container>
  );
};

export default withSideMenu(Settings, { activeItem: 'settings' });
