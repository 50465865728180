import styled from '@emotion/styled';

export const BorneEye = styled.div`
  background-image: url(/static/icons/icons/eye.svg);
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
`;

export const BorneSmallEye = styled.div`
  background-image: url(/static/icons/icons/eye.svg);
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
`;

export const BorneSmallEyeDark: any = styled.div`
  background-image: url(/static/icons/icons/eye-dark.svg);
  background-repeat: no-repeat;
  width: ${(props: any) => (props.size ? props.size : 14)}px;
  height: ${(props: any) => (props.size ? props.size : 14)}px;
`;

export const BorneCompare: any = styled.div`
  background-image: ${(props: any) =>
    props.active
      ? 'url(/static/icons/icons/compare_green.svg'
      : props.color === 'white'
      ? 'url(/static/icons/icons/compare_white.svg'
      : 'url(/static/icons/icons/compare.svg'});
  background-repeat: no-repeat;
  width: ${(props: any) => (props.size ? props.size : 14)}px;
  height: ${(props: any) => (props.size ? props.size : 14)}px;
`;

export const BorneSmallPlus = styled.div`
  background-image: url(/static/icons/icons/add.svg);
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
`;

export const BorneSmallSearch = styled.div`
  background-image: url(/static/icons/icons/search.svg);
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
`;

export const BorneSmallEmail = styled.div`
  background-image: url(/static/icons/icons/email.svg);
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
`;

export const BorneSmallChat = styled.div`
  background-image: url(/static/icons/icons/chat.svg);
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
`;

export const BorneSmallRightArrow = styled.div`
  background-image: url(/static/icons/icons/rightarrow.svg);
  background-repeat: no-repeat;
  width: 17px;
  height: 10px;
`;

export const BorneAlertIcon = styled.div`
  background-image: url(/static/icons/icons/alert.svg);
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  margin-right: 1rem;
`;

export const BorneCloseIcon = styled.div`
  background-image: url(/static/icons/icons/close.svg);
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  cursor: pointer;
  position absolute;
  right .5rem;
  top .5rem;
`;

export const BorneWhiteClose = styled.div`
  background-image: url(/static/icons/icons/white-close.svg);
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;

export const BorneLock = styled.div`
  background-image: url(/static/icons/icons/lock.svg);
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
`;

export const BorneExpand = styled.div`
  background-image: url(static/icons/icons/filters.svg);
  background-repeat: no-repeat;
  border: 0;
  width: 25px;
  height: 25px;
`;

export const BorneHelp = styled.div`
  background-image: url(static/icons/icons/help-filled.svg);
  background-repeat: no-repeat;
  border: 0;
  width: 16px;
  height: 16px;
`;
