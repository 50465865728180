import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, DropdownItemProps, Form as SemanticForm } from 'semantic-ui-react';
import { Field, Formik, Form as FormikForm, useField } from 'formik';
import * as Yup from 'yup';
import { yelpCuisines } from '../../data/cuisines';
import yelpCategories from '../../data/yelpCategories';
import HoursOfOperationQuestion from '../questions/HoursOfOperationQuestion';
import { affiliations, alcohols, prices, restaurantTypes, serviceQualities, serviceTypes } from '../../data/categories';
import { ReportConcept } from '../concept/concept';
import { useUpdateReportRequest } from '../../api/requests';
import FormPage from './FormPage';
import PriceDisclaimer from './PriceDisclaimer';

const Dropdown = ({ options, ...props }: any) => {
  const [field, meta, helpers] = useField(props);

  function toDropdownOption(value: string): DropdownItemProps {
    return {
      text: value,
      value,
    };
  }

  function isString(option: any): option is string {
    return typeof option === 'string';
  }

  return (
    <SemanticForm.Dropdown
      onChange={(_, v) => {
        helpers.setValue(v.value);
        helpers.setTouched(true, false);
      }}
      options={options.map((option: any) => (isString(option) ? toDropdownOption(option) : option))}
      value={field.value}
      error={meta.touched && !!meta.error}
      placeholder={meta.touched && meta.error}
      {...props}
    />
  );
};

const YesNoRadioGroup = (props: any) => {
  const [field, , helpers] = useField(props);

  return (
    <div className="tw-flex">
      <label className="tw-flex-none tw-mr-4">
        <input
          type="radio"
          name={props.name}
          className="tw-mr-2"
          checked={field.value}
          onChange={() => {
            helpers.setValue(true);
            helpers.setTouched(true, false);
          }}
        />
        Yes
      </label>
      <label className="tw-flex-none">
        <input
          type="radio"
          name={props.name}
          className="tw-mr-2"
          checked={!field.value}
          onChange={() => {
            helpers.setValue(false);
            helpers.setTouched(true, false);
          }}
        />
        No
      </label>
    </div>
  );
};

const restaurantStyles = [
  'Authentic',
  'Brewery',
  'Classic',
  'Contemporary',
  'Family',
  'Farm to table',
  'Full bar',
  'Fusion',
  'Grill',
  'Homemade',
  'Local',
  'Organic',
  'Sustainable',
];

const ConceptForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { concept: initialConceptValues }: { concept: ReportConcept } = {
    concept: {
      name: state.reportRequest?.name ?? '',
      cuisine: state.reportRequest?.cuisine ?? '',
      categories: state.reportRequest?.categories || [],
      word1: state.reportRequest?.word1 ?? '',
      word2: state.reportRequest?.word2 ?? '',
      word3: state.reportRequest?.word3 ?? '',
      styles: state.reportRequest?.styles || [],
      restaurant_type: state.reportRequest?.restaurant_type ?? '',
      service_type: state.reportRequest?.service_type ?? '',
      service_quality: state.reportRequest?.service_quality || 'casual',
      has_private_dining: state.reportRequest?.has_private_dining ?? false,
      has_third_party_delivery: state.reportRequest?.has_third_party_delivery ?? false,
      price_level: state.reportRequest?.price_level ?? '',
      alcohol_service: state.reportRequest?.alcohol_service ?? '',
      accepts_reservations: state.reportRequest?.accepts_reservations ?? false,
      affiliations: state.reportRequest?.affiliations ?? '',
    },
  };

  const updateReportRequest = useUpdateReportRequest();

  return (
    <FormPage currentStep="concept">
      <div className="tw-flex">
        <div className="tw-w-[40%]">
          <header className="tw-text-3xl tw-text-borneblue-darkest tw-mb-4">Concept Information</header>
          <PriceDisclaimer />
          <div className="tw-text-bornegray-darkest tw-mb-12">Describe the details of your concept.</div>
          <a
            href="#"
            onClick={(e) => {
              navigate(-1);
              e.preventDefault();
            }}
            className="tw-mt-12 tw-pt-[12px] tw-pb-[10px] tw-px-[15px] tw-border tw-border-gray-300 tw-uppercase tw-text-[0.8em] tw-text-[#707070] hover:tw-bg-[#bccd19] hover:tw-text-[#2f2e2b] tw-transition-colors tw-duration-300"
          >
            ← Previous Step
          </a>
        </div>
        <div className="tw-w-[60%] tw-pl-[3em] tw-pr-[6em]">
          <Formik
            initialValues={initialConceptValues}
            validationSchema={Yup.object({
              name: Yup.string().required('Please enter a name for your concept.'),
              cuisine: Yup.string().required('Please select a cuisine'),
              categories: Yup.array().max(10, 'Please select fewer categories'),
              word1: Yup.string(),
              word2: Yup.string(),
              word3: Yup.string(),
              styles: Yup.array(),
              hours_of_operation: Yup.object(),
              // TODO: When https://github.com/jquense/yup/issues/1298 is released,
              // replace [...restaurantTypes] with restaurantTypes.
              restaurant_type: Yup.string()
                .oneOf([...restaurantTypes])
                .required('Please select a restaurant type'),
              // TODO: When https://github.com/jquense/yup/issues/1298 is released,
              // replace [...serviceTypes] with serviceTypes.
              service_type: Yup.string()
                .oneOf([...serviceTypes])
                .required('Please select a service type'),
              // TODO: When https://github.com/jquense/yup/issues/1298 is released,
              // replace [...serviceQualities] with serviceQualities.
              service_quality: Yup.string()
                .oneOf([...serviceQualities])
                .required('Please select a service quality'),
              has_private_dining: Yup.boolean(),
              has_third_party_delivery: Yup.boolean(),
              // TODO: When https://github.com/jquense/yup/issues/1298 is released,
              // replace [...prices] with prices.
              price_level: Yup.string()
                .oneOf([...prices])
                .required('Please select a price level'),
              // TODO: When https://github.com/jquense/yup/issues/1298 is released,
              // replace [...alcohols] with alcohols.
              alcohol_service: Yup.string()
                .oneOf([...alcohols])
                .required('Please select alcohol service'),
              accepts_reservations: Yup.boolean(),
              // TODO: When https://github.com/jquense/yup/issues/1298 is released,
              // replace [...affiliations] with affiliations.
              affiliations: Yup.string().oneOf([...affiliations]),
            })}
            onSubmit={async (values) => {
              /*
          values = {
            name: 'Afghan Healthy Food',
            cuisine: 'Acai Bowls',
            categories: ['Acai Bowls', 'Afghan'],
            word1: 'one',
            word2: 'two',
            word3: 'three',
            styles: ['Family', 'Farm to table'],
            hours_of_operation: {
              Sunday: {
                Breakfast: true,
                Brunch: false,
                Lunch: false,
                Dinner: false,
                'Late Night': false,
              },
              Monday: {
                Breakfast: true,
                Brunch: false,
                Lunch: false,
                Dinner: false,
                'Late Night': false,
              },
              Tuesday: {
                Breakfast: true,
                Brunch: false,
                Lunch: false,
                Dinner: false,
                'Late Night': false,
              },
              Wednesday: {
                Breakfast: true,
                Brunch: false,
                Lunch: false,
                Dinner: false,
                'Late Night': false,
              },
              Thursday: {
                Breakfast: true,
                Brunch: false,
                Lunch: false,
                Dinner: false,
                'Late Night': false,
              },
              Friday: {
                Breakfast: true,
                Brunch: false,
                Lunch: false,
                Dinner: false,
                'Late Night': false,
              },
              Saturday: {
                Breakfast: true,
                Brunch: false,
                Lunch: false,
                Dinner: false,
                'Late Night': false,
              },
            },
            restaurant_type: 'neighborhood',
            service_type: 'counter_service',
            service_quality: 'casual',
            has_private_dining: true,
            has_third_party_delivery: true,
            price_level: '$$$',
            alcohol_service: 'none',
            accepts_reservations: false,
            affiliations: 'opened_3_4_concepts',
          };
          */
              if (values.service_type !== 'full_service') {
                values.service_quality = 'casual';
              }

              await updateReportRequest.mutateAsync({ reportRequestId: state.reportRequest.id, reportRequest: values });

              const reportRequest = {
                ...state.reportRequest,
                ...values,
              };

              // This supports keeping the form filled in when pressing back from next page.
              navigate(location.pathname, { replace: true, state: { reportRequest } });

              navigate('/report/contact', { state: { reportRequest } });
            }}
          >
            {(formikBag) => (
              <SemanticForm as={FormikForm} onSubmit={formikBag.handleSubmit}>
                <br />
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                  Concept name or working name
                </label>
                <Field name="name">
                  {({ field, meta }: { field: any; meta: any }) => (
                    <SemanticForm.Input {...field} error={meta.touched && !!meta.error} />
                  )}
                </Field>
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                  Primary cuisine category
                </label>
                <Dropdown name="cuisine" fluid search selection options={yelpCuisines} />
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
                  Secondary cuisine categories
                </label>
                <Dropdown name="categories" fluid multiple search selection options={yelpCategories} />
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
                  Describe your concept in three words
                </label>
                <div className="tw-grid tw-grid-cols-3 tw-gap-2">
                  {[1, 2, 3].map((index) => (
                    <Field name={`word${index}`} key={`word${index}`}>
                      {({ field, meta }: { field: any; meta: any }) => (
                        <SemanticForm.Input {...field} error={meta.touched && !!meta.error} />
                      )}
                    </Field>
                  ))}
                </div>
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
                  Select the styles that match your restaurant
                </label>
                <Dropdown name="styles" fluid multiple search selection options={restaurantStyles} />
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
                  Special affiliations
                </label>
                <Dropdown
                  name="affiliations"
                  clearable
                  fluid
                  selection
                  options={[
                    { text: 'Celebrity or Known Bartender', value: 'celebrity_known_bartender' },
                    { text: 'Celebrity or Known Chef', value: 'celebrity_known_chef' },
                    { text: 'Famous or Known Architect or Designer', value: 'famous_known_architect_designer' },
                    { text: 'Opened 0 Concepts', value: 'opened_0_concepts' },
                    { text: 'Opened 1-2 Concepts', value: 'opened_1_2_concepts' },
                    { text: 'Opened 3-4 Concepts', value: 'opened_3_4_concepts' },
                    { text: 'Opened 5+ Concepts', value: 'opened_5_concepts' },
                    { text: 'Own a 10 Year+ Concept/Legacy', value: 'own_10_year_concept_legacy' },
                    { text: 'Restaurant Group or Corporation', value: 'restaurant_group_corporation' },
                  ]}
                />
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
                  Select intended hours of operation
                </label>
                <HoursOfOperationQuestion loc="hours_of_operation" formikBag={formikBag} noLabel={true} />
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
                  Select the restaurant type
                </label>
                <Dropdown
                  name="restaurant_type"
                  fluid
                  selection
                  options={[
                    { text: 'Destination', value: 'destination' },
                    { text: 'Neighborhood', value: 'neighborhood' },
                  ]}
                />
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
                  Select the price level
                </label>
                <Dropdown name="price_level" fluid selection options={['$', '$$', '$$$', '$$$$']} />
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
                  Select the service type
                </label>
                <Dropdown
                  name="service_type"
                  fluid
                  selection
                  options={[
                    { text: 'Counter Service', value: 'counter_service' },
                    { text: 'Full Service', value: 'full_service' },
                    { text: 'Quick Serve', value: 'quick_serve' },
                    { text: 'Virtual Service', value: 'virtual_service' },
                  ]}
                />
                {formikBag.values.service_type === 'full_service' && (
                  <>
                    <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
                      Select the service quality
                    </label>
                    <Dropdown
                      name="service_quality"
                      fluid
                      selection
                      options={[
                        { text: 'Casual', value: 'casual' },
                        { text: 'Fast Casual', value: 'fast_casual' },
                        { text: 'Fine Dining', value: 'fine_dining' },
                      ]}
                    />
                  </>
                )}
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-mt-8 tw-uppercase">
                  Select the alcohol service type
                </label>
                <Dropdown
                  name="alcohol_service"
                  fluid
                  selection
                  options={[
                    { text: 'Full Bar', value: 'full_bar' },
                    { text: 'None', value: 'none' },
                    { text: 'Wine or Beer', value: 'wine_or_beer' },
                  ]}
                />
                <div className="tw-grid tw-grid-cols-6 tw-mt-8">
                  <div className="tw-col-span-5 tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                    Do you offer private dining?
                  </div>
                  <div className="tw-text-right tw-self-center">
                    <YesNoRadioGroup name="has_private_dining" />
                  </div>
                </div>
                <div className="tw-grid tw-grid-cols-6 tw-mt-4">
                  <div className="tw-col-span-5 tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                    Do you provide third-party delivery?
                  </div>
                  <div className="tw-text-right tw-self-center">
                    <YesNoRadioGroup name="has_third_party_delivery" />
                  </div>
                </div>
                <div className="tw-grid tw-grid-cols-6 tw-mt-4">
                  <div className="tw-col-span-5 tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                    Does your restaurant plan to use a reservation system?
                  </div>
                  <div className="tw-text-right tw-self-center">
                    <YesNoRadioGroup name="accepts_reservations" />
                  </div>
                </div>
                <Button
                  className="tw-bg-borneblue-darkest tw-uppercase tw-text-bornegreen-dark tw-font-normal tw-mt-16"
                  type="submit"
                  primary
                >
                  Enter Contact Info →
                </Button>
                <br />
                <br />
                <br />
              </SemanticForm>
            )}
          </Formik>
        </div>
      </div>
    </FormPage>
  );
};

export default ConceptForm;
