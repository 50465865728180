import React, { Component } from 'react';
import { Bar, BarChart, CartesianGrid, Label, Tooltip, XAxis, YAxis } from 'recharts';
import { Header, Segment } from 'semantic-ui-react';
import { PsychographicDatum } from '../../types';

interface Props {
  data: PsychographicDatum[];
}

class PsychographicsSegment extends Component<Props> {
  render(): JSX.Element {
    const { data } = this.props;

    return (
      <Segment>
        <Header as="h3">Psychographics &amp; Advertising</Header>
        <p>
          This section contains information about psychological attributes, such as values or attitudes, of the
          population around your concept. It can be used to understand patron identity for concept design and for
          marketing.
        </p>
        <BarChart
          layout="vertical"
          width={700}
          height={500}
          data={data}
          margin={{
            top: 30,
            bottom: 15,
          }}
        >
          <Tooltip />
          <CartesianGrid strokeDasharray="4" horizontal={false} />
          <XAxis type="number">
            <Label value="Number of people" offset={-10} position="insideBottom" />
          </XAxis>
          <YAxis dataKey="description" type="category" width={300} />
          <Bar dataKey="value" fill="#708090" />
        </BarChart>
      </Segment>
    );
  }
}

export default PsychographicsSegment;
