import React, { Component } from 'react';
import { Button, Grid, Header, Segment } from 'semantic-ui-react';
import { Bar, BarChart, XAxis, YAxis } from 'recharts';
import { PopularHours } from '../../types';

interface Props {
  popularTimes: PopularHours;
}

interface State {
  day: string;
  data: any;
}

function propsToData(day: string, { avgPopularity }: PopularHours): any {
  return avgPopularity
    .find((el: any) => el.day === day)
    .popularTimes.map((popularity: number, time: number) => {
      const standardTime = time % 12;
      const name = standardTime === 0 ? 12 : standardTime;
      return {
        name,
        popularity,
      };
    });
}

class PopularHoursSegment extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      day: 'Monday',
      data: propsToData('Monday', props.popularTimes),
    };
  }

  changeDay = (day: string): void => {
    this.setState({
      day,
      data: propsToData(day, this.props.popularTimes),
    });
  };

  render(): JSX.Element {
    return (
      <Segment>
        <Header as="h3">Popular Hours</Header>
        <p>This shows when people on average visit restaurants near this location.</p>
        <Grid centered padded="vertically">
          <BarChart width={500} height={200} data={this.state.data}>
            <XAxis dataKey="name" interval={2} tickLine={false} />
            <YAxis type="number" domain={[0, 100]} hide={true} />
            <Bar dataKey="popularity" fill="#8884d8" />
          </BarChart>
          <Button.Group>
            <Button active={this.state.day === 'Sunday'} onClick={(_) => this.changeDay('Sunday')}>
              Sun
            </Button>
            <Button active={this.state.day === 'Monday'} onClick={(_) => this.changeDay('Monday')}>
              Mon
            </Button>
            <Button active={this.state.day === 'Tuesday'} onClick={(_) => this.changeDay('Tuesday')}>
              Tues
            </Button>
            <Button active={this.state.day === 'Wednesday'} onClick={(_) => this.changeDay('Wednesday')}>
              Wed
            </Button>
            <Button active={this.state.day === 'Thursday'} onClick={(_) => this.changeDay('Thursday')}>
              Thurs
            </Button>
            <Button active={this.state.day === 'Friday'} onClick={(_) => this.changeDay('Friday')}>
              Fri
            </Button>
            <Button active={this.state.day === 'Saturday'} onClick={(_) => this.changeDay('Saturday')}>
              Sat
            </Button>
          </Button.Group>
        </Grid>
      </Segment>
    );
  }
}

export default PopularHoursSegment;
