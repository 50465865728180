import React, { Component } from 'react';
import { Header as BaseHeader, Segment, Table } from 'semantic-ui-react';

function chunk<T>(arr: T[], size: number): T[][] {
  const chunked: T[][] = [];
  for (let i = 0; i < arr.length; i += size) {
    chunked.push(arr.slice(i, i + size));
  }
  return chunked;
}

interface Props {
  missingCuisines: string[];
}

class GapAnalysisSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment>
        <BaseHeader as="h3">Gap Analysis</BaseHeader>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={2}>Cuisine</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {chunk(this.props.missingCuisines, 2).map((cuisinePairs, id) => (
              <Table.Row key={id}>
                <Table.Cell key={`${id}-1`} width={6}>
                  {cuisinePairs[0]}
                </Table.Cell>
                <Table.Cell key={`${id}-2`} width={6}>
                  {cuisinePairs[1] || ''}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default GapAnalysisSegment;
