import React, { ReactNode } from 'react';

type State = 'incomplete' | 'current' | 'completed';

interface StepProps {
  index: number;
  state: State;
  children: ReactNode;
  arrow: boolean;
}

const Step = ({ index, state, children, arrow }: StepProps) => {
  function getArrowOuterBorderColor(state: State): string {
    switch (state) {
      case 'incomplete':
        return 'before:tw-border-l-bornegray-dark';
      case 'current':
        return 'before:tw-border-l-bornegreen-dark';
      case 'completed':
        return 'before:tw-border-l-white';
    }
  }

  const arrowOuterClassNames = arrow
    ? [
        'before:tw-border-y-[23px]',
        'before:tw-border-y-transparent',
        'before:tw-border-l-[15px]',
        getArrowOuterBorderColor(state),
        'before:tw-absolute',
        'before:tw-right-[-15px]',
        'before:tw-z-1',
      ]
    : [];

  function getArrowInnerBorderColor(state: State): string {
    switch (state) {
      case 'incomplete':
        return 'after:tw-border-l-white';
      case 'current':
        return 'after:tw-border-l-bornegreen-dark';
      case 'completed':
        return 'after:tw-border-l-borneblue-darkest';
    }
  }

  const arrowInnerClassNames = arrow
    ? [
        'after:tw-border-y-[22px]',
        'after:tw-border-y-transparent',
        'after:tw-border-l-[14px]',
        getArrowInnerBorderColor(state),
        'after:tw-absolute',
        'after:tw-top-[1px]',
        'after:tw-right-[-14px]',
        'after:tw-z-1',
      ]
    : [];

  function getBorderColor(state: State): string {
    switch (state) {
      case 'incomplete':
        return 'tw-border-bornegray-dark';
      case 'current':
        return 'tw-border-bornegreen-dark';
      case 'completed':
        return 'tw-border-borneblue-darkest';
    }
  }

  const borderClassNames = [getBorderColor(state), 'tw-border-y', 'first:tw-border-l', 'last:tw-border-r'];

  function getBackgroundColor(state: State): string {
    switch (state) {
      case 'incomplete':
        return 'tw-bg-white';
      case 'current':
        return 'tw-bg-bornegreen-dark';
      case 'completed':
        return 'tw-bg-borneblue-darkest';
    }
  }

  function getTextColor(state: State): string {
    switch (state) {
      case 'incomplete':
        return 'tw-text-bornegray-dark';
      case 'current':
        return 'tw-text-borneblue-darkest';
      case 'completed':
        return 'tw-text-borneblue-medium';
    }
  }

  const classNames = [
    'tw-grow',
    'tw-relative',
    getBackgroundColor(state),
    getTextColor(state),
    'tw-text-center',
    'tw-leading-[46px]',
    ...borderClassNames,
    ...arrowOuterClassNames,
    ...arrowInnerClassNames,
  ];

  return (
    <div className={classNames.join(' ')} style={{ zIndex: -index }}>
      {children}
    </div>
  );
};

interface StepConfiguration {
  name: string;
  text: string;
}

interface Props {
  children: StepConfiguration[];
  className?: string;
  current: string;
}

const Steps = ({ children, className, current }: Props) => {
  const currentIndex = children.findIndex((stepConfiguration) => stepConfiguration.name === current);

  const getState = (index: number): State => {
    if (index < currentIndex) {
      return 'completed';
    }

    if (index > currentIndex) {
      return 'incomplete';
    }

    return 'current';
  };

  return (
    <div className={`tw-flex tw-h-[46px]${className ? ' ' + className : ''}`}>
      {children.map((stepConfiguration, index, children) => {
        return (
          <Step index={index} state={getState(index)} arrow={index !== children.length - 1}>
            {stepConfiguration.text}
          </Step>
        );
      })}
    </div>
  );
};

export default Steps;
