import React from 'react';
import { useParams } from 'react-router-dom';
import FormPage from './FormPage';

const PurchaseComplete = () => {
  const { orderNumber } = useParams<any>();

  return (
    <FormPage currentStep="complete">
      <br />
      <div className="tw-mx-auto tw-mt-8 tw-w-1/2 tw-text-center">
        <header className="tw-text-3xl tw-text-borneblue-darkest tw-mb-4">Success!</header>
        <p className="tw-text-bornegray-darkest">
          We received your Borne Report<sup>&#174;</sup> order. We'll begin running the report and pulling the data into
          your customized report document as soon as possible.
        </p>
        <p className="tw-text-bornegray-darkest">
          Please allow for 5 business days before receiving your report. In the meantime, you can reach out to{' '}
          <a href="mailto:support@bornellc.com">support@bornellc.com</a> for any questions regarding your report. Your
          order number is <b>{orderNumber}</b>.
        </p>
      </div>
    </FormPage>
  );
};

export default PurchaseComplete;
