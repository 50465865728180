import { Header, Description } from '../../styled/Typography';
import React from 'react';
import { Dropdown, Grid } from 'semantic-ui-react';
import Auth from '../../../auth/Auth';
import { StudyAreaODMap } from '../../maps/StudyAreaODMap';
import { DayOfWeek } from 'borne_ui/src/types';


interface DescriptionProps{
  setDayOfWeek: (dow: DayOfWeek) => void;
  dayOfWeek: DayOfWeek;
}

export const StudyAreaDetailODDescription: React.FC<DescriptionProps> = ({setDayOfWeek,dayOfWeek}) => {
  return (
    <>
      <Header as="h2">Trip Sources</Header>
      <Description as="p">
        Understanding where your patrons live, work, go to school, and come from helps to better understand how
        positioning your location fits into their daily trips. View trip sources for an average 
        <Dropdown inline value={dayOfWeek} options={[
          { key: 1, text: 'weekday', value: 'thu' },
          { key: 2, text: 'weekend', value: 'sat' }
        ]} onChange={(e, data) => setDayOfWeek(data.value as DayOfWeek ?? 'thu')} />
      </Description>
      
    </>
  );
};

interface DataProps {
  studyAreaId: string;
  auth: Auth;
  dayOfWeek: DayOfWeek;
}

export const StudyAreaDetailODData: React.FC<DataProps> = ({ studyAreaId, auth, dayOfWeek }) => {
  return (
    <Grid attached="top" textAlign="center">
      <Grid.Row>
        <Grid.Column style={{height: '60vh'}}>
          <StudyAreaODMap studyAreaId={studyAreaId} auth={auth} dayOfWeek={dayOfWeek}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
