import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import {
  //  PieLabelRenderProps,
  PieChart,
  Pie,
  Legend as BaseLegend,
  Cell,
} from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeDark2 } from 'd3-scale-chromatic';
import { Gender } from '../../types';

// TODO: @types/recharts needs to be updated to include the new
// paylodUniq property that we are using.
const Legend = BaseLegend as any;

const colors = scaleOrdinal(schemeDark2).range();

// TODO: The types of many properties in PieLabelRenderProps are
// "string | number | undefined". We need to investigate when it
// can be a string and when it can be undefined, since we have not
// seen this in practice. In the meantime, turn undefined into an
// arbitrary default value and convert string to a number.
function toNumericValue(value: string | number | undefined): number {
  if (value === undefined) {
    return 0;
  }
  if (typeof value === 'string') {
    return parseFloat(value);
  }
  return value;
}

const RADIAN = Math.PI / 180;
const renderLabel: any = (props: any /*PieLabelRenderProps*/): JSX.Element => {
  const { fill } = props;
  const cx = toNumericValue(props.cx);
  const cy = toNumericValue(props.cy);
  const midAngle = toNumericValue(props.midAngle);
  const outerRadius = toNumericValue(props.outerRadius);
  const percent = toNumericValue(props.percent);
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={5} textAnchor={textAnchor} fill={fill}>{`${(
        percent * 100
      ).toFixed(2)}%`}</text>
    </g>
  );
};

interface Props {
  gender: Gender;
}

class GenderSegment extends Component<Props> {
  render(): JSX.Element {
    const gender = this.props.gender;

    const data = [
      {
        name: 'Male',
        percentage: gender.malesPercentage || 0,
      },
      {
        name: 'Female',
        percentage: 100 - (gender.malesPercentage || 0),
      },
    ];

    return (
      <Segment>
        <Header as="h3">Gender</Header>
        <PieChart width={670} height={350}>
          <Legend paylodUniqBy layout="vertical" align="right" verticalAlign="middle" />
          <Pie data={data} dataKey="percentage" label={renderLabel} outerRadius="70%" isAnimationActive={false}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
        </PieChart>
      </Segment>
    );
  }
}

export default GenderSegment;
