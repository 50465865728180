import React, { SyntheticEvent } from 'react';
import { DayOfWeek, TwentyFourHourTimeString } from '.';
import { ScheduleReducerAction, ScheduleReducerState } from './reducers';
import { BorneCloseIcon, BorneSmallPlus } from '../../generic/BorneStyledcons';
import { endShiftValues, formattedTime } from './formatedTimeService';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { BorneToggle } from '../../generic/BorneComponents';
import { Group, Switch, Text } from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';

interface Props {
  hoursOfOps: ScheduleReducerState;
  dispatchHoursOfOps: React.Dispatch<ScheduleReducerAction>;
  time?: ScheduleReducerState;
  setTime?: () => void;
}

export const checkTimeValidity = (shiftStart: string | undefined, shiftEnd: string | undefined) => {
  if (shiftEnd && shiftStart) {
    let tempStart = Array.from(shiftStart);
    tempStart.splice(2, 1);
    let tempEnd = Array.from(shiftEnd);
    tempEnd.splice(2, 1);
    return Number(tempStart.join('')) < Number(tempEnd.join(''));
  }
  return true;
};

const useWindowSize = () => {
  const [size, setSize] = React.useState([0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const days: Array<DayOfWeek> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const HoursOfOperations: React.FunctionComponent<Props> = ({
  hoursOfOps: schedule,
  dispatchHoursOfOps: dispatchScheduleAction,
  time,
  setTime,
}: Props) => {
  React.useEffect(() => {
    days
      .filter((as) => as != 'Sunday')
      .map((z) => {
        dispatchScheduleAction({ actionType: 'addDay', day: z });
      });
  }, []);

  const [duplicate, setDuplicate] = React.useState<boolean>(true);
  const [selectedDay, setSelectedDay] = React.useState<DayOfWeek>('Monday');
  const [width, height] = useWindowSize();

  if (width < 500 || height < 500) {
    return (
      <div className="tw-flex tw-flex-col">
        <div className=" tw-flex-col">
          <span onClick={() => setTime} className="tw-mr-2">
            Hours of Operation
          </span>
        </div>
        <Text my={6} size="sm">
          Click a day to change its schedule
        </Text>
        {/* <Select
          defaultValue={'24/7'}
          label="Templates"
          data={[
            { value: '24/7', label: 'whee' },
            { value: '9 to 9', label: '9 to 9' },
            { value: '7am to  2pm', label: '7am to 2pm' },
          ]}
        /> */}
        <Switch
          mt={12}
          mb={6}
          radius="xl"
          size="sm"
          label="All open days have the same schedule"
          checked={duplicate}
          onChange={() => setDuplicate(!duplicate)}
          color="lime"
        />
        <Group spacing="xs" mt={6}>
          {days.map((day) => (
            <div
              className="tw-flex tw-justify-center tw-items-center tw-cursor-pointer"
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '20%',
                border: schedule.scheduleState.has(day) ? '1px solid rgb(184 217 71)' : 'gray',
                backgroundColor: day === selectedDay ? 'rgb(184 217 71)' : '',
              }}
              onClick={() => {
                setSelectedDay(day);
              }}
            >
              {day[0]}
            </div>
          ))}
        </Group>
        <Text className={schedule.scheduleState.has(selectedDay) ? `tw-my-1` : 'tw-my-4'}>
          {schedule.scheduleState.has(selectedDay) ? `Edit ${selectedDay} hours` : `${selectedDay} is closed`}
        </Text>
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-align-start">
            <div className="tw-w-full">
              {schedule.scheduleState.has(selectedDay) ? (
                schedule.scheduleState
                  .get(selectedDay)!
                  .keySeq()
                  .toArray()
                  .map((shift, i) => {
                    return (
                      <>
                        <div className="tw-flex tw-mb-2 tw-w-full tw-items-center">
                          <Dropdown
                            placeholder="start"
                            className="tw-text-[12px] tw-ml-1"
                            selectOnBlur={false}
                            selection
                            fluid
                            value={schedule.scheduleState.get(selectedDay)?.get(shift)![0]}
                            onChange={(e: SyntheticEvent, data: DropdownProps) => {
                              if (duplicate) {
                                dispatchScheduleAction({
                                  actionType: 'duplicateShiftStart',
                                  day: selectedDay,
                                  shiftId: shift,
                                  shift: data.value as TwentyFourHourTimeString,
                                });
                              } else {
                                dispatchScheduleAction({
                                  actionType: 'changeShiftStart',
                                  day: selectedDay,
                                  shiftId: shift,
                                  shift: data.value as TwentyFourHourTimeString,
                                });
                              }
                            }}
                            options={formattedTime()}
                            selectOnNavigation={false}
                          />
                          <div className="tw-p-1 tw-pb-0">to</div>
                          <Dropdown
                            placeholder="end"
                            selectOnBlur={false}
                            className="tw-text-[12px] tw-mr-1"
                            style={{ marginLeft: 0 }}
                            selection
                            fluid
                            value={schedule.scheduleState.get(selectedDay)?.get(shift)![1]}
                            onChange={(e: SyntheticEvent, data: DropdownProps) => {
                              if (duplicate) {
                                dispatchScheduleAction({
                                  actionType: 'duplicateShiftEnd',
                                  day: selectedDay,
                                  shiftId: shift,
                                  shift: data.value as TwentyFourHourTimeString,
                                });
                              } else {
                                dispatchScheduleAction({
                                  actionType: 'changeShiftEnd',
                                  day: selectedDay,
                                  shiftId: shift,
                                  shift: data.value as TwentyFourHourTimeString,
                                });
                              }
                            }}
                            options={endShiftValues()}
                            selectOnNavigation={false}
                          />
                          {shift > 1 ? (
                            <div
                              className="tw-flex tw-justify-center tw-items-center tw-mt-0 tw-cursor-pointer"
                              style={{
                                fontWeight: 'bold',
                                color: 'white',
                                width: '20px',
                                height: '20px',
                                borderRadius: '25%',
                                backgroundColor: 'red',
                              }}
                              onClick={() => {
                                if (duplicate && shift != 1) {
                                  dispatchScheduleAction({
                                    actionType: 'duplicateRemoveShift',
                                    day: selectedDay,
                                    shiftId: shift,
                                  });
                                  return;
                                }
                                if (!duplicate) {
                                  dispatchScheduleAction({
                                    actionType: 'removeShift',
                                    day: selectedDay,
                                    shiftId: shift,
                                  });
                                }
                              }}
                            >
                              <IconTrash />
                            </div>
                          ) : (
                            <div
                              className="tw-flex tw-justify-center tw-items-center tw-mt-0 tw-cursor-pointer"
                              style={{
                                display: 'hidden',
                                color: 'white',
                                width: '20px',
                                height: '20px',
                                borderRadius: '25%',
                              }}
                            >
                              <IconTrash />
                            </div>
                          )}
                        </div>
                        {!checkTimeValidity(
                          schedule.scheduleState.get(selectedDay)?.get(shift)![0],
                          schedule.scheduleState.get(selectedDay)?.get(shift)![1]
                        ) && <span className="tw-text-red-600">Make sure that shift start is before shift end</span>}
                      </>
                    );
                  })
              ) : (
                <div
                  className="tw-flex tw-w-full tw-rounded-lg tw-justify-center tw-items-center tw-mt-0 tw-cursor-pointer"
                  style={{
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: 'rgb(139 189 65)',
                  }}
                  onClick={() => dispatchScheduleAction({ actionType: 'addDay', day: selectedDay })}
                >
                  <IconPlus />
                  <span>Add Day</span>
                </div>
              )}
              {schedule.scheduleState.has(selectedDay) && (
                <>
                  <div
                    className="tw-flex tw-w-full tw-rounded-lg tw-justify-center tw-items-center tw-mt-0 tw-cursor-pointer"
                    style={{
                      fontWeight: 'bold',
                      height: '2.3rem',
                      color: 'white',
                      backgroundColor: 'rgb(139 189 65)',
                    }}
                    onClick={() => {
                      if (duplicate) {
                        dispatchScheduleAction({ actionType: 'duplicateAddShift', day: selectedDay });
                        return;
                      }
                      dispatchScheduleAction({ actionType: 'addShift', day: selectedDay });
                    }}
                  >
                    <IconPlus />
                    <span className="tw-ml-2">Add Shift</span>
                  </div>
                  <div
                    className="tw-flex tw-w-full tw-rounded-lg tw-justify-center tw-items-center tw-mt-0 tw-cursor-pointer tw-mt-4"
                    style={{
                      fontWeight: 'bold',
                      height: '2.3rem',
                      color: 'white',
                      backgroundColor: 'rgb(216 89 83)',
                    }}
                    onClick={() => {
                      dispatchScheduleAction({ actionType: 'removeDay', day: selectedDay });
                      return;
                    }}
                  >
                    <IconTrash />
                    <span className="tw-ml-2">Remove Day</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="tw-flex tw-flex-col">
          <div className="tw-mb-1 tw-flex-col">
            <span onClick={() => setTime} className="tw-mr-2">
              Hours of Operation
            </span>
          </div>
          <div className="tw-flex tw-mb-4 tw-items-end">
            {days.map((day) => (
              <div
                className="tw-flex tw-w-full tw-rounded-lg tw-justify-center tw-items-center tw-mt-0 tw-cursor-pointer"
                style={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  marginRight: '.25rem',
                  backgroundColor: schedule.scheduleState.has(day) ? 'rgb(184 217 71)' : 'gray',
                }}
                onClick={() => {
                  schedule.scheduleState.has(day)
                    ? dispatchScheduleAction({ actionType: 'removeDay', day: day })
                    : dispatchScheduleAction({ actionType: 'addDay', day: day });
                }}
              >
                {day[0]}
              </div>
            ))}
            <span className="tw-text-xs">Click to remove/add day</span>
          </div>
          <div>
            <BorneToggle
              shape="slim"
              color="success"
              checked={duplicate}
              onChange={() => setDuplicate(!duplicate)}
              className="tw-mr-5"
            />
            Duplicate
          </div>
          {days.map((day, dayIndex) => {
            if (schedule.scheduleState.has(day)) {
              return (
                <div className="tw-flex tw-align-start">
                  <div className="tw-mr-2 tw-pt-1 tw-w-24">{day}</div>
                  <div className="lg:tw-w-[50%]">
                    {schedule.scheduleState
                      .get(day)!
                      .keySeq()
                      .toArray()
                      .map((shift, i) => {
                        return (
                          <>
                            <div className="tw-flex tw-mb-2 tw-w-full tw-items-center">
                              <Dropdown
                                placeholder="start"
                                className="tw-text-[12px]"
                                selectOnBlur={false}
                                disabled={duplicate && dayIndex !== 0}
                                style={{ marginLeft: 0 }}
                                search
                                selection
                                fluid
                                value={schedule.scheduleState.get(day)?.get(shift)![0]}
                                onChange={(e: SyntheticEvent, data: DropdownProps) => {
                                  if (duplicate && dayIndex == 0) {
                                    dispatchScheduleAction({
                                      actionType: 'duplicateShiftStart',
                                      day: day,
                                      shiftId: shift,
                                      shift: data.value as TwentyFourHourTimeString,
                                    });
                                  } else {
                                    dispatchScheduleAction({
                                      actionType: 'changeShiftStart',
                                      day: day,
                                      shiftId: shift,
                                      shift: data.value as TwentyFourHourTimeString,
                                    });
                                  }
                                }}
                                options={formattedTime()}
                                selectOnNavigation={false}
                              />
                              <div className="tw-p-1 tw-pb-0">to</div>
                              <Dropdown
                                placeholder="end"
                                selectOnBlur={false}
                                className="tw-text-[12px] tw-mr-2"
                                style={{ marginLeft: 0 }}
                                search
                                disabled={duplicate && dayIndex !== 0}
                                selection
                                fluid
                                value={schedule.scheduleState.get(day)?.get(shift)![1]}
                                onChange={(e: SyntheticEvent, data: DropdownProps) => {
                                  if (duplicate && dayIndex == 0) {
                                    dispatchScheduleAction({
                                      actionType: 'duplicateShiftEnd',
                                      day: day,
                                      shiftId: shift,
                                      shift: data.value as TwentyFourHourTimeString,
                                    });
                                  } else {
                                    dispatchScheduleAction({
                                      actionType: 'changeShiftEnd',
                                      day: day,
                                      shiftId: shift,
                                      shift: data.value as TwentyFourHourTimeString,
                                    });
                                  }
                                }}
                                options={endShiftValues()}
                                selectOnNavigation={false}
                              />
                              {shift > 1 && (
                                <div
                                  className="tw-flex tw-justify-center tw-items-center tw-mt-1 tw-ml-[2px] tw-cursor-pointer"
                                  style={{ fontSize: '20px', fontWeight: 'bold' }}
                                  onClick={() =>
                                    dispatchScheduleAction({ actionType: 'removeShift', day: day, shiftId: shift })
                                  }
                                >
                                  <BorneCloseIcon />
                                </div>
                              )}
                              {shift == 1 && (
                                <div
                                  className="tw-flex tw-justify-center tw-items-center tw-mt-0 tw-cursor-pointer"
                                  style={{
                                    fontWeight: 'bold',
                                    color: 'white',
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%',
                                    backgroundColor: 'rgb(139 189 65)',
                                  }}
                                  onClick={() => dispatchScheduleAction({ actionType: 'addShift', day: day })}
                                >
                                  <BorneSmallPlus />
                                </div>
                              )}
                            </div>
                            {!checkTimeValidity(
                              schedule.scheduleState.get(day)?.get(shift)![0],
                              schedule.scheduleState.get(day)?.get(shift)![1]
                            ) && (
                              <span className="tw-text-red-600">Make sure that shift start is before shift end</span>
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </>
    );
  }
};

export default HoursOfOperations;
