import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form as SemanticForm } from 'semantic-ui-react';
import { Field, Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { ReportConceptContact } from '../concept/concept';
import CorporateStructuredAddressQuestion from '../questions/CorporateStructuredAddressQuestion';
import { useUpdateReportRequest } from '../../api/requests';
import FormPage from './FormPage';
import PriceDisclaimer from './PriceDisclaimer';

const ContactForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const initialContactValues: ReportConceptContact = {
    id: state.reportRequest.id,
    first_name: state.reportRequest.first_name ?? '',
    last_name: state.reportRequest.last_name ?? '',
    organization: state.reportRequest.organization ?? '',
    organization_street_address: state.reportRequest.organization_street_address ?? '',
    organization_address_line2: state.reportRequest.organization_address_line2 ?? '',
    organization_city: state.reportRequest.organization_city ?? '',
    organization_state: state.reportRequest.organization_state ?? '',
    organization_postal_code: state.reportRequest.organization_postal_code ?? '',
    email: state.reportRequest.email ?? '',
    phone_number: state.reportRequest.phone_number ?? '',
  };

  const updateReportRequest = useUpdateReportRequest();

  return (
    <FormPage currentStep="contact">
      <div className="tw-flex">
        <div className="tw-w-[40%]">
          <header className="tw-text-3xl tw-text-borneblue-darkest tw-mb-4">Contact Information</header>
          <PriceDisclaimer />
          <div className="tw-text-bornegray-darkest tw-mb-12">Tell us who you are and how to reach you!</div>
          <a
            href="#"
            onClick={(e) => {
              navigate(-1);
              e.preventDefault();
            }}
            className="tw-mt-12 tw-pt-[12px] tw-pb-[10px] tw-px-[15px] tw-border tw-border-gray-300 tw-uppercase tw-text-[0.8em] tw-text-[#707070] hover:tw-bg-[#bccd19] hover:tw-text-[#2f2e2b] tw-transition-colors tw-duration-300"
          >
            ← Previous Step
          </a>
        </div>
        <div className="tw-w-[60%] tw-pl-[3em] tw-pr-[6em]">
          <Formik
            initialValues={initialContactValues}
            validationSchema={Yup.object({
              first_name: Yup.string().required('Please enter your first name'),
              last_name: Yup.string().required('Please enter your last name'),
              email: Yup.string().required('Please enter your email'),
            })}
            onSubmit={async (values) => {
              /*
              values = {
                name: 'Teddy',
                organization: 'Borne',
                email: 't.ni@bornellc.com'
              };
              */
              await updateReportRequest.mutateAsync({ reportRequestId: state.reportRequest.id, reportRequest: values });

              const reportRequest = {
                ...state.reportRequest,
                ...values,
              };

              // This supports keeping the form filled in when pressing back from next page.
              navigate(location.pathname, { replace: true, state: { reportRequest } });

              navigate('/report/purchase', { state: { reportRequest } });
            }}
          >
            {(formikBag) => (
              <SemanticForm as={FormikForm} onSubmit={formikBag.handleSubmit}>
                <br />
                <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                  Company name
                </label>
                <Field name="organization">
                  {({ field, meta }: { field: any; meta: any }) => (
                    <SemanticForm.Input
                      {...field}
                      error={meta.touched && !!meta.error}
                      placeholder={meta.touched && meta.error}
                    />
                  )}
                </Field>
                <div className="tw-flex tw-gap-2 tw-mt-8">
                  <div className="tw-flex-1">
                    <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                      Contact first name
                    </label>
                    <Field name="first_name">
                      {({ field, meta }: { field: any; meta: any }) => (
                        <SemanticForm.Input
                          {...field}
                          error={meta.touched && !!meta.error}
                          placeholder={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="tw-flex-1">
                    <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                      Contact last name
                    </label>
                    <Field name="last_name">
                      {({ field, meta }: { field: any; meta: any }) => (
                        <SemanticForm.Input
                          {...field}
                          error={meta.touched && !!meta.error}
                          placeholder={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="tw-flex tw-gap-2 tw-mt-8 tw-mb-8">
                  <div className="tw-flex-1">
                    <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                      Phone number
                    </label>
                    <Field name="phone_number">
                      {({ field, meta }: { field: any; meta: any }) => (
                        <SemanticForm.Input
                          {...field}
                          error={meta.touched && !!meta.error}
                          placeholder={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="tw-flex-1">
                    <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">Email</label>
                    <Field name="email">
                      {({ field, meta }: { field: any; meta: any }) => (
                        <SemanticForm.Input
                          {...field}
                          error={meta.touched && !!meta.error}
                          placeholder={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <CorporateStructuredAddressQuestion />
                <Button
                  className="tw-bg-borneblue-darkest tw-uppercase tw-text-bornegreen-dark tw-font-normal tw-mt-16"
                  type="submit"
                  primary
                >
                  Enter Payment Info →
                </Button>
                <br />
                <br />
                <br />
              </SemanticForm>
            )}
          </Formik>
        </div>
      </div>
    </FormPage>
  );
};

export default ContactForm;
