import { DashboardSvg } from '../../../static/icons/icons/dashboard';
import { MarketSvg } from '../../../static/icons/icons/markets';
import { SiteReportSvg } from '../../../static/icons/icons/sitereport';
import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import styled from '@emotion/styled';
import { StudyAreaSvg } from '../../../static/icons/icons/studyarea';
import { Link } from 'react-router-dom';
import { ProfileSvg } from '../../../static/icons/icons/profile';
import { Icon } from 'semantic-ui-react';
import SentryFeedbackModal from '../modals/SentryFeedbackModal';
import Auth from '../../auth/Auth';
import { User } from '../../api/models';
import Users from '../../api/Users';

const BorneNav = styled.div`
  margin-right: 0.5rem;
  display: flex;
  top: 0;
  position: sticky;
  z-index: 1005;
  background-color: white;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  width: 7.5rem;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
`;

const BorneNavSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BorneNavItem = styled.div`
  width: 100%;
  height: fit-content;
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.25);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
  h1 {
    text-transform: uppercase;
    margin-top: 0.5rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px !important;
    letter-spacing: 1px;
  }
  img {
    width: 60%;
    height: 60%;
  }
`;

const SideMenu2: React.FC = () => {
  const auth = new Auth();
  const [user, setUser] = React.useState<User>();

  React.useEffect(() => {
    if (auth.hasCredentials()) {
      const userAPI = new Users(auth);
      userAPI.getCurrentUser().then(setUser);
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation().pathname;
  const name = location.slice(1);
  const [sentryFeedbackModalOpen, setSentryFeedbackModalOpen] = React.useState<boolean>(false);

  const menuItems = [
    {
      name: 'Dashboard',
      icon: () => <DashboardSvg size={36} active={/(dashboard)/.test(location.slice(1).toLowerCase()) || name == ''} />,
      id: 'dashboard',
    },
    {
      name: 'Markets',
      icon: () => <MarketSvg size={36} active={/(markets)/.test(location.slice(1).toLowerCase())} />,
      id: 'markets',
    },
    {
      name: 'Study Areas',
      icon: () => <StudyAreaSvg size={36} active={/(studyareas)/.test(location.slice(1).toLowerCase())} />,
      id: 'studyareas',
    },
    {
      name: 'Site Reports',
      icon: () => <SiteReportSvg size={36} active={/(sitereports)/.test(location.slice(1).toLowerCase())} />,
      id: 'sitereports',
    },
  ];

  const lowerMenuItems1 = [
    { name: 'Profile', icon: (active: boolean) => <ProfileSvg size={30} active={active} /> },
    {
      name: 'Log Out',
      icon: () => <Icon size="big" name="log out" style={{ color: '#2a4e55' }} />,
    },
  ];

  const lowerMenuItems2 = [
    {
      name: 'Log Out',
      icon: () => <Icon size="big" name="log out" style={{ color: '#2a4e55' }} />,
    },
  ];

  const menuElements = menuItems.map((item) => {
    if (item.id == name.toLowerCase() || (item.id == 'dashboard' && name == '')) {
      return (
        <BorneNavItem key={item.name} onClick={() => navigate(`/${item.id}`)}>
          {item.icon()}
          <h1 style={{ color: '#bccd19' }}>{item.name}</h1>
        </BorneNavItem>
      );
    } else {
      return (
        <BorneNavItem key={item.name} onClick={() => navigate(`/${item.id}`)}>
          {item.icon()}
          <h1>{item.name}</h1>
        </BorneNavItem>
      );
    }
  });
  const lowerMenuItems = user?.enterprise_id === 1 ? lowerMenuItems1 : lowerMenuItems2;
  const lowMenuElements = lowerMenuItems.map((item) => {
    let name = location.slice(1);

    if (item.name.toLowerCase() == name) {
      return (
        <BorneNavItem key={item.name} onClick={() => navigate(`/${item.name.replace(' ', '')}`)}>
          {item.icon ? item.icon(true) : ''}
          <h1 style={{ color: '#bccd19' }}>{item.name}</h1>
        </BorneNavItem>
      );
    } else {
      return (
        <BorneNavItem key={item.name} onClick={() => navigate(`/${item.name.replace(' ', '')}`)}>
          {item.icon ? item.icon(false) : ''}
          <h1>{item.name}</h1>
        </BorneNavItem>
      );
    }
  });

  return (
    <BorneNav>
      <BorneNavSection>
        <>{menuElements}</>
      </BorneNavSection>
      <BorneNavSection>
        <BorneNavItem>
          <Icon
            onClick={() => {
              setSentryFeedbackModalOpen(true);
            }}
            name="question circle outline"
            className="helpIcon"
            link
            inverted
            size="big"
          />
          <span>Help</span>
        </BorneNavItem>
        {lowMenuElements}
        <Link to={'/'}>
          <BorneNavItem>
            <img src={'/static/B_Icon.png'} />
          </BorneNavItem>
        </Link>
      </BorneNavSection>
      <SentryFeedbackModal isOpen={sentryFeedbackModalOpen} setOpen={setSentryFeedbackModalOpen} />
    </BorneNav>
  );
};

export default SideMenu2;
