import React from 'react';
import { Header, Grid, Placeholder } from 'semantic-ui-react';

export const VisitorTrafficDescription: React.FC<{}> = () => {
  return (
    <>
      <Header> Visitor Traffic </Header>
      <p>
        Etiam congue, arcu et dictum bibendum, dolor ex varius metus, et ornare ipsum turpis sed nisl. Nulla vulputate
        dignissim elit, et volutpat purus molestie eget. Vestibulum at lacus vel orci imperdiet auctor. Nam fermentum
        fringilla odio sit amet laoreet.
      </p>
    </>
  );
};

export const VisitorTrafficData: React.FC<{}> = () => {
  return (
    <>
      <Grid columns="equal" relaxed padded>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header>Visitor Traffic</Header>
            <p>
              Its important to understand local foor traffic. We source Geolocation data collected in bulk from cell
              phone usage. This chart plots the numer of unique daily visitors in each of the selected, nearby, areas of
              interest {`(AOIs)`}
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header>Customer Loyalty</Header>
            <p>
              We also consider how loyal customers to dining and shopping in a given area are. This chart compares the
              number of customers have also been sean at other AOIs `(in teal)` to only observed in one `(in purple)`.
            </p>
            <p>Higer values of purple indicate that customers are loyal to patronizing that particular area.</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Placeholder>
              <Placeholder.Image />
            </Placeholder>
          </Grid.Column>
          <Grid.Column>
            <Placeholder>
              <Placeholder.Image />
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
