import { Header, Description, ChartHeader } from '../../styled/Typography';
import React, { useEffect, useState } from 'react';
import { Dropdown, Grid, Segment, Tab } from 'semantic-ui-react';
import { StudyAreaSingleLocationMap } from '../../maps/StudyAreaSingleLocationMap';
import Auth from '../../../auth/Auth';
import { useStudyAreaCuisines } from '../../../api/requests';
import DataTable from '../../generic/DataTable';
import ObjectPieChart from '../../generic/ObjectPieChart';

export const StudyAreaDetailRestaurantLandscapeDescription: React.FC = ({}) => {
  return (
    <>
      <Header as="h2">Restaurant Landscape</Header>
      <Description as="p">
        The defining feature of a study area is the restaurants within its bounds. Here, explore restaurants in the
        area, how their concepts vary, and how your concept(s) may fit into this landscape.
      </Description>
    </>
  );
};

interface DataProps {
  studyAreaRestaurantLandscapeData: any;
  studyAreaId: string;
  auth: Auth;
}

export const StudyAreaDetailRestaurantLandscapeData: React.FC<DataProps> = ({
  studyAreaRestaurantLandscapeData,
  studyAreaId,
  auth,
}) => {
  const studyAreaCuisinesRes = useStudyAreaCuisines(auth, studyAreaId, {
    refetchOnWindowFocus: false,
  });

  const [currentCuisine, setCurrentCuisine] = useState<string | undefined>();

  const [dayOfWeek, setDayOfWeek] = useState<string | undefined>();
  const [hour, setHour] = useState<number | undefined>();

  // This is needed in order to reset isFetching flag here for some reason
  // otherwise, the Data Table does not rerender
  useEffect(() => {
    studyAreaCuisinesRes.refetch();
  }, []);

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const daysOfWeekOptions = daysOfWeek.map((day: string) => ({
    key: day,
    text: day,
    value: day.toLowerCase(),
  }));

  const hoursOptions = [
    {
      key: 0,
      text: '12 AM',
      value: 0,
    },
    {
      key: 1,
      text: '1 AM',
      value: 1,
    },
    {
      key: 2,
      text: '2 AM',
      value: 2,
    },
    {
      key: 3,
      text: '3 AM',
      value: 3,
    },
    {
      key: 4,
      text: '4 AM',
      value: 4,
    },
    {
      key: 5,
      text: '5 AM',
      value: 5,
    },
    {
      key: 6,
      text: '6 AM',
      value: 6,
    },
    {
      key: 7,
      text: '7 AM',
      value: 7,
    },
    {
      key: 8,
      text: '8 AM',
      value: 8,
    },
    {
      key: 9,
      text: '9 AM',
      value: 9,
    },
    {
      key: 10,
      text: '10 AM',
      value: 10,
    },
    {
      key: 11,
      text: '11 AM',
      value: 11,
    },
    {
      key: 12,
      text: '12 PM',
      value: 12,
    },
    {
      key: 13,
      text: '1 PM',
      value: 13,
    },
    {
      key: 14,
      text: '2 PM',
      value: 14,
    },
    {
      key: 15,
      text: '3 PM',
      value: 15,
    },
    {
      key: 16,
      text: '4 PM',
      value: 16,
    },
    {
      key: 17,
      text: '5 PM',
      value: 17,
    },
    {
      key: 18,
      text: '6 PM',
      value: 18,
    },
    {
      key: 19,
      text: '7 PM',
      value: 19,
    },
    {
      key: 20,
      text: '8 PM',
      value: 20,
    },
    {
      key: 21,
      text: '9 PM',
      value: 21,
    },
    {
      key: 22,
      text: '10 PM',
      value: 22,
    },
    {
      key: 23,
      text: '11 PM',
      value: 23,
    },
  ];

  const panes = [
    {
      menuItem: 'Open',
      render: () => (
        <Tab.Pane style={{ borderStyle: 'hidden' }}>
          <Grid attached="top" textAlign="center" padded="horizontally" columns="equal">
            <Grid.Row>
              <Grid.Column style={{ height: '40vh' }}>
                <StudyAreaSingleLocationMap
                  dayOfWeek={dayOfWeek}
                  hour={hour}
                  studyAreaId={studyAreaId}
                  auth={auth}
                  status="open"
                  currentCuisine={currentCuisine}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: '20px' }}>
              <Dropdown
                clearable
                placeholder="Day of Week"
                selection
                options={daysOfWeekOptions}
                style={{ zIndex: 500 }}
                onChange={(e, { value }) => setDayOfWeek(value as string)}
              />
              &nbsp;&nbsp;
              <Dropdown
                clearable
                placeholder="Hour"
                selection
                options={hoursOptions}
                style={{ zIndex: 500 }}
                onChange={(e, { value }) => setHour(value as number)}
              />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ChartHeader as="h3">Price Level</ChartHeader>
                <ObjectPieChart
                  width="100%"
                  height={300}
                  data={studyAreaRestaurantLandscapeData}
                  normalize
                  otherThreshold={0}
                  keys={['price_cheap', 'price_moderate', 'price_expensive', 'price_very_expensive']}
                  labels={['$', '$$', '$$$', '$$$$']}
                  key="open_pie_chart"
                />
              </Grid.Column>
              <Grid.Column>
                {studyAreaCuisinesRes.isSuccess && !studyAreaCuisinesRes.isFetching && (
                  <DataTable
                    data={studyAreaCuisinesRes.data.open.map((row) => ({
                      id: row.cuisine,
                      cuisine: row.cuisine,
                      total: row.total,
                    }))}
                    tableProps={{ selectable: true }}
                    idColumn="id"
                    scrolling
                    onRowClick={({ cuisine }) => {
                      setCurrentCuisine(cuisine);
                    }}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      ),
    },
  ];

  if (studyAreaCuisinesRes.isSuccess && studyAreaCuisinesRes.data.closed.length) {
    panes.push({
      menuItem: 'Closed',
      render: () => (
        <Tab.Pane style={{ borderStyle: 'hidden' }}>
          <></>
          <Grid attached="top" textAlign="center" padded="horizontally" columns="equal">
            <Grid.Row>
              <Grid.Column style={{ height: '40vh' }}>
                <StudyAreaSingleLocationMap
                  studyAreaId={studyAreaId}
                  auth={auth}
                  status="closed"
                  color="#C3743A"
                  currentCuisine={currentCuisine}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ChartHeader as="h3">Price Level</ChartHeader>
                <ObjectPieChart
                  width="100%"
                  height={300}
                  data={studyAreaRestaurantLandscapeData}
                  normalize
                  otherThreshold={0}
                  keys={[
                    'price_closed_cheap',
                    'price_closed_moderate',
                    'price_closed_expensive',
                    'price_closed_very_expensive',
                  ]}
                  labels={['$', '$$', '$$$', '$$$$']}
                  key="closed_pie_chart"
                />
              </Grid.Column>
              <Grid.Column>
                {studyAreaCuisinesRes.isSuccess && (
                  <DataTable
                    data={studyAreaCuisinesRes.data.closed.map((row) => ({
                      id: row.cuisine,
                      cuisine: row.cuisine,
                      total: row.total,
                    }))}
                    tableProps={{ selectable: true }}
                    idColumn="id"
                    scrolling
                    onRowClick={({ cuisine }) => {
                      setCurrentCuisine(cuisine);
                    }}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      ),
    });
  }

  return (
    <>
      {studyAreaRestaurantLandscapeData ? (
        <Tab panes={panes} menu={{ secondary: true, pointing: true, widths: 'eight' }} renderActiveOnly={true} />
      ) : (
        <Segment placeholder />
      )}
    </>
  );
};
