import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import {
  //  PieLabelRenderProps,
  PieChart,
  Pie,
  Legend as BaseLegend,
  Cell,
} from 'recharts';
import { schemeBlues, schemeGreens, schemeGreys, schemeOranges, schemePurples, schemeReds } from 'd3-scale-chromatic';
import { Occupation } from '../../types';

// TODO: @types/recharts needs to be updated to include the new
// paylodUniq property that we are using.
const Legend = BaseLegend as any;

function transpose(matrix: any) {
  let newMatrix: any = [];

  let m = matrix.length;
  let n = matrix[0].length;

  for (let i = 0; i < m; i++) {
    newMatrix.push([]);
  }

  for (let i = 0; i < m; i++) {
    for (let j = 0; j < n; j++) {
      newMatrix[j].push(matrix[i][j]);
    }
  }

  return newMatrix;
}

const colors = transpose([
  schemeBlues[8].slice(4),
  schemeOranges[8].slice(4),
  schemePurples[8].slice(4),
  schemeGreens[8].slice(4),
  schemeReds[8].slice(4),
  schemeGreys[8].slice(4),
]).flat();

const hideOccupationPercentThreshold = 2;

// TODO: The types of many properties in PieLabelRenderProps are
// "string | number | undefined". We need to investigate when it
// can be a string and when it can be undefined, since we have not
// seen this in practice. In the meantime, turn undefined into an
// arbitrary default value and convert string to a number.
function toNumericValue(value: string | number | undefined): number {
  if (value === undefined) {
    return 0;
  }
  if (typeof value === 'string') {
    return parseFloat(value);
  }
  return value;
}

const RADIAN = Math.PI / 180;
const renderLabel: any = (props: any /*PieLabelRenderProps*/): JSX.Element => {
  const { fill } = props;
  const cx = toNumericValue(props.cx);
  const cy = toNumericValue(props.cy);
  const midAngle = toNumericValue(props.midAngle);
  const outerRadius = toNumericValue(props.outerRadius);
  const percent = toNumericValue(props.percent);
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={5} textAnchor={textAnchor} fill={fill}>{`${(
        percent * 100
      ).toFixed(2)}%`}</text>
    </g>
  );
};

interface Props {
  occupation: Occupation;
}

class OccupationSegment extends Component<Props> {
  render(): JSX.Element {
    const occupation = this.props.occupation;

    let data = [
      {
        name: 'Management',
        percentage: occupation['Management'] || 0,
      },
      {
        name: 'Business/Financial',
        percentage: occupation['Business/Financial'] || 0,
      },
      {
        name: 'Computer/Mathematical',
        percentage: occupation['Computer/Mathematical'] || 0,
      },
      {
        name: 'Architecture/Engineering',
        percentage: occupation['Architecture/Engineering'] || 0,
      },
      {
        name: 'Life/Physical/Social Science',
        percentage: occupation['Life/Physical/Social Science'] || 0,
      },
      {
        name: 'Community/Social Service',
        percentage: occupation['Community/Social Service'] || 0,
      },
      {
        name: 'Legal',
        percentage: occupation['Legal'] || 0,
      },
      {
        name: 'Education/Training/Library',
        percentage: occupation['Education/Training/Library'] || 0,
      },
      {
        name: 'Arts/Design/Entertainment/Sports/Media',
        percentage: occupation['Arts/Design/Entertainment/Sports/Media'] || 0,
      },
      {
        name: 'Healthcare Practitioner/Technician',
        percentage: occupation['Healthcare Practitioner/Technician'] || 0,
      },
      {
        name: 'Healthcare Support',
        percentage: occupation['Healthcare Support'] || 0,
      },
      {
        name: 'Protective Service',
        percentage: occupation['Protective Service'] || 0,
      },
      {
        name: 'Food Preparation/Serving Related',
        percentage: occupation['Food Preparation/Serving Related'] || 0,
      },
      {
        name: 'Building/Grounds Cleaning/Maintenance',
        percentage: occupation['Building/Grounds Cleaning/Maintenance'] || 0,
      },
      {
        name: 'Personal Care/Service',
        percentage: occupation['Personal Care/Service'] || 0,
      },
      {
        name: 'Sales and Sales Related',
        percentage: occupation['Sales and Sales Related'] || 0,
      },
      {
        name: 'Office/Administrative Support',
        percentage: occupation['Office/Administrative Support'] || 0,
      },
      {
        name: 'Farming/Fishing/Forestry',
        percentage: occupation['Farming/Fishing/Forestry'] || 0,
      },
      {
        name: 'Construction/Extraction',
        percentage: occupation['Construction/Extraction'] || 0,
      },
      {
        name: 'Installation/Maintenance/Repair',
        percentage: occupation['Installation/Maintenance/Repair'] || 0,
      },
      {
        name: 'Production',
        percentage: occupation['Production'] || 0,
      },
      {
        name: 'Transportation/Material Moving',
        percentage: occupation['Transportation/Material Moving'] || 0,
      },
    ];

    data = data.filter((occupation) => occupation.percentage > hideOccupationPercentThreshold);
    data.sort((el1, el2) => el2.percentage - el1.percentage);

    const otherPercentage = 100 - data.map((occupation) => occupation.percentage).reduce((a, b) => a + b, 0);

    data.push({
      name: 'Other',
      percentage: otherPercentage,
    });

    return (
      <Segment>
        <Header as="h3">Occupation</Header>
        <PieChart width={750} height={350}>
          <Legend paylodUniqBy layout="vertical" align="right" verticalAlign="middle" />
          <Pie data={data} dataKey="percentage" label={renderLabel} outerRadius="70%" isAnimationActive={false}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
        </PieChart>
      </Segment>
    );
  }
}

export default OccupationSegment;
