import React, { Component } from 'react';
import { Header as BaseHeader, Segment, SemanticCOLORS } from 'semantic-ui-react';

interface Props {
  color: SemanticCOLORS;
  modelEstimation: number;
}

class ModelResultSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">Estimated Yelp Star Count</BaseHeader>
        <p>
          Our proprietary model was developed to look at the concepts&apos; cuisine categories, nearby demographics, and
          more to estimate the number of stars this concept would receive on Yelp if it were planted in this location.
          This estimation is designed to give insight on how the demographic in this location will perceive your concept
          as a restaurant. Keep in mind that the cleanliness, food-quality, and all other factors about the way you run
          your restaurant in reality will have a profound impact on this predicted star count.
        </p>
        <p>
          {this.props.modelEstimation ? (
            <span>
              The predicted Yelp star count for this concept in this location is{' '}
              <b>{this.props.modelEstimation.toFixed(2)}</b> stars!
            </span>
          ) : (
            'Model Estimation is unavailable for this location'
          )}
        </p>
      </Segment>
    );
  }
}

export default ModelResultSegment;
