import { Flex, Paper, Stack, Transition, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

interface Props {
  opened: boolean;
  setOpened: (x: boolean) => void;
}

const BorneUiMobileMenu: React.FC<Props> = ({ opened, setOpened }: Props) => {
  const navigate = useNavigate();

  const scaleY = {
    in: { opacity: 1, transform: 'scaleY(1)' },
    out: { opacity: 0, transform: 'scaleY(0)' },
    common: { transformOrigin: 'top' },
    transitionProperty: 'transform, opacity',
  };

  const location = useLocation().pathname.slice(1);

  return (
    <div
      className="lg:tw-hidden md:tw-hidden"
      style={{
        maxWidth: '100vw',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        zIndex: 10000,
      }}
    >
      <Transition mounted={opened} transition={scaleY} duration={200} timingFunction="ease">
        {(styles) => (
          <Paper
            shadow="md"
            style={{
              ...styles,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '100vh',
              width: '100vw',
              padding: '4rem 7% 3rem 7%',
              overflow: 'hidden',
            }}
          >
            <IconX
              size={40}
              style={{ position: 'absolute', top: '4rem', left: '7%' }}
              onClick={() => setOpened(false)}
            />
            <Flex align="center" justify="center">
              <img width={56} height={40} src={'/static/B_Icon.png'} />
              <h1 className="tw-mt-0">Borne</h1>
            </Flex>
            <Stack style={{ position: 'absolute', top: '40%', left: '0', width: '100%' }}>
              <Flex
                onClick={() => navigate('/')}
                className={location == '' ? `tw-bg-gray-200 tw-py-4 tw-pl-[7%]` : 'tw-py-4 tw-pl-[7%]'}
              >
                <Text fz="xl">Run a Report</Text>
              </Flex>
              <Flex
                onClick={() => navigate('/reports')}
                className={location == 'reports' ? `tw-bg-gray-200 tw-py-4 tw-pl-[7%]` : 'tw-py-4 tw-pl-[7%]'}
              >
                <Text fz="xl">My Reports</Text>
              </Flex>
            </Stack>
            <Text style={{ position: 'absolute', top: '90%', left: '7%' }} fz="xl">
              Logout
            </Text>
          </Paper>
        )}
      </Transition>
    </div>
  );
};

export default BorneUiMobileMenu;
