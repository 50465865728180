import Auth from 'borne_ui/src/auth/Auth';
import React, { useMemo, useState, useEffect } from 'react';
import { Layer as LeafletLayer } from 'leaflet';
import { TileLayer, useMap, Pane } from 'react-leaflet';
import GeoJSON from '../generic/GeoJSON';
import { Loader } from 'semantic-ui-react';
import { useMarketTSGeo, useStudyAreaGeo } from '../../api/requests';
import withMapContext from './WithMapContext';
import Chroma from 'chroma-js';

interface Props {
  auth: Auth;
  studyAreaId: string;
  marketId: string;
  ts: string;
}

const MarketTSMapComponent: React.FC<Props> = ({ auth, studyAreaId, marketId, ts }: Props) => {
  const map = useMap();
  const [colorScale, setColorScale] = useState<Chroma.Scale>();
  const onEachFeatureDetail = (feature: any, layer: LeafletLayer) => {
    if (feature.properties && feature.properties.population) {
      layer.bindTooltip(`Population: ${feature.properties.population}`, { sticky: true, opacity: 0.9 });
    }
  };

  const studyAreaGeoRes = useStudyAreaGeo(auth, studyAreaId, {
    refetchOnWindowFocus: false,
  });

  const marketTSRes = useMarketTSGeo(auth, marketId, ts, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      map.invalidateSize();
      const volumes = [...data.features.map(({ properties }) => properties!.population as number)];
      const chroma = Chroma.scale('YlGn').domain([0, Math.max(...volumes)]);
      setColorScale(() => chroma);
    },
  });

  useEffect(() => {
    marketTSRes.refetch();
  }, [ts]);

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
        zIndex={100}
      />
    ),
    []
  );

  return (
    <>
      {studyAreaGeoRes.isSuccess && !studyAreaGeoRes.isFetching ? (
        <>
          <Pane name="data" style={{ zIndex: 200 }}>
            {marketTSRes.isSuccess && !marketTSRes.isFetching && colorScale && (
              <GeoJSON
                data={marketTSRes.data}
                onEachFeature={onEachFeatureDetail}
                style={(record) => ({
                  color: 'rgba(0,0,0,.04)',
                  weight: 1,
                  fillOpacity: 0.7,
                  zIndex: 10,
                  fillColor: colorScale(record?.properties.population as number).hex(),
                })}
              />
            )}
          </Pane>
          {Tiles}
          <Pane name="boundary" style={{ zIndex: 1000 }}>
            <GeoJSON
              data={studyAreaGeoRes.data}
              style={(_) => ({
                color: 'rgba(0,0,0,.4)',
                fillColor: 'rgba(0,0,0,0.01)',
              })}
            />
          </Pane>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export const MarketTSMap = withMapContext(MarketTSMapComponent, { height: '40vh' });
