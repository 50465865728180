import React from 'react';

interface Props {
  active?: boolean;
  size?: number;
}

export const ProfileSvg = ({ active = false, size = 32 }: Props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <path
        fill={active ? '#bccd19' : '#2a4e55'}
        d="M31.44,13.92c0-7.57-6.14-13.72-13.72-13.72C13,0.2,8.84,2.59,6.37,6.22L-0.3,19.17h4.1l1.6,10.72h5.48v9.81h17.33
	l-1.89-15.09C29.44,22.09,31.44,18.24,31.44,13.92z M23.34,24.98l1.47,11.72H13.88v-9.81H7.99l-1.22-8.16l-0.38-2.56H4.62l4.33-8.41
	c2.01-2.85,5.28-4.56,8.77-4.56c5.91,0,10.72,4.81,10.72,10.72c0,3.26-1.46,6.3-4,8.35l-1.31,1.05L23.34,24.98z M25.54,14.12V12.5
	h-1.62c-0.07-0.51-0.2-1-0.4-1.46l1.41-0.81l-0.81-1.41l-1.41,0.82c-0.3-0.4-0.66-0.76-1.06-1.07l0.81-1.41l-1.4-0.81l-0.82,1.41
	c-0.46-0.19-0.95-0.33-1.46-0.39V5.74h-1.62v1.63c-0.51,0.06-1,0.2-1.46,0.39l-0.81-1.41l-1.41,0.81l0.82,1.41
	c-0.4,0.31-0.76,0.67-1.07,1.07l-1.41-0.82l-0.81,1.41l1.41,0.81c-0.19,0.46-0.33,0.95-0.39,1.46H10.4v1.62h1.63
	c0.06,0.51,0.2,1.01,0.39,1.47l-1.41,0.81l0.81,1.4l1.41-0.81c0.31,0.4,0.67,0.76,1.07,1.07l-0.82,1.4l1.41,0.81l0.81-1.41
	c0.46,0.2,0.95,0.33,1.46,0.4v1.62h1.62v-1.62c0.51-0.07,1-0.2,1.46-0.4l0.82,1.41l1.4-0.81l-0.81-1.4c0.4-0.31,0.76-0.67,1.06-1.07
	l1.41,0.81l0.81-1.4l-1.41-0.81c0.2-0.46,0.33-0.96,0.4-1.47H25.54z M14.97,13.31c0-1.65,1.34-3,3-3s3.01,1.35,3.01,3
	c0,1.66-1.35,3.01-3.01,3.01S14.97,14.97,14.97,13.31z"
      />
    </svg>
  );
};
