import React from 'react';
import { Stripe } from '@stripe/stripe-js';
import Auth from '../auth/Auth';
import { User } from '../types';

interface AppContextType {
  auth: Auth;
  user?: User;
  stripe?: Promise<Stripe | null>;
}

export const AppContext = React.createContext<AppContextType>({
  auth: new Auth(),
  user: undefined,
  stripe: undefined,
});
