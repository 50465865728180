import BarChart from '@bornellc/bach/BarChart';
import { Header, ChartHeader, Description } from '../../styled/Typography';
import React from 'react';
import { Divider, Grid, Segment } from 'semantic-ui-react';
import ObjectPieChart from '../../generic/ObjectPieChart';
import { isSeriesValid, buildSeries } from '../../../reactHelpers';

export const MarketDetailDemographicDescription: React.FC = ({}) => {
  return (
    <>
      <Header as="h2">Demographics</Header>
      <Description as="p">Understand the demographics for the residents of this market.</Description>
    </>
  );
};

interface DataProps {
  marketSummaryData: any;
}

export const MarketDetailDemographicData: React.FC<DataProps> = ({ marketSummaryData }) => {
  return (
    <>
      {marketSummaryData ? (
        <Grid padded="vertically" attached="top" columns="2" textAlign="center">
          {isSeriesValid(marketSummaryData, 'age_') && (
            <Grid.Column>
              <ChartHeader as="h3">Age</ChartHeader>
              <Divider fitted />
              <BarChart
                orientation="h"
                x="key"
                y="value"
                grid="v"
                data={buildSeries(marketSummaryData, 'age_', [
                  '< 4',
                  '5 to 14',
                  '15 to 17',
                  '18 to 24',
                  '25 to 34',
                  '35 to 64',
                  '65+',
                ])}
                dataKey="age_bar_chart"
                height={300}
              />
            </Grid.Column>
          )}
          {isSeriesValid(marketSummaryData, 'race_') && (
            <Grid.Column>
              <ChartHeader as="h3">Race</ChartHeader>
              <ObjectPieChart
                width="100%"
                height={300}
                data={marketSummaryData}
                normalize
                keys={[
                  'race_white',
                  'race_two_or_more_races',
                  'race_other_race_alone',
                  'race_black_african_american',
                  'race_american_indian_alaska_native',
                  'race_hawaiian_pacific',
                  'race_asian',
                  'race_hispanic_or_latino',
                ]}
                labels={[
                  'White',
                  'Two or More',
                  'Other',
                  'Black',
                  'American Indian',
                  'Hawaiian / PI',
                  'Asian',
                  'Hispanic or Latino',
                ]}
              />
            </Grid.Column>
          )}
          {isSeriesValid(marketSummaryData, 'household_income_') && (
            <Grid.Column>
              <ChartHeader as="h3">Household Income</ChartHeader>
              <Divider fitted />
              <BarChart
                orientation="h"
                x="key"
                y="value"
                grid="v"
                data={buildSeries(marketSummaryData, 'household_income_', [
                  '< 10k',
                  '10k to 40k',
                  '40k to 75k',
                  '75k to 125k',
                  '125k+',
                ])}
                dataKey="household_income_bar_chart"
                height={300}
              />
            </Grid.Column>
          )}
          {isSeriesValid(marketSummaryData, 'employment_') && (
            <Grid.Column>
              <ChartHeader as="h3">Employment</ChartHeader>
              <ObjectPieChart
                width="100%"
                height={300}
                data={marketSummaryData}
                otherThreshold={0}
                normalize
                keys={['employment_employed', 'employment_unemployed', 'employment_notinlf', 'employment_under_16']}
                labels={['Employed', 'Unemployed', 'Not in Labor Force', 'Under 16']}
              />
            </Grid.Column>
          )}
          {isSeriesValid(marketSummaryData, 'education_') && (
            <Grid.Column>
              <ChartHeader as="h3">Educational Attainment</ChartHeader>
              <Divider fitted />
              <BarChart
                orientation="h"
                x="key"
                y="value"
                grid="v"
                data={buildSeries(marketSummaryData, 'education_', [
                  'Bachelors',
                  'Some College',
                  'High School',
                  'K-12',
                  '< 3',
                ])}
                dataKey="education_bar_chart"
                height={300}
              />
            </Grid.Column>
          )}
          {isSeriesValid(marketSummaryData, 'tenure_') && (
            <Grid.Column>
              <ChartHeader as="h3">Tenure</ChartHeader>
              <ObjectPieChart
                width="100%"
                height={300}
                data={marketSummaryData}
                otherThreshold={0}
                normalize
                keys={['tenure_owner', 'tenure_renter', 'tenure_group_quarters']}
                labels={['Owner', 'Renter', 'Group Quarters']}
              />
            </Grid.Column>
          )}
          {isSeriesValid(marketSummaryData, 'movedin_') && (
            <Grid.Column>
              <ChartHeader as="h3">Residency Term</ChartHeader>
              <BarChart
                orientation="h"
                x="key"
                y="value"
                grid="v"
                data={buildSeries(marketSummaryData, 'movedin_', [
                  '< 1 Year',
                  '1 to 2 Years',
                  '2 to 4 Years',
                  '5 to 9 Years',
                  '10 to 19 Years',
                  '20 to 29 Years',
                  '30+ Years',
                ])}
                dataKey="movedin_bar_chart"
                height={300}
              />
            </Grid.Column>
          )}
          {isSeriesValid(marketSummaryData, 'family_structure_') && (
            <Grid.Column>
              <ChartHeader as="h3">Household Type</ChartHeader>
              <ObjectPieChart
                width="100%"
                height={300}
                data={marketSummaryData}
                normalize
                otherThreshold={0}
                keys={[
                  'family_structure_living_alone',
                  'family_structure_family_single',
                  'family_structure_nonfamily_single',
                  'family_structure_married_couple',
                  'family_structure_group_quarters',
                ]}
                labels={['Living Alone', 'Single, Family', 'Single, Non-family', 'Married', 'Group Quarters']}
              />
            </Grid.Column>
          )}
        </Grid>
      ) : (
        <Segment placeholder />
      )}
    </>
  );
};
