import { FormikProps } from 'formik';
import React, { Component, Fragment } from 'react';
import { Header as BaseHeader, Table } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { Day, days, HoursOfOperation, Meal, meals } from '../../data/categories';

const Header = styled(BaseHeader)`
  &&&& {
    margin: 20px 0 20px;
  }
`;

const TableHeaderCell = styled(Table.HeaderCell)`
  &&&& {
    cursor: pointer;
  }
`;

const TableCell = styled(Table.Cell)`
  cursor: pointer;
`;

const mealDescriptions: Readonly<Partial<Record<Meal, string>>> = {
  'Late Night': '(10pm - 2am)',
};

function createDefaultHoursOfOperation(): HoursOfOperation {
  return Object.assign(
    {},
    ...days.map((day) => ({
      [day]: Object.assign({}, ...meals.map((meal) => ({ [meal]: false }))),
    }))
  );
}

interface Props {
  loc: string;
  noLabel?: boolean;
  formikBag: FormikProps<any>;
}

interface State {
  hoursOfOperation: HoursOfOperation;
}

function updateDayAndMealState(prevHoursOfOperation: HoursOfOperation, day: Day, meal: Meal): State {
  return {
    hoursOfOperation: {
      ...prevHoursOfOperation,
      [day]: {
        ...prevHoursOfOperation[day],
        [meal]: !prevHoursOfOperation[day][meal],
      },
    },
  };
}

function isOpenAllDay(hoursOfOperation: HoursOfOperation, day: Day): boolean {
  return meals.every((meal) => hoursOfOperation[day][meal]);
}

function isOpenAllMeals(hoursOfOperation: HoursOfOperation, meal: Meal): boolean {
  return days.every((day) => hoursOfOperation[day][meal]);
}

function updateDayState(prevHoursOfOperation: HoursOfOperation, day: Day): State {
  const open = isOpenAllDay(prevHoursOfOperation, day);
  return {
    hoursOfOperation: {
      ...prevHoursOfOperation,
      [day]: Object.assign({}, ...meals.map((meal) => ({ [meal]: !open }))),
    },
  };
}

function updateMealState(prevHoursOfOperation: HoursOfOperation, meal: Meal): State {
  const open = isOpenAllMeals(prevHoursOfOperation, meal);
  // can we do this functionally?
  const hoursOfOperation: any = {};
  days.forEach((day) => {
    hoursOfOperation[day] = {
      ...prevHoursOfOperation[day],
      [meal]: !open,
    };
  });
  return {
    hoursOfOperation,
  };
}

class HoursOfOperationQuestion extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    const { loc, formikBag } = props;
    const meta = formikBag.getFieldMeta(loc);
    const hoursOfOperation = (meta.initialValue as HoursOfOperation) || createDefaultHoursOfOperation();
    this.state = {
      hoursOfOperation,
    };
  }

  updateDayAndMeal = (day: Day, meal: Meal): void =>
    this.setState(
      ({ hoursOfOperation }) => updateDayAndMealState(hoursOfOperation, day, meal),
      () => this.props.formikBag.getFieldHelpers(this.props.loc).setValue(this.state.hoursOfOperation)
    );

  updateDay = (day: Day): void =>
    this.setState(
      ({ hoursOfOperation }) => updateDayState(hoursOfOperation, day),
      () => this.props.formikBag.getFieldHelpers(this.props.loc).setValue(this.state.hoursOfOperation)
    );

  updateMeal = (meal: Meal): void =>
    this.setState(
      ({ hoursOfOperation }) => updateMealState(hoursOfOperation, meal),
      () => this.props.formikBag.getFieldHelpers(this.props.loc).setValue(this.state.hoursOfOperation)
    );

  render(): JSX.Element {
    return (
      <Fragment>
        {!this.props.noLabel && <Header as="h3">What are your hours of operation?</Header>}
        <Table definition celled columns={10}>
          <Table.Header>
            <Table.Row>
              <TableHeaderCell width={3} />
              <TableHeaderCell textAlign="center" onClick={() => this.updateDay('Sunday')}>
                Sun.
              </TableHeaderCell>
              <TableHeaderCell textAlign="center" onClick={() => this.updateDay('Monday')}>
                Mon.
              </TableHeaderCell>
              <TableHeaderCell textAlign="center" onClick={() => this.updateDay('Tuesday')}>
                Tues.
              </TableHeaderCell>
              <TableHeaderCell textAlign="center" onClick={() => this.updateDay('Wednesday')}>
                Wed.
              </TableHeaderCell>
              <TableHeaderCell textAlign="center" onClick={() => this.updateDay('Thursday')}>
                Thurs.
              </TableHeaderCell>
              <TableHeaderCell textAlign="center" onClick={() => this.updateDay('Friday')}>
                Fri.
              </TableHeaderCell>
              <TableHeaderCell textAlign="center" onClick={() => this.updateDay('Saturday')}>
                Sat.
              </TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {meals.map((meal, i) => (
              <Table.Row key={i}>
                <TableCell width={3} textAlign="right" onClick={() => this.updateMeal(meal)}>
                  {meal}
                  {mealDescriptions[meal] && (
                    <span>
                      <br />
                      {mealDescriptions[meal]}
                    </span>
                  )}
                </TableCell>
                {days.map((day, j) => (
                  <TableCell
                    key={j}
                    selectable
                    positive={this.state.hoursOfOperation[day][meal]}
                    negative={!this.state.hoursOfOperation[day][meal]}
                    onClick={() => this.updateDayAndMeal(day, meal)}
                  />
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Fragment>
    );
  }
}

export default HoursOfOperationQuestion;
