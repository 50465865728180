import React, { useEffect, useState } from 'react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Segment,
  Dimmer,
  Loader,
  Image,
  Container,
  Grid,
  Menu,
  SemanticCOLORS,
  Statistic as BaseStatistic,
  Button,
} from 'semantic-ui-react';
import NearbyCuisinesSegment from './segments/NearbyCuisinesSegment';
import NearbyAllRestaurantsSegment from './segments/NearbyAllRestaurantsSegment';
import GapAnalysisSegment from './segments/GapAnalysisSegment';
import PopulationDensitySegment from './segments/PopulationDensitySegment';
import MedianIncomeSegment from './segments/MedianIncomeSegment';
import NearbyBusinessesSegment from './segments/NearbyBusinessesSegment';
import WalkTransitScoreSegment from './segments/WalkTransitScoreSegment';
import NearbyParkingSegment from './segments/NearbyParkingSegment';
import ReservationsSegment from './segments/ReservationsSegment';
import AverageRestaurantRatingSegment from './segments/AverageRestaurantRatingSegment';
import NearbyBarsSegment from './segments/NearbyBarsSegment';
import OccupancyBudgetSegment from './segments/OccupancyBudgetSegment';
import YearlySalesSegment from './segments/YearlySalesSegment';
import TotalScoreSegment from './segments/TotalScoreSegment';
import ConceptTopInfoSegment from './segments/ConceptTopInfoSegment';
import ConceptInfoSegment from './segments/ConceptInfoSegment';
import RestaurantSpendingSegment from './segments/RestaurantSpendingSegment';
import AlcoholSpendingSegment from './segments/AlcoholSpendingSegment';
import MarketSegmentationSegment from './segments/MarketSegmentationSegment';
import PsychographicsSegment from './segments/PsychographicsSegment';
import RaceSegment from './segments/RaceSegment';
import AgeSegment from './segments/AgeSegment';
import OccupationSegment from './segments/OccupationSegment';
import GenderSegment from './segments/GenderSegment';
import PopularHoursSegment from './segments/PopularHoursSegment';
import { formatNumber } from '../helpers';
import { Result } from '../types';
import Auth from '../auth/Auth';
import MinimumWageSegment from './segments/MinimumWageSegment';
import CountyUnemploymentSegment from './segments/CountyUnemploymentSegment';
import ModelResultSegment from './segments/ModelResultSegment';
import KeywordTrendsSegment from './segments/KeywordTrendsSegment';
import TakeoutTrendsSegment from './segments/TakeoutTrendsSegment';
import NearbyClosedRestaurantsSegment from './segments/NearbyClosedRestaurantsSegment';
import { useLocation } from 'react-router';
import { Concept, ReportConcept } from './concept/concept';
import { useCreateReport, useReport } from '../api/requests';

//import mockResponse from "../mock/200-west-12th-street-kansas-city-mo";
const mockResponse = undefined;

const Header = styled(Menu)`
  &&&& {
    background: #24282b;
    border-radius: 0;
  }
`;

const globalStyle = css`
  body,
  html {
    width: 210mm;
    height: 296mm;
    margin: 0 auto;
    font-size: 3.7mm !important;
  }

  @media print {
    @page {
      size: 210mm 4000mm;
    }
  }

  // Fixes https://bugs.chromium.org/p/chromium/issues/detail?id=426294.
  .gm-style div > img {
    position: absolute;
  }
`;

const GridRow = styled(Grid.Row)`
  //  height: 60mm;

  &&&& {
    .segment {
      height: 100%;
    }
  }
`;

const RestaurantSpendingRow = styled(GridRow)`
  //  height: 100mm;
`;

const PopularHoursRow = styled(GridRow)`
  //  height: 155mm;
`;

const CountyUnemploymentRow = styled(GridRow)`
  //  height: 120mm;
`;

const KeywordTrendsRow = styled(GridRow)`
  //  height: 115mm;
`;

const TakeoutTrendsRow = styled(GridRow)`
  //  height: 195mm;
`;

const ModelRow = styled(GridRow)`
  //  height: 80mm;
`;

const MinimumWageRow = styled(GridRow)`
  //  height: 110mm;
`;

const NearbyClosedRow = styled(GridRow)`
  //  height: 225mm;
`;

const NearbyBusinessesRow = styled(GridRow)`
  //  height: 100mm;
  overflow-y: auto;
`;

const Statistic = styled(BaseStatistic)`
  &&&&& {
    min-height: 70px;
    .value {
      font-size: 2em !important;
    }
  }
`;

const MarketShareRow = styled(GridRow)`
  //  height: 133mm;
  overflow-y: auto;
`;

const RaceRow = styled(GridRow)`
  //  height: 180mm;
`;

const GenderRow = styled(GridRow)`
  //  height: 130mm;
`;

const AgeRow = styled(GridRow)``;

const OccupationRow = styled(GridRow)``;

const NearbyBarsRow = styled(GridRow)`
  //  height: 170mm;
  overflow-y: auto;
`;

const SearchGridRow = styled(GridRow)`
  height: auto;

  @media print {
    &&&& {
      display: none;
    }
  }
`;

const ConceptInfoRow = styled(GridRow)`
  //  margin-top: 100mm;
`;

interface Props {
  reportId?: number;
  password?: string;
  auth?: Auth;
}

const Results = ({ auth, reportId, password }: Props) => {
  useEffect(() => {
    document.title = 'Borne Report\u00ae';
  }, []);

  const [locationresults, setLocationresults] = useState<Result>();

  const reportRequestToConcept = (reportRequest: ReportConcept): Concept => {
    const { restaurant_square_footage, word1, word2, word3, ...partialConcept } = reportRequest;

    return {
      ...partialConcept,
      size: { squareFootage: restaurant_square_footage },
      trending_words: `${word1}${word2 ? ', ' + word2 : ''}${word3 ? ', ' + word3 : ''}`,
      concept_type: 'single_location',
      states: new Set(),
    };
  };

  if (mockResponse) {
    if (!locationresults) {
      setLocationresults(mockResponse);
    }
  } else if (!reportId) {
    const concept = useLocation().state as Concept;
    const createReport = useCreateReport(auth!);

    useEffect(() => {
      createReport.mutate(concept);
    }, []);

    useEffect(() => {
      setLocationresults(createReport.data);
    }, [createReport.data]);
  } else {
    const report = useReport(reportId, password!);

    useEffect(() => {
      if (report.isSuccess) {
        const locationresults = {
          ...report.data.data,
          concept: reportRequestToConcept(report.data.report_request),
        };

        setLocationresults(locationresults);
      } else if (report.isError && (report.error as any).response.status === 403) {
        alert('Incorrect password. Please try again.');
        window.location.reload();
      }
    }, [report.status]);
  }

  const deriveColorFromPoints = (score: number): SemanticCOLORS => {
    if (score === 4) return 'blue';
    if (score === 3) return 'grey';
    if (score === 2) return 'yellow';
    if (score === 1) return 'red';
    return 'black';
  };

  return (
    <>
      <Global styles={globalStyle} />
      <Header borderless inverted size="massive">
        <Container>
          <Menu.Item as="a" href="https://bornereport.com/">
            <Image
              src="/static/B_Icon_transparent.png"
              style={{
                width: '50px',
                marginRight: '9px',
              }}
            />{' '}
            Borne Report<sup>&#174;</sup>
          </Menu.Item>
        </Container>
      </Header>
      {locationresults ? (
        <>
          <Container>
            <Grid className="sheet">
              <GridRow>
                <Grid.Column>
                  <ConceptTopInfoSegment concept={locationresults['concept']} />
                </Grid.Column>
              </GridRow>
              <GridRow>
                <Grid.Column>
                  <TotalScoreSegment
                    totalPoints={locationresults['totalPoints']}
                    totalPossiblePoints={locationresults['totalPossiblePoints']}
                  />
                </Grid.Column>
              </GridRow>
              <GridRow>
                <Grid.Column>
                  <YearlySalesSegment
                    color={deriveColorFromPoints(locationresults['yearlySales']['points']!)}
                    yearlySalesDict={locationresults.yearlySales}
                  />
                </Grid.Column>
              </GridRow>
              <GridRow>
                <Grid.Column>
                  <OccupancyBudgetSegment occupancyDict={locationresults.occupancy} />
                </Grid.Column>
              </GridRow>
              {locationresults.modelEstimation && (
                <ModelRow>
                  <Grid.Column>
                    <ModelResultSegment color="green" modelEstimation={locationresults.modelEstimation} />
                  </Grid.Column>
                </ModelRow>
              )}
              <Grid.Row>
                <Grid.Column>
                  <PsychographicsSegment data={locationresults.psychographics} />
                </Grid.Column>
              </Grid.Row>
              <GridRow>
                {locationresults['allRestaurantsNearby'] ? (
                  <Grid.Column>
                    <NearbyAllRestaurantsSegment
                      conceptLatLng={locationresults.latlng}
                      restaurants={locationresults['allRestaurantsNearby'] || []}
                    />
                  </Grid.Column>
                ) : null}
              </GridRow>
              <NearbyBarsRow>
                <Grid.Column>
                  <NearbyBarsSegment
                    conceptLatLng={locationresults.latlng}
                    barsNearby={locationresults['barsNearby']}
                  />
                </Grid.Column>
              </NearbyBarsRow>
            </Grid>
            <Grid>
              <MarketShareRow>
                {locationresults['cuisinesNearby'] ? (
                  <Grid.Column>
                    <NearbyCuisinesSegment
                      color={deriveColorFromPoints(locationresults['cuisinesNearby']['points']!)}
                      similarRestaurants={locationresults['cuisinesNearby']['similarRestaurants'] || []}
                    />
                  </Grid.Column>
                ) : null}
              </MarketShareRow>
              <GridRow>
                {locationresults['missingCuisines'] ? (
                  <Grid.Column>
                    <GapAnalysisSegment missingCuisines={locationresults['missingCuisines'] || []} />
                  </Grid.Column>
                ) : null}
              </GridRow>
              <NearbyClosedRow stretched columns={1}>
                <Grid.Column width={16}>
                  <NearbyClosedRestaurantsSegment
                    conceptLatLng={locationresults.latlng}
                    restaurants={locationresults.nearbyClosed}
                    restaurantStats={locationresults.nearbyClosedStats}
                  />
                </Grid.Column>
              </NearbyClosedRow>
              <GridRow>
                <Grid.Column>
                  <Segment textAlign="center" inverted style={{ background: '#e9e9e9' }}>
                    <Statistic horizontal size="small">
                      <BaseStatistic.Value>
                        {formatNumber(Math.round(locationresults['populationDensityProjection']))}
                      </BaseStatistic.Value>
                      <BaseStatistic.Label>2025 Population Density (Projected)</BaseStatistic.Label>
                    </Statistic>
                  </Segment>
                </Grid.Column>
              </GridRow>
              <GridRow stretched columns={2}>
                <Grid.Column>
                  <WalkTransitScoreSegment
                    color={deriveColorFromPoints(locationresults['walkscore']['points']!)}
                    concept={locationresults['concept']}
                    walkScoreDict={locationresults['walkscore']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <PopulationDensitySegment
                    color={deriveColorFromPoints(locationresults['populationDensity']['points']!)}
                    concept={locationresults['concept']}
                    populationDensityDict={locationresults['populationDensity']}
                    populationDensityProjection={Math.round(locationresults['populationDensityProjection'])}
                  />
                </Grid.Column>
              </GridRow>
              <GridRow stretched columns={2}>
                <Grid.Column>
                  <MedianIncomeSegment
                    color={deriveColorFromPoints(locationresults['medianIncome']['points']!)}
                    medianIncomeDict={locationresults['medianIncome']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <AverageRestaurantRatingSegment
                    averageGoogleRatingDict={locationresults['averageGoogleRating']}
                    averageYelpRatingDict={locationresults['averageYelpRating']}
                  />
                </Grid.Column>
              </GridRow>
              <GridRow stretched columns={2}>
                <Grid.Column>
                  <ReservationsSegment
                    color={deriveColorFromPoints(locationresults['reservation']['points']!)}
                    concept={locationresults['concept']}
                    reservationsDict={locationresults['reservation']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <NearbyParkingSegment
                    color={deriveColorFromPoints(locationresults['parking']['points']!)}
                    parkingDict={locationresults['parking']}
                  />
                </Grid.Column>
              </GridRow>
              <RestaurantSpendingRow stretched columns={2}>
                <Grid.Column>
                  <RestaurantSpendingSegment
                    color={deriveColorFromPoints(locationresults['foodSpending']['points']!)}
                    restaurantSpendingDict={locationresults['foodSpending']}
                    foodAtHomeSpendingDict={
                      locationresults['concept'].service_type !== 'virtual_service'
                        ? locationresults['foodAtHomeSpending']
                        : undefined
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <AlcoholSpendingSegment
                    color={deriveColorFromPoints(locationresults['alcoholSpending']['points']!)}
                    concept={locationresults['concept']}
                    alcoholSpendingDict={locationresults['alcoholSpending']}
                  />
                </Grid.Column>
              </RestaurantSpendingRow>
              <GridRow stretched>
                <Grid.Column>
                  <MarketSegmentationSegment marketSegmentation={locationresults['marketSegmentation']} />
                </Grid.Column>
              </GridRow>
              {locationresults.popularTimes && (
                <PopularHoursRow>
                  <Grid.Column>
                    <PopularHoursSegment popularTimes={locationresults.popularTimes} />
                  </Grid.Column>
                </PopularHoursRow>
              )}
              <RaceRow>
                <Grid.Column>
                  <RaceSegment race={locationresults.race} />
                </Grid.Column>
              </RaceRow>
              <GenderRow>
                <Grid.Column>
                  <GenderSegment gender={locationresults.gender} />
                </Grid.Column>
              </GenderRow>
              <AgeRow>
                <Grid.Column>
                  <AgeSegment age={locationresults.age} />
                </Grid.Column>
              </AgeRow>
              <NearbyBusinessesRow>
                <Grid.Column>
                  <NearbyBusinessesSegment
                    color={deriveColorFromPoints(locationresults['businessesNearby']['points']!)}
                    concept={locationresults['concept']}
                    conceptLatLng={locationresults.latlng}
                    businessesNearbyDict={locationresults['businessesNearby']}
                  />
                </Grid.Column>
              </NearbyBusinessesRow>
              <OccupationRow>
                <Grid.Column>
                  <OccupationSegment occupation={locationresults.occupation} />
                </Grid.Column>
              </OccupationRow>
              <MinimumWageRow>
                <Grid.Column>
                  <MinimumWageSegment color="blue" minWageInfo={locationresults.minimumWageInfo} />
                </Grid.Column>
              </MinimumWageRow>
              <CountyUnemploymentRow>
                <Grid.Column>
                  <CountyUnemploymentSegment countyUnemployment={locationresults.countyUnemployment} />
                </Grid.Column>
              </CountyUnemploymentRow>
              <KeywordTrendsRow>
                <Grid.Column>
                  <KeywordTrendsSegment keywordTrendsData={locationresults.keywordTrendsData} />
                </Grid.Column>
              </KeywordTrendsRow>
              <TakeoutTrendsRow>
                <Grid.Column>
                  <TakeoutTrendsSegment takeoutTrendsData={locationresults.takeoutTrendsData} />
                </Grid.Column>
              </TakeoutTrendsRow>
              <SearchGridRow columns={2}>
                <Grid.Column>
                  <Button
                    size="huge"
                    style={{ width: '100%' }}
                    onClick={() =>
                      window.open(
                        encodeURI(
                          'https://google.com/search?q=' +
                            locationresults!['concept'].trending_words +
                            ' near ' +
                            locationresults!['concept']['address']
                        ),
                        '_blank'
                      )
                    }
                  >
                    Google
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    size="huge"
                    positive
                    style={{ width: '100%' }}
                    onClick={() =>
                      window.open(
                        encodeURI(
                          'https://www.yelp.com/search?find_desc= ' +
                            locationresults!['concept'].trending_words +
                            '&find_loc=' +
                            locationresults!['concept']['address']
                        ),
                        '_blank'
                      )
                    }
                  >
                    Yelp
                  </Button>
                </Grid.Column>
              </SearchGridRow>
              <GridRow>
                <Grid.Column>
                  <TotalScoreSegment
                    totalPoints={locationresults['totalPoints']}
                    totalPossiblePoints={locationresults['totalPossiblePoints']}
                  />
                </Grid.Column>
              </GridRow>
              <ConceptInfoRow>
                <Grid.Column>
                  <ConceptInfoSegment concept={locationresults['concept']} isOnlineReport={!!reportId} />
                </Grid.Column>
              </ConceptInfoRow>
            </Grid>
          </Container>
        </>
      ) : (
        <Container>
          <br />
          <br />
          <Segment basic>
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
            <Image src="static/paragraph.png" />
          </Segment>
        </Container>
      )}
    </>
  );
};

export default Results;
