import React, { useMemo } from 'react';
import { TileLayer, CircleMarker, Tooltip, useMap } from 'react-leaflet';
import withMapContext from '../../maps/WithMapContext';

interface Props {
  address: string;
  latitude: number;
  longitude: number;
}

const AddressMapComponent: React.FC<Props> = ({ address, latitude, longitude }: Props) => {
  const map = useMap();

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
    ),
    []
  );

  map.setView([latitude, longitude], 18);

  return (
    <>
      {Tiles}
      <CircleMarker center={[latitude, longitude]} stroke={false} fillOpacity={1} fillColor="#0c7c59" radius={5}>
        <Tooltip>{address}</Tooltip>
      </CircleMarker>
    </>
  );
};

export const AddressMap = withMapContext(AddressMapComponent, { height: '100%' });
