import BarChart from '@bornellc/bach/BarChart';
import PopulationPyramid from '@bornellc/bach/PopulationPyramid';
import React, { useState } from 'react';
import { Header, Grid, Form, List, Icon, Segment, Button } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { formatCurrency, strSnakeToProperCase } from '../../../../helpers';

interface DescriptionProps {
  clusterOptions: Array<string>;
  clusterNames: Map<string, string>;
  selectedClusters: Set<string>;
  dispatchClusterSelection: React.Dispatch<{
    action: string;
    clusterId: string;
  }>;
}

const ChartColumn = styled(Grid.Column)`
  height: 35vh;
`;

export const ClusterComparisonDescriptionSegment: React.FC<DescriptionProps> = ({
  clusterOptions,
  selectedClusters,
  dispatchClusterSelection,
  clusterNames,
}) => {
  return (
    <>
      <Header as="h1">Cluster Comparison</Header>
      <Header as="p" color="grey" size="tiny">
        Select up to four clusters to compare
      </Header>
      <Form>
        <Form.Dropdown
          placeholder="Area"
          fluid
          search
          selection
          options={clusterOptions.map((cluster: string) => ({
            text: clusterNames.get(cluster),
            value: cluster,
          }))}
          onChange={(_, data) => {
            dispatchClusterSelection({ action: 'add', clusterId: data.value as string });
          }}
        />
      </Form>
      <Header as="h4">Selected clusters:</Header>
      <List selection verticalAlign="middle">
        {selectedClusters.size ? (
          Array.from(selectedClusters).map((cluster) => (
            <List.Item key={cluster} onClick={() => dispatchClusterSelection({ action: 'remove', clusterId: cluster })}>
              <Icon name="close" />
              <List.Content>
                <List.Description>{clusterNames.get(cluster)}</List.Description>
              </List.Content>
            </List.Item>
          ))
        ) : (
          <List.Item disabled>
            <List.Content>
              <List.Header style={{ color: '#a0a0a0' }}>No clusters selected</List.Header>
            </List.Content>
          </List.Item>
        )}
      </List>
    </>
  );
};

interface DataProps {
  selectedClusters: Set<string>;
  clusterNames: Map<string, string>;
  report: any;
}

export const ClusterComparisonDataSegment: React.FC<DataProps> = ({ selectedClusters, report, clusterNames }) => {
  const [radius, setRadius] = useState(5);
  const demographics = selectedClusters
    ? report.demographics.filter((record: any) => selectedClusters.has(record.cluster_id) && record.radius == radius)
    : [];
  const restaurants = selectedClusters
    ? report.open_restaurants.filter((record: any) => selectedClusters.has(record.cluster_id))
    : [];

  return (
    <>
      {selectedClusters ? (
        <Grid padded="vertically" attached="top" columns="equal" textAlign="center">
          <Grid.Row>
            <Header as="h2">Restaurants</Header>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header style={{ marginBottom: '-2em' }} color="grey" as="h3">
                Cluster Size
              </Header>

              <BarChart
                orientation="h"
                x="name"
                y="total_restaurants"
                tooltip
                tooltipRenderer={(keyStr, value, color, _) => (
                  <span style={{ color: color, fontWeight: 300, fontSize: '12pt' }}>
                    <strong>{strSnakeToProperCase(keyStr)}:</strong>
                    {value}
                  </span>
                )}
                data={restaurants}
                dataKey="restaurants_bar_chart"
                height={230}
              />
            </Grid.Column>
            <Grid.Column>
              <Header style={{ marginBottom: '-2em' }} color="grey" as="h3">
                Price Level
              </Header>
              <BarChart
                orientation="h"
                normalize
                x="name"
                y={[
                  { key: 'price_level_$', label: '$' },
                  { key: 'price_level_$$', label: '$$' },
                  { key: 'price_level_$$$', label: '$$$' },
                  { key: 'price_level_$$$$', label: '$$$$' },
                ]}
                tooltip
                tooltipRenderer={(keyStr, value, color, _) => (
                  <span style={{ color: color, fontWeight: 300, fontSize: '12pt' }}>
                    <strong>{strSnakeToProperCase(keyStr)}:</strong> {value}
                  </span>
                )}
                data={restaurants}
                dataKey="price_level_bar_chart"
                height={230}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Header as="h2">Demographics</Header>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p>Show data based on people who live within a {radius} minute drive.</p>
              <Button.Group>
                <Button basic={radius != 5} onClick={() => setRadius(5)}>
                  5 minutes
                </Button>
                <Button basic={radius != 10} onClick={() => setRadius(10)}>
                  10 minutes
                </Button>
                <Button basic={radius != 15} onClick={() => setRadius(15)}>
                  15 minutes
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header style={{ marginBottom: '-2em' }} color="grey" as="h3">
                Population
              </Header>
              <BarChart
                orientation="v"
                x="name"
                y="population"
                tooltip
                tooltipRenderer={(keyStr, value, color, _) => (
                  <span style={{ color: color, fontWeight: 300, fontSize: '12pt' }}>
                    <strong>{strSnakeToProperCase(keyStr)}:</strong> {value}
                  </span>
                )}
                data={demographics}
                dataKey="population_bar_chart_detail"
                height={230}
              />
            </Grid.Column>
            <Grid.Column>
              <Header style={{ marginBottom: '-2em' }} color="grey" as="h3">
                Median Household Income
              </Header>
              <BarChart
                orientation="v"
                x="name"
                y="median_household_income"
                tooltip
                tooltipRenderer={(keyStr, value, color, _) => (
                  <span style={{ color: color, fontWeight: 300, fontSize: '12pt' }}>
                    <strong>{strSnakeToProperCase(keyStr)}:</strong> {formatCurrency(value as number)}
                  </span>
                )}
                data={demographics}
                dataKey="income_bar_chart_detail"
                height={230}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header style={{ marginBottom: '-2em' }} color="grey" as="h3">
                Ethnicity
              </Header>
              <BarChart
                orientation="h"
                normalize
                x="name"
                y={[
                  { key: 'white', label: 'White' },
                  { key: 'black', label: 'Black' },
                  { key: 'asian', label: 'Asian' },
                  { key: 'native_american', label: 'Native American' },
                  { key: 'pacific_islander', label: 'Pacific Islander' },
                  { key: 'hispanic_or_latino', label: 'Hispanic or Latino' },
                ]}
                tooltip
                tooltipRenderer={(keyStr, value, color, _) => (
                  <span style={{ color: color, fontWeight: 300, fontSize: '12pt' }}>
                    <strong>{strSnakeToProperCase(keyStr)}:</strong> {value}
                  </span>
                )}
                data={demographics}
                dataKey="race_bar_chart_detail"
                height={230}
              />
            </Grid.Column>
            <Grid.Column>
              <Header style={{ marginBottom: '-2em' }} color="grey" as="h3">
                Education
              </Header>
              <BarChart
                orientation="h"
                normalize
                x="name"
                y={[
                  { key: 'less_than_high_school', label: '< High School' },
                  { key: 'high_school_graduate', label: 'High School Graduate' },
                  { key: 'some_college', label: 'Some College' },
                  { key: 'bachelors', label: 'Bachelors' },
                  { key: 'graduate_or_professional', label: 'Graduate or Professional' },
                ]}
                tooltip
                tooltipRenderer={(keyStr, value, color, _) => (
                  <span style={{ color: color, fontWeight: 300, fontSize: '12pt' }}>
                    <strong>{strSnakeToProperCase(keyStr)}:</strong> {value}
                  </span>
                )}
                data={demographics}
                dataKey="education_bar_chart_detail"
                height={230}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Header as="h3" color="grey">
              Gender and Age
            </Header>
          </Grid.Row>
          <Grid.Row>
            {Array.from(selectedClusters).map((cluster: string, idx) => (
              <ChartColumn width={8}>
                <Header style={{ marginBottom: '-2em' }} color="grey" as="h4">
                  {clusterNames.get(cluster)}
                </Header>
                <PopulationPyramid entries={[]} leftColor="#AEDAD8" rightColor="#EF839C" />
              </ChartColumn>
            ))}
          </Grid.Row>
        </Grid>
      ) : (
        <Segment placeholder />
      )}
    </>
  );
};
