import React from 'react';
import { Alcohol } from '../../data/categories';
import { WrappedButtonGroupField } from './FormikUtil';

const options: Readonly<Record<Alcohol, string>> = {
  full_bar: 'Full Bar',
  wine_or_beer: 'Wine or Beer',
  none: 'None',
};

interface Props {
  loc: string;
  useFormLabel?: boolean;
}

const AlcoholQuestion = ({ loc, useFormLabel }: Props) => {
  return (
    <WrappedButtonGroupField
      label="Does your restaurant serve alcohol?"
      options={options}
      name={loc}
      useFormLabel={useFormLabel}
    />
  );
};

export default AlcoholQuestion;
