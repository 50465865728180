import Auth from 'borne_ui/src/auth/Auth';
import React, { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Layer } from 'leaflet';
import { TileLayer, useMap } from 'react-leaflet';
import GeoJSON from '../generic/GeoJSON';
import {
  Dropdown as BaseDropdown,
  DropdownProps,
  Form,
  Loader,
  Search,
  SearchProps,
  SearchResultData,
  SearchResultProps,
} from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { useMarketBlockGroupGeos, useAddressToMarketAndTract, useSubscribedMarketBounds } from '../../api/requests';
import withMapContext from './WithMapContext';
import styled from '@emotion/styled';

const Dropdown = styled(BaseDropdown)`
  // Overrides the padding from Semantic UI when an icon exists.
  &.search.selection.dropdown {
    &.icon > input.search {
      padding: inherit;
    }
  }
`;

export interface MarketBlockGroupSelectionState {
  blockGroups: Set<string>;
  selectedMarket: string;
}

export interface MarketBlockGroupSelectionDispatch {
  type: 'add' | 'remove' | 'reset';
  blockGroup?: string;
  selectedMarket?: string;
  locationCount?: number;
}

interface Props {
  auth: Auth;
  dispatchSelection: React.Dispatch<MarketBlockGroupSelectionDispatch>;
  selectionState: MarketBlockGroupSelectionState;
  initialMarket?: string;
}

const MIN_CHARACTERS = 5;

const MarketBlockGroupSelectMapComponent: React.FC<Props> = ({
  auth,
  selectionState,
  dispatchSelection,
  initialMarket,
}: Props) => {
  const map = useMap();

  const [marketChanged, setMarketChanged] = useState<boolean>(false);
  const [selectedMarketIndex, setSelectedMarketIndex] = useState<number | undefined>();

  const isMounted = useRef<boolean>(false);

  const [session] = useState(uuidv4());
  const [address, setAddress] = useState('');
  const [autoComplete, setAutoComplete] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');

  // TODO: tni: This is an awful way to ensure that we select a specific
  // census block by default after flying to a new market. This MUST be
  // refactored...
  const [selectedCensusBlock, setSelectedCensusBlock] = useState('');

  const runAutoComplete = async (address: string) => {
    try {
      const response = await auth.authenticatedAPICall({
        method: 'GET',
        url: `/api/addresses?address=${encodeURIComponent(address)}&session=${session}`,
      });

      setAutoComplete(
        response.data.map((datum: any) => {
          return { title: datum.description };
        })
      );
    } catch (ex) {
      console.error(`Auto complete call failed with: ${ex}`);
    }
  };

  const updateAddress = (address?: string): void => {
    setAddress(address || '');
    if (address && address.length >= MIN_CHARACTERS) {
      runAutoComplete(address);
    }
  };

  const marketQueryResult = useSubscribedMarketBounds(auth, {
    refetchOnWindowFocus: false,
    onSuccess: (data) =>
      setSelectedMarketIndex(
        initialMarket && typeof initialMarket == 'number'
          ? initialMarket
          : typeof initialMarket == 'string'
          ? data.findIndex((market) => market.id == initialMarket)
          : 0
      ),
  });
  console.log();
  useAddressToMarketAndTract(auth, selectedAddress, {
    enabled: !!selectedAddress && marketQueryResult.isSuccess,
    onSuccess: (data) => {
      if (data.market_id) {
        const index = marketQueryResult.data!.findIndex((market) => market.id == data.market_id);
        if (index !== -1) {
          setSelectedCensusBlock(data.blockgroup_id);
          setMarketChanged(true);
          setSelectedMarketIndex(index);
          map.flyToBounds(marketQueryResult.data![index].extent);

          dispatchSelection({
            type: 'add',
            blockGroup: data.blockgroup_id,
            // TODO: tni: Do we really need an accurate location count?
            locationCount: 0,
          });
        }
      }
    },
  });

  const marketId =
    marketQueryResult.isSuccess && selectedMarketIndex !== undefined
      ? marketQueryResult.data[selectedMarketIndex].id
      : undefined;

  const marketGeosRes = useMarketBlockGroupGeos(auth, marketId as string, {
    enabled: !!marketId,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (marketChanged) {
        dispatchSelection({
          type: 'reset',
          selectedMarket: marketId,
        });

        if (selectedCensusBlock) {
          dispatchSelection({
            type: 'add',
            blockGroup: selectedCensusBlock,
            // TODO: tni: Do we really need an accurate location count?
            locationCount: 0,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (selectedMarketIndex !== undefined) {
      const data = marketQueryResult.data!;

      if (isMounted.current) {
        map.flyToBounds(data[selectedMarketIndex].extent);
      } else {
        map.fitBounds(data[selectedMarketIndex].extent);
        isMounted.current = true;
      }

      if (marketChanged || !initialMarket) {
        dispatchSelection({
          type: 'reset',
          selectedMarket: marketId,
        });
      }
    }
  }, [selectedMarketIndex]);

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
    ),
    []
  );

  return (
    <>
      {marketQueryResult.isSuccess ? (
        <>
          {Tiles}
          {marketGeosRes.isSuccess && (
            <GeoJSON
              data={marketGeosRes.data}
              style={(record) => ({
                color: selectionState.blockGroups.has(record?.id as string) ? '#bccd19' : '#bbbbbb',
              })}
              onEachFeature={(feature: any, layer: Layer) => {
                if (feature.properties) {
                  layer.bindTooltip(`${feature.id}`, { sticky: true, opacity: 0.9 });
                  layer.on({
                    click: (ev) => {
                      dispatchSelection({
                        // onEachFeature is only called once per geometry, unfortunately, this binds the scope to
                        // the values at creation time (where selectionState gets locked), this causes it to not update
                        // so we did this check instead where we look at the color... Not the most elegant, but shrug.
                        type: ev.target.options.color === '#bccd19' ? 'remove' : 'add',
                        blockGroup: ev.target.feature.id,
                        locationCount: ev.target.feature.properties?.total_locations ?? 0,
                      });
                    },
                  });
                }
              }}
            />
          )}
          <div className="leaflet-top leaflet-right leaflet-control">
            <Dropdown
              inline
              onOpen={() => {
                map.dragging.disable();
                map.touchZoom.disable();
                map.doubleClickZoom.disable();
                map.scrollWheelZoom.disable();
                map.boxZoom.disable();
                map.keyboard.disable();
                if (map.tap) map.tap.disable();
              }}
              onClose={() => {
                map.dragging.enable();
                map.touchZoom.enable();
                map.doubleClickZoom.enable();
                map.scrollWheelZoom.enable();
                map.boxZoom.enable();
                map.keyboard.enable();
                if (map.tap) map.tap.enable();
              }}
              value={selectedMarketIndex}
              className="icon leaflet-control"
              style={{ clear: 'none' }}
              options={marketQueryResult.data.map((obj: any, idx: number) => ({ text: obj.id, value: idx }))}
              disabled={marketGeosRes.isFetching}
              selection
              labeled
              search
              button
              selectOnNavigation={false}
              icon="caret right"
              onChange={(_: unknown, { value }: DropdownProps) => {
                setMarketChanged(true);
                setSelectedMarketIndex(value as number);
                setSelectedAddress('');
                setAddress('');
              }}
            />
            <div className="leaflet-control" style={{ display: 'inline-block', clear: 'none' }}>
              <Form.Field
                control={Search}
                placeholder="Address"
                fluid
                minCharacters={MIN_CHARACTERS}
                value={address}
                onResultSelect={(_: SyntheticEvent, { result }: SearchResultData) => {
                  updateAddress(result.title);
                  setSelectedAddress(result.title);
                }}
                onSearchChange={(_: SyntheticEvent, { value }: SearchProps) => updateAddress(value)}
                resultRenderer={({ title }: SearchResultProps) => <div>{title}</div>}
                results={autoComplete}
                onFocus={() => {
                  map.dragging.disable();
                  map.touchZoom.disable();
                  map.doubleClickZoom.disable();
                  map.scrollWheelZoom.disable();
                  map.boxZoom.disable();
                  map.keyboard.disable();
                  if (map.tap) map.tap.disable();
                }}
                onBlur={() => {
                  map.dragging.enable();
                  map.touchZoom.enable();
                  map.doubleClickZoom.enable();
                  map.scrollWheelZoom.enable();
                  map.boxZoom.enable();
                  map.keyboard.enable();
                  if (map.tap) map.tap.enable();

                  setSelectedAddress(address);
                }}
                onKeyPress={(event: any) => {
                  if (event.charCode === 13) {
                    event.target.blur();
                  }
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <Loader inverted content="Loading Map" />
      )}
    </>
  );
};

export const MarketBlockGroupSelectMap = withMapContext(MarketBlockGroupSelectMapComponent, { height: '60vh' });
