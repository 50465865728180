import React, { Component, Fragment } from 'react';
import { Checkbox, Input, Grid, Header as BaseHeader } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { CheckboxValue } from '../../types';
import { FormikProps } from 'formik';

const Header = styled(BaseHeader)`
  &&&& {
    margin: 50px 0 20px;
  }
`;

const knownRestaurantStyles = [
  'Authentic',
  'Brewery',
  'Classic',
  'Contemporary',
  'Family',
  'Farm to table',
  'Full bar',
  'Fusion',
  'Grill',
  'Homemade',
  'Local',
  'Organic',
  'Sustainable',
] as const;

type RestaurantStyle = typeof knownRestaurantStyles[number];

const labels = [...knownRestaurantStyles, 'Other'];
const numColumns = 3;

function partition<T>(array: readonly T[], partitionSize: number): T[][] {
  const partitionedArray: T[][] = [];
  for (let i = 0; i < array.length; i += partitionSize) {
    partitionedArray.push(array.slice(i, i + partitionSize));
  }
  return partitionedArray;
}

interface Props {
  loc: string;
  formikBag: FormikProps<any>;
}

interface State {
  restaurantStyles: string[];
  otherRestaurantStyle?: string;
}

/**
 * NOTE(Sean) I'm not entirely sure why we even offer an "other" option...
 * It drastically complicates the UI, and gives little signal.
 *
 * To make this work in Formik, I'm going to hack around the existing code rather than
 * trying to abstract, for the sake of development velocity.
 *
 * For now I will "deprecate" this component and make the recommendation to remove the
 * ad-hoc entry piece of this concept question.
 * @deprecated
 */
class RestaurantStylesQuestion extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    const { loc, formikBag } = props;
    this.state = {
      restaurantStyles:
        formikBag.getFieldMeta(loc).initialValue instanceof Array
          ? (formikBag.getFieldMeta(loc).initialValue as Array<string>)
          : [],
      otherRestaurantStyle: '', // Fully aware that this breaks for initial values but ¯\_(ツ)_/¯
    };
    formikBag.getFieldHelpers(loc).setValue(this.state.restaurantStyles);
  }

  updateRestaurantStyles = (value: CheckboxValue, checked = false): void => {
    const restaurantStyle = value as RestaurantStyle;
    if (!knownRestaurantStyles.includes(restaurantStyle)) {
      throw new Error(`Unexpected checkbox value ${value}.`);
    }
    this.setState(
      ({ restaurantStyles: prevRestaurantStyles }) => {
        const restaurantStyles = [...prevRestaurantStyles];
        if (checked) {
          restaurantStyles.push(restaurantStyle);
        } else {
          restaurantStyles.splice(restaurantStyles.indexOf(restaurantStyle), 1);
        }
        return {
          restaurantStyles,
        };
      },
      () => this.saveRestaurantStyles(this.state)
    );
  };

  updateOtherStyle = (otherRestaurantStyle: string): void =>
    this.setState(
      (_) => ({
        otherRestaurantStyle: otherRestaurantStyle || undefined,
      }),
      () => this.saveRestaurantStyles(this.state)
    );

  saveRestaurantStyles = (state: State) => {
    const field = this.props.formikBag.getFieldHelpers(this.props.loc);
    const restaurantStyles = state.otherRestaurantStyle
      ? [...state.restaurantStyles, state.otherRestaurantStyle]
      : state.restaurantStyles;
    field.setValue(restaurantStyles);
    field.setTouched(true, false);
  };

  render(): JSX.Element {
    return (
      <Fragment>
        <Header as="h3">What are your restaurant styles?</Header>
        <Grid stackable padded="vertically" columns={numColumns}>
          {partition(labels, numColumns).map((styleRow, i) => (
            <Grid.Row key={i}>
              {styleRow.map((style, j) => (
                <Grid.Column key={j}>
                  {style !== 'Other' ? (
                    <Checkbox
                      label={style}
                      value={style}
                      onChange={(_, { value, checked }) => this.updateRestaurantStyles(value, checked)}
                      checked={this.state.restaurantStyles.includes(style as RestaurantStyle)}
                    />
                  ) : (
                    <Input
                      placeholder="Other"
                      onChange={(_, { value }) => this.updateOtherStyle(value)}
                      value={this.state.otherRestaurantStyle || ''}
                    />
                  )}
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
        </Grid>
      </Fragment>
    );
  }
}

export default RestaurantStylesQuestion;
