import React, { Component } from 'react';
import { Divider, Header as BaseHeader, Icon, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { formatCurrency } from '../../helpers';
import { FoodSpending, FoodAtHomeSpending } from '../../types';

interface Props {
  color: SemanticCOLORS;
  restaurantSpendingDict: FoodSpending;
  foodAtHomeSpendingDict?: FoodAtHomeSpending;
}

class RestaurantSpendingSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">Restaurant Spending</BaseHeader>
        <p>The more families spend eating out, the more successful a restaurant is likely to be.</p>
        {this.props.restaurantSpendingDict.error ? (
          <p>Restaurant spending could not be retrieved for this area.</p>
        ) : (
          <span>
            <p>
              Around this area, families spend on average{' '}
              {this.props.restaurantSpendingDict['avgAnnualSpendingOnFood']
                ? formatCurrency(this.props.restaurantSpendingDict['avgAnnualSpendingOnFood'])
                : '?'}{' '}
              per year at restaurants out of{' '}
              {this.props.restaurantSpendingDict['avgAnnualSpendingOnRetail']
                ? formatCurrency(this.props.restaurantSpendingDict['avgAnnualSpendingOnRetail'])
                : '?'}{' '}
              per year total. This means <b>{this.props.restaurantSpendingDict['percentageOnFood']}%</b> of their
              spending is at restaurants.
            </p>
            {this.props.foodAtHomeSpendingDict && (
              <span>
                <Divider horizontal>
                  <BaseHeader as="h4">
                    <Icon name="chart line" />
                  </BaseHeader>
                </Divider>
                <p>
                  Families spend on average{' '}
                  {this.props.foodAtHomeSpendingDict['avgAnnualSpendingOnFoodAtHome']
                    ? formatCurrency(this.props.foodAtHomeSpendingDict['avgAnnualSpendingOnFoodAtHome'])
                    : '?'}{' '}
                  per year on groceries and{' '}
                  {this.props.foodAtHomeSpendingDict['avgAnnualSpendingOnFrozenPrepared']
                    ? formatCurrency(this.props.foodAtHomeSpendingDict['avgAnnualSpendingOnFrozenPrepared'])
                    : '?'}{' '}
                  of that on frozen foods.
                </p>
                <p>
                  In the current climate, this might be an opportunity to expand your delivery service or grow your
                  virtual brand.
                </p>
              </span>
            )}
          </span>
        )}
      </Segment>
    );
  }
}

export default RestaurantSpendingSegment;
