import React, { Component } from 'react';
import { Header as BaseHeader, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { formatCurrency } from '../../helpers';
import { YearlySales } from '../../types';

interface Props {
  color: SemanticCOLORS;
  yearlySalesDict: YearlySales;
}

class YearlySalesSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">Projection of Top Line Sales</BaseHeader>
        <p>
          {this.props.yearlySalesDict.error ? (
            this.props.yearlySalesDict.error
          ) : (
            <span>
              Based on your check average, seats, hours of operation, and activity of nearby businesses, we anticipate
              you will have <b>{formatCurrency(this.props.yearlySalesDict.yearlySales!)}</b> in sales per year.
            </span>
          )}
        </p>
      </Segment>
    );
  }
}

export default YearlySalesSegment;
