import React from 'react';
import { Form, Header as BaseHeader } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { Affiliation } from '../../data/categories';
import { useField } from 'formik';

const Header = styled(BaseHeader)`
  &&&& {
    margin: 50px 0 20px;
  }
`;

const options: Readonly<Record<Affiliation, string>> = {
  restaurant_group_corporation: 'Restaurant Group or Corporation',
  celebrity_known_chef: 'Celebrity or Known Chef',
  celebrity_known_bartender: 'Celebrity or Known Bartender',
  opened_0_concepts: 'Opened 0 Concepts',
  opened_1_2_concepts: 'Opened 1-2 Concepts',
  opened_3_4_concepts: 'Opened 3-4 Concepts',
  opened_5_concepts: 'Opened 5+ Concepts',
  famous_known_architect_designer: 'Famous or Known Architect or Designer',
  own_10_year_concept_legacy: 'Own a 10 Year+ Concept/Legacy',
  none: 'None of the Above',
};

interface Props {
  loc: string;
}

const AffiliationsQuestion = ({ loc }: Props) => {
  const [field, , helpers] = useField(loc);
  return (
    <>
      <Header as="h3">Are you affiliated with anything that might drive business?</Header>
      <Form.Group grouped>
        {Object.keys(options).map((option) => (
          <Form.Field key={options[option as Affiliation]}>
            <Form.Radio
              label={options[option as Affiliation]}
              value={option}
              checked={field.value === option}
              onChange={(_, { value }) => {
                helpers.setValue(value);
                helpers.setTouched(true, false);
              }}
            />
          </Form.Field>
        ))}
      </Form.Group>
    </>
  );
};

export default AffiliationsQuestion;
