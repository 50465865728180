import React from 'react';
import { Container } from 'semantic-ui-react';
import SideMenu from './SideMenu';

interface ChildProps {
  [key: string]: any;
}

interface ParentProps {
  // This is coming from SideMenu props, maybe refactor, these need to be coupled
  activeItem?:
    | 'navigator'
    | 'concepts'
    | 'markets'
    | 'reports'
    | 'users'
    | 'settings'
    | 'explore'
    | 'comparison'
    | 'study areas'
    | 'site reports';
  // withBackButton?: boolean this may be included, maybe
}

const withSideMenu = (ChildComponent: React.FC<any>, pProps: ParentProps) => {
  return (props: ChildProps) => {
    const { activeItem } = pProps;
    return (
      <>
        <SideMenu activeItem={activeItem} />
        <Container fluid style={{ paddingLeft: '100px' }}>
          <ChildComponent {...props} />
        </Container>
      </>
    );
  };
};

export default withSideMenu;
