import React, { useRef, useState } from 'react';
import { Grid, Menu, Ref } from 'semantic-ui-react';
import { Waypoint } from 'react-waypoint';
import { useNavigate } from 'react-router';

interface Props {
  title: string;
  children: any;
  noPad?: boolean;
  withLogout?: boolean;
}

const ScrollSpyGrid: React.FC<Props> = ({ children, title, noPad, withLogout }: Props) => {
  const contextRef = useRef<any>();
  const scrollRefs = useRef(new Array());
  const [activeItem, setActiveItem] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const scrollSpyItems = React.Children.toArray(children)
    .filter((child: any) => 'scrollspyname' in child.props)
    .map((child: any) => child.props.scrollspyname);

  return (
    <>
      <Menu
        compact
        secondary
        pointing
        fixed="top"
        style={{
          marginLeft: noPad ? '0px' : '100px',
          zIndex: 1001,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
          backgroundColor: 'rgba(255,255,255,.7)',
          backdropFilter: 'blur(5px)',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {scrollSpyItems.map((item, index) => (
          <Menu.Item
            key={item}
            active={activeItem == item}
            content={item}
            onClick={() => {
              scrollRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }}
          />
        ))}
        {withLogout && (
          <Menu.Menu position="right">
            <Menu.Item
              content="Logout"
              onClick={() => {
                navigate('logout');
              }}
            />
          </Menu.Menu>
        )}
      </Menu>
      <Ref innerRef={contextRef}>
        <Grid style={{ margin: 0 }}>
          <Grid.Column width="16" style={{ paddingLeft: 0 }}>
            <Grid padded>
              {React.Children.map(children, (child) => {
                const wrappedChild = (
                  <div style={{ width: '100%' }} ref={(element) => scrollRefs.current.push(element)}>
                    {child}
                  </div>
                );
                return 'scrollspyname' in child.props ? (
                  <>
                    <Waypoint
                      topOffset="-50%"
                      bottomOffset="50%"
                      onEnter={() => {
                        setActiveItem(child.props.scrollspyname);
                      }}
                    ></Waypoint>
                    {wrappedChild}
                  </>
                ) : (
                  child
                );
              })}
            </Grid>
          </Grid.Column>
        </Grid>
      </Ref>
    </>
  );
};

export default ScrollSpyGrid;
