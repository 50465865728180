import React, { Component } from 'react';
import { Header, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { formatNumber } from '../../helpers';
import { Concept, PopulationDensity } from '../../types';

interface Props {
  color: SemanticCOLORS;
  concept: Concept;
  populationDensityDict: PopulationDensity;
  populationDensityProjection: number;
}

class PopulationDensitySegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <Header as="h3">Population Density</Header>
        <p>
          Population density is crucial when deciding where to plant a concept. If the density is low, typically
          neighborhood restaurants struggle, but Destination restaurants can thrive.
        </p>
        <p>
          {this.props.populationDensityDict ? (
            this.props.populationDensityDict['error'] ? (
              this.props.populationDensityDict['error']
            ) : (
              <span>
                {' '}
                The density is
                <b>
                  {' '}
                  {this.props.populationDensityDict['densityPerSquareMile']
                    ? formatNumber(this.props.populationDensityDict['densityPerSquareMile'])
                    : '?'}{' '}
                </b>
                people per square mile. The projected density in 2025 will be
                <b> {formatNumber(this.props.populationDensityProjection)} </b>
                people per square mile.{' '}
                {this.props.concept.is_near_office_park_or_tourism &&
                  'Note that this data might not be accurate ' +
                    'because the concept is in an office park or a tourist destination and not a residential ' +
                    'area.'}
              </span>
            )
          ) : (
            'Population density not found.'
          )}
        </p>
      </Segment>
    );
  }
}

export default PopulationDensitySegment;
