import Auth from 'borne_ui/src/auth/Auth';
import { DayOfWeek } from 'borne_ui/src/types';
import React from 'react';
import { Dropdown, Grid } from 'semantic-ui-react';
import { BorneFlexContainer } from '../../generic/BorneComponents';
import { StudyAreaODMap } from '../../maps/StudyAreaODMap';

interface DataProps {
  studyAreaId: string;
  auth: Auth;
  dayOfWeek: DayOfWeek;
  setDayOfWeek: (dow: DayOfWeek) => void;
}

export const StudyAreasTripSourcesV2: React.FC<DataProps> = ({
  studyAreaId,
  auth,
  dayOfWeek,
  setDayOfWeek,
}: DataProps) => {
  return (
    <Grid.Row>
      <BorneFlexContainer className="tw-h-[35rem] tw-items-start tw-mt-8">
        <div className="tw-mr-4 tw-h-fit tw-w-[30%] tw-sitcky tw-top-0">
          <p>
            Understanding where your patrons live, work, go to school, and come from helps to better understand how
            positioning your location fits into their daily trips. View trip sources for an average{' '}
            <Dropdown
              inline
              value={dayOfWeek}
              options={[
                { key: 1, text: 'weekday', value: 'thu' },
                { key: 2, text: 'weekend', value: 'sat' },
              ]}
              onChange={(e, data) => setDayOfWeek((data.value as DayOfWeek) ?? 'thu')}
            />
          </p>
        </div>
        <div className="tw-w-[70%] tw-h-full">
          <StudyAreaODMap studyAreaId={studyAreaId} auth={auth} />
          <div className="tw-h-20 tw-w-full"> </div>
        </div>
      </BorneFlexContainer>
    </Grid.Row>
  );
};
