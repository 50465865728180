import React, { SyntheticEvent } from 'react';
import { Dropdown, Header as BaseHeader, DropdownProps } from 'semantic-ui-react';
import { useField } from 'formik';
import styled from '@emotion/styled';
import Auth from '../../auth/Auth';
import { useConcepts } from '../../api/requests';

const MIN_CHARACTERS = 5;
const Header = styled(BaseHeader)`
  &&&& {
    margin: 20px 0 20px;
  }
`;

interface Props {
  loc: string;
  auth: Auth;
}

const ConceptQuestion = ({ loc, auth }: Props) => {
  const concepts = useConcepts(auth);

  const [field, _, helpers] = useField({ name: loc });

  const updateConcept = (conceptId?: number): void => {
    helpers.setValue(conceptId);
    helpers.setTouched(true, false);
  };

  const options = (concepts.data || [])
    .sort((a: any, b: any) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    .map((concept: any) => ({
      key: concept.id,
      value: concept.id,
      text: concept.name,
    }));

  return (
    <>
      <Header as="h3">Please choose a concept for your restaurant:</Header>
      <Dropdown
        placeholder="Concept"
        fluid
        search
        selection
        minCharacters={MIN_CHARACTERS}
        value={field.value}
        options={options}
        onChange={(_: SyntheticEvent, { value }: DropdownProps) => updateConcept(value as number)}
      />
    </>
  );
};

export default ConceptQuestion;
