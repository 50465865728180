import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { Concept } from '../../types';

const ConceptQuestionCell = styled(Table.Cell)`
  font-weight: bold;
  background: #f9fafb;
`;

interface Props {
  concept: Concept;
}

class ConceptTopInfoSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Table celled structured>
        <Table.Body>
          <Table.Row>
            <ConceptQuestionCell>Address</ConceptQuestionCell>
            <Table.Cell colSpan="8">{this.props.concept.address}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Cuisine</ConceptQuestionCell>
            <Table.Cell colSpan="8">{this.props.concept.cuisine}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <ConceptQuestionCell>Categories</ConceptQuestionCell>
            <Table.Cell colSpan="8">{(this.props.concept.categories || []).join(', ')}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export default ConceptTopInfoSegment;
