import React from 'react';
import { useFormikContext, Formik, Form as FormikForm } from 'formik';
import { Button, Dimmer, Loader, Message, Modal, ModalProps, Form as SemanticForm, Header } from 'semantic-ui-react';
import * as Yup from 'yup';
import Auth from '../../../auth/Auth';
import { createSiteReport } from '../../../api/requests';
import AddressQuestion from '../../questions/AddressQuestion';
import ConceptQuestion from '../../questions/ConceptQuestion';
import OrderModeQuestion from '../../questions/OrderModeQuestion';
import RestaurantSquareFootageQuestion from '../../questions/RestaurantSquareFootageQuestion';
import SharedWithEnterpriseQuestion from '../../questions/SharedWithEnterpriseQuestion';
import { useNavigate } from 'react-router';

interface Props extends ModalProps {
  auth: Auth;
  handleClose: () => void;
}

const Submit = ({ disabled }: { disabled: boolean }) => {
  const { submitForm } = useFormikContext();

  return (
    <Button onClick={() => submitForm()} positive disabled={disabled}>
      Run Report
    </Button>
  );
};

const NewSiteReportModal = (props: Props) => {
  const { auth, handleClose, ...modalProps } = props;

  const createSiteReportRequest = createSiteReport(auth);

  function internalHandleClose() {
    createSiteReportRequest.reset();
    handleClose();
  }

  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        order_modes: [],
      }}
      validateOnMount
      validationSchema={Yup.object({
        address: Yup.string().required('Please enter the address!'),
        concept_id: Yup.number().required('Missing concept.'),
        restaurant_square_footage: Yup.number().required('Missing restaurant square footage.'),
        order_modes: Yup.array().min(1, 'Please choose at least one way patrons order.'),
      })}
      onSubmit={async (values) => {
        try {
          await createSiteReportRequest.mutateAsync(values);
        } catch (error) {
          // Don't close the modal, because an error occurred.
          return;
        }
        navigate('/sitereports');

        internalHandleClose();
      }}
    >
      {(bag) => (
        <Modal dimmer="blurring" {...modalProps}>
          <Modal.Header>
            <Header as="h1">Create Site Report</Header>
            <SharedWithEnterpriseQuestion loc="shared_w_enterprise" />
          </Modal.Header>
          <Modal.Content scrolling>
            {createSiteReportRequest.isLoading ? (
              <>
                <Dimmer inverted active>
                  <Loader inverted content="Running report..." />
                </Dimmer>
              </>
            ) : createSiteReportRequest.isError ? (
              <Message negative>{(createSiteReportRequest.error as any).response.data.detail}</Message>
            ) : (
              <SemanticForm as={FormikForm} onSubmit={bag.handleSubmit}>
                <AddressQuestion loc="address" />
                <ConceptQuestion loc="concept_id" auth={auth} />
                <br />
                <RestaurantSquareFootageQuestion loc="restaurant_square_footage" />
                <OrderModeQuestion loc="order_modes" label="How can your patrons order from you?" />
              </SemanticForm>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={internalHandleClose} secondary disabled={createSiteReportRequest.isLoading}>
              Cancel
            </Button>
            {!createSiteReportRequest.isError && (
              <Submit disabled={!bag.isValid || createSiteReportRequest.isLoading} />
            )}
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

export default NewSiteReportModal;
