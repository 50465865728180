import React, { SyntheticEvent } from 'react';
import { ServiceStyle, AlcoholService, OrderModes, CompetitiveRadiusDistances, BorneReportInput } from '.';
import { borneBlue, BorneSmallButton, ReportPage } from '../../generic/BorneComponents';
import {
  Dropdown,
  Icon,
  Input,
  Label,
  Loader,
  Progress,
  Search,
  SearchProps,
  SearchResultData,
  SearchResultProps,
} from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import Auth from 'borne_ui/src/auth/Auth';
import SentryFeedbackModal from '../../modals/SentryFeedbackModal';
import {
  useConcepts,
  useCreateBorneReport,
  useCurrentUser,
  useFetchBorneReport,
  useFetchBorneReportLimit,
} from '../../../api/requests';
import HoursOfOperations, { checkTimeValidity } from './HoursOfOperations';
import { ScheduleReducerAction, ScheduleReducerState, scheduleReducer } from './reducers';
import { Map } from 'immutable';
import { IconMenu2 } from '@tabler/icons-react';
import { useNavigate } from 'react-router';
import BorneUiMobileMenu from './Menu';
import { Concept } from '../../concept/concept';

interface Props {
  auth: Auth;
}

const INITIAL_SELECTION: BorneReportInput = {
  address: undefined,
  serviceStyle: 'casual_dining',
  squareFootage: 1000,
  alcoholService: 'full_bar',
  orderModes: [],
  radiusInMiles: 0.5,
  hops: Map(),
};

const BorneReportRequestPage = ({ auth }: Props) => {
  const [submissionSuccess, setSubmissionSuccess] = React.useState(false);
  const [checkError, setCheckError] = React.useState<boolean>(false);
  const [selections, setSelections] = React.useState<BorneReportInput>(INITIAL_SELECTION);
  const [address, setAddress] = React.useState('');
  const [autoComplete, setAutoComplete] = React.useState([]);
  const [session] = React.useState(uuidv4());
  const [sentryFeedbackModalOpen, setSentryFeedbackModalOpen] = React.useState<boolean>(false);
  const [opened, setOpened] = React.useState(false);
  const [selectedConcept, setSelectedConcept] = React.useState<Concept>();
  const navigate = useNavigate();

  const conceptAlctoBRAlc = Map({ none: 'no_alc', wine_or_beer: 'beer_wine', full_bar: 'full_bar' });

  const [hoursOfOps, dispatchHoursOfOps] = React.useReducer<React.Reducer<ScheduleReducerState, ScheduleReducerAction>>(
    scheduleReducer,
    { scheduleState: Map() }
  );

  const borneReportLimit = useFetchBorneReportLimit(auth);

  const hoursOfOpsValid = Array.from(hoursOfOps.scheduleState.values())
    .flatMap((schedule) => Array.from(schedule.values()))
    .every((shift) => checkTimeValidity(shift[0], shift[1]));

  const safeForSubmission =
    selections.address &&
    selections.serviceStyle &&
    selections.alcoholService &&
    selections.orderModes.length > 0 &&
    hoursOfOpsValid &&
    borneReportLimit.isSuccess &&
    borneReportLimit.data.remaining > 0;

  const borneReports = useFetchBorneReport(auth);
  const createBorneReport = useCreateBorneReport(auth);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);

  const runAutoComplete = async (address: string) => {
    try {
      const response = await auth.authenticatedAPICall({
        method: 'GET',
        url: `/api/addresses?address=${encodeURIComponent(address)}&session=${session}`,
      });

      setAutoComplete(
        response.data.map((datum: any) => {
          return { title: datum.description };
        })
      );
    } catch (ex) {
      console.error(`Auto complete call failed with: ${ex}`);
    }
  };
  const meResponse = useCurrentUser(auth, { refetchOnWindowFocus: false });
  const [sharedEmail, setSharedEmail] = React.useState<string | undefined>(meResponse.data?.email);

  const updateAddress = (address: string): void => {
    setAddress(address);
    if (address != undefined && address.length >= 3) {
      runAutoComplete(address);
    }
  };

  const handleSubmit = async () => {
    if (safeForSubmission) {
      await createBorneReport.mutateAsync({
        address: selections.address,
        squareFootage: selections.squareFootage,
        orderModes: selections.orderModes,
        alcoholService: selections.alcoholService,
        serviceStyle: selections.serviceStyle,
        hops: hoursOfOps.scheduleState,
        radiusInMiles: selections.radiusInMiles,
        conceptId: selectedConcept ? (selectedConcept?.id as unknown as number) : undefined,
        emailAddress: sharedEmail,
      });
      setAddress('');
      setSelections(INITIAL_SELECTION);
      scrollRef.current && scrollRef.current.scrollTo(0, 0);
      setSubmissionSuccess(true);
    } else {
      setCheckError(true);
    }
  };

  const concept = useConcepts(auth, {
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <BorneUiMobileMenu opened={opened} setOpened={setOpened} />
      <ReportPage className="lg:tw-flex-row md:tw-flex-row tw-flex-col" ref={scrollRef}>
        <div className="tw-mb-4 tw-w-full tw-rounded-lg lg:tw-hidden md:tw-hidden">
          <IconMenu2 size={40} color="#2a4e55" onClick={() => setOpened(true)} />
        </div>
        <div className="tw-w-full  tw-mb-4 lg:tw-hidden md:tw-hidden tw-rounded-lg tw-bg-white tw-shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
          {borneReportLimit.isSuccess ? (
            <div>
              <Progress
                className="tw-h-4"
                color="green"
                indicating
                percent={(1 - borneReportLimit.data.remaining / borneReportLimit.data.limit) * 100}
                attached="top"
                size="large"
              />
              <div className="tw-flex tw-items-center tw-p-6">
                <div className="tw-items-end">
                  <img className="tw-mr-2" width={70} height={70} src={'/static/B_Icon.png'} />
                </div>
                <h2 className="tw-mt-0 tw-ml-2">
                  {borneReportLimit.data.remaining}/{borneReportLimit.data.limit}{' '}
                  {borneReportLimit.data.remaining == 1 ? 'report' : 'reports'} remaining
                </h2>
              </div>
            </div>
          ) : (
            <div className="tw-pt-3">
              <Loader active inline="centered" />
            </div>
          )}
        </div>
        <div className="tw-w-[100%] lg:tw-w-[70%] md:tw-w-[70%] tw-rounded-lg tw-bg-white tw-p-6 tw-shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
          <div className="lg:tw-flex md:tw-flex tw-mb-6 tw-hidden tw-items-end">
            <img className="tw-mr-2" width={70} height={70} src={'/static/B_Icon.png'} />
            <h1 className="tw-text-4xl tw-mb-1">
              {submissionSuccess ? (
                'Next Steps'
              ) : (
                <>
                  Run a Borne Report<sup>®</sup>
                </>
              )}
            </h1>
          </div>
          {borneReportLimit.isLoading || createBorneReport.isLoading ? (
            <Loader active inline="centered" />
          ) : submissionSuccess ? (
            <>
              Report has been successfully submitted for computation. Once complete a link will be sent to the email
              associated with the Borne Report account as well as added to the shared Google Drive folder.
              <div
                className="tw-mt-4 tw-text-[#0000EE] tw-cursor-pointer tw-underline"
                onClick={() => {
                  setSubmissionSuccess(false);
                }}
              >
                Run another report
              </div>
            </>
          ) : borneReportLimit.isSuccess && borneReportLimit.data.remaining === 0 ? (
            <div className="tw-text-xl">
              You are out of reports for today. More can be run at midnight, Pacific time.
            </div>
          ) : (
            <>
              <div className=" tw-mb-6">
                {meResponse?.data?.settings?.SINGLE_BRAND_REPORT &&
                  concept.isSuccess &&
                  !concept.isFetching &&
                  concept.data && (
                    <div className="tw-flex tw-flex-col tw-mb-4">
                      <div className="tw-mb-1">Concept</div>
                      <Dropdown
                        search
                        className={``}
                        selection
                        value={selectedConcept?.id}
                        onChange={(e: any, { value }: any) => {
                          const changeConcept = concept.data.filter(({ id }) => id === value)[0];
                          setSelectedConcept(changeConcept);
                          setSelections({
                            ...selections,
                            serviceStyle:
                              changeConcept.restaurant_category === 'casual_dining'
                                ? changeConcept.restaurant_category
                                : 'fast_casual',
                            alcoholService: conceptAlctoBRAlc.get(changeConcept.alcohol_service!) as AlcoholService,
                          });
                        }}
                        options={concept.data
                          .map((item: any) => ({ text: item.name, value: item.id }))
                          .sort((a, b) => a.text.localeCompare(b.text))}
                      />
                    </div>
                  )}
                {meResponse?.data?.settings.ENABLE_DELIVERY_EMAIL && (
                  <>
                    <div>Shared Email</div>
                    <Input
                      className="tw-mb-1 tw-w-full"
                      placeholder="Emails"
                      value={sharedEmail}
                      onChange={(_: SyntheticEvent, { value }: SearchProps) => setSharedEmail(value as string)}
                    />
                  </>
                )}
                <div className="tw-mb-1">Address</div>
                <Search
                  placeholder="Address"
                  className={`searchAddress ${checkError && !selections.address ? 'errored' : ''}`}
                  fluid
                  minCharacters={3}
                  value={address}
                  onSearchChange={(_: SyntheticEvent, { value }: SearchProps) => updateAddress(value as string)}
                  onResultSelect={(_: SyntheticEvent, { result }: SearchResultData) => {
                    updateAddress(result.title);
                    setSelections({ ...selections, address: result.title });
                  }}
                  resultRenderer={({ title }: SearchResultProps) => <div>{title}</div>}
                  results={autoComplete}
                  onKeyPress={(event: any) => {
                    if (event.charCode === 13) {
                      event.target.blur();
                    }
                  }}
                />
                {checkError && !selections.address && (
                  <span className="tw-text-red-600 tw-text-xs">Select address from autofill options</span>
                )}
              </div>
              <div className="tw-grid tw-flex-col tw-gap-2 lg:tw-grid-cols-2 tw-grid-cols-1 ">
                <div className="tw-flex tw-flex-col tw-mb-4">
                  <div className="tw-mb-1">Service Type</div>
                  <Dropdown
                    search
                    className={`${checkError && selections.serviceStyle == undefined ? 'errored' : ''}`}
                    selection
                    value={selections.serviceStyle}
                    onChange={(e: any, { value }) =>
                      setSelections({ ...selections, serviceStyle: value as ServiceStyle })
                    }
                    options={[
                      { text: 'Full Service', value: 'casual_dining' },
                      { text: 'Counter Service', value: 'fast_casual' },
                    ]}
                  />
                  {checkError && selections.serviceStyle == undefined && (
                    <span className="tw-text-red-600 tw-text-xs">Select service type</span>
                  )}
                </div>
                <div className="tw-flex tw-flex-col tw-mb-4">
                  <div className="tw-mb-1">Order modes</div>
                  <Dropdown
                    search
                    className={`${checkError && selections.orderModes.length <= 0 ? 'errored' : ''}`}
                    selection
                    multiple
                    value={selections.orderModes}
                    onChange={(e: any, { value }) =>
                      setSelections({ ...selections, orderModes: value as Array<OrderModes> })
                    }
                    options={[
                      { text: 'Delivery', value: 'delivery' },
                      { text: 'Dine In', value: 'dine_in' },
                      { text: 'Drive-Thru', value: 'drive_thru' },
                      { text: 'Takeout', value: 'take_out' },
                      { text: 'Third Party', value: 'third_party' },
                    ]}
                  />
                  {checkError && selections.orderModes.length <= 0 && (
                    <span className="tw-text-red-600 tw-text-xs">Select order modes</span>
                  )}
                </div>
                <div className="tw-flex tw-flex-col tw-mb-4">
                  <div className="tw-mb-1">Square Footage</div>
                  <input
                    defaultValue={0}
                    type="number"
                    className={`${checkError && selections.squareFootage < 0 ? 'errored' : ''}`}
                    value={selections.squareFootage}
                    onChange={(e) => {
                      setSelections({ ...selections, squareFootage: e.target.value as unknown as number });
                    }}
                  />
                  {checkError && selections.squareFootage < 0 && (
                    <span className="tw-text-red-600 tw-text-xs">Square footage can be no less than 0</span>
                  )}
                </div>
                <div className="tw-flex tw-flex-col tw-mb-4">
                  <div className="tw-mb-1">Alcohol Service</div>
                  <Dropdown
                    search
                    value={selections.alcoholService}
                    selection
                    className={`${checkError && selections.alcoholService == undefined ? 'errored' : ''}`}
                    onChange={(e: any, { value }) =>
                      setSelections({ ...selections, alcoholService: value as AlcoholService })
                    }
                    options={[
                      { text: 'Full Bar', value: 'full_bar' },
                      { text: 'Wine or Beer', value: 'beer_wine' },
                      { text: 'None', value: 'no_alc' },
                    ]}
                  />
                  {checkError && selections.alcoholService == undefined && (
                    <span className="tw-text-red-600 tw-text-xs">Select alcohol service</span>
                  )}
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-mb-4">
                <div className="tw-mb-1">Competitive Radius</div>
                <Dropdown
                  search
                  value={selections.radiusInMiles}
                  selection
                  onChange={(e: any, { value }) =>
                    setSelections({ ...selections, radiusInMiles: value as CompetitiveRadiusDistances })
                  }
                  options={[
                    { text: '0.5 miles', value: 0.5 },
                    { text: '1 mile', value: 1 },
                    { text: '1.5 miles', value: 1.5 },
                    { text: '2 miles', value: 2 },
                    { text: '2.5 miles', value: 2.5 },
                  ]}
                />
              </div>
              <HoursOfOperations hoursOfOps={hoursOfOps} dispatchHoursOfOps={dispatchHoursOfOps} />
              <div className="tw-hidden tw-w-full lg:tw-flex md:tw-flex tw-justify-between  tw-mt-24">
                <BorneSmallButton
                  style={{ backgroundColor: 'rgb(184 217 71)', color: borneBlue }}
                  onClick={async () => {
                    setSentryFeedbackModalOpen(true);
                  }}
                >
                  Help
                </BorneSmallButton>
                <BorneSmallButton
                  active={safeForSubmission}
                  onClick={async () => {
                    await handleSubmit();
                  }}
                >
                  Compute
                </BorneSmallButton>
              </div>
            </>
          )}
        </div>
        <div className=" lg:tw-hidden md:tw-hidden tw-w-full tw-mt-4 tw-rounded-lg tw-bg-white tw-p-6 tw-shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
          <BorneSmallButton
            className="tw-w-full"
            active={safeForSubmission}
            onClick={async () => {
              await handleSubmit();
            }}
          >
            Compute
          </BorneSmallButton>
        </div>
        <div className="lg:tw-flex md:tw-flex tw-flex-col tw-w-[28%] tw-hidden">
          <div className="tw-w-full tw-pb-3 tw-mb-4 tw-rounded-lg tw-bg-white tw-shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
            {borneReportLimit.isSuccess ? (
              <>
                <Progress
                  className="tw-h-4"
                  color="green"
                  indicating
                  percent={(1 - borneReportLimit.data.remaining / borneReportLimit.data.limit) * 100}
                  attached="top"
                  size="large"
                />
                <h2 className="tw-mt-3 tw-mb-0 tw-ml-6">
                  {borneReportLimit.data.remaining} {borneReportLimit.data.remaining == 1 ? 'report' : 'reports'}{' '}
                  remaining
                </h2>
                <span className="tw-ml-6">Out of {borneReportLimit.data.limit} for the day</span>
              </>
            ) : (
              <div className="tw-pt-3">
                <Loader active inline="centered" />
              </div>
            )}
          </div>
          <div className="tw-w-full tw-rounded-lg tw-max-h-[70vh] tw-overflow-y-scroll tw-bg-white tw-shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
            <div className="tw-px-4 tw-pt-6 tw-pb-2 tw-bg-white tw-w-[100%] tw-border-b-[1px] tw-sticky tw-top-0">
              <h3 className="tw-mb-0">Your Reports</h3>
              <span>Select report to see more</span>
            </div>
            <div className="">
              {borneReports.isSuccess ? (
                borneReports.data.map((report: any, i: any) => {
                  return (
                    <div
                      className=" tw-px-6 tw-grid tw-grid-cols-2 tw-py-3 tw-gap-1 tw-cursor-pointer hover:tw-bg-gray-200"
                      onClick={() => navigate(`/reportstatus/${report.id}`)}
                    >
                      <h5 className="tw-mb-1">
                        {report.state_type === 'COMPLETED' ? (
                          <Label className="tw-flex tw-w-min" color="green" horizontal>
                            <Icon className="tw-mr-0 lg:tw-mr-1" name="check" />
                            <span className="tw-hidden lg:tw-flex">Completed</span>
                          </Label>
                        ) : report.state_type === 'PENDING' ? (
                          <Label className="tw-flex sm:tw-pb-4 tw-w-min" color="yellow" horizontal>
                            <span className="tw-hidden lg:tw-flex">Pending</span>
                          </Label>
                        ) : (
                          <Label className="tw-hidden lg:tw-flex tw-w-min" color="grey" horizontal>
                            <span className="tw-hidden lg:tw-flex">Delayed </span>
                          </Label>
                        )}
                      </h5>
                      <div className="tw-justify-self-end tw-text-gray-400">{report.created}</div>
                      <div className="tw-mb-0 tw-pb-0 tw-col-span-2">{report.name}</div>
                    </div>
                  );
                })
              ) : (
                <div className="tw-pt-3">
                  <Loader active inline="centered" />
                </div>
              )}
            </div>
          </div>
        </div>
        <SentryFeedbackModal isOpen={sentryFeedbackModalOpen} setOpen={setSentryFeedbackModalOpen} />
      </ReportPage>
    </>
  );
};

export default BorneReportRequestPage;
