export const gradeColors = new Map<string, string>([
  ['A+', 'green'],
  ['A', 'green'],
  ['A-', 'green'],
  ['B+', 'olive'],
  ['B', 'olive'],
  ['B-', 'olive'],
  ['C+', 'yellow'],
  ['C', 'yellow'],
  ['C-', 'yellow'],
  ['D+', 'orange'],
  ['D', 'orange'],
  ['D-', 'orange'],
  ['F', 'red'],
]);
