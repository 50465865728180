import Auth from '../../../auth/Auth';
import { MapContainer, Pane } from 'react-leaflet';
import React, { useMemo } from 'react';
import { TileLayer } from 'react-leaflet';
import GeoJSON from '../../generic/GeoJSON';
import { Loader } from 'semantic-ui-react';
import { useMarketStudyAreaGeos, useStudyAreaGeo, useSubscribedMarketBounds } from '../../../api/requests';
import { Layer as LeafletLayer } from 'leaflet';
import { Feature } from 'geojson';
import { gradeColors } from '../../../data/gradeColors';
import FeatureTooltip from '../../generic/FeatureTooltip';
import FeaturePopup from '../../generic/FeaturePopup';
import { Link } from 'react-router-dom';
import { StudyAreaMarketGrade } from 'borne_ui/src/types';

interface Props {
  auth: Auth;
  studyAreaId: string;
  currentMarket?: number;
  studyAreaData: any;
}

const StudyAreaSingleComparisonMap: React.FC<Props> = ({ auth, studyAreaId, currentMarket, studyAreaData }: Props) => {
  const marketsRes = useSubscribedMarketBounds(auth);

  // useEffect(() => {
  //   if(selectedConceptId) studyAreaData.refetch()
  // }, [selectedConceptId]);

  const studyAreasRes = useMarketStudyAreaGeos(
    auth,
    marketsRes.isSuccess && currentMarket !== undefined ? marketsRes.data[currentMarket].id : undefined,
    {
      enabled: marketsRes.isSuccess && currentMarket !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const studyAreaGeoRes = useStudyAreaGeo(auth, studyAreaId, {
    refetchOnWindowFocus: false,
  });

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
    ),
    []
  );

  const getStudyAreaTooltip = (feature: Feature, layer: LeafletLayer) => {
    if (feature.properties && feature.properties.name) {
      const safeForGrade =
        studyAreaData.isSuccess && !studyAreaData.isFetching && studyAreasRes.isSuccess && !studyAreasRes.isFetching;

      if (safeForGrade) {
        const gradeResponse = Object.values(studyAreaData.data)[0] as StudyAreaMarketGrade;
        const className = `ui circular label big ${gradeColors.get(gradeResponse!.grade[0])}`;

        return (
          <>
            <FeatureTooltip layer={layer} permanent={true} className={className}>
              {gradeResponse!.score != null ? gradeResponse!.score : gradeResponse!.grade}
            </FeatureTooltip>
          </>
        );
      }
    }
  };

  const getStudyAreaPopup = (feature: Feature, layer: LeafletLayer) => {
    if (feature.properties && feature.properties.name) {
      const safeForGrade =
        studyAreaData.isSuccess && !studyAreaData.isFetching && studyAreasRes.isSuccess && !studyAreasRes.isFetching;

      if (safeForGrade) {
        const gradeResponse = Object.values(studyAreaData.data)[0] as StudyAreaMarketGrade;
        const grade = (
          <>
            <span>
              Concept {gradeResponse!.score != null ? 'score' : 'grade'}:{' '}
              <strong>{gradeResponse!.score != null ? gradeResponse!.score : gradeResponse!.grade}</strong>
            </span>
            <br />
          </>
        );

        return (
          <>
            <FeaturePopup key={feature.id} layer={layer}>
              <h3>{feature.properties.name}</h3>
              {grade}
              <>
                <br />
                We estimate that your concept would rank {gradeResponse.study_area_rank} out of {gradeResponse.count}{' '}
                restaurants in this area.
              </>
              <br />
              <br />
              <Link to={`/studyareas/${feature.id}`}>Go to Detail Page</Link>
            </FeaturePopup>
          </>
        );
      }
    }
  };

  return (
    <>
      {studyAreaGeoRes.isSuccess ? (
        <>
          {Tiles}
          <Pane name="PolygonPane" style={{ zIndex: 406 }}>
            <GeoJSON
              data={studyAreaGeoRes.data}
              style={(_) => ({
                color: '#BFD251',
              })}
            >
              {(feature: Feature, layer: LeafletLayer) => (
                <>
                  {getStudyAreaPopup(feature, layer)}
                  {getStudyAreaTooltip(feature, layer)}
                </>
              )}
            </GeoJSON>
          </Pane>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

interface ChildProps {
  [key: string]: any;
}

interface ParentProps {
  height?: string | number;
}

const withMapContext = (ChildComponent: React.FC<any>, pProps: ParentProps) => {
  return (props: ChildProps) => {
    // const { height } = pProps;
    return (
      // Until Leaflet 1.8 is released, with fix to this bug:
      //
      //   https://github.com/Leaflet/Leaflet/issues/7255
      //
      // we need to disable tap.
      //
      <MapContainer
        attributionControl={false}
        style={{
          height: '60vh',
          width: `98%`,
          border: 'gray solid 1px',
          backgroundColor: '#e8e8e8',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        tap={false}
      >
        <ChildComponent {...props} />
      </MapContainer>
    );
  };
};

export const StudyAreaSingleMapComparison = withMapContext(StudyAreaSingleComparisonMap, { height: '100%' });
