import React from 'react';

interface Props {
  active?: boolean;
  size?: number;
}

export const DashboardSvg = ({ active = false, size = 32 }: Props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <path
        fill={active ? '#bccd19' : '#2a4e55'}
        d="M20.07,3.61l1.67,1.26v2.39v7.94v3h3h7.93h2.4l1.26,1.66l-1.27,1.67h-2.4h-7.93h-3v3v7.93v2.39l-1.67,1.26l-1.66-1.26v-2.4
			v-7.93v-3h-3H7.47h-2.4l-1.27-1.67l1.26-1.66h2.4h7.93h3v-3V7.26v-2.4L20.07,3.61 M20.07-0.16l-9.79,7.42h5.13v7.94H7.47v-5.13
			l-7.43,9.79l7.43,9.79v-5.12h7.93v7.93h-5.13l9.79,7.42l9.79-7.42h-5.12v-7.93h7.93v5.12l7.43-9.79l-7.43-9.79v5.13h-7.93V7.26h5.12
			L20.07-0.16L20.07-0.16z"
      />
    </svg>
  );
};
