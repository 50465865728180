import BarChart from '@bornellc/bach/BarChart';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { ChartHeader } from '../../styled/Typography';
import BarChartToggleNormalize from '../../generic/BarChartToggleNormalize';

interface Props {
  data: any;
}

const TripCharacteristicsContents: React.FC<Props> = (props: Props) => {
  const data = props.data.map((record: any) => ({ ...record.selected_sa_summaries[0], name: record.name }));

  return (
    <Grid columns={2} doubling>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Mode of Travel</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="name"
            y={[
              { key: 'mode_private_auto', label: 'Private Auto' },
              { key: 'mode_walking', label: 'Walking' },
              { key: 'mode_biking', label: 'Biking' },
              { key: 'mode_public_transit', label: 'Public Transit' },
              { key: 'mode_carpool', label: 'Carpool' },
            ]}
            data={data}
            dataKey="mode_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Previous Activity</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="name"
            y={[
              { key: 'previous_activity_home', label: 'Home' },
              { key: 'previous_activity_work', label: 'Work' },
              { key: 'previous_activity_shop', label: 'Shop' },
              { key: 'previous_activity_social', label: 'Social' },
              { key: 'previous_activity_eat', label: 'Eat' },
              { key: 'previous_activity_school', label: 'School' },
            ]}
            data={data}
            dataKey="pa_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Distance Traveled</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="name"
            y={[
              { key: 'distance_half_to_1', label: '< 1 mi' },
              { key: 'distance_1_to_2', label: '1 to 2 mi' },
              { key: 'distance_2_to_4', label: '2 to 4 mi' },
              { key: 'distance_4_to_8', label: '4 to 8 mi' },
              { key: 'distance_8_to_16', label: '8 to 16 mi' },
              { key: 'distance_16_plus', label: '16+ mi' },
            ]}
            data={data.map((record: any) => ({
              ...record,
              distance_16_plus:
                record.distance_16_to_32 ?? 0 + record.distance_32_to_64 ?? 0 + record.distance_64_plus ?? 0,
            }))}
            dataKey="distance_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
      <Grid.Column>
        <BarChartToggleNormalize defaultNormalize>
          <ChartHeader as="h3">Duration Traveled</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="name"
            y={[
              { key: 'duration_0_to_5', label: '< 5 min' },
              { key: 'duration_5_to_10', label: '5 to 10 min' },
              { key: 'duration_10_to_20', label: '10 to 20 min' },
              { key: 'duration_20_to_40', label: '20 to 40 min' },
              { key: 'duration_40_plus', label: '40+ min' },
            ]}
            data={data.map((record: any) => ({
              ...record,
              duration_40_plus: record.duration_40_to_80 ?? 0 + record.duration_80_plus ?? 0,
            }))}
            dataKey="duration_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
    </Grid>
  );
};

export default TripCharacteristicsContents;
