import {
  useConcepts,
  useCurrentUser,
  useMarketAmenityGeos,
  useMarketChains,
  useSubscribedMarketBounds,
} from '../../api/requests';
import Auth from '../../auth/Auth';
import { amenityIcons } from '../../data/amenityIcons';
import React from 'react';
import { Icon, Accordion, Checkbox, Container, List, Search, AccordionTitleProps, Image } from 'semantic-ui-react';
import { BorneToggle, SidebarIcon } from './BorneComponents';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from '../redux/testSlice';
import { RootState } from '../redux/store';

interface Props {
  currentMarket?: number;
  auth: Auth;
  sideBarVisibility: boolean;
  setSideBarVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentMarket: (marketIndex: number) => void;
}

interface MenuContainerProps {
  sideBarVisibility: boolean;
}

export const SidebarMenuContainer = styled.div<MenuContainerProps>`
  .ui.large.list {
    font-family: nunito sans;
    font-size: 14px;
  }
  position: relative;
  height: 100%;
  width: 280px;
  position: fixed;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  z-index: 1004;
  background-color: white;
  overflow-x: hidden;
  transform: ${(props) => (props.sideBarVisibility ? 'translateX(7vw)' : 'translateX(-100%)')};
  font-family: nunito sans;
  transition: transform 0.4s ease;
  border-right: ${(props) => (props.sideBarVisibility ? '1px solid lightgrey' : '0')};
`;

const SideBarMenu: React.FC<Props> = ({
  setCurrentMarket,
  currentMarket,
  auth,
  sideBarVisibility,
  setSideBarVisibility,
}: Props) => {
  const dispatch = useDispatch();

  const currentAccordionFold = useSelector((state: RootState) => state.main.currentFold);
  const showBorneLocations = useSelector((state: RootState) => state.main.borneLocation);
  const showUserLocations = useSelector((state: RootState) => state.main.userLocation);
  const brandSearchKey = useSelector((state: RootState) => state.main.brandSearchKey);
  const visibleLayersSet = useSelector((state: RootState) => state.main.visibleMapLayers);
  const showBorneStudyAreas = useSelector((state: RootState) => state.main.borneStudyAreas);
  const showCustomStudyAreas = useSelector((state: RootState) => state.main.customStudyAreas);
  const selectedBrands = useSelector((state: RootState) => state.main.brands);
  const amenityCategorySet = useSelector((state: RootState) => state.main.amenitiyCategorySet);
  const selectedConcept = useSelector((state: RootState) => state.main.conceptId);
  const showConcpets = useSelector((state: RootState) => state.main.showConceptId);

  const changeCurrentFold = (x: number) => {
    dispatch(mainActions.changeCurrentFold(x));
  };
  const changeBorneLocationShow = (x: boolean) => {
    dispatch(mainActions.changeBorneLocationShow(x));
  };
  const changeUserLocationShow = (x: boolean) => {
    dispatch(mainActions.changeUserLocationShow(x));
  };
  const changeBrandKey = (x: string) => {
    dispatch(mainActions.changeBrandKey(x));
  };
  const dispatchVisibleLayerSelection = (x: { set: Set<string>; type: string; value: string }) => {
    dispatch(mainActions.toggleVisibleMapLayer(x));
  };
  const setShowBorneStudyAreas = (x: boolean) => {
    dispatch(mainActions.changeBorneStudyAreas(x));
  };
  const setShowCustomStudyAreas = (x: boolean) => {
    dispatch(mainActions.toggleShowCustomStudyAreas(x));
  };
  const dispatchBrandSelection = (x: string) => {
    dispatch(mainActions.changeSelectedBrand(x));
  };
  const dispatchAmenityCategorySelection = (x: { set: Set<string>; type: string; value: string }) => {
    dispatch(mainActions.changeAmenityCategorySelection(x));
  };
  const selectConceptId = (x: number) => {
    dispatch(mainActions.selectConceptId(x));
  };
  const toggleConcepts = (x: boolean) => {
    dispatch(mainActions.toggleConceptId(x));
  };

  const meResponse = useCurrentUser(auth, { refetchOnWindowFocus: false });

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, titleProps: AccordionTitleProps) => {
    const { index } = titleProps;
    const newIndex = currentAccordionFold === index ? -1 : (index as number);
    changeCurrentFold(newIndex);
  };

  const marketsRes = useSubscribedMarketBounds(auth, {
    enabled: meResponse.isSuccess,
    onSuccess: (data) => {
      if (meResponse.data!.settings?.preferredMarket && currentMarket === undefined) {
        const indexedMarkets = data
          .map((market, index) => ({ index: index, ...market }))
          .filter((market: any) => meResponse.data!.settings.preferredMarket === market.id);
        setCurrentMarket(indexedMarkets.length != 0 ? indexedMarkets[0]['index'] : 0);
      } else if (currentMarket === undefined) {
        setCurrentMarket(0);
      }
    },
    refetchOnWindowFocus: false,
  });

  const marketBrands = useMarketChains(
    auth,
    marketsRes.isSuccess && currentMarket !== undefined ? marketsRes.data[currentMarket].id : undefined,
    {
      enabled: marketsRes.isSuccess && currentMarket !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const amenitiesRes = useMarketAmenityGeos(
    auth,
    marketsRes.isSuccess && currentMarket !== undefined ? marketsRes.data[currentMarket].id : undefined,
    {
      enabled: marketsRes.isSuccess && currentMarket !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const concepts = useConcepts(auth);

  return (
    <>
      <SidebarMenuContainer sideBarVisibility={sideBarVisibility}>
        <SidebarIcon>
          <Icon name="close" onClick={() => setSideBarVisibility(false)} />
        </SidebarIcon>
        <div>
          <Accordion fluid>
            {/* Locations */}
            <Accordion.Title
              onClick={handleClick}
              index={0}
              style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '3rem', alignItems: 'center' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ margin: '0' }}>Location</h3>
                {currentAccordionFold != 0 ? <Icon name="dropdown" /> : <Icon name="caret down" />}
              </div>
              <BorneToggle
                shape="slim"
                color="success"
                checked={visibleLayersSet.has('locations')}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  dispatchVisibleLayerSelection({
                    set: visibleLayersSet,
                    type: 'toggle',
                    value: 'locations',
                  });
                }}
              />
            </Accordion.Title>
            <Accordion.Content active={currentAccordionFold === 0}>
              <List size="large" verticalAlign="middle">
                <List.Item
                  style={{
                    minHeight: '1.61em',
                    borderLeft: `10px solid #db2828`,
                    paddingLeft: '10px',
                    paddingTop: '0.214286em',
                    paddingBottom: '0',
                  }}
                >
                  <List.Content floated="left">My Locations</List.Content>
                  <List.Content
                    style={{
                      marginRight: '5px',
                    }}
                    floated="left"
                  >
                    <Checkbox
                      radio
                      checked={showUserLocations}
                      onClick={() => changeUserLocationShow(!showUserLocations)}
                    />
                  </List.Content>
                </List.Item>
                <List.Item
                  style={{
                    minHeight: '1.61em',
                    marginTop: '.5em',
                    borderLeft: `10px solid #0c7c59`,
                    paddingLeft: '10px',
                  }}
                >
                  <List.Content floated="left">All Locations</List.Content>
                  <List.Content
                    style={{
                      marginRight: '5px',
                    }}
                    floated="left"
                  >
                    <Checkbox
                      radio
                      checked={showBorneLocations}
                      onClick={() => changeBorneLocationShow(!showBorneLocations)}
                    />
                  </List.Content>
                </List.Item>
              </List>
            </Accordion.Content>

            {/* Study Areas */}
            <Accordion.Title
              onClick={handleClick}
              index={2}
              style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '3rem', alignItems: 'center' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ margin: '0' }}>Study Areas</h3>
                {currentAccordionFold != 2 ? <Icon name="dropdown" /> : <Icon name="caret down" />}
              </div>
              <BorneToggle
                shape="slim"
                checked={visibleLayersSet.has('studyAreas')}
                onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                  e.stopPropagation();
                  dispatchVisibleLayerSelection({
                    set: visibleLayersSet,
                    type: 'toggle',
                    value: 'studyAreas',
                  });
                }}
              />
            </Accordion.Title>
            <Accordion.Content active={currentAccordionFold === 2}>
              <List size="large" verticalAlign="middle">
                <List.Item
                  style={{
                    minHeight: '1.61em',
                    borderLeft: `10px solid #bccd19`,
                    paddingLeft: '10px',
                    paddingTop: '0.214286em',
                    paddingBottom: '0',
                  }}
                >
                  <List.Content floated="left">Borne Study Areas</List.Content>
                  <List.Content
                    style={{
                      marginRight: '5px',
                    }}
                    floated="right"
                  >
                    <Checkbox
                      checked={showBorneStudyAreas}
                      onClick={() => setShowBorneStudyAreas(!showBorneStudyAreas)}
                    />
                  </List.Content>
                </List.Item>
                <List.Item
                  style={{
                    minHeight: '1.61em',
                    marginTop: '.5em',
                    borderLeft: `10px solid #7cb0ba`,
                    paddingLeft: '10px',
                  }}
                >
                  <List.Content floated="left">Custom Study Areas</List.Content>
                  <List.Content
                    style={{
                      marginRight: '5px',
                    }}
                    floated="right"
                  >
                    <Checkbox
                      checked={showCustomStudyAreas}
                      onClick={() => setShowCustomStudyAreas(!showCustomStudyAreas)}
                    />
                  </List.Content>
                </List.Item>
              </List>
            </Accordion.Content>

            {/* Brands */}
            <Accordion.Title
              onClick={handleClick}
              index={1}
              style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '3rem', alignItems: 'center' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ margin: '0' }}>Brands</h3>
                {currentAccordionFold != 1 ? <Icon name="dropdown" /> : <Icon name="caret down" />}
              </div>
              <BorneToggle
                shape="slim"
                color="success"
                checked={visibleLayersSet.has('brands')}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  dispatchVisibleLayerSelection({
                    set: visibleLayersSet,
                    type: 'toggle',
                    value: 'brands',
                  });
                }}
              />
            </Accordion.Title>
            <Accordion.Content active={currentAccordionFold === 1}>
              <Search
                input={{
                  fluid: true,
                }}
                style={{
                  paddingBottom: '1em',
                }}
                open={false}
                placeholder="Search..."
                onSearchChange={(_, { value }) => changeBrandKey(value ?? '')}
              />
              <Container fluid style={{ height: '30vh', overflowY: 'scroll' }}>
                <List verticalAlign="middle">
                  {marketBrands.isSuccess &&
                    !marketBrands.isFetching &&
                    selectedBrands &&
                    marketBrands.data
                      .filter(
                        (brand) => !!brand.name && brand.name.toLowerCase()?.includes(brandSearchKey.toLowerCase())
                      )
                      .map((marketBrand: any) => (
                        <List.Item key={marketBrand.name}>
                          <List.Content
                            style={{
                              marginTop: '6px',
                              marginLeft: '5px',
                            }}
                            floated="left"
                          >
                            <Checkbox
                              radio
                              checked={selectedBrands.some((o) => o.name == marketBrand.name)}
                              onClick={() => {
                                dispatchBrandSelection(marketBrand.name);
                              }}
                            />
                          </List.Content>
                          <List.Content floated="left">
                            <Image
                              src={`/static/icons/${marketBrand.cid}.png`}
                              style={{
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                                marginRight: '8px',
                              }}
                              height="32px"
                              width="32px"
                              circular
                              inline
                            />
                            {marketBrand.name} ({marketBrand.total})
                          </List.Content>
                        </List.Item>
                      ))}
                </List>
              </Container>
            </Accordion.Content>
            <Accordion.Title
              onClick={handleClick}
              index={5}
              style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '3rem', alignItems: 'center' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ margin: '0' }}>Amenities</h3>
                {currentAccordionFold != 5 ? <Icon name="dropdown" /> : <Icon name="caret down" />}
              </div>
              <BorneToggle
                shape="slim"
                color="success"
                checked={visibleLayersSet.has('amenities')}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  dispatchVisibleLayerSelection({
                    set: visibleLayersSet,
                    type: 'toggle',
                    value: 'amenities',
                  });
                }}
              />
            </Accordion.Title>
            <Accordion.Content active={currentAccordionFold === 5}>
              {amenitiesRes.isSuccess && (
                <List size="large" verticalAlign="middle">
                  {Array.from(new Set(amenitiesRes.data.map(({ category }) => category)).values())
                    .sort()
                    .map((category, index) => (
                      <List.Item key={index}>
                        <List.Content
                          style={{
                            marginLeft: '5px',
                            marginTop: '.25em',
                          }}
                          floated="left"
                          verticalAlign="middle"
                        >
                          <Checkbox
                            radio
                            checked={amenityCategorySet.has(category)}
                            onClick={() =>
                              dispatchAmenityCategorySelection({
                                set: amenityCategorySet,
                                type: 'toggle',
                                value: category,
                              })
                            }
                          />
                        </List.Content>
                        <List.Content verticalAlign="middle" floated="left" style={{ textTransform: 'capitalize' }}>
                          <Icon
                            name={amenityIcons.get(category)}
                            color="grey"
                            circular
                            style={{ marginRight: '10px' }}
                            inverted
                            size="small"
                          />
                          {category}
                        </List.Content>
                      </List.Item>
                    ))}
                </List>
              )}
            </Accordion.Content>
            <Accordion.Title
              onClick={handleClick}
              index={6}
              style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '3rem', alignItems: 'center' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ margin: '0' }}>Concept</h3>
                {currentAccordionFold != 6 ? <Icon name="dropdown" /> : <Icon name="caret down" />}
              </div>
              <BorneToggle
                shape="slim"
                color="success"
                checked={showConcpets}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  if (showConcpets) {
                    toggleConcepts(false);
                    selectConceptId(0);
                  } else {
                    toggleConcepts(true);
                  }
                }}
              />
            </Accordion.Title>
            <Accordion.Content active={currentAccordionFold === 6}>
              {concepts.isSuccess && (
                <List size="large" verticalAlign="middle">
                  {concepts.data
                    .sort((a: any, b: any) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                    .map((co: any, index: number) => (
                      <List.Item key={index}>
                        <List.Content
                          style={{
                            marginLeft: '5px',
                            marginTop: '.25em',
                          }}
                          floated="left"
                          verticalAlign="middle"
                        >
                          <Checkbox
                            radio
                            checked={co.id == selectedConcept}
                            onClick={() => {
                              if (showConcpets) {
                                if (co.id == selectedConcept) {
                                  selectConceptId(0);
                                  return;
                                }
                                selectConceptId(co.id);
                              }
                            }}
                          />
                        </List.Content>
                        <List.Content>{co.name}</List.Content>
                      </List.Item>
                    ))}
                </List>
              )}
            </Accordion.Content>
          </Accordion>
        </div>
      </SidebarMenuContainer>
    </>
  );
};

export default SideBarMenu;
