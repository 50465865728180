import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import Auth from './Auth';

interface Props {
  auth: Auth;
  children: ReactNode;
}

const Authenticated = ({ auth, children }: Props) =>
  auth.hasCredentials() ? <>{children}</> : <Navigate to="/" state={{ path: location.pathname }} />;

export default Authenticated;
