import { SemanticICONS } from 'semantic-ui-react';

export const amenityIcons = new Map<string, SemanticICONS>([
  ['airport', 'plane'],
  ['casino', 'money bill alternate outline'],
  ['hospital', 'h square'],
  ['university', 'university'],
  ['mall', 'shopping bag'],
  ['grocery','shopping basket']
]);
