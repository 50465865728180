import { Icon } from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import React, { Component } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Header as BaseHeader, Segment, Table } from 'semantic-ui-react';
import { ClosedRestaurant } from '../../types';

// The default bundler configuration will not work with the way leaflet loads
// its images, so doing this avoids seeing broken image icons.
const markerIcon = new Icon({ iconUrl: markerIconPng });

interface Props {
  conceptLatLng: [number, number];
  restaurants: ClosedRestaurant[];
  restaurantStats: Record<string, number>;
}

class NearbyClosedRestaurantsSegment extends Component<Props> {
  overlayClickEvent = (obj: any) => {
    const { overlay, map } = obj;
    overlay?.customInfoWindow.open(map, overlay);
  };

  render(): JSX.Element {
    const cells = Object.keys(this.props.restaurantStats).map((key: string) => {
      return (
        <Table.Row key={key}>
          <Table.Cell>{key}</Table.Cell>
          <Table.Cell>{this.props.restaurantStats[key]}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Segment>
        <BaseHeader as="h3">Nearby Closed Restaurants</BaseHeader>
        <p>
          Below are all of the restaurants which have closed in the last ~12 years within 5km of the desired location.
        </p>
        <MapContainer center={this.props.conceptLatLng} zoom={13} style={{ height: 475 }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={this.props.conceptLatLng} icon={markerIcon} />
          {this.props.restaurants.map((rest) => (
            <Marker position={[rest.lat, rest.long]} icon={markerIcon}>
              <Popup>
                {rest.dateClosed && <div id="siteNotice">Date closed: {rest.dateClosed}</div>}
                <h3 id="firstHeading" className="firstHeading">
                  {rest.name}
                </h3>
                <div id="bodyContent">
                  <p>
                    <ul>
                      <li>Address: {rest.address}</li>
                      <li>City: {rest.city}</li>
                      <li>Cuisines: {rest.cuisines.join(', ')}</li>
                      <li>Distance from chosen location: {rest.distance} km</li>
                    </ul>
                  </p>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
        <div
          style={{ maxHeight: '270px', overflowY: 'auto', marginTop: '1em', marginBottom: '0.5em' }}
          className="tw-max-h-[270px] tw-overflow-y-auto tw-mt-4 tw-mb-2"
        >
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Cuisine</Table.HeaderCell>
                <Table.HeaderCell>Number of Closed Restaurants</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{cells}</Table.Body>
          </Table>
        </div>
      </Segment>
    );
  }
}

export default NearbyClosedRestaurantsSegment;
