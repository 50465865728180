import React from 'react';
import { Header, Grid, Placeholder } from 'semantic-ui-react';

export const SearchEngineDescription: React.FC<{}> = () => {
  return (
    <>
      <Header> Search Engine Optimization</Header>
      <p>
        Etiam congue, arcu et dictum bibendum, dolor ex varius metus, et ornare ipsum turpis sed nisl. Nulla vulputate
        dignissim elit, et volutpat purus molestie eget. Vestibulum at lacus vel orci imperdiet auctor. Nam fermentum
        fringilla odio sit amet laoreet.
      </p>
    </>
  );
};

export const SearchEngineData: React.FC<{}> = () => {
  return (
    <>
      <Grid columns="equal" relaxed padded>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header>SEO keywords</Header>
            <p>
              The Y-axis represents search interes relative to the other terms and the history of any given term. A
              value of 100 is the peak popularity for any given term. A value of 50 means that the term is hald as
              popular, while a value of 0 means there was not enough data to properly analyse this term
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header>Takeout Trend</Header>
            <p>The graphs shows how popular takeout has been (in this area) over the last 12 months</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Placeholder>
              <Placeholder.Image />
            </Placeholder>
          </Grid.Column>
          <Grid.Column>
            <Placeholder>
              <Placeholder.Image />
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
