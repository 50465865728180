import React, { useEffect, useRef, useState } from 'react';
import { Feature } from 'geojson';
import { GeoJSON as LeafletGeoJSON, LatLngBoundsExpression, Layer as LeafletLayer } from 'leaflet';
import { GeoJSON as BaseGeoJSON, GeoJSONProps, useMap } from 'react-leaflet';

interface FeatureLayer extends LeafletLayer {
  feature: Feature;
}

interface Props extends Omit<GeoJSONProps, 'children'> {
  children?: (feature: Feature, layer: LeafletLayer) => JSX.Element;
}

const GeoJSON = ({ children, ...props }: Props) => {
  const map = useMap();
  const leafletGeoJSON = useRef<LeafletGeoJSON>(null);

  const [featureLayers, setFeatureLayers] = useState<FeatureLayer[]>([]);

  // This effect updates the Leaflet layer with new geometry, which does not
  // happen automatically, per:
  //
  // https://github.com/PaulLeCam/react-leaflet/issues/332
  //
  useEffect(() => {
    const geoJson = leafletGeoJSON.current!;
    geoJson.clearLayers().addData(props.data);
    setFeatureLayers(geoJson.getLayers() as FeatureLayer[]);

    if (props.data.bbox) {
      map.fitBounds([
        props.data.bbox.slice(0, 2).reverse(),
        props.data.bbox.slice(2).reverse(),
      ] as LatLngBoundsExpression);
      map.invalidateSize();
    }
  }, [props.data]);

  return (
    <BaseGeoJSON ref={leafletGeoJSON} {...props}>
      {children && featureLayers.map((featureLayer) => children(featureLayer.feature, featureLayer))}
    </BaseGeoJSON>
  );
};

export default GeoJSON;
