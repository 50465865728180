import { Header, Description } from '../../styled/Typography';
import React, { useMemo } from 'react';
import { Segment } from 'semantic-ui-react';
import Auth from '../../../auth/Auth';
import { useConcepts, useRevenues, useStudyAreaGrade } from '../../../api/requests';
import { gradeColors } from '../../../data/gradeColors';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

function formatCurrency(value: number) {
  return Math.round(value).toLocaleString('en-US');
}

export const LocationDetailBrandMatrixDescription: React.FC = ({}) => {
  return (
    <>
      <Header as="h2">Brand Matrix</Header>
      <Description as="p">
        The brand matrix compares your brands on both our fit score and estimated standalone revenue.
      </Description>
    </>
  );
};

interface DataProps {
  enterpriseLocationId: number;
  studyAreaId: string;
  auth: Auth;
}

export const LocationDetailBrandMatrixData: React.FC<DataProps> = ({ enterpriseLocationId, studyAreaId, auth }) => {
  const concepts = useConcepts(auth);

  const conceptGrades = useStudyAreaGrade(
    auth,
    studyAreaId,
    concepts.data?.map((concept: any) => concept.id) ?? [],
    true,
    {
      enabled: concepts.isSuccess,
    }
  );

  const revenues = useRevenues(auth, enterpriseLocationId);

  const getGradeNode = (data: any) => {
    const grade = data.value.conceptGrade!;

    return <div className={`ui circular label big ${gradeColors.get(grade)}`}>{data.value.conceptScore || grade}</div>;
  };

  const gradeComparator = (a: any, b: any) => (a.conceptScore ? a.conceptScore - b.conceptScore : a.grade - b.grade);

  const getRevenueNode = (data: any) => `$${formatCurrency(data.value)}`;

  const rowData = (concepts.data || []).map((concept: any) => ({
    name: concept.name,
    ...(conceptGrades.isSuccess ? { score: conceptGrades.data[concept.id] } : {}),
    ...(revenues.isSuccess ? { revenue: revenues.data[concept.id] } : {}),
  }));

  const columnDefs = [
    { field: 'name' },
    ...(conceptGrades.isSuccess
      ? [{ field: 'score', cellRenderer: getGradeNode, autoHeight: true, comparator: gradeComparator }]
      : []),
    ...(revenues.isSuccess ? [{ field: 'revenue', cellRenderer: getRevenueNode }] : []),
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  return (
    <>
      {conceptGrades.isSuccess || revenues.isSuccess ? (
        <div className="ag-theme-alpine">
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            domLayout="autoHeight"
          />
        </div>
      ) : (
        <Segment placeholder />
      )}
    </>
  );
};
