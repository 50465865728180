import React from 'react';

interface PopulationPyramidEntry {
  label: string;
  left: number;
  right: number;
}

interface PopulationPyramidProps {
  entries: PopulationPyramidEntry[];
  leftColor: string;
  rightColor: string;
}

const PopulationPyramid: React.FC<PopulationPyramidProps> = (props: PopulationPyramidProps) => {
  const { entries, leftColor, rightColor } = props;

  const maxValue = Math.max(...entries.flatMap((entry) => [entry.left, entry.right]));

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: 1 }}>
        {entries.map((entry) => {
          const width = (entry.left / maxValue) * 100;
          return (
            <div style={{ background: leftColor, margin: `0.2em ${100 - width}%`, width: `${width}%` }}>&nbsp;</div>
          );
        })}
      </div>
      <div style={{ padding: '0 0.4em' }}>
        {entries.map((entry) => (
          <div style={{ margin: '0.2em 0', textAlign: 'center' }}>{entry.label}</div>
        ))}
      </div>
      <div style={{ flexGrow: 1 }}>
        {entries.map((entry) => (
          <div style={{ background: rightColor, margin: '0.2em 0', width: `${(entry.right / maxValue) * 100}%` }}>
            &nbsp;
          </div>
        ))}
      </div>
    </div>
  );
};

export type { PopulationPyramidEntry };

export default PopulationPyramid;
