import BarChart from '@bornellc/bach/BarChart';
import LineChart from '@bornellc/bach/LineChart';
import React from 'react';
import { Divider, Grid } from 'semantic-ui-react';
import { ChartHeader } from '../../styled/Typography';
import BarChartToggleNormalize from '../../generic/BarChartToggleNormalize';

interface Props {
  data: any;
}

function convertMilitaryTimeToLabel(hour: number) {
  if (hour === 0) {
    return '12AM';
  }

  if (hour === 12) {
    return 'Noon';
  }

  return `${hour % 12}${hour > 12 ? 'PM' : 'AM'}`;
}

const MarketRestaurantsContents: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const posData = data.map((d: any) => d.marketpossummary);
  const arrivalData = data.map((d: any) => d.market_arrival_times);

  arrivalData.forEach((marketArrivalData: any) => {
    marketArrivalData.sort((x: any, y: any) => {
      return y.hour - x.hour;
    });
  });

  return (
    <Grid columns={2} doubling>
      <Grid.Column>
        <ChartHeader as="h3">Number of Locations</ChartHeader>
        <Divider fitted />
        <BarChart
          grid="v"
          orientation="h"
          x="market"
          y="number_of_locations"
          data={data}
          dataKey="locations_bar_chart"
          role="chart"
        />
      </Grid.Column>
      <Grid.Column>
        <BarChartToggleNormalize>
          <ChartHeader as="h3">Locations by Price</ChartHeader>
          <BarChart
            grid="v"
            orientation="h"
            x="market"
            y={[
              { key: 'price_cheap', label: '$' },
              { key: 'price_moderate', label: '$$' },
              { key: 'price_expensive', label: '$$$' },
              { key: 'price_very_expensive', label: '$$$$' },
            ]}
            data={data}
            dataKey="price_level_bar_chart"
            role="chart"
          />
        </BarChartToggleNormalize>
      </Grid.Column>
      <Grid.Column>
        <ChartHeader as="h3">Check Average</ChartHeader>
        <Divider fitted />
        <BarChart
          grid="v"
          orientation="h"
          x="market"
          y="check_average"
          data={posData}
          dataKey="check_average_bar_chart"
          role="chart"
          valueLabelFormatter={(value) => `$${value}`}
        />
      </Grid.Column>
      <Grid.Column>
        <ChartHeader as="h3">Check Breakdown</ChartHeader>
        <Divider fitted />
        <BarChart
          grid="v"
          orientation="h"
          normalize
          x="market"
          y={[
            { key: 'check_average_0_to_5', label: '< $5' },
            { key: 'check_average_5_to_12', label: '$5 to 12' },
            { key: 'check_average_12_to_20', label: '$12 to 20' },
            { key: 'check_average_20_to_40', label: '$20 to 40' },
            { key: 'check_average_over_40', label: '$40+' },
          ]}
          data={posData}
          dataKey="check_total_bar_chart"
          role="chart"
        />
      </Grid.Column>
      <Grid.Column>
        <ChartHeader as="h3">Order Mode</ChartHeader>
        <Divider fitted />
        <BarChart
          grid="v"
          orientation="h"
          normalize
          x="market"
          y={[
            { key: 'dine_in', label: 'Dine-in' },
            { key: 'delivery', label: 'Delivery' },
            { key: 'third_party', label: 'Third Party' },
            { key: 'take_out', label: 'Takeout' },
          ]}
          data={posData}
          dataKey="order_mode_bar_chart"
          role="chart"
        />
      </Grid.Column>
      <Grid.Row columns={1}>
        <Grid.Column>
          <ChartHeader as="h3">Arrival Time</ChartHeader>
          <Divider fitted />
          <Grid columns={2} style={{ marginTop: 25 }}>
            {data.map((market: any) => (
              <Grid.Row style={{ padding: 0 }}>
                <Grid.Column textAlign="right" width={3}>
                  <span style={{ lineHeight: '200px', fontFamily: 'Nunito Sans' }}>{market.market}</span>
                </Grid.Column>
                <Grid.Column width={13}>
                  <LineChart
                    data={market.market_arrival_times}
                    dataKey="volume"
                    x="hour"
                    y="volume"
                    orientation="h"
                    height={150}
                    isArea={true}
                    isCurve={true}
                    margin={{
                      top: 0,
                      bottom: 30,
                      left: 100,
                      right: 0,
                    }}
                    axes={['bottom']}
                    xLabelFormatter={convertMilitaryTimeToLabel}
                  />
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default MarketRestaurantsContents;
