import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, DropdownItemProps, Form as SemanticForm } from 'semantic-ui-react';
import { Field, Formik, Form as FormikForm, useField } from 'formik';
import * as Yup from 'yup';
import StructuredAddressQuestion from '../questions/StructuredAddressQuestion';
import { communityTypes } from '../../data/categories';
import { ReportConcept } from '../concept/concept';
import { useCreateReportRequest, useUpdateReportRequest } from '../../api/requests';
import FormPage from './FormPage';
import PriceDisclaimer from './PriceDisclaimer';

const Dropdown = ({ options, ...props }: any) => {
  const [field, meta, helpers] = useField(props);

  function toDropdownOption(value: string): DropdownItemProps {
    return {
      text: value,
      value,
    };
  }

  function isString(option: any): option is string {
    return typeof option === 'string';
  }

  return (
    <SemanticForm.Dropdown
      onChange={(_, v) => {
        helpers.setValue(v.value);
        helpers.setTouched(true, false);
      }}
      options={options.map((option: any) => (isString(option) ? toDropdownOption(option) : option))}
      value={field.value}
      error={meta.touched && !!meta.error}
      placeholder={meta.touched && meta.error}
      {...props}
    />
  );
};

const YesNoRadioGroup = (props: any) => {
  const [field, , helpers] = useField(props);

  return (
    <div className="tw-flex">
      <label className="tw-flex-none tw-mr-4">
        <input
          type="radio"
          name={props.name}
          className="tw-mr-2"
          checked={field.value}
          onChange={() => {
            helpers.setValue(true);
            helpers.setTouched(true, false);
          }}
        />
        Yes
      </label>
      <label className="tw-flex-none">
        <input
          type="radio"
          name={props.name}
          className="tw-mr-2"
          checked={!field.value}
          onChange={() => {
            helpers.setValue(false);
            helpers.setTouched(true, false);
          }}
        />
        No
      </label>
    </div>
  );
};

const LocationForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { concept: initialConceptValues }: { concept: ReportConcept } = {
    concept: {
      street_address: state?.reportRequest?.street_address ?? '',
      city: state?.reportRequest?.city ?? '',
      state: state?.reportRequest?.state ?? '',
      postal_code: state?.reportRequest?.postal_code ?? '',
      community_type: state?.reportRequest?.community_type ?? '',
      restaurant_square_footage: state?.reportRequest?.restaurant_square_footage ?? '',
      has_parking_lot: state?.reportRequest?.has_parking_lot ?? false,
    },
  };

  const createReportRequest = useCreateReportRequest();
  const updateReportRequest = useUpdateReportRequest();

  return (
    <FormPage currentStep="location">
      <div className="tw-flex">
        <div className="tw-w-[40%]">
          <header className="tw-text-3xl tw-text-borneblue-darkest tw-mb-4">Location Information</header>
          <PriceDisclaimer />
          <div className="tw-text-bornegray-darkest">Describe the details of the location.</div>
        </div>
        <div className="tw-w-[60%] tw-pl-[3em] tw-pr-[6em]">
          <Formik
            initialValues={initialConceptValues}
            validationSchema={Yup.object({
              street_address: Yup.string().required('Please enter an address'),
              city: Yup.string().required('Please enter a city'),
              state: Yup.string().required('Please select a state'),
              postal_code: Yup.string().required('Please enter a postal code'),
              // TODO: When https://github.com/jquense/yup/issues/1298 is released,
              // replace [...communityTypes] with communityTypes.
              community_type: Yup.string()
                .oneOf([...communityTypes])
                .required('Please select a community'),
              restaurant_square_footage: Yup.string().required('Please enter the restaurant square footage'),
              has_parking_lot: Yup.boolean(),
            })}
            onSubmit={async (values) => {
              /*
          values = {
            street_address: '716 Farringdon Lane',
            city: 'Burlingame',
            state: 'California',
            postal_code: '94010',
            community_type: 'suburban',
            restaurant_square_footage: '1234',
            has_parking_lot: false
          };
          */
              values.address = `${values.street_address}, ${values.city}, ${values.state}, ${values.postal_code}`;

              let reportRequest;
              if (state?.reportRequest?.id) {
                reportRequest = await updateReportRequest.mutateAsync({
                  reportRequestId: state.reportRequest.id,
                  reportRequest: values,
                });
              } else {
                reportRequest = await createReportRequest.mutateAsync(values);
              }

              // Copy over the client-only fields onto the report request.
              // tni: I don't like how the client and server representations are diverging
              // and leading to this sort of manipulation. I took some shorcuts here.
              reportRequest.street_address = values.street_address;
              reportRequest.city = values.city;
              reportRequest.state = values.state;
              reportRequest.postal_code = values.postal_code;

              // This supports keeping the form filled in when pressing back from next page.
              navigate(location.pathname, { replace: true, state: { reportRequest } });

              navigate('/report/concept', { state: { reportRequest } });
            }}
          >
            {(formikBag) => (
              <SemanticForm as={FormikForm} onSubmit={formikBag.handleSubmit}>
                <br />
                <StructuredAddressQuestion />
                <div className="tw-flex tw-gap-2 tw-mt-8 tw-mb-2">
                  <div className="tw-flex-1">
                    <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                      Neighborhood type
                    </label>
                    <Dropdown
                      name="community_type"
                      fluid
                      selection
                      options={[
                        { text: 'Rural', value: 'rural' },
                        { text: 'Suburban', value: 'suburban' },
                        { text: 'Urban', value: 'urban' },
                      ]}
                    />
                  </div>
                  <div className="tw-flex-1">
                    <label className="tw-block tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                      Square footage
                    </label>
                    <Field name="restaurant_square_footage">
                      {({ field, meta }: { field: any; meta: any }) => (
                        <SemanticForm.Input
                          {...field}
                          error={meta.touched && !!meta.error}
                          placeholder={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="tw-grid tw-grid-cols-6 tw-mt-8">
                  <div className="tw-col-span-5 tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
                    Does your restaurant have a parking lot?
                  </div>
                  <div className="tw-text-right tw-self-center">
                    <YesNoRadioGroup name="has_parking_lot" />
                  </div>
                </div>
                <Button
                  className="tw-bg-borneblue-darkest tw-uppercase tw-text-bornegreen-dark tw-font-normal tw-mt-16"
                  type="submit"
                  primary
                >
                  Enter Concept Info →
                </Button>
                <br />
                <br />
                <br />
              </SemanticForm>
            )}
          </Formik>
        </div>
      </div>
    </FormPage>
  );
};

export default LocationForm;
