import { Bar, BarChart, CartesianGrid, Label, Tooltip, XAxis, YAxis } from 'recharts';
import React, { useState } from 'react';
import { Container, Divider, Grid, Icon, Popup } from 'semantic-ui-react';
import { BorneFlexContainer } from '../../generic/BorneComponents';

interface DataProps {
  marketPotentialQueryResponse: any;
}

interface MarketPotentialDatum {
  description: string;
  Total: number;
}

function buildSeries(data: { [key: string]: number }): MarketPotentialDatum[] {
  return Object.entries(data)
    .filter(([_, value]) => value)
    .map(([key, value]) => {
      const description = key.slice('2022 '.length);

      return {
        description,
        Total: value,
      };
    });
}

type MarketCategory = 'alcohol' | 'beverage' | 'food' | 'preferences' | 'shopping';

export const StudyAreasMarketPotentialV2: React.FC<DataProps> = ({ marketPotentialQueryResponse }: DataProps) => {
  const [marketCategory, setMarketCategory] = useState<MarketCategory>();

  function renderBarChart(data: { [key: string]: number }) {
    const series = buildSeries(data);
    return (
      <div style={{ maxHeight: '800px', overflowY: 'auto' }}>
        <Grid attached="top" textAlign="center" columns="equal" padded="horizontally">
          <Grid.Row>
            <Grid.Column>
              {marketPotentialQueryResponse.isSuccess && (
                <BarChart
                  layout="vertical"
                  width={1000}
                  height={series.length * 32}
                  data={series}
                  margin={{
                    top: 30,
                    bottom: 15,
                  }}
                >
                  <Tooltip />
                  <CartesianGrid strokeDasharray="4" horizontal={false} />
                  <XAxis type="number" orientation="top">
                    <Label value="Expected number of consumers" offset={10} position="top" />
                  </XAxis>
                  <YAxis dataKey="description" type="category" width={400} />
                  <Bar dataKey="Total" fill="#9ED2D0" />
                </BarChart>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  return (
    <Grid.Row>
      <BorneFlexContainer className="tw-items-start tw-pt-8">
        <div className="tw-w-[30%] tw-sticky tw-top-0 tw-pt-8">
          <p>
            Explore what products and services consumers in this study area desire. This is determined from surveying
            consumers in the study area.
          </p>
        </div>
        <div className="tw-w-[70%} tw-h-full tw-pb-8">
          <Container textAlign="center" fluid>
            <Popup
              trigger={
                <Icon
                  bordered
                  link
                  style={{ color: marketCategory === 'alcohol' ? '#1B1C1B' : '#bccd19' }}
                  name="cocktail"
                  size="huge"
                  onClick={() => setMarketCategory('alcohol')}
                />
              }
              content="Alcohol"
              position="top center"
            />
            <Popup
              trigger={
                <Icon
                  bordered
                  link
                  style={{ color: marketCategory === 'beverage' ? '#1B1C1B' : '#bccd19' }}
                  name="gulp"
                  size="huge"
                  onClick={() => setMarketCategory('beverage')}
                />
              }
              content="Non-alcoholic Beverages"
              position="top center"
            />
            <Popup
              trigger={
                <Icon
                  bordered
                  link
                  style={{ color: marketCategory === 'food' ? '#1B1C1B' : '#bccd19' }}
                  name="food"
                  size="huge"
                  onClick={() => setMarketCategory('food')}
                />
              }
              content="Food"
              position="top center"
            />
            <Popup
              trigger={
                <Icon
                  bordered
                  link
                  style={{ color: marketCategory === 'shopping' ? '#1B1C1B' : '#bccd19' }}
                  name="shop"
                  size="huge"
                  onClick={() => setMarketCategory('shopping')}
                />
              }
              content="Shopping"
              position="top center"
            />
            <Popup
              trigger={
                <Icon
                  bordered
                  link
                  style={{ color: marketCategory === 'preferences' ? '#1B1C1B' : '#bccd19' }}
                  name="thumbs up"
                  size="huge"
                  onClick={() => setMarketCategory('preferences')}
                />
              }
              content="Preferences"
              position="top center"
            />
            {marketCategory && marketPotentialQueryResponse.data && (
              <>
                <Divider />
                {renderBarChart(marketPotentialQueryResponse.data[marketCategory])}
              </>
            )}
          </Container>
        </div>
      </BorneFlexContainer>
    </Grid.Row>
  );
};
