import React from 'react';
import { CellClickedEvent, ColDef, ColGroupDef } from 'ag-grid-community';
import { DataFormat } from './BorneDisplayTable';
import { AgGridReact } from 'ag-grid-react';

interface TableProps {
  width?: number;
  height?: number;
  rowData: any[];
  rowsPerPage?: number;
  headers: (ColDef | ColGroupDef)[];
  pagination?: boolean;
  onCellClick?: (params: CellClickedEvent) => void;
  filterText?: string;
  fill?: boolean;
  className?: string;
}

const BorneDataTable: React.FC<TableProps> = ({
  rowData,
  rowsPerPage = 5,
  headers,
  width = 150,
  height = 150,
  pagination = false,
  onCellClick,
  filterText = '',
  fill = false,
  className = '',
}: TableProps) => {
  return (
    <>
      <DataFormat
        className={className + ' dtable'}
        style={{ height: fill ? '100%' : height, width: fill ? '100%' : width }}
      >
        <AgGridReact
          animateRows
          rowModelType="clientSide"
          onCellClicked={onCellClick}
          pagination={pagination}
          paginationPageSize={rowsPerPage}
          containerStyle={10}
          rowHeight={40}
          headerHeight={30}
          rowData={rowData}
          columnDefs={headers}
          quickFilterText={filterText}
        />
      </DataFormat>
    </>
  );
};

export default BorneDataTable;
