import React, { Component } from 'react';
import { Header as BaseHeader, Segment } from 'semantic-ui-react';
import { formatCurrency } from '../../helpers';
import { Occupancy } from '../../types';

interface Props {
  occupancyDict: Occupancy;
}

class OccupancyBudgetSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment>
        <BaseHeader as="h3">Occupancy Budget</BaseHeader>
        <p>
          {this.props.occupancyDict.error ? (
            this.props.occupancyDict.error
          ) : (
            <span>
              Your occupancy budget should not surpass{' '}
              <b>{formatCurrency(this.props.occupancyDict.yearlyOccupancyBudget!)}</b> per year (
              <b>{formatCurrency(Math.floor(this.props.occupancyDict.yearlyOccupancyBudget! / 12))}</b> per month) in
              order for you to have a 7% or less occupancy/rent. Do not consider your concept in this property if the
              requested rent is higher.
            </span>
          )}
        </p>
      </Segment>
    );
  }
}

export default OccupancyBudgetSegment;
