import React from 'react';
import { WrappedButtonGroupField } from './FormikUtil';

const options: Readonly<Record<number, string>> = {
  0: 'No',
  1: 'Yes',
};

interface Props {
  loc: string;
}

const OfficeParkTourismQuestion = ({ loc }: Props) => {
  return (
    <WrappedButtonGroupField
      label="Is your restaurant located in an office park or near a tourist destination?"
      options={options}
      name={loc}
    />
  );
};

export default OfficeParkTourismQuestion;
