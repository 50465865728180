import React from 'react';

interface Props {
  active?: boolean;
  size?: number;
}

export const MarketSvg = ({ active = false, size = 32 }: Props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <path
        fill={active ? '#bccd19' : '#2a4e55'}
        d="M36.64,32.9l3.12-4.87L36.64,23H20.83v-1.55h7.94v-9.89h-7.94v-1.55h13.63l3.12-4.86l-3.12-5.04H8.95v9.9h8.88v1.55H3.26
	l-3.12,4.86l3.12,5.03h14.57V23h-6.7v9.9h6.7v4.12H12.1v3h14.03v-3h-5.3V32.9H36.64z M11.95,7.01v-3.9h20.84l1.24,2.01l-1.21,1.89
	H11.95z M4.93,18.45l-1.24-2l1.21-1.89h20.87v3.89H4.93z M14.13,29.9V26h20.84l1.24,2.01L35,29.9H14.13z"
      />
    </svg>
  );
};
