import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Menu, Image, Icon, Divider } from 'semantic-ui-react';
import SentryFeedbackModal from '../modals/SentryFeedbackModal';
import { DashboardSvg } from '../../../../borne_ui/static/icons/icons/dashboard';
import { MarketSvg } from '../../../../borne_ui/static/icons/icons/markets';
import { SiteReportSvg } from '../../../../borne_ui/static/icons/icons/sitereport';
import { StudyAreaSvg } from '../../../../borne_ui/static/icons/icons/studyarea';

interface Props {
  activeItem?: string;
}

const SideMenu: React.FC<Props> = (props: Props) => {
  const { activeItem } = props;
  const navigate = useNavigate();
  const [sentryFeedbackModalOpen, setSentryFeedbackModalOpen] = useState<boolean>(false);

  const menuItems = [
    { name: 'explore', icon: <DashboardSvg active={!(activeItem === 'explore')} />, id: 'dashboard' },
    { name: 'markets', icon: <MarketSvg active={!(activeItem === 'markets')} />, id: 'markets' },
    { name: 'study areas', icon: <StudyAreaSvg active={!(activeItem === 'study areas')} />, id: 'studyareas' },
    { name: 'site reports', icon: <SiteReportSvg active={!(activeItem === 'site reports')} />, id: 'sitereports' },
    { name: 'log out', id: 'sitereports' },
  ];

  const menuElements = () => {
    return menuItems.map((item, indx) => {
      return (
        <Menu.Item
          header={activeItem === item.name}
          key={item.name}
          active={activeItem === item.name}
          onClick={() => navigate(`/${item.name.replace(' ', '')}`)}
        >
          {item.name === 'log out' ? (
            <Icon
              name="log out"
              style={{ color: activeItem === item.name ? '#bccd19' : '#bccd19', paddingTop: '.1em' }}
            />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>{item.icon}</div>
          )}
          <span style={{ textTransform: 'capitalize', fontSize: '8pt' }}>{item.name}</span>
        </Menu.Item>
      );
    });
  };

  return (
    <>
      <Menu
        vertical
        borderless
        icon="labeled"
        fixed="left"
        size="small"
        style={{
          paddingTop: '12vh',
          width: '100px',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
          zIndex: 1000,
        }}
      >
        <Menu.Item>
          <Image src="/static/B_Icon.png" centered size="tiny" style={{ paddingBottom: '1.5vh' }} />
        </Menu.Item>
        <Divider style={{ marginTop: 0, marginBottom: 0 }} />
        <Menu.Item
          style={{
            zIndex: '1001',
            position: 'fixed',
            bottom: '10px',
            left: '10px',
          }}
          active={false}
          borderless
          onClick={() => {
            setSentryFeedbackModalOpen(true);
          }}
          as="span"
        >
          <Icon name="question circle outline" link inverted onClick={() => {}} />
          <span>Help</span>
        </Menu.Item>
        {menuElements()}
      </Menu>
      <SentryFeedbackModal isOpen={sentryFeedbackModalOpen} setOpen={setSentryFeedbackModalOpen} />
    </>
  );
};

export default SideMenu;
