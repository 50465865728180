/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import styled from '@emotion/styled';
import { borneLightBlue, borneBlue } from './BorneComponents';
import { CellClickedEvent, ColDef, ColGroupDef } from 'ag-grid-community';

interface TableProps {
  width?: number;
  height?: number;
  rowData: any[];
  rowsPerPage?: number;
  headers: (ColDef | ColGroupDef)[];
  pagination?: boolean;
  onCellClick?: (params: CellClickedEvent) => void;
  fill?: boolean;
  className?: string;
}

const BorneTable = styled.div`
  width: 100%;
  height: 100%;
  color: ${borneBlue};

  & .ag-row-even {
    background-color: ${borneLightBlue};
  }

  & .ag-cell {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-family: nunito sans;
    font-size: 14px;
  }

  & .ag-header-container {
    padding-left: 20px;
    font-weight: 700;
  }

  & .ag-body-viewport {
    width: max-content;
  }

  & .ag-cell-label-container:hover {
    background-color: ${borneLightBlue};
  }

  & .ag-icon {
    display: none;
  }

  & .ag-body-horizontal-scroll {
    display: none;
  }
  & ::-webkit-scrollbar-track {
    margin-bottom: 4px;
  }
  & .ag-cell::-webkit-scrollbar-thumb {
    display: none;
  }

  & .ag-cell::-webkit-scrollbar-track {
    display: none;
  }

  & .ag-header {
    min-height: 0px !important;
    height: 0px !important;
  }
`;

export const DataFormat = styled.div`
  width: 100%;
  height: 100%;
  color: ${borneBlue};

  & .ag-row-even {
    background-color: ${borneLightBlue};
  }

  & .ag-cell {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-family: nunito sans;
    font-size: 14px;
  }

  & .ag-header-container {
    padding-left: 20px;
    font-weight: 700;
  }

  & .ag-body-viewport {
    width: max-content;
  }

  & .ag-cell-label-container:hover {
    background-color: ${borneLightBlue};
  }

  & .ag-icon {
    display: none;
  }

  & .ag-body-horizontal-scroll {
    display: none;
  }
  & ::-webkit-scrollbar-track {
    margin-bottom: 4px;
  }
  & .ag-cell::-webkit-scrollbar-thumb {
    display: none;
  }

  & .ag-cell::-webkit-scrollbar-track {
    display: none;
  }
`;

export const BorneFoooter = styled.div`
  display: flex;
`;

export const DisplayTable: React.FC<TableProps> = ({
  rowData,
  rowsPerPage = 6,
  headers,
  width = 150,
  height = 150,
  pagination = false,
  onCellClick,
  fill = false,
  className = '',
}: TableProps) => {
  return (
    <BorneTable className={className + ' dTable'} style={{ height: height, width: fill ? '100%' : width }}>
      <AgGridReact
        rowStyle={{ cursor: 'pointer' }}
        className="tw-cursor-pointer"
        rowModelType="clientSide"
        onCellClicked={onCellClick}
        paginationPageSize={rowsPerPage}
        rowHeight={40}
        rowData={rowData}
        columnDefs={headers}
      />
    </BorneTable>
  );
};
