import React from 'react';
import { WrappedInputField } from './FormikUtil';

interface Props {
  loc: string;
}

const RestaurantSquareFootageQuestion = ({ loc }: Props) => {
  return <WrappedInputField label="How many square feet is your restaurant space?" name={loc} />;
};

export default RestaurantSquareFootageQuestion;
