import React from 'react';
import { WrappedDropdownField } from './FormikUtil';

interface Props {
  loc: string;
  label: string;
}

const OrderModeQuestion = ({ loc, label }: Props) => {
  return (
    <WrappedDropdownField
      label={label}
      name={loc}
      options={['Delivery', 'Dine In', 'Drive-Thru', 'Takeout', 'Third Party']}
      placeholder="Select Order Modes"
      fluid
      multiple
      search
      selection
    />
  );
};

export default OrderModeQuestion;
