import React, { Component } from 'react';
import { Header as BaseHeader, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { Concept, Reservation } from '../../types';

function getFlavorText(concept: Concept, points: number): string {
  if (points === 4) {
    if (concept.accepts_reservations) {
      return 'Your restaurant accepts reservations.';
    } else {
      return 'Your restaurant does not need to accept reservations.';
    }
  } else {
    return 'Your restaurant does not accept reservations, but it should based on the clientele it serves.';
  }
}

interface Props {
  color: SemanticCOLORS;
  concept: Concept;
  reservationsDict: Reservation;
}

class ReservationsSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">Reservations</BaseHeader>
        <p>
          Deciding whether or not to take reservations can be difficult. If the restaurant is trying to cater towards
          the impromptu crowd, it may be perfectly viable to not accept reservations.
        </p>
        <p>{getFlavorText(this.props.concept, this.props.reservationsDict['points']!)}</p>
      </Segment>
    );
  }
}

export default ReservationsSegment;
