import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Auth from '../../auth/Auth';

interface Props {
  auth: Auth;
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CreditCard: React.FunctionComponent<Props> = (props: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardSetup('{{CLIENT_SECRET}}', {
      payment_method: {
        card: elements.getElement(CardElement)!,
        billing_details: {
          name: 'Jenny Rosen',
        },
      },
    });

    if (result.error) {
      // Display result.error.message in your UI.
    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
    }
  };

  return (
    <Form onSubmit={handleSubmit} style={{ overflow: 'auto' }}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <Button type="submit" disabled={!stripe} primary floated="right" style={{ marginTop: '15px' }}>
        Save
      </Button>
    </Form>
  );
};

export default CreditCard;
