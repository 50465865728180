import { Header, Description, ChartHeader } from '../../styled/Typography';
import React, { useEffect } from 'react';
import { Grid, Segment, Tab } from 'semantic-ui-react';
import { MarketSingleLocationMap } from '../../maps/MarketSingleLocationMap';
import Auth from '../../../auth/Auth';
import { useMarketCuisines } from '../../../api/requests';
import DataTable from '../../generic/DataTable';
import ObjectPieChart from '../../generic/ObjectPieChart';

export const MarketDetailRestaurantLandscapeDescription: React.FC = ({}) => {
  return (
    <>
      <Header as="h2">Restaurant Landscape</Header>
      <Description as="p">
        The defining feature of a market is the restaurants within its bounds. Here, explore restaurants in the market,
        how their concepts vary, and how your concept(s) may fit into this landscape.
      </Description>
    </>
  );
};

interface DataProps {
  marketRestaurantLandscapeData: any;
  marketId: string;
  auth: Auth;
}

export const MarketDetailRestaurantLandscapeData: React.FC<DataProps> = ({
  marketRestaurantLandscapeData,
  marketId,
  auth,
}) => {
  const marketCuisinesRes = useMarketCuisines(auth, marketId);

  // This is needed in order to reset isFetching flag here for some reason
  // otherwise, the Data Table does not rerender
  useEffect(() => {
    marketCuisinesRes.refetch();
  }, []);

  const panes = [
    {
      menuItem: 'Open',
      render: () => (
        <Tab.Pane style={{ borderStyle: 'hidden' }}>
          <Grid attached="top" textAlign="center" padded="horizontally" columns="equal">
            <Grid.Row>
              <Grid.Column style={{ height: '40vh' }}>
                <MarketSingleLocationMap marketId={marketId} auth={auth} status="open" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ChartHeader as="h3">Price Level</ChartHeader>
                <ObjectPieChart
                  width="100%"
                  height={300}
                  data={marketRestaurantLandscapeData}
                  normalize
                  otherThreshold={0}
                  keys={['price_cheap', 'price_moderate', 'price_expensive', 'price_very_expensive']}
                  labels={['$', '$$', '$$$', '$$$$']}
                  key="open_pie_chart"
                />
              </Grid.Column>
              <Grid.Column>
                {marketCuisinesRes.isSuccess && !marketCuisinesRes.isFetching && (
                  <DataTable
                    data={marketCuisinesRes.data.open.map((row) => ({
                      id: row.cuisine,
                      cuisine: row.cuisine,
                      total: row.total,
                    }))}
                    idColumn="id"
                    scrolling
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      ),
    },
  ];

  if (marketCuisinesRes.isSuccess && marketCuisinesRes.data.closed.length) {
    panes.push({
      menuItem: 'Closed',
      render: () => (
        <Tab.Pane style={{ borderStyle: 'hidden' }}>
          <></>
          <Grid attached="top" textAlign="center" padded="horizontally" columns="equal">
            <Grid.Row>
              <Grid.Column style={{ height: '40vh' }}>
                <MarketSingleLocationMap marketId={marketId} auth={auth} status="closed" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column />
              <Grid.Column>
                {marketCuisinesRes.isSuccess && (
                  <DataTable
                    data={marketCuisinesRes.data.closed.map((row) => ({
                      id: row.cuisine,
                      cuisine: row.cuisine,
                      total: row.total,
                    }))}
                    idColumn="id"
                    scrolling
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      ),
    });
  }

  return (
    <>
      {marketRestaurantLandscapeData ? (
        <Tab panes={panes} menu={{ secondary: true, pointing: true, widths: 'eight' }} renderActiveOnly={true} />
      ) : (
        <Segment placeholder />
      )}
    </>
  );
};
