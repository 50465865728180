import { Map } from 'leaflet';
import { ReactElement } from 'react';
import { useMap } from 'react-leaflet';

export interface MapConsumerProps {
  children: (map: Map) => ReactElement | null;
}

export default function MapConsumer({ children }: MapConsumerProps) {
  return children(useMap());
}
