import React from 'react';

interface Props {
  active?: boolean;
  size?: number;
}

export const SiteReportSvg = ({ active = false, size = 32 }: Props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <path
        fill={active ? '#bccd19' : '#2a4e55'}
        d="M20.09,3.11c6.04,0,10.95,4.91,10.95,10.95c0,6.05-6.77,13.42-10.95,17.19C15.9,27.48,9.14,20.11,9.14,14.07
	C9.14,8.03,14.05,3.11,20.09,3.11 M20.09,0.11c-7.71,0-13.95,6.25-13.95,13.95c0,10.1,13.95,21.12,13.95,21.12
	s13.95-11.02,13.95-21.12C34.05,6.36,27.8,0.11,20.09,0.11L20.09,0.11z M20.09,19.76c-3.65,0-6.61-2.97-6.61-6.61
	s2.97-6.61,6.61-6.61s6.61,2.97,6.61,6.61S23.74,19.76,20.09,19.76z M20.09,9.54c-1.99,0-3.61,1.62-3.61,3.61s1.62,3.61,3.61,3.61
	s3.61-1.62,3.61-3.61S22.08,9.54,20.09,9.54z M29.46,36.75H10.73v3h18.73V36.75z"
      />
    </svg>
  );
};
