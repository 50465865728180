import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Modal } from 'semantic-ui-react';
import { useStripe, useElements, Elements, PaymentElement } from '@stripe/react-stripe-js';
import { AppContext } from '../AppContext';
import { useSetupPurchaseReport } from '../../api/requests';
import Terms from '../report/terms';

const TermsOfServiceModal = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <a className="tw-underline" href="#" onClick={(e) => e.preventDefault()}>
          Borne Service Agreement
        </a>
      }
    >
      <Modal.Header>Borne Service Agreement</Modal.Header>
      <Modal.Content scrolling>{Terms}</Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} primary>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

interface OneTimePaymentFormProps {
  price: string;
  orderNumber: string;
}

const OneTimePaymentForm: React.FunctionComponent<OneTimePaymentFormProps> = ({ price, orderNumber }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.hostname}/report/complete/${orderNumber}`,
      },
    });

    if (result.error) {
      // Display result.error.message in your UI.
    }
  };

  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);

  return (
    <Form onSubmit={handleSubmit}>
      <PaymentElement />
      <Divider section />
      <div className="tw-flex">
        <div className="tw-flex-initial tw-mr-2 tw-self-center">
          <input id="terms" type="checkbox" checked={agreeToTerms} onChange={() => setAgreeToTerms((prev) => !prev)} />
        </div>
        <div className="tw-text-bornegray-darkest tw-text-lg tw-mb-2 tw-uppercase">
          <label htmlFor="terms">
            I have read and agree to the <TermsOfServiceModal />
          </label>
        </div>
      </div>
      <Button
        className="tw-bg-borneblue-darkest tw-uppercase tw-text-bornegreen-dark tw-font-normal tw-mt-16"
        type="submit"
        primary
        disabled={!stripe || !agreeToTerms}
      >
        Pay &amp; Submit →
      </Button>
    </Form>
  );
};

interface WrapperProps extends OneTimePaymentFormProps {
  address: string;
  reportRequestId: number;
}

const Wrapper = ({ address, reportRequestId, ...rest }: WrapperProps) => {
  const setupPurchaseReport = useSetupPurchaseReport();

  useEffect(() => {
    setupPurchaseReport.mutate({ address, reportRequestId });
  }, []);

  return setupPurchaseReport.isSuccess ? (
    <AppContext.Consumer>
      {(appContext) => (
        <Elements stripe={appContext.stripe!} options={{ clientSecret: setupPurchaseReport.data!.data.client_secret }}>
          <OneTimePaymentForm {...rest} />
        </Elements>
      )}
    </AppContext.Consumer>
  ) : setupPurchaseReport.isError ? (
    <>{setupPurchaseReport.error}</>
  ) : (
    <>Loading</>
  );
};

export default Wrapper;
