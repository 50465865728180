import { useEnterpriseLocation, useStudyArea } from '../../../api/requests';
import Auth from '../../../auth/Auth';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Divider, Grid, Header, Icon, List } from 'semantic-ui-react';
import { ReportRowLayout } from '../../generic/ReportLayout';
import ScrollSpyGrid from '../../generic/ScrollSpyGrid';
import { InlineListDescription, InlineListHeader } from '../../styled/Typography';
import {
  LocationDetailBrandMatrixDescription,
  LocationDetailBrandMatrixData,
} from './LocationDetailBrandMatrixSegment';
import {
  StudyAreaDetailRestaurantLandscapeData,
  StudyAreaDetailRestaurantLandscapeDescription,
} from '../StudyAreasDetail/StudyAreaDetailRestaurantLandscapeSegment';
import {
  StudyAreaDetailDemographicData,
  StudyAreaDetailDemographicDescription,
} from '../StudyAreasDetail/StudyAreaDetailDemographicsSegment';
import { DayOfWeek } from '../../../types';
import { StudyAreaDetailTSData, StudyAreaDetailTSDescription } from '../StudyAreasDetail/StudyAreaDetailTS';
import { StudyAreaDetailODDescription, StudyAreaDetailODData } from '../StudyAreasDetail/StudyAreaDetailOD';
import { StudyAreaDetailTrendsDescription, StudyAreaDetailTrendsData } from '../StudyAreasDetail/StudyAreaDetailTrends';
import { StudyAreaDetailPricingInsightsData, StudyAreaDetailPricingInsightsDescription } from './PricingInsights';
import { StudyAreaDetailTerritoryPlanningData, StudyAreaDetailTerritoryPlanningDescription } from './TerritoryPlanning';
import { SingleLocationMap } from '../../maps/LocationSingleMap';
import { formatCurrency, formatNumber } from '../../../helpers';

interface Params {
  id: string;
  studyareaid: string;
}

interface Props {
  auth: Auth;
  ignoreParams?: boolean;
  entLocId?: string;
  studyAreaId?: string;
}

const LocationDetail = ({ auth, ignoreParams, entLocId, studyAreaId }: Props) => {
  var stringId, studyareaid;

  if (ignoreParams && entLocId && studyAreaId) [stringId, studyareaid] = [entLocId, studyAreaId];
  else ({ id: stringId, studyareaid } = useParams<keyof Params>() as Params);

  // TODO: tni: Error handling!!!
  const id = parseInt(stringId as string);

  const [dayOfWeek, setDayOfWeek] = useState<DayOfWeek>('thu');
  const [selectedBrand, setSelectedBrand] = useState<string>();
  const [fetching, setFetching] = useState<boolean>(false);

  const studyAreaRes = useStudyArea(auth, studyareaid, dayOfWeek, {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5minutes
  });

  const entLocRes = useEnterpriseLocation(auth, stringId, {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5minutes
  });

  return (
    <>
      {studyAreaRes.isSuccess && (
        <ScrollSpyGrid title={`${id}`} noPad withLogout>
          <Grid.Row style={{ height: '50vh' }}>
            {!entLocRes.isFetching && entLocRes.isSuccess && (
              <Grid.Column width="6" style={{ paddingTop: '2em', paddingLeft: '3vw' }}>
                <Header as="h1">
                  <Icon color="olive" name="map marker alternate" />
                  {entLocRes.data.name}
                </Header>
                <Divider hidden={true} />
                <List>
                  <List.Item>
                    <InlineListHeader>Address</InlineListHeader>
                    <InlineListDescription>{entLocRes.data.address}</InlineListDescription>
                  </List.Item>
                  <List.Item>
                    <InlineListHeader>Walmart Sales/Yr.</InlineListHeader>
                    <InlineListDescription>{formatCurrency(entLocRes.data.revenue)}</InlineListDescription>
                  </List.Item>
                  <List.Item>
                    <InlineListHeader>Walmart Transactions/Yr.</InlineListHeader>
                    <InlineListDescription>{formatNumber(entLocRes.data.meta.WMTransYr)}</InlineListDescription>
                  </List.Item>
                  <List.Item>
                    <InlineListHeader>Tenant Space (sq. ft)</InlineListHeader>
                    <InlineListDescription>{formatNumber(entLocRes.data.meta.TenantSpaceSF)}</InlineListDescription>
                  </List.Item>
                  <List.Item>
                    <InlineListHeader>People/Day</InlineListHeader>
                    <InlineListDescription>{formatNumber(entLocRes.data.meta.AvgPeopleDay)}</InlineListDescription>
                  </List.Item>
                  <List.Item>
                    <InlineListHeader>Transactions/Day</InlineListHeader>
                    <InlineListDescription>{formatNumber(entLocRes.data.meta.AvgTransPerDay)}</InlineListDescription>
                  </List.Item>
                </List>
              </Grid.Column>
            )}
            <Grid.Column width="3" />
            <Grid.Column width="7">
              {!entLocRes.isFetching && entLocRes.isSuccess && (
                <SingleLocationMap
                  auth={auth}
                  marketId={studyAreaRes.data.market}
                  coords={entLocRes.data.geom.coordinates}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row scrollspyname="Brand Matrix">
            <ReportRowLayout
              DescriptionComponent={<LocationDetailBrandMatrixDescription />}
              DataComponent={
                <LocationDetailBrandMatrixData auth={auth} studyAreaId={studyareaid} enterpriseLocationId={id} />
              }
            />
          </Grid.Row>
          <Grid.Row scrollspyname="Territory Planning">
            <ReportRowLayout
              DescriptionComponent={
                <StudyAreaDetailTerritoryPlanningDescription
                  studyAreaRes={studyAreaRes}
                  auth={auth}
                  setSelectedBrand={setSelectedBrand}
                  selectedBrand={selectedBrand}
                  setFetching={setFetching}
                  isFetching={fetching}
                />
              }
              DataComponent={
                <StudyAreaDetailTerritoryPlanningData
                  studyAreaRes={studyAreaRes}
                  selectedBrand={selectedBrand}
                  auth={auth}
                  setFetching={setFetching}
                  entLocId={stringId}
                />
              }
            />
          </Grid.Row>
          <Grid.Row scrollspyname="Pricing Insights">
            <ReportRowLayout
              DescriptionComponent={<StudyAreaDetailPricingInsightsDescription />}
              DataComponent={<StudyAreaDetailPricingInsightsData studyAreaId={studyareaid} auth={auth} />}
            />
          </Grid.Row>
          <Grid.Row scrollspyname="Restaurant Landscape">
            <ReportRowLayout
              DescriptionComponent={<StudyAreaDetailRestaurantLandscapeDescription />}
              DataComponent={
                <StudyAreaDetailRestaurantLandscapeData
                  studyAreaRestaurantLandscapeData={studyAreaRes.data['selected_sa_summaries']![0]}
                  auth={auth}
                  studyAreaId={studyareaid}
                />
              }
            />
          </Grid.Row>
          <Grid.Row scrollspyname="Trip Takers">
            <ReportRowLayout
              DescriptionComponent={
                <StudyAreaDetailDemographicDescription dayOfWeek={dayOfWeek} setDayOfWeek={setDayOfWeek} />
              }
              DataComponent={
                <StudyAreaDetailDemographicData studyAreaSummaryData={studyAreaRes.data['selected_sa_summaries']![0]} />
              }
            />
          </Grid.Row>
          <Grid.Row scrollspyname="Tapestry Segmentation">
            <ReportRowLayout
              DescriptionComponent={<StudyAreaDetailTSDescription />}
              DataComponent={
                <StudyAreaDetailTSData auth={auth} studyAreaId={studyareaid} marketId={studyAreaRes.data.market} />
              }
            />
          </Grid.Row>
          <Grid.Row scrollspyname="Trends">
            <ReportRowLayout
              DescriptionComponent={<StudyAreaDetailTrendsDescription />}
              DataComponent={<StudyAreaDetailTrendsData auth={auth} studyAreaId={studyareaid} />}
            />
          </Grid.Row>
          <Grid.Row scrollspyname="Trip Sources">
            <ReportRowLayout
              DescriptionComponent={<StudyAreaDetailODDescription dayOfWeek={dayOfWeek} setDayOfWeek={setDayOfWeek} />}
              DataComponent={<StudyAreaDetailODData auth={auth} studyAreaId={studyareaid} dayOfWeek={dayOfWeek} />}
            />
          </Grid.Row>
        </ScrollSpyGrid>
      )}
      ;
    </>
  );
};

export default LocationDetail;
