import {
  useCurrentUser,
  useMarketAmenityGeos,
  useMarketGrade,
  useMarketLocations,
  useMarketStudyAreaGeos,
  useSubscribedMarketBounds,
} from '../../../api/requests';
import L, {
  LatLngBounds,
  Layer as LeafletLayer,
  TileLayer as LeafletTileLayer,
  divIcon,
  LatLngBoundsExpression,
} from 'leaflet';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  CircleMarker,
  Tooltip,
  Pane,
  Marker,
  AttributionControl,
  useMap,
} from 'react-leaflet';
import { Feature } from 'geojson';
import GeoJSON from '../../generic/GeoJSON';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button } from 'semantic-ui-react';
import { US_BOUNDING_BOX } from '../../maps/Constants';
import MapConsumer from '../../maps/MapConsumer';
import { commonActions, mainActions } from '../../redux/testSlice';
import { RootState } from '../../redux/store';
import Auth from '../../../auth/Auth';
import { formatCurrency } from '../../../helpers';
import { amenityIcons } from '../../../data/amenityIcons';
import { FeatureCollection } from 'geojson';
import { gradeColors } from '../../../data/gradeColors';
import BrandsPaneV2 from '../BrandsPaneV2';
import FeaturePopup from '../../generic/FeaturePopup';
import { Link, useNavigate } from 'react-router-dom';
import FeatureTooltip from '../../generic/FeatureTooltip';

interface Props {
  auth: Auth;
  currentMarket?: number;
  setCurrentMarket: (marketIndex: number) => void;
}

interface MapWheeProps {
  newLocation: LatLngBoundsExpression | undefined;
}

const MapWhee = ({ newLocation }: MapWheeProps) => {
  const mapRef = useMap();
  useEffect(() => {
    if (newLocation !== undefined) {
      mapRef.flyToBounds(newLocation);
    }
  }, [newLocation]);
  return <></>;
};

export const DashboardMap: React.FC<Props> = ({ auth, currentMarket, setCurrentMarket }: Props) => {
  const showBorneLocations = useSelector((state: RootState) => state.main.borneLocation);
  const showUserLocations = useSelector((state: RootState) => state.main.userLocation);
  // const brandSearchKey = useSelector((state: RootState) => state.main.brandSearchKey);
  const satelliteEnabled = useSelector((state: RootState) => state.main.satelliteEnabled);
  const visibleLayersSet = useSelector((state: RootState) => state.main.visibleMapLayers);
  const marketOptions = useSelector((state: RootState) => state.common.currentMarkets);
  const selectedConceptId = useSelector((state: RootState) => state.main.conceptId);
  const showCustomStudyAreas = useSelector((state: RootState) => state.main.customStudyAreas);
  const showBorneStudyAreas = useSelector((state: RootState) => state.main.borneStudyAreas);
  const selectedBrands = useSelector((state: RootState) => state.main.brands);
  const amenityCategorySet = useSelector((state: RootState) => state.main.amenitiyCategorySet);
  const [newLocation, setNewLocation] = useState<LatLngBoundsExpression>();
  const considerImpact = false;
  const navigate = useNavigate();

  const isMounted = useRef<boolean>(false);

  const [selectedStudyAreaName, setSelectedStudyAreaName] = useState<string>();

  const dispatch = useDispatch();
  const changeSatelliteEnabled = (x: boolean) => {
    dispatch(mainActions.changeSatelliteEnabled(x));
  };
  const setMarketOptions = (x: string[]) => {
    dispatch(commonActions.setCurrentMarket(x));
  };

  const [amenitiesLayer, setAmenitiesLayer] = useState<any>(<></>);

  const meResponse = useCurrentUser(auth, { refetchOnWindowFocus: false });

  const marketsRes = useSubscribedMarketBounds(auth, {
    enabled: meResponse.isSuccess,
    onSuccess: (data) => {
      if (meResponse.data!.settings.preferredMarket && currentMarket === undefined) {
        const indexedMarkets = data
          .map((market, index) => ({ index: index, ...market }))
          .filter((market: any) => meResponse.data!.settings.preferredMarket === market.id);
        setCurrentMarket(indexedMarkets.length != 0 ? indexedMarkets[0]['index'] : 0);
        setMarketOptions([indexedMarkets[0].id]);
      } else if (currentMarket) {
        return;
      } else if (marketOptions[0] && currentMarket != undefined) {
        data.map((market, indx) => {
          if (marketOptions.some((x) => x == market.id)) {
            setCurrentMarket(indx);
            return;
          }
        });
      } else if (currentMarket === undefined) {
        setCurrentMarket(0);
        setMarketOptions([data[0].id]);
      }
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (marketsRes.data) setNewLocation(marketsRes.data[currentMarket as number].extent);
  }, [currentMarket]);

  const getStudyAreas = (allStudyAreas: FeatureCollection, borne: boolean) => {
    return {
      ...allStudyAreas,
      features: allStudyAreas.features.filter((feature: any) =>
        borne ? !feature.properties.user_id : !!feature.properties.user_id
      ),
    } as FeatureCollection;
  };

  const studyAreasRes = useMarketStudyAreaGeos(
    auth,
    marketsRes.isSuccess && currentMarket !== undefined ? marketsRes.data[currentMarket].id : undefined,
    {
      enabled: marketsRes.isSuccess && currentMarket !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const borneStudyAreas = useMemo(
    () => (studyAreasRes.isSuccess ? getStudyAreas(studyAreasRes.data, true) : undefined),
    [studyAreasRes.data]
  );

  const customStudyAreas = useMemo(
    () => (studyAreasRes.isSuccess ? getStudyAreas(studyAreasRes.data, false) : undefined),
    [studyAreasRes.data]
  );

  const marketGradesRes = useMarketGrade(
    auth,
    marketsRes.isSuccess && currentMarket !== undefined ? marketsRes.data[currentMarket].id : undefined,
    !!selectedConceptId ? selectedConceptId : undefined,
    considerImpact,
    {
      enabled: marketsRes.isSuccess && !!selectedConceptId && currentMarket !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const getStudyAreaPopup = (feature: Feature, layer: LeafletLayer) => {
    if (feature.properties && feature.properties.name) {
      const safeForGrade =
        !!selectedConceptId &&
        marketGradesRes.isSuccess &&
        !marketGradesRes.isFetching &&
        studyAreasRes.isSuccess &&
        !studyAreasRes.isFetching;

      const gradeResponse = safeForGrade ? marketGradesRes.data[parseInt(feature.id as string)] : undefined;

      if (gradeResponse) {
        const grade = (
          <>
            <span>
              Concept {gradeResponse!.score != null ? 'score' : 'grade'}:{' '}
              <strong>{gradeResponse!.score != null ? gradeResponse!.score : gradeResponse!.grade}</strong>
            </span>
            <br />
          </>
        );

        return (
          <FeaturePopup
            key={feature.id}
            layer={layer}
            onOpen={() => meResponse.data?.enterprise_id === 1 && setSelectedStudyAreaName(feature.properties!.name)}
            onClose={() => meResponse.data?.enterprise_id === 1 && setSelectedStudyAreaName(undefined)}
          >
            <h3>{feature.properties.name}</h3>
            {grade}
            <>
              <br />
              We estimate that your concept would rank {gradeResponse.study_area_rank} out of {gradeResponse.count}{' '}
              restaurants in this area.
            </>
            <br />
            <br />
            <Link to={`/studyareas/${feature.id}`}>Go to Detail Page</Link>
          </FeaturePopup>
        );
      }
    }
  };

  const getStudyAreaTooltip = (feature: Feature, layer: LeafletLayer) => {
    if (feature.properties && feature.properties.name) {
      const safeForGrade =
        !!selectedConceptId &&
        marketGradesRes.isSuccess &&
        !marketGradesRes.isFetching &&
        studyAreasRes.isSuccess &&
        !studyAreasRes.isFetching;

      const gradeResponse = safeForGrade ? marketGradesRes.data[parseInt(feature.id as string)] : undefined;

      if (gradeResponse) {
        const className = `ui circular label big ${gradeColors.get(gradeResponse!.grade[0])}`;

        return (
          <FeatureTooltip layer={layer} permanent={true} className={className}>
            {gradeResponse!.score != null ? gradeResponse!.score : gradeResponse!.grade}
          </FeatureTooltip>
        );
      }
    }
  };

  const openLocations = useMarketLocations(
    auth,
    marketsRes.isSuccess && currentMarket !== undefined ? marketsRes.data[currentMarket].id : undefined,
    'open',
    {
      enabled: marketsRes.isSuccess && currentMarket !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const locationsLayer = useMemo(
    () => (
      <>
        {openLocations.data?.map(({ coords, name }) => (
          <CircleMarker center={coords} stroke={false} fillOpacity={1} fillColor={'#0c7c59'} radius={3}>
            <Tooltip pane="tooltipPane">{name}</Tooltip>
          </CircleMarker>
        ))}
      </>
    ),
    [openLocations.data]
  );

  const userLocations = useMarketLocations(
    auth,
    marketsRes.isSuccess && currentMarket !== undefined ? marketsRes.data[currentMarket].id : undefined,
    'user',
    {
      enabled: marketsRes.isSuccess && currentMarket !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const userLocationsLayer = useMemo(
    () => (
      <>
        {userLocations.data?.map(({ id, coords, name, revenue, meta }) => {
          const isGhostKitchen = meResponse.data?.enterprise_id === 31;
          const eventHandlers = isGhostKitchen
            ? {
                click: () => navigate(`/locationdetail/${id}/${meta.studyarea_id}`),
              }
            : undefined;

          return (
            <CircleMarker
              key={id}
              center={coords}
              stroke={false}
              fillOpacity={1}
              fillColor={'#db2828'}
              radius={5}
              eventHandlers={eventHandlers}
            >
              <Tooltip pane="tooltipPane">
                <strong>{name}</strong>
                <br />
                {revenue ? formatCurrency(revenue) : ''}
              </Tooltip>
            </CircleMarker>
          );
        })}
      </>
    ),
    [userLocations.data]
  );

  const amenitiesRes = useMarketAmenityGeos(
    auth,
    marketsRes.isSuccess && currentMarket !== undefined ? marketsRes.data[currentMarket].id : undefined,
    {
      enabled: marketsRes.isSuccess && currentMarket !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  // const marketCorePatronHomeRes = useMarketCorePatronHome(
  //   auth,
  //   marketsRes.isSuccess ? marketsRes.data[currentMarket].id : undefined,
  //   {
  //     enabled: marketsRes.isSuccess,
  //     refetchOnWindowFocus: false,
  //     onSuccess: data => {
  //       const volumes = [...data.features.map(({ properties }) => properties!.volume)];
  //       const chroma = Chroma.scale('YlGn').domain([Math.min(...volumes), Math.max(...volumes)]);
  //       setColorScale(() => chroma);
  //     }
  //   }
  // )

  // const marketCorePatronDestRes = useMarketCorePatronDest(
  //   auth,
  //   marketsRes.isSuccess ? marketsRes.data[currentMarket].id : undefined,
  //   corePatronIdentity,
  //   {
  //     enabled: marketsRes.isSuccess && !!corePatronIdentity,
  //     refetchOnWindowFocus: false,
  //     onSuccess: (data) => {
  //       const volumes = [...data.features.map(({ properties }) => properties!.volume)];
  //       const chroma = Chroma.scale('YlGn').domain([Math.min(...volumes), Math.max(...volumes) - 100]);
  //       setColorScale(() => chroma);
  //     },
  //   }
  // );

  const CartoTiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
    ),
    []
  );

  const GoogleTiles = useMemo<LeafletTileLayer>(() => {
    return L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    });
  }, []);

  // const vertNearmapTiles = useMemo<LeafletTileLayer>(() => {
  //   return getLayerByHeading(
  //     HEADING_VERT,
  //     'https://api.nearmap.com/tiles/v3/{layer}/{z}/{x}/{y}.img?tertiary=satellite&apikey=NGRkNTNjY2EtZThhOS00M2U1LWE3OWUtZjMyMTE2MjdjM2I4'
  //   );
  // }, []);

  // const northPanoNearmapTiles = useMemo<LeafletTileLayer>(() => {
  //   return getLayerByHeading(
  //     HEADING_NORTH,
  //     'https://api.nearmap.com/tiles/v3/{layer}/{z}/{x}/{y}.img?tertiary=satellite&apikey=NGRkNTNjY2EtZThhOS00M2U1LWE3OWUtZjMyMTE2MjdjM2I4'
  //   );
  // }, []);

  useEffect(() => {
    if (isMounted.current && currentMarket) {
      studyAreasRes.refetch();
      amenitiesRes.refetch();
      // if (corePatronIdentity) {
      //   marketCorePatronDestRes.refetch();
      // }
      if (selectedConceptId) {
        marketGradesRes.refetch();
      }
    } else {
      isMounted.current = true;
    }
  }, [currentMarket]);

  useEffect(() => {
    if (selectedConceptId) marketGradesRes.refetch();
  }, [selectedConceptId]);

  useEffect(() => {
    if (amenitiesRes.isSuccess) {
      setAmenitiesLayer(
        <>
          {amenitiesRes.data
            .filter(({ category }) => amenityCategorySet.has(category))
            .map(({ coords, name, category }) => (
              <Marker
                position={coords}
                icon={divIcon({
                  html: `<div><i aria-hidden="true" class="${amenityIcons.get(
                    category
                  )} circular grey inverted icon"></i></div>`,
                  className: '',
                  iconSize: [40, 40],
                })}
              >
                <Tooltip pane="tooltipPane">{name}</Tooltip>
              </Marker>
            ))}
        </>
      );
    }
  }, [amenityCategorySet, amenitiesRes.isSuccess, amenitiesRes.isFetching, currentMarket]);

  return (
    <>
      {marketsRes.isSuccess && currentMarket !== undefined && (
        <MapContainer
          style={{ height: '100%' }}
          bounds={new LatLngBounds(marketsRes.data[currentMarket].extent)}
          maxBounds={US_BOUNDING_BOX}
          minZoom={7}
          attributionControl={false}
        >
          <MapWhee newLocation={newLocation} />
          <AttributionControl position="bottomright" />
          {!satelliteEnabled && CartoTiles}
          <Pane name="UserPointsPane" style={{ zIndex: 407 }}>
            {visibleLayersSet.has('locations') && userLocations.isSuccess && showUserLocations && userLocationsLayer}
          </Pane>
          <Pane name="PointsPane" style={{ zIndex: 408 }}>
            {visibleLayersSet.has('locations') && openLocations.isSuccess && showBorneLocations && locationsLayer}
            {visibleLayersSet.has('amenities') && amenitiesRes.isSuccess && amenitiesLayer}
          </Pane>
          {openLocations.isSuccess && visibleLayersSet.has('brands') && marketsRes.isSuccess && (
            <BrandsPaneV2
              auth={auth}
              market={marketsRes.data[currentMarket].id}
              locations={openLocations.data}
              selectedBrands={selectedBrands}
            />
          )}
          {/* <Pane name="CorePatronPane" style={{ zIndex: 402 }}>
                {marketCorePatronDestRes.isSuccess && visibleLayersSet.has('corePatronDestination') && colorScale && (
                  <GeoJSON
                    data={marketCorePatronDestRes.data}
                    onEachFeature={onEachCorePatronTrip}
                    style={(record) => ({
                      color: 'rgba(0,0,0,.04)',
                      weight: 1,
                      fillOpacity: 0.7,
                      zIndex: 10,
                      fillColor: colorScale(record?.properties.volume as number).hex(),
                    })}
                  />
                )}
              </Pane> */}
          <Pane name="PolygonPane" style={{ zIndex: 406 }}>
            {studyAreasRes.isSuccess &&
              visibleLayersSet.has('studyAreas') &&
              showBorneStudyAreas &&
              !marketGradesRes.isFetching && (
                <GeoJSON
                  data={borneStudyAreas!}
                  style={(_) => ({
                    color: '#bccd19',
                    fillColor: 'rgba(176,218,17,0.3)',
                    pane: 'PolygonPane',
                  })}
                >
                  {(feature: Feature, layer: LeafletLayer) => {
                    if (selectedConceptId === 0) {
                      return <></>;
                    } else {
                      return (
                        <>
                          {getStudyAreaPopup(feature, layer)}
                          {getStudyAreaTooltip(feature, layer)}
                        </>
                      );
                    }
                  }}
                </GeoJSON>
              )}

            {studyAreasRes.isSuccess &&
              visibleLayersSet.has('studyAreas') &&
              showCustomStudyAreas &&
              !marketGradesRes.isFetching && (
                <GeoJSON
                  data={customStudyAreas!}
                  style={(_) => ({
                    color: '#7cb0ba',
                    fillColor: 'rgb(237,247,248)',
                    pane: 'PolygonPane',
                  })}
                >
                  {(feature: Feature, layer: LeafletLayer) => {
                    if (selectedConceptId === 0) {
                      return <></>;
                    } else {
                      return (
                        <>
                          {getStudyAreaPopup(feature, layer)}
                          {getStudyAreaTooltip(feature, layer)}
                        </>
                      );
                    }
                  }}
                </GeoJSON>
              )}

            {/* {marketCorePatronHomeRes.isSuccess && showCorePatronBehavior && colorScale && (
                <GeoJSON
                  data={marketCorePatronHomeRes.data}
                  onEachFeature={onEachFeatureDetail}
                  style={(record) => ({
                    color: 'rgba(0,0,0,.04)',
                    weight: 1,
                    fillOpacity: .7,
                    zIndex:10,
                    fillColor: colorScale(record?.properties.volume as number).hex()
                  })}
                />
              )} */}
          </Pane>
          <MapConsumer>
            {(map) => {
              return (
                <>
                  <div className="leaflet-bottom leaflet-left leaflet-control">
                    {selectedStudyAreaName && meResponse.data?.enterprise_id === 1 && (
                      <div className="tw-bg-[#324d54] tw-text-white tw-p-12 tw-m-4 tw-max-w-sm">
                        <div className="tw-font-['Roboto_Condensed'] tw-text-2xl tw-mb-4">
                          <div>
                            About the Borne Score<sup>&trade;</sup> for {selectedStudyAreaName}
                          </div>
                        </div>
                        <div>
                          The Borne Score is calculated with a mix of multiple different data points that culminate into
                          a viability assessment. These data points include the following:
                          <ul className="tw-mt-2 tw-pl-4 tw-list-['→'] marker:tw-text-bornegreen-dark">
                            <li className="tw-p-2">Reason number one that this is the score it is. Blah blah blah.</li>
                            <li className="tw-p-2">Reason number one that this is the score it is. Blah blah blah.</li>
                            <li className="tw-p-2">Reason number one that this is the score it is. Blah blah blah.</li>
                            <li className="tw-p-2">Reason number one that this is the score it is. Blah blah blah.</li>
                          </ul>
                        </div>
                      </div>
                    )}
                    <Container style={{ paddingBottom: '8vh' }}>
                      <Button.Group
                        className="leaflet-control"
                        style={{
                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                        }}
                      >
                        <Button
                          active={!satelliteEnabled}
                          onClick={() => {
                            // Remove nearmap layers so MapComponent child TileLayers will take precedence
                            map.removeLayer(GoogleTiles);

                            // Hack to change CRS live without rerendering the entire component
                            // var center = map.getCenter();
                            // map.options.crs = CRS.EPSG3857;
                            // map.setView(center); //we need this, because after changing crs the center is shifted (as mentioned above probably it's an issue to)
                            // (map as any)._resetView(map.getCenter(), map.getZoom(), true); //we need this to redraw all layers (polygons, markers...) in the new projection.

                            changeSatelliteEnabled(false);
                          }}
                        >
                          Map
                        </Button>
                        <Button.Or />
                        <Button
                          active={satelliteEnabled}
                          onClick={() => {
                            // vertNearmapTiles.addTo(map);

                            // Hack to change CRS live without rerendering the entire component
                            // var center = map.getCenter();
                            // map.options.crs = getCRSByHeading(HEADING_VERT);
                            // map.setView(center); //we need this, because after changing crs the center is shifted (as mentioned above probably it's an issue to)
                            // (map as any)._resetView(map.getCenter(), map.getZoom(), true); //we need this to redraw all layers (polygons, markers...) in the new projection.
                            map.addLayer(GoogleTiles);

                            changeSatelliteEnabled(true);
                            // setNearmapHeading(HEADING_VERT);
                            map.invalidateSize();
                          }}
                        >
                          Satellite
                        </Button>
                      </Button.Group>
                    </Container>
                  </div>
                </>
              );
            }}
          </MapConsumer>
        </MapContainer>
      )}
    </>
  );
};
