import { useStudyAreaCuisines } from '../../../api/requests';
import Auth from 'borne_ui/src/auth/Auth';
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTitleProps, Dropdown, Grid, Icon } from 'semantic-ui-react';
import { BorneCheckbox, BorneFlexContainer } from '../../generic/BorneComponents';
import { StudyAreaSingleLocationMap } from '../../maps/StudyAreaSingleLocationMap';
import DataTable from '../../generic/DataTable';
import { Location } from '../../../api/models';
import BorneDataTable from '../../generic/BorneDataTableV2';

interface Props {
  openLocations: Location[];
  closedLocations: Location[];
  areaName: string;
  studyAreaId: any;
  auth: Auth;
}

export const StudyAreasOpenCloseTablesV2: React.FC<Props> = ({
  openLocations,
  closedLocations,
  areaName,
  studyAreaId,
  auth,
}: Props) => {
  const studyAreaCuisinesRes = useStudyAreaCuisines(auth, studyAreaId, {
    refetchOnWindowFocus: false,
  });

  const [currentCuisine, setCurrentCuisine] = useState<string | undefined>();
  const [status, setStatus] = useState('closed');
  const [currentAccordionFold, setCurrentAccordionFold] = useState<number>(2);
  const [dayOfWeek, setDayOfWeek] = useState<string | undefined>();
  const [hour, setHour] = useState<number | undefined>();

  useEffect(() => {
    studyAreaCuisinesRes.refetch();
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, titleProps: AccordionTitleProps) => {
    const { index } = titleProps;
    const newIndex = currentAccordionFold === index ? -1 : (index as number);
    setCurrentAccordionFold(newIndex);
  };

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const daysOfWeekOptions = daysOfWeek.map((day: string) => ({
    key: day,
    text: day,
    value: day.toLowerCase(),
  }));

  const hoursOptions = [
    {
      key: 0,
      text: '12 AM',
      value: 0,
    },
    {
      key: 1,
      text: '1 AM',
      value: 1,
    },
    {
      key: 2,
      text: '2 AM',
      value: 2,
    },
    {
      key: 3,
      text: '3 AM',
      value: 3,
    },
    {
      key: 4,
      text: '4 AM',
      value: 4,
    },
    {
      key: 5,
      text: '5 AM',
      value: 5,
    },
    {
      key: 6,
      text: '6 AM',
      value: 6,
    },
    {
      key: 7,
      text: '7 AM',
      value: 7,
    },
    {
      key: 8,
      text: '8 AM',
      value: 8,
    },
    {
      key: 9,
      text: '9 AM',
      value: 9,
    },
    {
      key: 10,
      text: '10 AM',
      value: 10,
    },
    {
      key: 11,
      text: '11 AM',
      value: 11,
    },
    {
      key: 12,
      text: '12 PM',
      value: 12,
    },
    {
      key: 13,
      text: '1 PM',
      value: 13,
    },
    {
      key: 14,
      text: '2 PM',
      value: 14,
    },
    {
      key: 15,
      text: '3 PM',
      value: 15,
    },
    {
      key: 16,
      text: '4 PM',
      value: 16,
    },
    {
      key: 17,
      text: '5 PM',
      value: 17,
    },
    {
      key: 18,
      text: '6 PM',
      value: 18,
    },
    {
      key: 19,
      text: '7 PM',
      value: 19,
    },
    {
      key: 20,
      text: '8 PM',
      value: 20,
    },
    {
      key: 21,
      text: '9 PM',
      value: 21,
    },
    {
      key: 22,
      text: '10 PM',
      value: 22,
    },
    {
      key: 23,
      text: '11 PM',
      value: 23,
    },
  ];

  return (
    <>
      {studyAreaCuisinesRes ? (
        <>
          <Grid.Row>
            <BorneFlexContainer className="tw-h-[30rem] tw-items-start tw-mt-8">
              <div className="tw-h-fit tw-w-[30%] tw-sticky tw-top-0">
                <p className="tw-pb-4">
                  The defining feature of a study area is the restaurants within its bounds. Here, explore restaurants
                  in the area, how their concepts vary, and how your concept(s) may fit into this landscape.
                </p>
                <BorneFlexContainer className="tw-flex-col tw-items-start">
                  <BorneFlexContainer>
                    <BorneCheckbox
                      shape="round"
                      onChange={() => (status == 'open' ? setStatus('closed') : setStatus('open'))}
                      checked={status == 'open'}
                    />
                    <label>Open Restaurants</label>
                  </BorneFlexContainer>
                  <BorneFlexContainer>
                    <BorneCheckbox
                      shape="round"
                      onChange={() => (status == 'closed' ? setStatus('open') : setStatus('closed'))}
                      checked={status == 'closed'}
                    />
                    <label>Closed Restaurants</label>
                  </BorneFlexContainer>
                </BorneFlexContainer>
                <Accordion fluid>
                  {/*Cuisines */}
                  <Accordion.Title
                    onClick={handleClick}
                    index={1}
                    className="tw-flex tw-justify-between tw-pr-12 tw-items-center"
                  >
                    <div className="tw-flex tw-items-center">
                      <p className="tw-m-0">Cuisine</p>
                      {currentAccordionFold != 1 ? <Icon name="dropdown" /> : <Icon name="caret down" />}
                    </div>
                  </Accordion.Title>
                  <Accordion.Content active={currentAccordionFold === 1}>
                    {studyAreaCuisinesRes.isSuccess && !studyAreaCuisinesRes.isFetching && (
                      <DataTable
                        resultsPerPage={3}
                        data={studyAreaCuisinesRes.data.open.map((row) => ({
                          id: row.cuisine,
                          cuisine: row.cuisine,
                          total: row.total,
                        }))}
                        tableProps={{ selectable: true }}
                        idColumn="id"
                        scrolling
                        onRowClick={({ cuisine }) => {
                          setCurrentCuisine(cuisine);
                        }}
                      />
                    )}
                  </Accordion.Content>
                  {/* Time */}
                  <Accordion.Title
                    onClick={handleClick}
                    index={0}
                    className="tw-flex tw-justify-between tw-pr-12 tw-items-center"
                  >
                    <div className="tw-flex tw-items-center">
                      <p className="tw-m-0">Day / Time</p>
                      {currentAccordionFold != 0 ? <Icon name="dropdown" /> : <Icon name="caret down" />}
                    </div>
                  </Accordion.Title>
                  <Accordion.Content active={currentAccordionFold === 0}>
                    <BorneFlexContainer className="tw-mr-5">
                      <Dropdown
                        search
                        selection
                        clearable
                        placeholder="Day of Week"
                        options={daysOfWeekOptions}
                        onChange={(e, { value }) => setDayOfWeek(value as string)}
                      />
                      <Dropdown
                        search
                        selection
                        clearable
                        placeholder="Hour"
                        options={hoursOptions}
                        onChange={(e, { value }) => setHour(value as number)}
                      />
                    </BorneFlexContainer>
                  </Accordion.Content>
                </Accordion>
              </div>
              <div className="tw-w-[70%] tw-h-full">
                <StudyAreaSingleLocationMap
                  dayOfWeek={dayOfWeek}
                  hour={hour}
                  studyAreaId={studyAreaId}
                  auth={auth}
                  status={status}
                  currentCuisine={currentCuisine}
                />
              </div>
            </BorneFlexContainer>
          </Grid.Row>
          <Grid.Row>
            <BorneFlexContainer className="tw-justify-between tw-items-start tw-mt-12">
              <div className="tw-w-[47%] tw-h-[400px]">
                <p>Open Restaurants {`(${openLocations.length})`}</p>
                <BorneDataTable
                  height={400}
                  fill
                  rowsPerPage={10}
                  headers={[
                    { field: 'text', headerName: 'Name', sortable: true },
                    { field: 'text2', headerName: 'Address', sortable: true },
                  ]}
                  rowData={openLocations.map((rest) => {
                    const container = {
                      text: '',
                      text2: '',
                    };
                    container.text = rest.name;
                    container.text2 = rest.address || '';
                    return container;
                  })}
                />
              </div>
              <div className="tw-w-[47%] tw-h-[400px]" style={{ width: '47%', height: '400px' }}>
                <p>Closed Restaurants {`(${closedLocations.length})`}</p>
                <BorneDataTable
                  height={400}
                  fill
                  rowsPerPage={10}
                  headers={[
                    { field: 'text', headerName: 'Name', sortable: true },
                    { field: 'text2', headerName: 'Address', sortable: true },
                  ]}
                  rowData={closedLocations.map((rest: any) => {
                    const container = {
                      text: '',
                      text2: '',
                    };
                    container.text = rest.name;
                    container.text2 = rest.address || '';
                    return container;
                  })}
                />
              </div>
            </BorneFlexContainer>
          </Grid.Row>
        </>
      ) : (
        ''
      )}
    </>
  );
};
