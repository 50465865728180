import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import type { User } from '../../api/models';

interface Props {
  header: JSX.Element;
  subtext: JSX.Element;
  open: boolean;
  setOpen: (open: boolean) => void;
  trigger: JSX.Element;
  user: User;
}

const ModelExplanationPopup = ({ header, subtext, open, setOpen, trigger, user }: Props) => {
  // The "true" condition to short circuit the explanation popup was
  // requested by Ken so that this does not show up in the demos that
  // he is giving while the data is being mocked.
  if (true || user.enterprise_id !== 1) {
    return trigger;
  }

  return (
    <Popup
      className={[
        'tw-bg-[#324d54]',
        'tw-text-white',
        'tw-p-12',
        'tw-max-w-sm',
        'tw-rounded-none',
        'before:tw-bg-[#324d54]',
        'before:tw-w-[1.2em]',
        'before:tw-h-[1.2em]',
        'before:tw-top-[-0.6em]',
        'before:tw-ml-[-0.6em]',
      ].join(' ')}
      // tni: I want to use "context" property to associate this popup with the
      // grade, but I can't get it to work! To position the popup correcly, I'm
      // putting the grade element in its trigger and changing the "on" property
      // so that the popup doesn't open through interaction with the grade.
      trigger={trigger}
      on={[]}
      open={open}
      position="bottom center"
    >
      <Popup.Header className="tw-font-['Roboto_Condensed'] tw-text-2xl tw-mb-4">
        <div className="tw-flex">
          <div>{header}</div>
          <button className="tw-ml-auto" onClick={() => setOpen(false)}>
            <Icon name="close" className="tw-text-bornegreen-dark" />
          </button>
        </div>
      </Popup.Header>
      <Popup.Content>
        {subtext}
        <ul className="tw-mt-2 tw-pl-4 tw-list-['→'] marker:tw-text-bornegreen-dark">
          <li className="tw-p-2">Reason number one that this is the score it is. Blah blah blah.</li>
          <li className="tw-p-2">Reason number one that this is the score it is. Blah blah blah.</li>
          <li className="tw-p-2">Reason number one that this is the score it is. Blah blah blah.</li>
          <li className="tw-p-2">Reason number one that this is the score it is. Blah blah blah.</li>
        </ul>
      </Popup.Content>
    </Popup>
  );
};

export default ModelExplanationPopup;
