import React from 'react';

export function join(elements: JSX.Element[], separator: JSX.Element): JSX.Element[] {
  const size = elements.length;
  if (size <= 1) {
    return elements;
  }

  const joined: JSX.Element[] = [];
  elements.forEach((element, index) => {
    joined.push(element);
    if (index < size - 1) {
      const key = `after-${element.key}`;
      joined.push(React.cloneElement(separator, { key }));
    }
  });

  return joined;
}

export const buildSeries = (
  data: { [key: string]: any },
  seriesKey: string,
  labels: Array<string> = []
): { key: string; value: any }[] => {
  const series = Object.keys(data)
    .filter((key) => key.startsWith(seriesKey) && !key.includes('mean') && !key.includes('median'))
    .map((key, idx) => ({
      key: labels.length ? labels[idx] : key.slice(seriesKey.length),
      value: data[key],
    }))
    .reverse();

  return series;
};

export const isSeriesValid = (data: { [key: string]: any }, seriesKey: string): boolean => {
  const isValid = Object.keys(data)
    .filter((key) => key.startsWith(seriesKey) && !key.includes('mean') && !key.includes('median'))
    .reduce((cur, key) => cur || data[key] != 0, false);
  return isValid;
};
