import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { GeoJsonObject, FeatureCollection, Geometry } from 'geojson';
import Auth, { unauthenticatedAPICall } from '../auth/Auth';
import useStreamQuery from '../hooks/useStreamQuery';

import type { UseQueryOptions } from '@tanstack/react-query';
import type { Concept } from '../components/concept/concept';
import type {
  Location as GenericLocation,
  LocationType,
  Page,
  StudyAreaLocationType,
  User,
  UserLocation,
} from './models';
import type {
  Amenity,
  CorePatronIdentity,
  CuisineSummary,
  MarketExtentResponse,
  MarketOverview,
  StudyArea,
  StudyAreaMarketGrade,
  StudyAreaTapestrySegmentation,
  TrendsSummary,
  BlockGroupProperties,
  DayOfWeek,
  StudyAreaPricingSummary,
} from '../types';

import type { UseStreamQueryOptions } from '../hooks/useStreamQuery';
import { BorneReportInput } from '../components/pages2/BorneReport';

type Location<T extends LocationType> = T extends 'user' ? UserLocation : GenericLocation;

async function fetchCurrentUser(auth: Auth): Promise<User> {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/users/me/',
  });

  return response.data;
}

export function useCurrentUser(auth: Auth, options?: Omit<UseQueryOptions<User>, 'queryKey' | 'queryFn'>) {
  return useQuery(['me'], () => fetchCurrentUser(auth), options);
}

// Subscribed Markets
// borne_maps.SubscribedMarkets
async function fetchMarkets(auth: Auth) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/map/myMarkets/',
  });

  return response.data;
}

export function useMarkets(auth: Auth, options: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>) {
  return useQuery(['markets'], () => fetchMarkets(auth), options);
}

async function fetchAllMarkets(auth: Auth) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/map/market/',
  });

  return response.data;
}

export function useAllMarkets(auth: Auth, options: Omit<UseQueryOptions<FeatureCollection>, 'queryKey' | 'queryFn'>) {
  return useQuery<FeatureCollection>(['allmarkets'], () => fetchAllMarkets(auth), options);
}

async function fetchMarketOverview(auth: Auth) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/data/marketOverview/',
  });

  return response.data;
}

export function useMarketOverview(
  auth: Auth,
  options: Omit<UseQueryOptions<Array<MarketOverview>>, 'queryKey' | 'queryFn'>
) {
  return useQuery<Array<MarketOverview>>(['marketOverview'], () => fetchMarketOverview(auth), options);
}

// borne_maps.MarketExtentViewSet
async function fetchSubscribedMarketBounds(auth: Auth) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/map/subscribedMarkets/',
  });

  return response.data;
}

export function useSubscribedMarketBounds(
  auth: Auth,
  options: Omit<UseQueryOptions<MarketExtentResponse>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<MarketExtentResponse>(['subscribedMarketBounds'], () => fetchSubscribedMarketBounds(auth), options);
}

async function fetchStudyAreaSummary(auth: Auth, studyAreaIds: string[]) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/data/studyAreas/',
    params: {
      studyAreaIds: JSON.stringify(studyAreaIds),
    },
  });

  return response.data;
}

export function useStudyAreaSummary(
  auth: Auth,
  studyAreaIds: string[],
  options?: Omit<UseQueryOptions<Array<any>>, 'queryKey' | 'queryFn'>
) {
  return useQuery<Array<any>>(
    ['studyAreaSummary', studyAreaIds],
    () => fetchStudyAreaSummary(auth, studyAreaIds),
    options
  );
}

async function fetchStudyAreaPricingSummary(auth: Auth, studyAreaId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/studyAreaPricingSummary/${studyAreaId}/`,
  });

  return response.data;
}

export function useStudyAreaPricingSummary(
  auth: Auth,
  studyAreaId: string,
  options?: Omit<UseQueryOptions<StudyAreaPricingSummary>, 'queryKey' | 'queryFn'>
) {
  return useQuery<StudyAreaPricingSummary>(
    ['studyAreaPricingSummary', studyAreaId],
    () => fetchStudyAreaPricingSummary(auth, studyAreaId),
    options
  );
}

async function fetchMarketSummary(auth: Auth, marketIds: string[]) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/data/marketSummary/',
    params: {
      marketIds: JSON.stringify(marketIds),
    },
  });

  return response.data;
}

export function useMarketSummary(
  auth: Auth,
  marketIds: string[],
  options?: Omit<UseQueryOptions<Array<any>>, 'queryKey' | 'queryFn'>
) {
  const optionsOverride = {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  };
  return useQuery<Array<any>>(['marketSummary', marketIds], () => fetchMarketSummary(auth, marketIds), optionsOverride);
}

// GET all chains
async function fetchChains(auth: Auth) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/data/chains/',
  });

  return response.data;
}

export function useChains(auth: Auth) {
  return useQuery<Array<any>>(['chains'], () => fetchChains(auth));
}

// GET all Market Chains
async function fetchMarketChains(auth: Auth, marketId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/marketChains/${marketId}/`,
  });

  return response.data;
}

export function useMarketChains(
  auth: Auth,
  marketId: string | undefined,
  options?: Omit<UseQueryOptions<Array<any>>, 'queryKey' | 'queryFn'>
) {
  return useQuery<Array<any>>(['marketChains', marketId], () => fetchMarketChains(auth, marketId as string), options);
}

// GET Market Chain Drive Times
async function fetchMarketDriveTimes(auth: Auth, marketId: string, chainId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/marketDriveTimes/${marketId}/?chainId=${chainId}`,
  });

  return response.data;
}

export function useMarketDriveTimes(
  auth: Auth,
  marketId: string | undefined,
  chainId: string | undefined,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>
) {
  return useQuery<any>(
    ['marketDriveTimes', marketId, chainId],
    () => fetchMarketDriveTimes(auth, marketId as string, chainId as string),
    options
  );
}

async function fetchMarketTerritory(auth: Auth, marketId: string, chainId: string, entLocId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/territoryPlanning/${marketId}/?chainId=${chainId}&enterpriseLocationId=${entLocId}`,
  });

  return response.data;
}

export function useMarketTerritory(
  auth: Auth,
  marketId: string | undefined,
  chainId: string | undefined,
  entLocId: string | undefined,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>
) {
  return useQuery<any>(
    ['marketTerritoryPlanning', marketId, chainId, entLocId],
    () => fetchMarketTerritory(auth, marketId as string, chainId as string, entLocId as string),
    options
  );
}

// GET BlockGroup GeoJSON for a Market
async function fetchMarketBlockGroupGeos(auth: Auth, marketId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/marketBlockGroups/${marketId}/`,
  });

  return response.data;
}

export function useMarketBlockGroupGeos(
  auth: Auth,
  marketId: string,
  options: Omit<UseQueryOptions<FeatureCollection<Geometry, BlockGroupProperties>>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<FeatureCollection<Geometry, BlockGroupProperties>>(
    ['marketBlockGroupGeos', marketId],
    () => fetchMarketBlockGroupGeos(auth, marketId),
    options
  );
}

// GET StudyArea GeoJSON for a Market
async function fetchMarketStudyAreaGeos(auth: Auth, marketId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/marketStudyAreas/${marketId}/`,
  });

  return response.data;
}

export function useMarketStudyAreaGeos(
  auth: Auth,
  marketId: string | undefined,
  options: Omit<UseQueryOptions<FeatureCollection>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<FeatureCollection>(
    ['marketStudyAreaGeos', marketId],
    () => fetchMarketStudyAreaGeos(auth, marketId as string),
    options
  );
}

// GET Core Patron Home for a Market
async function fetchMarketCorePatronHome(auth: Auth, marketId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/marketCorePatronHome/${marketId}/`,
  });

  return response.data;
}

export function useMarketCorePatronHome(
  auth: Auth,
  marketId: string | undefined,
  options: Omit<UseQueryOptions<FeatureCollection>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<FeatureCollection>(
    ['marketCorePatronHome'],
    () => fetchMarketCorePatronHome(auth, marketId as string),
    options
  );
}

// GET Core Patron Dest for a Market
async function fetchMarketCorePatronDest(auth: Auth, marketId: string, corePatronIdentity: CorePatronIdentity) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/marketCorePatronDest/${marketId}/`,
    params: corePatronIdentity,
  });

  return response.data;
}

export function useMarketCorePatronDest(
  auth: Auth,
  marketId?: string,
  corePatronIdentity?: CorePatronIdentity,
  options: Omit<UseQueryOptions<FeatureCollection>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<FeatureCollection>(
    ['marketCorePatronDest', marketId, corePatronIdentity],
    () => fetchMarketCorePatronDest(auth, marketId as string, corePatronIdentity as CorePatronIdentity),
    options
  );
}

async function fetchMarketLocations<T extends LocationType>(
  auth: Auth,
  marketId: string,
  type: T,
  offset: number,
  limit: number
): Promise<Page<Location<T>>> {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/marketLocations/?market=${marketId}&type=${type}&offset=${offset}&limit=${limit}`,
  });

  return response.data;
}

export function useMarketLocations<T extends LocationType>(
  auth: Auth,
  marketId: string | undefined,
  type: T,
  options: UseStreamQueryOptions<Location<T>> = {}
) {
  const pageSize = 500;

  return useStreamQuery(
    ['marketLocations', marketId, type],
    ({ pageParam = 0 }) =>
      typeof marketId === 'undefined'
        ? Promise.reject(new Error('Missing marketId parameter.'))
        : fetchMarketLocations(auth, marketId, type, pageParam, pageSize),
    options
  );
}

async function fetchMarketAmenityGeos(auth: Auth, marketId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/marketAmenities/${marketId}/`,
  });

  return response.data;
}

export function useMarketAmenityGeos(
  auth: Auth,
  marketId: string | undefined,
  options: Omit<UseQueryOptions<Array<Amenity>>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<Array<Amenity>>(
    ['marketAmenityGeos', marketId],
    () => fetchMarketAmenityGeos(auth, marketId as string),
    options
  );
}

async function fetchStudyAreas(auth: Auth, offset: number, limit: number): Promise<Page<StudyArea>> {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/studyAreas/?offset=${offset}&limit=${limit}`,
  });

  return response.data;
}

export function useAllStudyAreas(auth: Auth, options: UseStreamQueryOptions<StudyArea> = {}) {
  const pageSize = 500;

  return useStreamQuery(['allStudyAreas'], ({ pageParam = 0 }) => fetchStudyAreas(auth, pageParam, pageSize), options);
}

// POST Study Area for a Market
async function postStudyArea(auth: Auth, studyArea: StudyArea) {
  const response = await auth.authenticatedAPICall({
    method: 'POST',
    url: '/api/data/studyAreas/',
    data: studyArea,
  });

  return response.data;
}

export function addStudyAreaMutation(auth: Auth) {
  const queryClient = useQueryClient();

  return useMutation(['addStudyArea'], (studyArea: StudyArea) => postStudyArea(auth, studyArea), {
    onSuccess: () => queryClient.invalidateQueries(['allStudyAreas']),
  });
}

// PUT Study Area for a Market
async function putStudyArea(auth: Auth, studyArea: StudyArea) {
  const response = await auth.authenticatedAPICall({
    method: 'PUT',
    url: `/api/data/studyAreas/${studyArea.id}/`,
    data: studyArea,
  });

  return response.data;
}

export function editStudyAreaMutation(auth: Auth) {
  const queryClient = useQueryClient();

  return useMutation(['editStudyArea'], (studyArea: StudyArea) => putStudyArea(auth, studyArea), {
    onSuccess: () => queryClient.invalidateQueries(['allStudyAreas']),
  });
}

// DELETE Study Area for a Market
async function deleteStudyArea(auth: Auth, studyArea: StudyArea) {
  const response = await auth.authenticatedAPICall({
    method: 'DELETE',
    url: `/api/data/studyAreas/${studyArea.id}/`,
  });

  return response.data;
}

export function deleteStudyAreaMutation(auth: Auth) {
  const queryClient = useQueryClient();

  return useMutation(['deleteStudyArea'], (studyArea: StudyArea) => deleteStudyArea(auth, studyArea), {
    onSuccess: () => queryClient.invalidateQueries(['allStudyAreas']),
  });
}

// GET GeoJSON for all User Visible Study Areas
async function fetchAllStudyAreaGeos(auth: Auth) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/map/studyAreas/',
  });

  return response.data;
}

export function useAllStudyAreaGeos(
  auth: Auth,
  options: Omit<UseQueryOptions<GeoJsonObject>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<GeoJsonObject>(['allStudyAreaGeos'], () => fetchAllStudyAreaGeos(auth), options);
}

// GET data for an individual study area
async function fetchStudyArea(auth: Auth, studyAreaId: string, dayOfWeek: DayOfWeek) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/studyAreas/${studyAreaId}/`,
    params: {
      dow: dayOfWeek,
    },
  });

  return response.data;
}

export function useStudyArea(
  auth: Auth,
  studyAreaId: string,
  dayOfWeek: DayOfWeek,
  options: Omit<UseQueryOptions<StudyArea>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<StudyArea>(
    ['fetchStudyArea', dayOfWeek],
    () => fetchStudyArea(auth, studyAreaId, dayOfWeek),
    options
  );
}

async function fetchEnterpriseLocation(auth: Auth, enterpriseLocationId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/enterpriseLocation/${enterpriseLocationId}/`,
  });

  return response.data;
}

export function useEnterpriseLocation(
  auth: Auth,
  enterpriseLocationId: string,
  options: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<any>(
    ['fetchEnterpriseLocation', enterpriseLocationId],
    () => fetchEnterpriseLocation(auth, enterpriseLocationId),
    options
  );
}

// GET cuisine data for an individual study area
async function fetchStudyAreaCuisines(auth: Auth, studyAreaId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/studyAreaCuisines/${studyAreaId}/`,
  });

  return response.data;
}

export function useStudyAreaCuisines(
  auth: Auth,
  studyAreaId: string,
  options: Omit<UseQueryOptions<CuisineSummary>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<CuisineSummary>(['fetchStudyAreaCuisines'], () => fetchStudyAreaCuisines(auth, studyAreaId), options);
}

// GET tapestry segmentation data for an individual study area
async function fetchStudyAreaTS(auth: Auth, studyAreaId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/studyAreaTS/${studyAreaId}/`,
  });

  return response.data;
}

export function useStudyAreaTS(
  auth: Auth,
  studyAreaId: string,
  options: Omit<UseQueryOptions<StudyAreaTapestrySegmentation>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<StudyAreaTapestrySegmentation>(
    ['fetchStudyAreaTS'],
    () => fetchStudyAreaTS(auth, studyAreaId),
    options
  );
}

// GET consumer spending data for an individual study area
async function fetchStudyAreaConsumerSpending(auth: Auth, studyAreaId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/studyAreaCEX/${studyAreaId}/`,
  });

  return response.data;
}

export function useStudyAreaConsumerSpending(
  auth: Auth,
  studyAreaId: string,
  options: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<any>(
    ['fetchStudyAreaConsumerSpending'],
    () => fetchStudyAreaConsumerSpending(auth, studyAreaId),
    options
  );
}

// GET StudyArea-brand match score
async function fetchStudyAreaGrade(
  auth: Auth,
  studyAreaId: string,
  conceptIds: number[] | number,
  considerImpact: boolean
) {
  if (typeof conceptIds == 'number') {
    conceptIds = [conceptIds];
  }
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/score/conceptGrade/${studyAreaId}/`,
    params: {
      concept_ids: conceptIds,
      consider_impact: considerImpact,
    },
  });

  return response.data;
}

export function useStudyAreaGrade(
  auth: Auth,
  studyAreaId: string | undefined,
  conceptIds: number[],
  considerImpact: boolean,
  options: Omit<UseQueryOptions<{ [key: number]: StudyAreaMarketGrade }>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<{ [key: number]: StudyAreaMarketGrade }>(
    ['fetchStudyAreaGrade', studyAreaId, conceptIds, considerImpact],
    () => fetchStudyAreaGrade(auth, studyAreaId as string, conceptIds, considerImpact),
    options
  );
}

export function useStudyAreaGrades(
  auth: Auth,
  studyAreaIds: string[],
  conceptIds: number,
  considerImpact: boolean,
  options: Omit<UseQueryOptions<{ [key: number]: StudyAreaMarketGrade }>, 'queryKey' | 'queryFn'> = {}
) {
  return useQueries<any[]>({
    queries: studyAreaIds.map((x) => ({
      ...options,
      queryKey: ['fetchStudyAreaGrade', x, conceptIds, considerImpact],
      queryFn: () => fetchStudyAreaGrade(auth, x as string, conceptIds, considerImpact),
    })),
  });
}

// GET StudyArea-brand match score
async function fetchMarketGrade(auth: Auth, marketId: string, conceptId: number, considerImpact: boolean) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/score/marketGrade/${marketId}/`,
    params: {
      // TODO: Do we really need to convert to a string?
      concept_id: '' + conceptId,
      consider_impact: considerImpact,
    },
  });

  return response.data;
}

export function useMarketGrade(
  auth: Auth,
  marketId: string | undefined,
  conceptId: number | undefined,
  considerImpact: boolean,
  options: Omit<UseQueryOptions<{ [key: number]: StudyAreaMarketGrade }>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<{ [key: number]: StudyAreaMarketGrade }>(
    ['fetchStudyAreaMarketScores', marketId, conceptId, considerImpact],
    () => fetchMarketGrade(auth, marketId as string, conceptId as number, considerImpact),
    options
  );
}

// GET market potential data for an individual study area
async function fetchStudyAreaMarketPotential(auth: Auth, studyAreaId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/studyAreaMPI/${studyAreaId}/`,
  });

  return response.data;
}

export function useStudyAreaMarketPotential(
  auth: Auth,
  studyAreaId: string,
  options: Omit<UseQueryOptions<{ [key: string]: { [key: string]: number } }>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<{ [key: string]: { [key: string]: number } }>(
    ['fetchStudyAreaMarketPotential'],
    () => fetchStudyAreaMarketPotential(auth, studyAreaId),
    options
  );
}

async function fetchConcepts(auth: Auth) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/projects/',
  });

  return response.data;
}

export function useConcepts(auth: Auth, options: UseQueryOptions<any> = {}) {
  return useQuery<Array<Concept>>(['fetchConcepts'], () => fetchConcepts(auth), options);
}

export function useUpdateConcept(auth: Auth) {
  const queryClient = useQueryClient();

  return useMutation(
    ['updateConcept'],
    async (concept: any) => {
      const { id } = concept;

      try {
        await auth.authenticatedAPICall({
          method: id === undefined ? 'POST' : 'PUT',
          url: `/api/projects/${id === undefined ? '' : `${id}/`}`,
          data: JSON.stringify(concept),
        });
      } catch (err: any) {
        window.alert('Something went wrong creating or updating your project');
        console.log('Something went wrong creating or updating your project');
        console.log(err);
      }
    },
    {
      onSuccess: async () => queryClient.invalidateQueries(['fetchConcepts']),
    }
  );
}

export function useDeleteConcept(auth: Auth) {
  const queryClient = useQueryClient();

  return useMutation(['deleteConcept'], async (id: number) => {
    await auth.authenticatedAPICall({
      method: 'DELETE',
      url: `/api/projects/${id}/`,
    });

    queryClient.invalidateQueries(['fetchConcepts']);
  });
}

// GET GeoJSON for an individual market
async function fetchMarketGeo(auth: Auth, marketId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/market/${marketId}/`,
  });

  return response.data;
}

export function useMarketGeo(
  auth: Auth,
  marketId: string,
  options: Omit<UseQueryOptions<GeoJsonObject>, 'queryKey' | 'queryFn'> = {}
) {
  const optionsOverride = {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  };
  return useQuery<GeoJsonObject>(['fetchMarketGeo', marketId], () => fetchMarketGeo(auth, marketId), optionsOverride);
}

// GET cuisine data for an individual market
async function fetchMarketCuisines(auth: Auth, marketId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/marketCuisines/${marketId}/`,
  });

  return response.data;
}

export function useMarketCuisines(
  auth: Auth,
  marketId: string,
  options: Omit<UseQueryOptions<CuisineSummary>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<CuisineSummary>(['fetchMarketCuisines'], () => fetchMarketCuisines(auth, marketId), options);
}

// GET GeoJSON for an individual study area
async function fetchStudyAreaGeo(auth: Auth, studyAreaId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/studyAreas/${studyAreaId}/`,
  });

  return response.data;
}

export function useStudyAreaGeo(
  auth: Auth,
  studyAreaId: string,
  options: Omit<UseQueryOptions<GeoJsonObject>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<GeoJsonObject>(
    ['fetchStudyAreaGeo', studyAreaId],
    () => fetchStudyAreaGeo(auth, studyAreaId),
    options
  );
}

async function fetchStudyAreaLocations(
  auth: Auth,
  studyAreaId: string,
  type: StudyAreaLocationType
): Promise<GenericLocation[]> {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/studyAreaLocations/?studyArea=${studyAreaId}&type=${type}`,
  });

  return response.data;
}

export function useStudyAreaLocations(
  auth: Auth,
  studyAreaId: string,
  type: StudyAreaLocationType,
  options: Omit<UseQueryOptions<GenericLocation[]>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<GenericLocation[]>(
    ['fetchStudyAreaLocations', studyAreaId, type],
    () => fetchStudyAreaLocations(auth, studyAreaId, type),
    options
  );
}

// GET GeoJSON for origin-destination in an individual study area
async function fetchMarketTSGeo(auth: Auth, marketId: string, ts: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/marketTS/${marketId}/`,
    params: {
      ts: ts,
    },
  });

  return response.data;
}

export function useMarketTSGeo(
  auth: Auth,
  marketId: string,
  ts: string,
  options: Omit<UseQueryOptions<FeatureCollection>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<FeatureCollection>(['fetchMarketTSGeo'], () => fetchMarketTSGeo(auth, marketId, ts), options);
}

// GET GeoJSON for origin-destination in an individual study area
async function fetchStudyAreaODGeo(auth: Auth, studyAreaId: string, dayOfWeek: DayOfWeek) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/studyAreaOD/${studyAreaId}/`,
    params: {
      dow: dayOfWeek,
    },
  });

  return response.data;
}

export function useStudyAreaODGeo(
  auth: Auth,
  studyAreaId: string,
  dayOfWeek: DayOfWeek,
  options: Omit<UseQueryOptions<FeatureCollection>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<FeatureCollection>(
    [`fetchStudyAreaODGeo, ${dayOfWeek}`],
    () => fetchStudyAreaODGeo(auth, studyAreaId, dayOfWeek),
    options
  );
}

// GET GeoJSON for home-destination in an individual study area
async function fetchStudyAreaHomeDGeo(auth: Auth, studyAreaId: string, dayOfWeek: DayOfWeek) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/map/studyAreaHomeD/${studyAreaId}/`,
    params: {
      dow: dayOfWeek,
    },
  });

  return response.data;
}

export function useStudyAreaHomeDGeo(
  auth: Auth,
  studyAreaId: string,
  dayOfWeek: DayOfWeek,
  options: Omit<UseQueryOptions<FeatureCollection>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<FeatureCollection>(
    [`fetchStudyAreaHomeDGeo, ${dayOfWeek}}`],
    () => fetchStudyAreaHomeDGeo(auth, studyAreaId, dayOfWeek),
    options
  );
}

async function fetchStudyAreaTrends(auth: Auth, studyAreaId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/studyAreaTrends/${studyAreaId}/`,
  });

  return response.data;
}

export function useStudyAreaTrends(
  auth: Auth,
  studyAreaId: string,
  options: Omit<UseQueryOptions<TrendsSummary>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<TrendsSummary>(
    ['fetchStudyAreaTrends', studyAreaId],
    () => fetchStudyAreaTrends(auth, studyAreaId),
    options
  );
}

async function fetchPlaceToMarketAndTract(auth: Auth, placeId: string, session: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/placeToMarketAndTract/?placeId=${placeId}&session=${session}`,
  });

  return response.data;
}

export function usePlaceToMarketAndTract(auth: Auth, placeId: string, session: string) {
  return useQuery<any>(['fetchPlaceToMarketAndTract', placeId], () =>
    fetchPlaceToMarketAndTract(auth, placeId, session)
  );
}

async function fetchAddressToMarketAndTract(auth: Auth, address: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/data/placeToMarketAndTract/?address=${address}`,
  });

  return response.data;
}

export function useAddressToMarketAndTract(
  auth: Auth,
  address: string,
  options: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<any>(
    ['fetchAddressToMarketAndTract', address],
    () => fetchAddressToMarketAndTract(auth, address),
    options
  );
}

async function submitFeedback(auth: Auth, comments: string) {
  const response = await auth.authenticatedAPICall({
    method: 'POST',
    url: '/api/feedback/',
    data: { comments },
  });

  return response.data;
}

export function submitFeedbackMutation() {
  return useMutation(['submitFeedback'], ({ auth, comments }: { auth: Auth; comments: string }) =>
    submitFeedback(auth, comments)
  );
}

export function createSiteReport(auth: Auth) {
  const queryClient = useQueryClient();

  return useMutation(
    ['createSiteReport'],
    async (report: any) => {
      try {
        await auth.authenticatedAPICall({
          method: 'POST',
          url: '/api/siteReports/',
          data: JSON.stringify(report),
        });
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => queryClient.invalidateQueries(['fetchSiteReports']),
    }
  );
}

async function fetchSiteReports(auth: Auth) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/siteReports/',
  });

  return response.data;
}

export function useSiteReports(auth: Auth, options: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'> = {}) {
  return useQuery<any>(['fetchSiteReports'], () => fetchSiteReports(auth), options);
}

async function fetchSiteReport(auth: Auth, id: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/siteReports/${id}/`,
  });

  return response.data;
}

export function useSiteReport(
  auth: Auth,
  id: string,
  options: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<any>(['fetchSiteReport', id], () => fetchSiteReport(auth, id), options);
}

export function deleteSiteReport(auth: Auth) {
  const queryClient = useQueryClient();

  return useMutation(
    ['deleteSiteReport'],
    async (id: string) => {
      await auth.authenticatedAPICall({
        method: 'DELETE',
        url: `/api/siteReports/${id}/`,
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['fetchSiteReports']),
    }
  );
}

async function toggleScore(auth: Auth) {
  await auth.authenticatedAPICall({
    method: 'POST',
    url: '/api/toggle-grades/',
  });
}

export function toggleScoreMutation(auth: Auth) {
  return useMutation(['toggleScore'], () => toggleScore(auth));
}

export function useSetupPurchaseReport() {
  return useMutation(
    ['setupPurchaseReport'],
    async ({ address, reportRequestId }: { address: string; reportRequestId: number }) => {
      return await unauthenticatedAPICall({
        method: 'POST',
        url: `/api/purchase/report/`,
        data: { address, report_request_id: reportRequestId },
      });
    }
  );
}

export function useCreateReportRequest() {
  return useMutation(['createReportRequest'], async (reportRequest: any) => {
    try {
      const { data } = await unauthenticatedAPICall({
        method: 'POST',
        url: '/api/reportRequests/',
        data: reportRequest,
      });

      return data;
    } catch (err) {
      throw err;
    }
  });
}

export function useUpdateReportRequest() {
  return useMutation(
    ['updateReportRequest'],
    async ({ reportRequestId, reportRequest }: { reportRequestId: number; reportRequest: any }) => {
      try {
        const { data } = await unauthenticatedAPICall({
          method: 'PATCH',
          url: `/api/reportRequests/${reportRequestId}/`,
          data: reportRequest,
        });

        return data;
      } catch (err) {
        throw err;
      }
    }
  );
}

async function fetchReport(id: number, password: string): Promise<any> {
  const encodedPassword = btoa(password);

  const response = await unauthenticatedAPICall({
    method: 'GET',
    url: `/api/reports/${id}/`,
    headers: {
      Authorization: `Basic ${encodedPassword}`,
    },
  });

  return response.data;
}

export function useReport(
  id: number,
  password: string,
  options: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery<any>(['fetchSiteReport', id], () => fetchReport(id, password), options);
}

export function useCreateReport(auth: Auth) {
  return useMutation(['createReport'], async (concept: Concept) => {
    try {
      const { data } = await auth.authenticatedAPICall({
        method: 'POST',
        url: '/api/locationsearch/',
        data: JSON.stringify(concept),
      });

      return data;
    } catch (err) {
      throw err;
    }
  });
}

async function fetchRevenues(auth: Auth, enterpriseLocationId: number) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/score/revenues/${enterpriseLocationId}/`,
  });

  return response.data;
}

export function useRevenues(auth: Auth, enterpriseLocationId: number) {
  return useQuery<any>(['fetchRevenues', enterpriseLocationId], () => fetchRevenues(auth, enterpriseLocationId));
}

async function fetchLocationMarket(auth: Auth, marketId: string) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/location/markets/${marketId}/`,
    params: {
      subject: ['demographics', 'landscape', 'sales'],
    },
  });

  return response.data;
}

export function useLocationMarket(auth: Auth, marketId: string) {
  return useQuery(['locationMarket', marketId], () => fetchLocationMarket(auth, marketId));
}

async function fetchLocationStudyArea(auth: Auth, studyAreaId: number) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/location/studyareas/${studyAreaId}/`,
    params: {
      subject: [
        'consumer_expenditures',
        'demographics',
        'land_use',
        'landscape',
        'market_potential',
        'market_segmentation',
      ],
    },
  });

  return response.data;
}

export function useLocationStudyArea(auth: Auth, studyAreaId: number) {
  return useQuery(['locationStudyArea', studyAreaId], () => fetchLocationStudyArea(auth, studyAreaId));
}

async function fetchBorneReports(auth: Auth) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/borne-report/reports/',
  });

  return response.data;
}

export function useFetchBorneReport(
  auth: Auth,
  options: Omit<UseQueryOptions<GenericLocation[]>, 'queryKey' | 'queryFn'> = {}
) {
  return useQuery(['borneReports'], () => fetchBorneReports(auth), options);
}

async function createBorneReport(auth: Auth, data: BorneReportInput) {
  let data_flattened = {
    ...data,
    hops: data.hops.map((val: any) => Array.from(val.values())),
  };
  const response = await auth.authenticatedAPICall({
    method: 'POST',
    url: '/api/borne-report/reports/',
    data: data_flattened,
  });

  return response;
}

export function useCreateBorneReport(auth: Auth) {
  const queryClient = useQueryClient();

  return useMutation(['createBorneReport'], (data: BorneReportInput) => createBorneReport(auth, data), {
    onSuccess: () => queryClient.invalidateQueries(['borneReports']),
  });
}

async function fetchBorneReportLimit(auth: Auth) {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: '/api/borne-report/reports/limit/',
  });

  return response.data;
}

export function useFetchBorneReportLimit(auth: Auth) {
  return useQuery(['borneReports', 'limit'], () => fetchBorneReportLimit(auth));
}

const fetchAddressGeoconde = async (auth: Auth, address: string, session: string): Promise<any> => {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/addressGeocode?address=${address}&session=${session}`,
  });
  return response.data;
};

export function useAddressGeocode(auth: Auth, address: string, session: string, options?: UseQueryOptions<any>) {
  return useQuery<any>(['geoCode', address], () => fetchAddressGeoconde(auth, address, session), options);
}

const fetchGoogleDriveLinks = async (auth: Auth, flowRunId: string) => {
  const response = await auth.authenticatedAPICall({
    method: 'GET',
    url: `/api/borne-report/reports/${flowRunId}/`,
  });
  return response.data;
};

export function useFetchGoogleDriveLinks(auth: Auth, flowRunId: string, options?: UseQueryOptions<any>) {
  return useQuery<any>(['googleDriveLink', flowRunId], () => fetchGoogleDriveLinks(auth, flowRunId), options);
}
