import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './components/App';
import 'semantic-ui-css/semantic.min.css';
import 'leaflet/dist/leaflet.css';
import './fonts.css';
import { Provider } from 'react-redux';
import { store } from './components/redux/store';

// @ts-ignore
if (window.hasOwnProperty('borne_environment') && window.borne_environment == 'prod') {
  Sentry.init({
    dsn: 'https://1d36cec120204af1b01a97ebc261934f@o1054898.ingest.sentry.io/6096215',
    integrations: [new Integrations.BrowserTracing()],
    environment: 'production',
    tracesSampleRate: 0.2,
  });
}

const root = createRoot(document.getElementById('app')!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
