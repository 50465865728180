import React, { Component } from 'react';
import { Header as BaseHeader, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { formatNumber } from '../../helpers';
import { AlcoholSpending, Concept } from '../../types';

interface Props {
  color: SemanticCOLORS;
  concept: Concept;
  alcoholSpendingDict: AlcoholSpending;
}

class AlcoholSpendingSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">Alcohol Spending</BaseHeader>
        <p>
          If your restaurant serves alcohol, and people in the area spend a lot on alcohol, then it is more likely to be
          successful.
        </p>
        <p>
          {this.props.concept.alcohol_service === 'none' ? (
            <span>Your restaurant does not serve alcohol.</span>
          ) : this.props.alcoholSpendingDict.error ? (
            <p>Alcohol spending could not be retrieved for this area.</p>
          ) : (
            <span>
              Around this area, out of their total spending at similar restaurants, people spend{' '}
              <b>{formatNumber(this.props.alcoholSpendingDict['percentageOnBeer']!)}%</b> on beer,{' '}
              <b>{formatNumber(this.props.alcoholSpendingDict['percentageOnWine']!)}%</b> on wine, and{' '}
              <b>{formatNumber(this.props.alcoholSpendingDict['percentageOnLiquor']!)}%</b> on liquor.
            </span>
          )}
        </p>
      </Segment>
    );
  }
}

export default AlcoholSpendingSegment;
