import { buildChartTheme } from '@visx/xychart';

type Data = number | string | boolean;
type Row = {
  [key: string]: Data;
};
type Sample = {
  [key: string]: number;
};
type SampleBreakdown = {
  [key: string]: Array<string>;
};
type ScaleType = 'log' | 'linear' | 'band';
type GridType = 'v' | 'h' | 'full' | 'none';
type Direction = 'left' | 'bottom' | 'right' | 'top';
type Orientation = 'v' | 'h';
type ChartType = 'bar' | 'line';

interface SeriesFactoryOptions {
  data: Array<Row>; // The data [{"date": "12-3-2020", "value": 13.}, {...}, {...}]
  dataKey: string; // Uniquely identifes or prefixes the series returned by the factory
  x: string; // Identifies the attribute in data that maps to x (e.g. "date")
  y: string; // Identifies the attribute in data that maps to y (e.g. "value")
}

type SeriesFactory = (options: SeriesFactoryOptions) => JSX.Element[];

interface ChartProps {
  data: Array<Row>;
  dataKey: string;
  height?: number;
  width?: number;
  tooltip?: boolean;
  tooltipCrosshair?: Orientation;
  tooltipRenderer?: (keyStr: string, value?: Data, color?: string, datum?: Row) => JSX.Element;
  xScaleOptions?: { type: ScaleType; [key: string]: any };
  yScaleOptions?: { type: ScaleType; [key: string]: any };
  seriesArray?: JSX.Element[];
  grid?: GridType;
  axes?: Direction[];
  orientation: Orientation;
  chartOptions?: any;
}

interface BaseChartProps extends ChartProps {
  xScaleOptions: { type: ScaleType; [key: string]: any };
  yScaleOptions: { type: ScaleType; [key: string]: any };
  seriesArray: JSX.Element[];
  seriesLabels?: string[];
  grid: GridType;
  xLabelFormatter?: (value: any) => any;
  yLabelFormatter?: (value: any) => any;
  numInfoTicks?: number;
  numDependentTicks?: number;
  colors?: string[];
  role?: string;

  // TODO: Make this more elegant.
  margin?: any;
}

const colors = ['#7cb0ba', '#bccd19', '#ffc400', '#C35924', '#AA88CC', '#E8B4DA', '#bbbfc1', '#2a4e55'];

const theme = buildChartTheme({
  backgroundColor: '#FFFFFF',
  colors,
  gridColor: '#ECEBEA',
  gridColorDark: 'rgba(0,0,0,0.)',
  xTickLineStyles: {
    color: 'rgba(0,0,0,0.)',
    stroke: '0',
  },
  yTickLineStyles: {
    color: 'rgba(0,0,0,0.)',
    stroke: '0',
  },
  tickLength: 16,
  htmlLabel: {
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
    lineHeight: '18px',
  },
  svgLabelSmall: {
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
    lineHeight: '18px',
    baselineShift: '-2px',
  },
  svgLabelBig: {
    fontFamily: 'Nunito Sans',
    fontSize: '24px',
    lineHeight: '36px',
  },
});

export type {
  Data,
  Row,
  Sample,
  ScaleType,
  GridType,
  Direction,
  Orientation,
  ChartType,
  SeriesFactoryOptions,
  SeriesFactory,
  ChartProps,
  BaseChartProps,
  SampleBreakdown,
};

export { colors, theme };
