import React from 'react';
import { Grid } from 'semantic-ui-react';
import { BorneFlexContainer } from '../../generic/BorneComponents';
import { Layer, Rectangle, Sankey, Tooltip } from 'recharts';

interface DataProps {
  consumerSpending: any;
}

export const StudyAreasConsumerSpending: React.FC<DataProps> = ({ consumerSpending }: DataProps) => {
  const SankeyLabel = (props: any) => {
    const { x, y, width, height, index, payload, containerWidth } = props;

    const isOut = x + width + 6 > containerWidth;
    return (
      <Layer key={`CustomNode${index}`}>
        <Rectangle x={x} y={y} width={width} height={height} fill="#9ED2D0" fillOpacity="1" />
        <text
          textAnchor={isOut ? 'end' : 'start'}
          x={isOut ? x - 6 : x + width + 6}
          y={y + height / 2}
          fontSize="14"
          stroke="#333"
        >
          {payload.name}
        </text>
        <text
          textAnchor={isOut ? 'end' : 'start'}
          x={isOut ? x - 6 : x + width + 6}
          y={y + height / 2 + 13}
          fontSize="12"
          stroke="#333"
          strokeOpacity="0.5"
        >
          {`$${Math.round(payload.value)}`}
        </text>
      </Layer>
    );
  };
  return (
    <div>
      {consumerSpending.isSuccess && (
        <Grid.Row>
          <BorneFlexContainer className="tw-items-start">
            <div className="tw-w-[30%] tw-sticky tw-top-0 tw-pt-8">
              <p>
                Explore the spending habits of consumers in this study area. In this section, see how much an average
                household spends on food and beverage, weighted by where patrons of restaurants prefer to visit.
              </p>
            </div>
            <div className="tw-w-[70%] tw-h-full tw-pb-8">
              <Grid.Column>
                <Sankey
                  width={800}
                  height={800}
                  data={(consumerSpending.data || {})['2022']}
                  node={<SankeyLabel />}
                  nodePadding={20}
                  // @ts-ignore
                  link={{ stroke: '#bccd19' }}
                  iterations={100}
                  margin={{
                    top: 30,
                    bottom: 15,
                    right: 200,
                  }}
                >
                  <Tooltip />
                </Sankey>
              </Grid.Column>
            </div>
          </BorneFlexContainer>
        </Grid.Row>
      )}
    </div>
  );
};
