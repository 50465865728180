import React from 'react';

interface Props {
  active?: boolean;
  size?: number;
}

export const StudyAreaSvg = ({ active = false, size = 32 }: Props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <path
        fill={active ? '#bccd19' : '#2a4e55'}
        d="M20.08,3.2c9.26,0,16.79,7.53,16.79,16.79c0,9.26-7.53,16.79-16.79,16.79c-9.26,0-16.79-7.53-16.79-16.79
	C3.29,10.74,10.82,3.2,20.08,3.2 M20.08,0.2C9.17,0.2,0.29,9.08,0.29,20c0,10.91,8.88,19.79,19.79,19.79S39.88,30.91,39.88,20
	C39.88,9.08,31,0.2,20.08,0.2L20.08,0.2z M20.08,8.74c6.21,0,11.26,5.05,11.26,11.26s-5.05,11.26-11.26,11.26
	c-6.21,0-11.26-5.05-11.26-11.26S13.87,8.74,20.08,8.74 M20.08,5.74c-7.86,0-14.26,6.4-14.26,14.26s6.4,14.26,14.26,14.26
	c7.86,0,14.26-6.4,14.26-14.26S27.95,5.74,20.08,5.74L20.08,5.74z M20.08,14.27c3.16,0,5.73,2.57,5.73,5.73s-2.57,5.73-5.73,5.73
	s-5.73-2.57-5.73-5.73S16.93,14.27,20.08,14.27 M20.08,11.27c-4.81,0-8.73,3.92-8.73,8.73s3.92,8.73,8.73,8.73s8.73-3.92,8.73-8.73
	S24.9,11.27,20.08,11.27L20.08,11.27z"
      />
    </svg>
  );
};
