import { useAllStudyAreas, useSiteReports, useSubscribedMarketBounds } from '../../../api/requests';
import React, { useState } from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import {
  BorneDropDown,
  BorneFlexContainer,
  BorneLargeButton,
  BorneSmallButton,
  ContentContainer,
  PageContainer,
} from '../../generic/BorneComponents';
import SideMenu2 from '../../generic/SideMenu2';
import SideBarMenu from '../../generic/SideBarExtend';
import { BorneExpand, BorneEye, BorneSmallPlus } from '../../generic/BorneStyledcons';
import { DisplayTable } from '../../generic/BorneDisplayTable';
import { StringNullableChain } from 'lodash';
import { useDispatch } from 'react-redux';
import { commonActions } from '../../redux/testSlice';
import { DashboardMap } from './DashboardMap';
import { useNavigate } from 'react-router';
import { CellClickedEvent } from 'ag-grid-community';
import { HelpSvg } from '../../../../static/icons/icons/help';
import NewSiteReportModal from '../../pages/SiteReportList/NewSiteReportModal';
import { StudyArea } from 'borne_ui/src/types';
import CreateEditCustomStudyAreaModal from '../../modals/CreateEditCustomStudyAreaModal';
import { Link } from 'react-router-dom';

import type Auth from 'borne_ui/src/auth/Auth';

interface Props {
  auth: Auth;
  currentMarket?: number;
  setCurrentMarket: (marketIndex: number) => void;
}

interface ReportProps {
  id: string;
  address: StringNullableChain;
}

const Dashboard: React.FC<Props> = ({ auth, currentMarket, setCurrentMarket }: Props) => {
  const navigate = useNavigate();
  const [currentMutateStudyArea, setCurrentMutateStudyArea] = useState<StudyArea | undefined>(undefined);
  const [sideBarVisibility, setSideBarVisibility] = useState(false);
  const [siteModalOpen, setSiteModalOpen] = useState(false);
  const [studyModalOpen, setStudyModalOpen] = useState(false);

  // // For quick and easy testing on staging!
  // const locationStudyArea = useLocationStudyArea(auth, 371285);
  // console.log('My data!', locationStudyArea.data);

  const dispatch = useDispatch();
  const setCommonCurrentMarket = (x: string[]) => {
    dispatch(commonActions.setCurrentMarket(x));
  };
  const marketsRes = useSubscribedMarketBounds(auth);
  const studyAreas = useAllStudyAreas(auth, {
    refetchOnWindowFocus: false,
  });

  const siteReports = useSiteReports(auth);
  return (
    <PageContainer>
      <ContentContainer>
        <SideMenu2 />
        <SideBarMenu
          auth={auth}
          currentMarket={currentMarket}
          setCurrentMarket={setCurrentMarket}
          sideBarVisibility={sideBarVisibility}
          setSideBarVisibility={setSideBarVisibility}
        />
        <Grid className="tw-m-0 tw-pb-[25px] tw-w-[100%]">
          <NewSiteReportModal
            className="tw-z-[1100]"
            auth={auth}
            handleClose={() => setSiteModalOpen(false)}
            open={siteModalOpen}
            closeOnEscape={false}
          />
          <CreateEditCustomStudyAreaModal
            auth={auth}
            setOpen={setStudyModalOpen}
            isOpen={studyModalOpen}
            currentMutateStudyArea={currentMutateStudyArea}
            setCurrentMutateStudyArea={setCurrentMutateStudyArea}
            newUi
          />
          <BorneFlexContainer className="tw-my-4 tw-mx-0">
            <BorneFlexContainer className="tw-py-0.5 tw-w-[100%] tw-justify-between">
              <BorneFlexContainer>
                <BorneExpand
                  className="tw-cursor-pointer tw-mr-4 tw-min-w-[25px]"
                  onClick={() => {
                    setSideBarVisibility(!sideBarVisibility);
                  }}
                />
                <BorneFlexContainer className="lg:tw-hidden md:tw-flex tw-w-min tw-pr-2">
                  <h2 className="tw-my-0 tw-mr-0 tw-pr-0">Dashboard</h2>
                </BorneFlexContainer>
                <BorneFlexContainer className="tw-w-[15rem]">
                  {marketsRes.isSuccess && marketsRes.data.length > 0 && currentMarket != undefined ? (
                    <>
                      <p className="smallText">Market</p>
                      <BorneDropDown
                        selectOnBlur={false}
                        search
                        selection
                        placeholder="Select Market"
                        options={marketsRes.data.map((obj: any, idx: number) => ({
                          key: `dashboard-dropdown-${obj.id}`,
                          text: obj.id,
                          value: idx,
                        }))}
                        selectOnNavigation={false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, { value }: { value: number }) => {
                          setCurrentMarket(value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p className="smallText">Market</p>
                      <BorneDropDown
                        selectOnBlur={false}
                        search
                        selection
                        placeholder="Select Market"
                        noResultsMessage="Loading"
                      />
                    </>
                  )}
                </BorneFlexContainer>
              </BorneFlexContainer>
              <BorneFlexContainer className="lg:tw-justify-end lg:tw-flex lg:tw-w-full md:tw-hidden">
                <BorneSmallButton onClick={() => setStudyModalOpen(!studyModalOpen)} active>
                  <span>New Study Area</span>
                  <BorneSmallPlus />
                </BorneSmallButton>
                <BorneSmallButton className="tw-ml-[3.5%]" onClick={() => setSiteModalOpen(!siteModalOpen)} active>
                  <span>New Site Report</span>
                  <BorneSmallPlus />
                </BorneSmallButton>
              </BorneFlexContainer>
            </BorneFlexContainer>
          </BorneFlexContainer>
          <BorneFlexContainer className="lg:tw-flex md:tw-hidden">
            <h2 className="tw-my-0 tw-mr-0 tw-pr-0">Dashboard</h2>
            <HelpSvg className="tw-cursor-pointer tw-p-0 tw-pl-1" size={20} />
          </BorneFlexContainer>
          <Grid.Row>
            <Grid.Column>
              <div className="lg:tw-h-[60vh] md:tw-h-[80vh] tw-w-full tw-border-[1px] tw-border-gray-900">
                <DashboardMap auth={auth} setCurrentMarket={setCurrentMarket} currentMarket={currentMarket} />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column verticalAlign="top" className="tw-border-r-[1px]">
              <Grid.Row>
                <BorneFlexContainer className="tw-items-end tw-mb-4">
                  <h3 className="tw-mr-5 tw-ml-2.5 tw-my-0 tw-leading-none">Markets</h3>
                </BorneFlexContainer>
              </Grid.Row>
              <Grid.Row>
                <div className="tw-mb-4 tw-max-w-[28rem]">
                  {marketsRes.isSuccess && marketsRes.data.length > 0 ? (
                    <DisplayTable
                      fill
                      width={400}
                      height={125}
                      headers={[{ field: 'market', width: 382 }]}
                      onCellClick={(params: CellClickedEvent) => {
                        setCommonCurrentMarket([params.value]);
                        navigate('/markets');
                      }}
                      rowData={marketsRes.data.map((markets) => ({
                        market: markets.id,
                      }))}
                    />
                  ) : (
                    <div className="tw-w-20 tw-h-20">
                      <Loader active size="medium" />
                    </div>
                  )}
                </div>
              </Grid.Row>
              <Grid.Row>
                <BorneFlexContainer>
                  <Link to="/markets">
                    <BorneLargeButton className="tw-mr-5 tw-ml-2.5">
                      <a href="/markets">Compare Markets</a>
                      <BorneEye className="tw-mr-2.5" />
                    </BorneLargeButton>
                  </Link>
                </BorneFlexContainer>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column verticalAlign="top" className="tw-border-r-[1px]">
              <Grid.Row>
                <BorneFlexContainer className="tw-items-end tw-mb-4">
                  <h3 className="tw-mr-5 tw-ml-2.5 tw-my-0 tw-leading-none">Study Areas</h3>
                </BorneFlexContainer>
              </Grid.Row>
              <Grid.Row>
                <div className="tw-mb-4 tw-max-w-[28rem]">
                  {studyAreas.isSuccess ? (
                    <DisplayTable
                      fill
                      width={400}
                      height={125}
                      headers={[{ field: 'studyArea', width: 382, sort: 'desc' }]}
                      onCellClick={(params: CellClickedEvent) => {
                        navigate(`/studyAreas/${params.data.id as string}`);
                      }}
                      rowData={studyAreas.data?.map((sa) => ({
                        studyArea: `SA-${sa.id as string} / ${sa.name} / ${sa.market}`,
                        id: sa.id,
                      }))}
                    />
                  ) : (
                    <div className="tw-w-20 tw-h-20">
                      <Loader active size="medium" />
                    </div>
                  )}
                </div>
              </Grid.Row>
              <Grid.Row>
                <BorneFlexContainer>
                  <Link to="/studyareas">
                    <BorneLargeButton className="tw-mr-5">
                      Compare Study Areas
                      <BorneEye className="tw-mr-2.5" />
                    </BorneLargeButton>
                  </Link>
                </BorneFlexContainer>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column verticalAlign="top">
              <Grid.Row>
                <BorneFlexContainer className="tw-items-end tw-mb-4">
                  <h3 className="tw-mr-5 tw-ml-2.5 tw-my-0 tw-leading-none">Site Reports</h3>
                </BorneFlexContainer>
              </Grid.Row>
              <Grid.Row>
                <div className="tw-mb-4 tw-max-w-[28rem]">
                  {siteReports.isSuccess ? (
                    <DisplayTable
                      fill
                      width={400}
                      height={125}
                      headers={[{ field: 'siteReports', width: 382, sort: 'desc' }]}
                      onCellClick={(params: CellClickedEvent) => {
                        navigate(`/sitereports/${params.data.id}`);
                      }}
                      rowData={siteReports.data.map((report: ReportProps) => ({
                        siteReports: `SR-${report.id} / ${report.address}`,
                        id: report.id,
                      }))}
                    />
                  ) : (
                    <div className="tw-w-20 tw-h-20">
                      <Loader active size="medium" />
                    </div>
                  )}
                </div>
              </Grid.Row>
              <Grid.Row>
                <BorneFlexContainer>
                  <BorneLargeButton className="tw-mr-5 tw-ml-2.5" onClick={() => setSiteModalOpen(!siteModalOpen)}>
                    <span>Create Site Report</span>
                    <BorneEye className="tw-mr-2.5" />
                  </BorneLargeButton>
                </BorneFlexContainer>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentContainer>
    </PageContainer>
  );
};

export default Dashboard;
