import { useMarketSummary } from '../../../api/requests';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import Auth from '../../../auth/Auth';
import withSideMenu from '../../generic/MenuPage';
import ScrollSpyGrid from '../../generic/ScrollSpyGrid';
import { Divider, Grid, Icon, List } from 'semantic-ui-react';
import { Header, InlineListDescription, InlineListHeader } from '../../styled/Typography';
import { formatCurrency, formatNumber } from '../../../helpers';
import { MarketSingleMap } from '../../maps/MarketSingleMap';
import { ReportRowLayout } from '../../generic/ReportLayout';
import { MarketDetailDemographicData, MarketDetailDemographicDescription } from './MarketDetailDemographics';
import {
  MarketDetailRestaurantLandscapeData,
  MarketDetailRestaurantLandscapeDescription,
} from './MarketDetailRestaurantLandscape';
import { MarketDetailBrandImpactData, MarketDetailBrandImpactDescription } from './MarketDetailBrandImpact';

interface Params {
  id: string;
}

interface Props {
  auth: Auth;
}

const MarketDetail: React.FC<Props> = ({ auth }: Props) => {
  const { id } = useParams<keyof Params>() as Params;
  const marketsRes = useMarketSummary(auth, [id], {
    refetchOnWindowFocus: false,
  });

  const [selectedBrands, setSelectedBrands] = useState<Set<string>>(new Set());

  const updateBrandSelection = (event: { action: string; brandNames: string[] }) => {
    switch (event.action) {
      case 'add':
        setSelectedBrands((currentSelectedBrands) => {
          const newSelectedBrands = new Set(currentSelectedBrands);
          for (let brand of event.brandNames) {
            newSelectedBrands.add(brand);
          }
          return newSelectedBrands;
        });
        break;
      case 'remove':
        setSelectedBrands((currentSelectedBrands) => {
          const newSelectedBrands = new Set(currentSelectedBrands);
          for (let brand of event.brandNames) {
            newSelectedBrands.delete(brand);
          }
          return newSelectedBrands;
        });
        break;
      default:
        throw Error(`Invalid event action: ${event.action}`);
    }
  };

  return (
    <>
      {marketsRes.isSuccess && !marketsRes.isFetching && (
        <ScrollSpyGrid title={id}>
          <Grid.Row style={{ height: '50vh' }}>
            <Grid.Column width="5" style={{ paddingTop: '2em', paddingLeft: '3vw' }}>
              <Header as="h1">
                <Icon color="olive" name="map signs" />
                {id}
              </Header>
              <Divider hidden={true} />
              <List>
                <List.Item>
                  <InlineListHeader>Number of locations</InlineListHeader>
                  <InlineListDescription>{formatNumber(marketsRes.data[0].number_of_locations)}</InlineListDescription>
                </List.Item>
                <List.Item>
                  <InlineListHeader>Population</InlineListHeader>
                  <InlineListDescription>{formatNumber(marketsRes.data[0].population)}</InlineListDescription>
                </List.Item>
                <List.Item>
                  <InlineListHeader>Median household income</InlineListHeader>
                  <InlineListDescription>
                    {formatCurrency(marketsRes.data[0].household_income_median)}
                  </InlineListDescription>
                </List.Item>
                <List.Item>
                  <InlineListHeader>Median age</InlineListHeader>
                  <InlineListDescription>{formatNumber(marketsRes.data[0].age_median)}</InlineListDescription>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width="4" />
            <Grid.Column width="7">
              <MarketSingleMap auth={auth} marketId={id} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row scrollspyname="Restaurant Landscape">
            <ReportRowLayout
              DescriptionComponent={<MarketDetailRestaurantLandscapeDescription />}
              DataComponent={
                <MarketDetailRestaurantLandscapeData
                  marketRestaurantLandscapeData={marketsRes.data[0]}
                  auth={auth}
                  marketId={id}
                />
              }
            />
          </Grid.Row>
          <Grid.Row scrollspyname="Demographics">
            <ReportRowLayout
              DescriptionComponent={<MarketDetailDemographicDescription />}
              DataComponent={<MarketDetailDemographicData marketSummaryData={marketsRes.data[0]} />}
            />
          </Grid.Row>
          <Grid.Row scrollspyname="Brand Impact">
            <ReportRowLayout
              DescriptionComponent={
                <MarketDetailBrandImpactDescription
                  auth={auth}
                  marketId={id}
                  selectedBrands={selectedBrands}
                  dispatchBrandSelection={updateBrandSelection}
                />
              }
              DataComponent={<MarketDetailBrandImpactData auth={auth} marketId={id} selectedBrands={selectedBrands} />}
            />
          </Grid.Row>
        </ScrollSpyGrid>
      )}
    </>
  );
};

export default withSideMenu(MarketDetail, { activeItem: 'markets' });
