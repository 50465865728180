import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

const EnsureReportRequestState = ({ children }: Props) => {
  const { state } = useLocation();

  return state?.reportRequest ? <>{children}</> : <Navigate to="/report/location" replace />;
};

export default EnsureReportRequestState;
