import { Map } from 'immutable';
import { DayOfWeek, Schedule, TimeInterval, TwentyFourHourTimeString } from './index';

export interface ScheduleReducerState {
  scheduleState: Schedule;
}

export interface ScheduleReducerAction {
  actionType:
    | 'create'
    | 'clear'
    | 'addShift'
    | 'removeShift'
    | 'addDay'
    | 'removeDay'
    | 'changeShiftStart'
    | 'duplicateShiftStart'
    | 'duplicateShiftEnd'
    | 'duplicateAddShift'
    | 'duplicateRemoveShift'
    | 'changeShiftEnd';
  day?: DayOfWeek;
  shift?: TwentyFourHourTimeString;
  shiftId?: number;
}

export const scheduleReducer = (
  { scheduleState }: ScheduleReducerState,
  { actionType, day, shift, shiftId }: ScheduleReducerAction
) => {
  let newState: ScheduleReducerState;
  switch (actionType) {
    case 'create':
      break;
    case 'clear':
      break;
    case 'addDay':
      newState = { scheduleState: scheduleState.set(day!, Map<number, TimeInterval>().set(1, ['11:00', '21:00'])) };
      break;
    case 'removeDay':
      newState = { scheduleState: scheduleState.delete(day!) };
      break;
    case 'addShift':
      newState = {
        scheduleState: scheduleState.set(
          day!,
          scheduleState.get(day!)!.set(scheduleState.get(day!)!.keySeq().size! + 1, ['11:00', '21:00'])
        ),
      };
      break;
    case 'removeShift':
      newState = { scheduleState: scheduleState.set(day!, scheduleState.get(day!)!.delete(shiftId!)) };
      break;
    case 'changeShiftStart':
      newState = {
        scheduleState: scheduleState.set(
          day!,
          scheduleState.get(day!)!.set(shiftId!, [shift!, scheduleState.get(day!)!.get(shiftId!)![1]])
        ),
      };
      break;
    case 'changeShiftEnd':
      newState = {
        scheduleState: scheduleState.set(
          day!,
          scheduleState.get(day!)!.set(shiftId!, [scheduleState.get(day!)!.get(shiftId!)![0], shift!])
        ),
      };
      break;
    case 'duplicateShiftStart':
      let what = scheduleState.map((item) => item.set(shiftId!, [shift, scheduleState.get(day!)!.get(shiftId!)![1]]));
      newState = { scheduleState: what };
      break;
    case 'duplicateShiftEnd':
      let who = scheduleState.map((item) => item.set(shiftId!, [scheduleState.get(day!)!.get(shiftId!)![0], shift]));
      newState = { scheduleState: who };
      break;
    case 'duplicateAddShift':
      let when = scheduleState.map((item) => item.set(scheduleState.get(day!)!.keySeq().size! + 1, ['11:00', '21:00']));
      newState = { scheduleState: when };
      break;
    case 'duplicateRemoveShift':
      console.log(shiftId);
      let where = scheduleState.map((item) => item.delete(shiftId!));
      newState = { scheduleState: where };
      break;
    default:
      throw Error('Invalid Layer actionType Required');
  }
  return newState!;
};
