import React, { useState } from 'react';
import { Button, Modal, Grid, Divider, Form } from 'semantic-ui-react';
import { Header } from '../styled/Typography';
import { AppContext } from '../AppContext';
import { submitFeedbackMutation } from '../../api/requests';

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const SentryFeedbackModal: React.FC<Props> = ({ isOpen, setOpen }: Props) => {
  const [comments, setComments] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const submitFeedback = submitFeedbackMutation();

  return (
    <AppContext.Consumer>
      {(appContext) => (
        <Modal
          onClose={() => {
            setOpen(false);
          }}
          onOpen={() => setOpen(true)}
          open={isOpen}
          dimmer="blurring"
          style={{ width: '50vw', minWidth: '800px' }}
        >
          <Modal.Header>
            <Header as="h1">Provide Feedback to Borne</Header>
          </Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Column>
                <Modal.Description>
                  <p>
                    Do you have a recommendation? Are you experiencing a bug whilst navigating through the application?
                    Please let us know so we can swiftly address your concerns!
                  </p>
                  <Form>
                    <Form.TextArea
                      label="Please describe your problem or question below:"
                      value={comments}
                      onChange={(e) => {
                        setComments(e.target.value);
                      }}
                    />
                  </Form>
                  <Divider hidden />
                </Modal.Description>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button icon="times" color="black" onClick={() => setOpen(false)} content="Cancel" />
            <Button
              content="Submit"
              icon="comments"
              loading={loading}
              disabled={!comments}
              onClick={async () => {
                setLoading(true);
                try {
                  await submitFeedback.mutateAsync({ auth: appContext.auth, comments });
                } catch (error) {
                  console.error(error);

                  // TODO: Show an error!
                }

                setLoading(false);
                setOpen(false);
                setComments('');
              }}
              positive
            />
          </Modal.Actions>
        </Modal>
      )}
    </AppContext.Consumer>
  );
};

export default SentryFeedbackModal;
