import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';
import { Menu, Grid, Header, Dimmer, Loader, Container } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Auth from '../../../auth/Auth';
import withSideMenu from '../../generic/MenuPage';

import { Link } from 'react-router-dom';
import ClusterMap from '@bornellc/bach/ClusterMap';
import { ReportRowLayout } from '../../generic/ReportLayout';
import { DemographicDataSegment, DemographicDescriptionSegment } from './MarketDetailSections/DemographicSection';
import {
  ClosedRestaurantDataSegment,
  ClosedRestaurantDescriptionSegment,
} from './MarketDetailSections/ClosedRestaurantSection';
import {
  ClusterComparisonDescriptionSegment,
  ClusterComparisonDataSegment,
} from './MarketDetailSections/ClusterComparisonSection';
import { AccessMapDataSegment, AccessMapDescriptionSegment } from './MarketDetailSections/AccessMap';
import { BrandImpactDataSegment, BrandImpactDescriptionSegment } from './MarketDetailSections/BrandImpactSection';

const Date = styled.div`
  text-transform: uppercase;
`;

const Brand = styled.div`
  font-size: 1.5em;
  font-weight: bold;
`;

interface Params {
  reportId: string;
  index: string;
}

interface Props {
  auth: Auth;
}

const MarketDetailPage: React.FC<Props> = (props: Props) => {
  const { auth } = props;
  const { reportId, index } = useParams<keyof Params>() as Params;

  const [report, setReport] = useState<any>();
  const [detailReport, setDetailReport] = useState<any>();
  const [demographicKey, setDemographicKey] = useState<string>('gender');
  const [closedRestaurantKey, setClosedRestaurantKey] = useState<string>('price_level');
  const [accessMap, setAccessMap] = useState<any>();

  const demographicBreakdown = {
    ethnicity: ['white', 'black', 'asian', 'hispanic_or_latino', 'pacific_islander', 'native_american'],
    gender: ['total_males', 'total_females'],
    age: ['0_to_9', '10_to_19', '20_to_29', '30_to_39', '40_to_49', '50_to_59', '60_to_69', '70_to_79', '80_plus'],
    household_income: [
      'under_15k',
      'within_15k_30k',
      'within_30k_45k',
      'within_45k_60k',
      'within_60k_75k',
      'above_75k',
    ],
    education: [
      'less_than_high_school',
      'high_school_graduate',
      'some_college',
      'bachelors',
      'graduate_or_professional',
    ],
  };

  const closedRestaurantBreakdown = {
    price_level: ['price_level_$', 'price_level_$$', 'price_level_$$$', 'price_level_$$$$'],
    service_quality: [
      'service_quality_quick_serve',
      'service_quality_fast_casual',
      'service_quality_casual',
      'service_quality_fine_dining',
    ],
  };

  // Cluster Selection
  const clusterSelectionReducer = (selectedClusters: Set<string>, event: { action: string; clusterId: string }) => {
    switch (event.action) {
      case 'add':
        selectedClusters.add(event.clusterId);
        break;
      case 'remove':
        selectedClusters.delete(event.clusterId);
        break;
      default:
        throw Error(`Invalid event action: ${event.action}`);
    }
    return new Set(selectedClusters);
  };
  const [selectedClusters, dispatchClusterSelection] = useReducer(clusterSelectionReducer, new Set([]));

  // Brand Selection
  const brandSelectionReducer = (selectedBrands: Set<string>, event: { action: string; brandId: string }) => {
    switch (event.action) {
      case 'add':
        selectedBrands.add(event.brandId);
        break;
      case 'remove':
        selectedBrands.delete(event.brandId);
        break;
      default:
        throw Error(`Invalid event action: ${event.action}`);
    }
    return new Set(selectedBrands);
  };
  const [selectedBrands, dispatchBrandSelection] = useReducer(brandSelectionReducer, new Set([]));

  const marketDemographics = report?.data.demographics.filter(
    (record: any) => record.post_office_city == detailReport?.post_office_city
  )[0];

  // Add age breakdown to market demographics.
  if (marketDemographics) {
    marketDemographics['0_to_9'] = marketDemographics['females_0_to_9'] + marketDemographics['males_0_to_9'];
    marketDemographics['10_to_19'] = marketDemographics['females_10_to_19'] + marketDemographics['males_10_to_19'];
    marketDemographics['20_to_29'] = marketDemographics['females_20_to_29'] + marketDemographics['males_20_to_29'];
    marketDemographics['30_to_39'] = marketDemographics['females_30_to_39'] + marketDemographics['males_30_to_39'];
    marketDemographics['40_to_49'] = marketDemographics['females_40_to_49'] + marketDemographics['males_40_to_49'];
    marketDemographics['50_to_59'] = marketDemographics['females_50_to_59'] + marketDemographics['males_50_to_59'];
    marketDemographics['60_to_69'] = marketDemographics['females_60_to_69'] + marketDemographics['males_60_to_69'];
    marketDemographics['70_to_79'] = marketDemographics['females_70_to_79'] + marketDemographics['males_70_to_79'];
    marketDemographics['80_plus'] = marketDemographics['females_80_plus'] + marketDemographics['males_80_plus'];
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await auth.authenticatedAPICall({
          method: 'GET',
          url: `/api/reports/${reportId}/`,
        });

        setReport(response.data);
        console.log(response.data);
        setDetailReport(response.data.data.market_detail[index]);
      } catch (err) {
        console.log(err);
        window.alert('An error occurred retrieving your report');
      }
    })();
  }, []);

  return detailReport ? (
    <>
      <Menu fixed="bottom">
        <Menu.Item as="div">
          <Link to={`/scaleReport/${reportId}/`}>Back to Market Comparison</Link>
        </Menu.Item>

        <Menu.Item as="a" header position="right">
          <Menu.Header>
            <Brand>{report?.concept.name || "Carl's Jr."}</Brand>
            <Date>{moment(report?.concept.updated).format('MMM D, YYYY')}</Date>
          </Menu.Header>
        </Menu.Item>
      </Menu>
      <Grid style={{ paddingBottom: '10em' }} padded="vertically">
        <Grid.Row>
          <ClusterMap
            mapData={detailReport?.report['map']}
            renderDetailPaneForCluster={(clusterId, data) => (
              <>
                <Grid>
                  <Grid.Row>
                    <Header>{data.name}</Header>
                  </Grid.Row>
                </Grid>
              </>
            )}
            width={1030}
            height={500}
          />
        </Grid.Row>
        <Grid.Row>
          <ReportRowLayout
            DescriptionComponent={
              <DemographicDescriptionSegment
                marketName={detailReport?.post_office_city}
                populationPerCircle={Math.floor(marketDemographics['population'] / 100)}
                keys={Object.keys(demographicBreakdown)}
                curKey={demographicKey}
                setCurKey={setDemographicKey}
              />
            }
            DataComponent={
              <DemographicDataSegment
                breakdown={demographicBreakdown}
                sample={marketDemographics}
                keys={Object.keys(demographicBreakdown)}
                curKey={demographicKey}
              />
            }
          />
        </Grid.Row>
        <Grid.Row>
          <ReportRowLayout
            DescriptionComponent={
              <ClusterComparisonDescriptionSegment
                clusterOptions={detailReport?.report['map'].data.map((cluster: any) => cluster.cluster_id)}
                clusterNames={
                  new Map(detailReport?.report['map'].data.map((cluster: any) => [cluster.cluster_id, cluster.name]))
                }
                selectedClusters={selectedClusters}
                dispatchClusterSelection={dispatchClusterSelection}
              />
            }
            DataComponent={
              <ClusterComparisonDataSegment
                selectedClusters={selectedClusters}
                clusterNames={
                  new Map(detailReport?.report['map'].data.map((cluster: any) => [cluster.cluster_id, cluster.name]))
                }
                report={detailReport?.report}
              />
            }
          />
        </Grid.Row>
        <Grid.Row>
          <ReportRowLayout
            DescriptionComponent={
              <BrandImpactDescriptionSegment
                selectedBrands={selectedBrands}
                dispatchBrandSelection={dispatchBrandSelection}
                brandNames={detailReport?.report['brands'].map(({ brand_name }: any) => brand_name)}
                brandId={report?.concept.brand_id}
              />
            }
            DataComponent={
              <BrandImpactDataSegment
                selectedBrands={selectedBrands}
                report={detailReport?.report}
                brandId={report?.concept.brand_id}
              />
            }
          />
        </Grid.Row>
        <Grid.Row>
          <ReportRowLayout
            DescriptionComponent={
              <ClosedRestaurantDescriptionSegment
                marketName={detailReport?.post_office_city}
                totalClosedRestaurants={closedRestaurantBreakdown.price_level.reduce(
                  (a, b) => a + detailReport?.report['closed_restaurants'][b],
                  0
                )}
                keys={Object.keys(closedRestaurantBreakdown)}
                curKey={closedRestaurantKey}
                setCurKey={setClosedRestaurantKey}
              />
            }
            DataComponent={
              <ClosedRestaurantDataSegment
                breakdown={closedRestaurantBreakdown}
                totalClosedRestaurants={closedRestaurantBreakdown.price_level.reduce(
                  (a, b) => a + detailReport?.report['closed_restaurants'][b],
                  0
                )}
                sample={detailReport?.report['closed_restaurants']}
                keys={Object.keys(closedRestaurantBreakdown)}
                curKey={closedRestaurantKey}
              />
            }
          />
        </Grid.Row>
        <Grid.Row>
          <ReportRowLayout
            DescriptionComponent={
              <AccessMapDescriptionSegment
                auth={auth}
                post_office_city={detailReport?.post_office_city}
                setAccessMap={setAccessMap}
              />
            }
            DataComponent={<AccessMapDataSegment mapData={detailReport?.report['map']} accessMap={accessMap} />}
          />
        </Grid.Row>
      </Grid>
    </>
  ) : (
    <Container fluid style={{ paddingTop: '50vh' }}>
      <Dimmer active inverted>
        <Loader size="big">Loading Report</Loader>
      </Dimmer>
    </Container>
  );
};

export default withSideMenu(MarketDetailPage, { activeItem: 'reports' });
