import React, { Component } from 'react';
import { Header as BaseHeader, Segment, SemanticCOLORS } from 'semantic-ui-react';
import { formatCurrency } from '../../helpers';
import { MedianHouseholdIncome } from '../../types';

interface Props {
  color: SemanticCOLORS;
  medianIncomeDict: MedianHouseholdIncome;
}

class MedianIncomeSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment color={this.props.color}>
        <BaseHeader as="h3">Median Income</BaseHeader>
        <p>
          One must take the median income of the area into account when trying to place higher-end concepts.
          Lower-priced locations need to know, also, what the pricing bounds should be, especially for neighborhood
          locations.
        </p>
        <p>
          {this.props.medianIncomeDict['error'] ? (
            this.props.medianIncomeDict['error']
          ) : (
            <span>
              The median income for this area is{' '}
              <b>{formatCurrency(this.props.medianIncomeDict['medianHouseholdIncome']!)}</b>.
            </span>
          )}
        </p>
      </Segment>
    );
  }
}

export default MedianIncomeSegment;
