import React, { useEffect, useMemo, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { useMarketGeo } from '../../../api/requests';
import { useSubscribedMarketBounds } from '../../../api/requests';
import { TileLayer, useMap } from 'react-leaflet';
import GeoJSON from '../../generic/GeoJSON';
import Auth from '../../../auth/Auth';

interface Props {
  auth: Auth;
  marketId: string;
  numberOfMarkets?: number;
}

export const MarketMap2: React.FC<Props> = ({ auth, marketId, numberOfMarkets }: Props) => {
  const [mapSwitch, setMapSwitch] = useState(false);
  const marketsRes = useSubscribedMarketBounds(auth, {
    onSuccess: (data) => {
      if (data.some((market) => market.id == marketId)) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].id === marketId) {
            setMapSwitch(true);
          }
        }
      } else {
        setMapSwitch(false);
      }
    },
    refetchOnWindowFocus: false,
  });
  const map = useMap();
  map.removeControl(map.zoomControl);
  map.doubleClickZoom.disable();
  map.scrollWheelZoom.disable();
  map.dragging.disable();
  useEffect(() => {
    if (marketGeoRes.data?.bbox) {
      map.invalidateSize();
    }
  }, [numberOfMarkets, marketId]);

  const marketGeoRes = useMarketGeo(auth, marketId);

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
    ),
    [marketId]
  );

  if (marketsRes.isSuccess) {
    return (
      <>
        {mapSwitch && marketGeoRes.isSuccess ? (
          <>
            {Tiles}
            <GeoJSON
              data={marketGeoRes.data}
              style={(_) => ({
                color: '#0c7c59',
              })}
            />
          </>
        ) : (
          ''
        )}
      </>
    );
  } else {
    return (
      <>
        {marketGeoRes.isSuccess ? (
          <>
            {Tiles}
            <GeoJSON
              data={marketGeoRes.data}
              style={(_) => ({
                color: '#0c7c59',
              })}
            />
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
};
