import React from 'react';
import { WrappedButtonGroupField } from './FormikUtil';

const options: Readonly<Record<number, string>> = {
  0: 'No',
  1: 'Yes',
};

interface Props {
  loc: string;
}

const ReservationQuestion = ({ loc }: Props) => {
  return (
    <WrappedButtonGroupField
      label="Does your restaurant plan to use a reservation system?"
      options={options}
      name={loc}
    />
  );
};

export default ReservationQuestion;
