import { useFetchBorneReport } from '../../../api/requests';
import Auth from 'borne_ui/src/auth/Auth';
import React from 'react';
import { useNavigate } from 'react-router';
import { Icon, Label, Loader } from 'semantic-ui-react';
import { ReportPage } from '../../generic/BorneComponents';
import BorneUiMobileMenu from './Menu';
import { IconMenu2 } from '@tabler/icons-react';

interface Props {
  auth: Auth;
}

const MyReports: React.FC<Props> = ({ auth }: Props) => {
  const [opened, setOpened] = React.useState(false);

  const borneReports = useFetchBorneReport(auth);
  const navigate = useNavigate();

  return (
    <>
      <BorneUiMobileMenu opened={opened} setOpened={setOpened} />
      <ReportPage>
        <div className="tw-flex-col tw-w-full">
          <div className="lg:tw-hidden md:tw-hidden tw-mb-4">
            <IconMenu2 size={40} color="#2a4e55" onClick={() => setOpened(true)} />
          </div>
          <div className="tw-w-full tw-rounded-lg tw-bg-white tw-shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
            <div className="tw-px-4 tw-rounded-t-lg tw-mb-0 tw-pt-6 tw-bg-white tw-w-[100%] tw-border-b-[1px]">
              <h3 className="tw-mb-0">Your Reports</h3>
              <span>Select a report see more</span>
            </div>
            <div className="tw-p-6">
              {borneReports.isSuccess ? (
                borneReports.data.map((report: any, i: any) => {
                  return (
                    <div
                      className="tw-grid tw-grid-cols-2 tw-mb-6 tw-gap-1 tw-cursor-pointer"
                      onClick={() => navigate(`/reportstatus/${report.id}`)}
                    >
                      <h5 className="tw-mb-1">
                        {report.state_type === 'COMPLETED' ? (
                          <Label className="tw-flex tw-w-min" color="green" horizontal>
                            <Icon className="tw-mr-0 lg:tw-mr-1" name="check" />
                            <span className="tw-hidden lg:tw-flex">Completed</span>
                          </Label>
                        ) : report.state_type === 'PENDING' ? (
                          <Label className="tw-flex sm:tw-pb-4 tw-w-min" color="yellow" horizontal>
                            <span className="tw-hidden lg:tw-flex">Pending</span>
                          </Label>
                        ) : (
                          <Label className=" lg:tw-flex tw-w-min" color="grey" horizontal>
                            <span className="tw-hidden lg:tw-flex">Delayed </span>
                          </Label>
                        )}
                      </h5>
                      <div className="tw-justify-self-end tw-text-gray-400">{report.created}</div>
                      <div className="tw-mb-0 tw-pb-0 tw-col-span-2">{report.name}</div>
                    </div>
                  );
                })
              ) : (
                <div className="tw-pt-3">
                  <Loader active inline="centered" />
                </div>
              )}
            </div>
          </div>
        </div>
      </ReportPage>
    </>
  );
};

export default MyReports;
