import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import ModelExplanationPopup from '../../generic/ModelExplanationPopup';
import { useStudyAreaGrade } from '../../../api/requests';
import { gradeColors } from '../../../data/gradeColors';

import type Auth from '../../../auth/Auth';
import type { User } from '../../../api/models';

interface Props {
  auth: Auth;
  studyAreaId: string;
  selectedConceptId?: number;
  considerImpact: boolean;
  user: User;
}

const GradeStatistic = ({ auth, studyAreaId, selectedConceptId, considerImpact, user }: Props) => {
  const [gradePopupOpen, setGradePopupOpen] = useState<boolean>(false);

  const studyAreaBrandScoreRes = useStudyAreaGrade(auth, studyAreaId, [selectedConceptId!], considerImpact, {
    enabled: !!selectedConceptId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnWindowFocus: false,
  });

  return (
    <div>
      {studyAreaBrandScoreRes.isSuccess ? (
        <ModelExplanationPopup
          header={
            <>
              About the Borne Score<sup>&trade;</sup>
            </>
          }
          subtext={
            <>
              The Borne Score is calculated with a mix of multiple different data points that culminate into a viability
              assessment. These data points include the following:
            </>
          }
          open={gradePopupOpen}
          setOpen={setGradePopupOpen}
          trigger={
            <div
              className="tw-w-[150px] tw-h-[150px] tw-text-white tw-flex tw-justify-center"
              style={{
                backgroundColor: gradeColors.get(studyAreaBrandScoreRes.data[selectedConceptId!].grade!),
                alignItems: 'center',
                borderRadius: '50%',
                fontWeight: '900',
                fontSize: '50px',
              }}
            >
              <>
                {studyAreaBrandScoreRes.data[selectedConceptId!].score != null
                  ? studyAreaBrandScoreRes.data[selectedConceptId!].score
                  : studyAreaBrandScoreRes.data[selectedConceptId!].grade}
              </>
            </div>
          }
          user={user}
        />
      ) : (
        <div className="tw-w-[150px] tw-h-[150px] tw-flex tw-flex-col tw-justify-center tw-items-center">
          <Popup
            trigger={<Icon disabled size="huge" name="question" style={{ cursor: 'pointer' }} />}
            content="To see the Study Area Brand Match score, please select a concept above."
            position="top center"
          />
          <span>Grade</span>
        </div>
      )}
      {
        // The "false" condition to short circuit the explanation trigger was
        // requested by Ken so that this does not show up in the demos that
        // he is giving while the data is being mocked.
        false && user.enterprise_id === 1 && studyAreaBrandScoreRes.isSuccess && !gradePopupOpen && (
          <a
            href="#"
            className="tw-block tw-underline tw-text-black tw-text-center tw-mt-4"
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              setGradePopupOpen(true);
              e.preventDefault();
            }}
          >
            What's this mean?
          </a>
        )
      }
    </div>
  );
};

export default GradeStatistic;
