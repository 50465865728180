import React from 'react';
import { CommunityType } from '../../data/categories';
import { WrappedButtonGroupField } from './FormikUtil';

const options: Readonly<Record<CommunityType, string>> = {
  urban: 'Urban',
  suburban: 'Suburban',
  rural: 'Rural',
};

interface Props {
  loc: string;
}

const CommunityTypeQuestion = ({ loc }: Props) => {
  return (
    <WrappedButtonGroupField label="Please choose the community around your restaurant:" options={options} name={loc} />
  );
};

export default CommunityTypeQuestion;
