import BarChart from '@bornellc/bach/BarChart';
import { Header, Description, ChartHeader } from '../../styled/Typography';
import React from 'react';
import { Divider, Dropdown, Form, Grid, Icon, List, Segment } from 'semantic-ui-react';
import Auth from '../../../auth/Auth';
import { useMarketChains } from '../../../api/requests';
import { strSnakeToProperCase } from '../../../helpers';

interface DescriptionProps {
  marketId: string;
  auth: Auth;
  selectedBrands: Set<string>;
  dispatchBrandSelection: React.Dispatch<{
    action: string;
    brandNames: string[];
  }>;
}

interface Chain {
  name: string;
  total: number;
}

export const MarketDetailBrandImpactDescription: React.FC<DescriptionProps> = ({
  auth,
  marketId,
  selectedBrands,
  dispatchBrandSelection,
}) => {
  const chains = useMarketChains(auth, marketId);

  function sortChains(chains: Chain[]) {
    const result = [...chains];
    result.sort((a, b) => a.name.localeCompare(b.name));
    return result;
  }

  return (
    <>
      <Header as="h2">Brand Impact</Header>
      <Description as="p">Compare your brand's development to other brands in this market.</Description>
      {chains.isSuccess && (
        <>
          <Form>
            <Dropdown
              placeholder="Add a brand"
              fluid
              search
              selection
              value=""
              disabled={!chains.data.length}
              options={sortChains(chains.data)
                .filter((chain: Chain) => !selectedBrands.has(chain.name))
                .map((chain: Chain) => ({
                  text: chain.name,
                  value: chain.name,
                }))}
              onChange={(_, data) => {
                dispatchBrandSelection({ action: 'add', brandNames: [data.value as string] });
              }}
            />
          </Form>
          <h4>Selected brands:</h4>
          <List selection verticalAlign="middle">
            {selectedBrands.size ? (
              Array.from(selectedBrands).map((brandName) => (
                <List.Item
                  key={brandName}
                  onClick={() => dispatchBrandSelection({ action: 'remove', brandNames: [brandName] })}
                >
                  <Icon name="close" />
                  <List.Content>
                    <List.Description>{brandName}</List.Description>
                  </List.Content>
                </List.Item>
              ))
            ) : (
              <List.Item disabled>
                <List.Content>
                  <List.Header style={{ color: '#a0a0a0' }}>No brands selected</List.Header>
                </List.Content>
              </List.Item>
            )}
          </List>
        </>
      )}
    </>
  );
};

interface DataProps {
  marketId: string;
  auth: Auth;
  selectedBrands: Set<string>;
}

export const MarketDetailBrandImpactData: React.FC<DataProps> = ({ marketId, auth, selectedBrands }) => {
  const chains = useMarketChains(auth, marketId);

  const data = chains.data?.filter((chain) => selectedBrands.has(chain.name)) || [];

  return (
    <>
      {chains.isSuccess && selectedBrands.size ? (
        <Grid attached="top" textAlign="center" padded="horizontally" columns={2} doubling>
          <Grid.Row>
            <Grid.Column>
              <ChartHeader as="h3">Number of Locations</ChartHeader>
              <Divider fitted />
              <BarChart
                grid="v"
                orientation="h"
                x="name"
                y="total"
                tooltip
                tooltipRenderer={(keyStr, value, color, _) => (
                  <span style={{ color: color, fontWeight: 300, fontSize: '12pt' }}>
                    <strong>{strSnakeToProperCase(keyStr)}:</strong> {value}
                  </span>
                )}
                data={data}
                dataKey="brands_bar_chart"
                height={230}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <Segment placeholder />
      )}
    </>
  );
};
