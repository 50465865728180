import React from 'react';
import { RestaurantType } from '../../data/categories';
import { WrappedButtonGroupField } from './FormikUtil';

const options: Readonly<Record<RestaurantType, string>> = {
  neighborhood: 'Neighborhood',
  destination: 'Destination',
};

interface Props {
  loc: string;
}

const RestaurantTypeQuestion = ({ loc }: Props) => {
  return <WrappedButtonGroupField label="Please choose the restaurant type:" options={options} name={loc} />;
};

export default RestaurantTypeQuestion;
