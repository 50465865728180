import Auth from 'borne_ui/src/auth/Auth';
import React, { Dispatch, useEffect, useMemo, useReducer, useState } from 'react';
import { Feature, Geometry } from 'geojson';
import { Button, Modal, Grid, Divider, List, Form, Popup } from 'semantic-ui-react';
import {
  MarketBlockGroupSelectionDispatch,
  MarketBlockGroupSelectionState,
  MarketBlockGroupSelectMap,
} from '../maps/MarketBlockGroupSelectMap';
import { addStudyAreaMutation, editStudyAreaMutation, useMarketBlockGroupGeos } from '../../api/requests';
import { BlockGroupProperties, StudyArea } from 'borne_ui/src/types';
import { Header } from '../styled/Typography';
import { BorneFlexContainer } from '../generic/BorneComponents';
import { BorneCloseIcon } from '../generic/BorneStyledcons';

interface Props {
  auth: Auth;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  currentMutateStudyArea?: StudyArea;
  setCurrentMutateStudyArea: Dispatch<StudyArea | undefined>;
  newUi?: boolean;
  currentMarket?: number;
}

export const CreateEditCustomStudyAreaModal: React.FC<Props> = ({
  auth,
  isOpen,
  setOpen,
  currentMutateStudyArea,
  setCurrentMutateStudyArea,
  newUi = false,
  currentMarket,
}: Props) => {
  const [studyAreaName, setStudyAreaName] = useState<string>(currentMutateStudyArea ? currentMutateStudyArea.name : '');
  const [shareWithEnterprise, setShareWithEnterprise] = useState<boolean>(
    currentMutateStudyArea ? currentMutateStudyArea.shared_w_enterprise : false
  );
  const addStudyArea = addStudyAreaMutation(auth);
  const editStudyArea = editStudyAreaMutation(auth);

  const selectionReducer = (
    selectionState: MarketBlockGroupSelectionState,
    action: MarketBlockGroupSelectionDispatch
  ): MarketBlockGroupSelectionState => {
    switch (action.type) {
      case 'add':
        selectionState.blockGroups.add(action.blockGroup!);
        break;
      case 'remove':
        selectionState.blockGroups.delete(action.blockGroup!);
        break;
      case 'reset':
        return {
          blockGroups: new Set<string>([]),
          selectedMarket: action.selectedMarket ?? '',
        };
      default:
        throw Error('Invalid selection action');
    }
    return {
      blockGroups: new Set(selectionState.blockGroups),
      selectedMarket: selectionState.selectedMarket,
    };
  };

  const [selectionState, dispatchSelection] = useReducer(selectionReducer, {
    blockGroups: new Set<string>([]),
    selectedMarket: '',
  });

  const marketBlockGroups = useMarketBlockGroupGeos(auth, selectionState.selectedMarket, {
    enabled: !!selectionState.selectedMarket,
    refetchOnWindowFocus: false,
  });

  const blockGroupToTotalLocationMap = useMemo(() => {
    const blockGroups = marketBlockGroups.data?.features || [];
    return Object.fromEntries(
      blockGroups.map((blockGroup: Feature<Geometry, BlockGroupProperties>) => [
        blockGroup.id,
        blockGroup.properties.total_locations,
      ])
    );
  }, [marketBlockGroups.data]);

  // Return [true, <errorMessage>] in the event of an error, else [false, '']
  const validateAndErrMessage = (blockGroups: Set<string>): [boolean, string] => {
    if (blockGroups.size <= 0) {
      return [true, 'Please select more regions.'];
    }
    if (blockGroups.size > 20) {
      return [true, 'Please select fewer regions.'];
    }

    const totalLocationsWithinStudyArea = Array.from(blockGroups).reduce(
      (numLocations, blockGroup) => numLocations + (blockGroupToTotalLocationMap[blockGroup] || 0),
      0
    );

    if (totalLocationsWithinStudyArea < 5) {
      return [true, 'Please select more regions.'];
    }
    if (!studyAreaName) {
      return [true, 'Please name your study area.'];
    }

    return [false, ''];
  };

  useEffect(() => {
    if (currentMutateStudyArea) {
      setStudyAreaName(currentMutateStudyArea.name);
      setShareWithEnterprise(currentMutateStudyArea.shared_w_enterprise);
      dispatchSelection({ type: 'reset', selectedMarket: currentMutateStudyArea.market });
      currentMutateStudyArea.block_groups!.map((bgid) =>
        dispatchSelection({
          type: 'add',
          blockGroup: bgid,
        })
      );
    }
  }, [currentMutateStudyArea]);

  return (
    <Modal
      onClose={() => {
        dispatchSelection({
          type: 'reset',
          selectedMarket: selectionState.selectedMarket,
        });
        setCurrentMutateStudyArea(undefined);
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={isOpen}
      dimmer="blurring"
      closeOnDimmerClick={false}
      closeOnEscape={false}
      style={{ width: '75vw', minWidth: '800px' }}
    >
      <Modal.Header>
        <Header as="h1">
          {currentMutateStudyArea ? `Edit ${currentMutateStudyArea?.name}` : `Create Custom Study Area`}
        </Header>
        <Form.Radio
          label="Share with Enterprise"
          checked={shareWithEnterprise}
          onChange={() => setShareWithEnterprise(!shareWithEnterprise)}
          slider
        />
      </Modal.Header>
      <Modal.Content>
        <Grid style={{ height: '60vh' }}>
          <Grid.Column width={5}>
            <Modal.Description>
              {newUi ? (
                <p>
                  Select block groups to create a custom study area. This study area will be available under{' '}
                  <em>Study Areas</em>.
                </p>
              ) : (
                <p>
                  Select block groups to create a custom study area. This study area will be available in{' '}
                  <em>Explore</em> and under <em>Study Areas Created by Me</em>.
                </p>
              )}
              <Divider />
              <Form>
                <Form.Input
                  label="Name"
                  value={studyAreaName}
                  onChange={(e) => {
                    setStudyAreaName(e.target.value);
                  }}
                />
              </Form>
              <Divider hidden />
              <strong>Selected Block Groups</strong>
              <div style={{ overflowY: 'scroll' }}>
                <List>
                  {Array.from((selectionState as MarketBlockGroupSelectionState).blockGroups.values()).map(
                    (blockGroup) => (
                      <List.Item
                        key={blockGroup}
                        content={
                          <BorneFlexContainer className="tw-justify-between tw-pr-4">
                            {blockGroup}
                            <BorneCloseIcon
                              className="tw-cursor-pointer"
                              onClick={() => {
                                dispatchSelection({
                                  type: 'remove',
                                  blockGroup: blockGroup,
                                });
                              }}
                            />
                          </BorneFlexContainer>
                        }
                      />
                    )
                  )}
                </List>
              </div>
            </Modal.Description>
          </Grid.Column>
          <Grid.Column width={11}>
            <Grid.Row style={{ height: '100%' }}>
              <MarketBlockGroupSelectMap
                auth={auth}
                selectionState={selectionState}
                dispatchSelection={dispatchSelection}
                initialMarket={currentMutateStudyArea?.market || currentMarket}
              />
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="times"
          color="black"
          onClick={() => {
            setOpen(false);
            setCurrentMutateStudyArea(undefined);
          }}
          content="Cancel"
        />
        <Popup
          popper={<div style={{ filter: 'none' }}></div>}
          content={validateAndErrMessage(selectionState.blockGroups)[1]}
          open={validateAndErrMessage(selectionState.blockGroups)[0]}
          style={{ zIndex: 10001 }}
          trigger={
            currentMutateStudyArea ? (
              <Button
                content="Save"
                icon="save"
                loading={editStudyArea.isLoading}
                disabled={validateAndErrMessage(selectionState.blockGroups)[0]}
                onClick={async () => {
                  try {
                    if (selectionState.blockGroups.size > 0) {
                      await editStudyArea.mutate({
                        id: currentMutateStudyArea!.id,
                        name: studyAreaName,
                        market: selectionState.selectedMarket,
                        block_group_ids: Array.from(selectionState.blockGroups.values()),
                        shared_w_enterprise: shareWithEnterprise,
                      });
                    } else {
                      window.alert('Study areas must contain selected block groups.');
                    }
                  } catch (err) {
                    console.log(err);
                    return;
                  }

                  dispatchSelection({
                    type: 'reset',
                    selectedMarket: selectionState.selectedMarket,
                  });
                  setOpen(false);
                  setCurrentMutateStudyArea(undefined);
                }}
                positive
              />
            ) : (
              <Button
                content="Create"
                icon="plus"
                disabled={validateAndErrMessage(selectionState.blockGroups)[0]}
                onClick={async () => {
                  try {
                    if (selectionState.blockGroups.size > 0) {
                      await addStudyArea.mutateAsync({
                        name: studyAreaName,
                        market: selectionState.selectedMarket,
                        block_group_ids: Array.from(selectionState.blockGroups.values()),
                        shared_w_enterprise: shareWithEnterprise,
                      });
                    } else {
                      window.alert('Study area must contain at least one selected ');
                    }
                  } catch (err) {
                    console.log(err);
                    return;
                  }

                  // TODO: tni: Make this happen always when the window closes instead
                  // of repeating this dispatch everywhere we set open to false.
                  dispatchSelection({
                    type: 'reset',
                    selectedMarket: selectionState.selectedMarket,
                  });
                  setOpen(false);
                  setCurrentMutateStudyArea(undefined);
                }}
                loading={addStudyArea.isLoading}
                positive
              />
            )
          }
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CreateEditCustomStudyAreaModal;
