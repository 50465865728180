import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Divider, Form, Form as SemanticForm } from 'semantic-ui-react';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import AddressQuestion from './questions/AddressQuestion';
import CuisineQuestion from './questions/CuisineQuestion';
import CategoriesQuestion from './questions/CategoriesQuestion';
import ThreeWordsQuestion from './questions/ThreeWordsQuestion';
import RestaurantStylesQuestion from './questions/RestaurantStylesQuestion';
import HoursOfOperationQuestion from './questions/HoursOfOperationQuestion';
import CommunityTypeQuestion from './questions/CommunityTypeQuestion';
import {
  affiliations,
  alcohols,
  communityTypes,
  prices,
  restaurantTypes,
  serviceQualities,
  serviceTypes,
} from '../data/categories';
import OfficeParkTourismQuestion from './questions/OfficeParkTourismQuestion';
import RestaurantTypeQuestion from './questions/RestaurantTypeQuestion';
import ServiceTypeQuestion from './questions/ServiceTypeQuestion';
import ServiceQualityQuestion from './questions/ServiceQualityQuestion';
import RestaurantSizeQuestion from './questions/RestaurantSizeQuestion';
import PrivateDiningQuestion from './questions/PrivateDiningQuestion';
import ThirdPartyDeliveryQuestion from './questions/ThirdPartyDeliveryQuestion';
import PriceQuestion from './questions/PriceQuestion';
import AlcoholQuestion from './questions/AlcoholQuestion';
import ReservationQuestion from './questions/ReservationQuestion';
import ParkingLotQuestion from './questions/ParkingLotQuestion';
import ContractQuestion from './questions/ContractQuestion';
import AffiliationsQuestion from './questions/AffiliationsQuestion';
import ScaleQuestion from './questions/ScaleQuestion';
import NameQuestion from './questions/NameQuestion';
import ProjectQuestion from './questions/ProjectQuestion';
import { Concept } from './concept/concept';

const ConceptForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { concept: initialConceptValues }: { concept: Concept } = state || {
    concept: {
      project: '',
      name: '',
      address: '',
      cuisine: '',
      categories: [],
      trending_words: '',
      styles: [],
      hours_of_operation: null,
      community_type: '',
      is_near_office_park_or_tourism: null,
      restaurant_type: '',
      service_type: '',
      service_quality: '',
      size: null,
      has_private_dining: null,
      has_third_party_delivery: null,
      price_level: '',
      alcohol_service: '',
      accepts_reservations: null,
      has_parking_lot: null,
      has_management_contract: null,
      affiliations: '',
      intent_to_scale: null,
    },
  };

  // Hook into location changes and when page change happens, scroll to top of page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Container text>
      <Formik
        initialValues={initialConceptValues}
        validationSchema={Yup.object({
          project: Yup.string(),
          name: Yup.string(),
          address: Yup.string().required('Please enter the address!'),
          cuisine: Yup.string().required('Please enter the cuisine!'),
          categories: Yup.array().max(10, 'Please enter fewer categories').required('Missing value'),
          trending_words: Yup.string()
            .max(60, 'You entered a lot of characters... Please enter fewer.')
            .required('Missing value'),
          styles: Yup.array().required('Missing value'),
          hours_of_operation: Yup.object().required('Missing value'),
          // TODO: When https://github.com/jquense/yup/issues/1298 is released,
          // replace [...communityTypes] with communityTypes.
          community_type: Yup.string()
            .oneOf([...communityTypes])
            .required('Missing value'),
          is_near_office_park_or_tourism: Yup.boolean().required('Missing value'),
          // TODO: When https://github.com/jquense/yup/issues/1298 is released,
          // replace [...restaurantTypes] with restaurantTypes.
          restaurant_type: Yup.string()
            .oneOf([...restaurantTypes])
            .required('Missing value'),
          // TODO: When https://github.com/jquense/yup/issues/1298 is released,
          // replace [...serviceTypes] with serviceTypes.
          service_type: Yup.string()
            .oneOf([...serviceTypes])
            .required('Missing value'),
          // TODO: When https://github.com/jquense/yup/issues/1298 is released,
          // replace [...serviceQualities] with serviceQualities.
          service_quality: Yup.string()
            .oneOf([...serviceQualities])
            .required('Missing value'),
          size: Yup.object().required('Missing value'),
          has_private_dining: Yup.boolean().required('Missing value'),
          has_third_party_delivery: Yup.boolean().required('Missing value'),
          // TODO: When https://github.com/jquense/yup/issues/1298 is released,
          // replace [...prices] with prices.
          price_level: Yup.string()
            .oneOf([...prices])
            .required('Missing value'),
          // TODO: When https://github.com/jquense/yup/issues/1298 is released,
          // replace [...alcohols] with alcohols.
          alcohol_service: Yup.string()
            .oneOf([...alcohols])
            .required('Missing value'),
          accepts_reservations: Yup.boolean().required('Missing value'),
          has_parking_lot: Yup.boolean().required('Missing value'),
          has_management_contract: Yup.boolean().required('Missing value'),
          // TODO: When https://github.com/jquense/yup/issues/1298 is released,
          // replace [...affiliations] with affiliations.
          affiliations: Yup.string()
            .oneOf([...affiliations])
            .required('Missing value'),
          intent_to_scale: Yup.boolean().required('Missing value'),
        })}
        onSubmit={(concept) => {
          navigate('/results', { state: concept });
        }}
      >
        {(formikBag) => (
          <SemanticForm as={FormikForm} onSubmit={formikBag.handleSubmit}>
            <br />
            <Form.Group inline widths="equal">
              <ProjectQuestion loc="project" />
              <NameQuestion loc="name" />
            </Form.Group>
            <AddressQuestion loc="address" />
            <CuisineQuestion loc="cuisine" useYelp={true} />
            <CategoriesQuestion
              loc="categories"
              label="Please choose any other categories that match your restaurant:"
            />
            <ThreeWordsQuestion loc="trending_words" />
            <RestaurantStylesQuestion loc="styles" formikBag={formikBag} />
            <HoursOfOperationQuestion loc="hours_of_operation" formikBag={formikBag} />
            <CommunityTypeQuestion loc="community_type" />
            <OfficeParkTourismQuestion loc="is_near_office_park_or_tourism" />
            <RestaurantTypeQuestion loc="restaurant_type" />
            <ServiceTypeQuestion loc="service_type" />
            <ServiceQualityQuestion loc="service_quality" />
            <RestaurantSizeQuestion loc="size" formikBag={formikBag} />
            <PrivateDiningQuestion loc="has_private_dining" />
            <ThirdPartyDeliveryQuestion loc="has_third_party_delivery" />
            <PriceQuestion loc="price_level" />
            <AlcoholQuestion loc="alcohol_service" />
            <ReservationQuestion loc="accepts_reservations" />
            <ParkingLotQuestion loc="has_parking_lot" />
            <ContractQuestion loc="has_management_contract" />
            <AffiliationsQuestion loc="affiliations" />
            <ScaleQuestion loc="intent_to_scale" />
            <Divider section />
            <Button type="submit" floated="right" color="grey">
              Compute
            </Button>
            <br />
            <br />
            <br />
          </SemanticForm>
        )}
      </Formik>
    </Container>
  );
};

export default ConceptForm;
