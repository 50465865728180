import React, { Component } from 'react';
import { Header as BaseHeader, Segment } from 'semantic-ui-react';
import { AverageRestaurantRating } from '../../types';

function round(avgRating: number): number {
  return avgRating ? +avgRating.toFixed(2) : avgRating;
}

interface Props {
  averageGoogleRatingDict: AverageRestaurantRating;
  averageYelpRatingDict: AverageRestaurantRating;
}

class AverageRestaurantRatingSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Segment>
        <BaseHeader as="h3">Average Restaurant Rating</BaseHeader>
        <p>
          The mean Yelp rating in the surrounding area is <b>{round(this.props.averageYelpRatingDict['mean'])}</b>.
        </p>
        <p>
          The mean Google rating in the surrounding area is <b>{round(this.props.averageGoogleRatingDict['mean'])}</b>.
        </p>
      </Segment>
    );
  }
}

export default AverageRestaurantRatingSegment;
