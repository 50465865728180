import { Icon } from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import React, { Component, Fragment } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Header as BaseHeader, Segment } from 'semantic-ui-react';
import { RestaurantNearby } from '../../types';

interface Props {
  conceptLatLng: [number, number];
  restaurants: RestaurantNearby[];
}

// The default bundler configuration will not work with the way leaflet loads
// its images, so doing this avoids seeing broken image icons.
const markerIcon = new Icon({ iconUrl: markerIconPng });

class NearbyAllRestaurantsSegment extends Component<Props> {
  render(): JSX.Element {
    return (
      <Fragment>
        <Segment>
          <BaseHeader as="h3">Nearby Restaurants</BaseHeader>
          <p>All the restaurants nearby to be used in gap analysis.</p>
          <MapContainer center={this.props.conceptLatLng} zoom={15} style={{ height: 320 }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {this.props.restaurants.map((restaurant, id) => (
              <Marker key={id} position={[restaurant.latitude, restaurant.longitude]} icon={markerIcon}>
                <Popup key={id}>
                  <h3 id="firstHeading" className="firstHeading">
                    {restaurant.name}
                  </h3>
                  <div id="bodyContent">
                    <p>
                      <ul>
                        <li>Distance from Concept: {restaurant.distanceMeters.toFixed(2)} meters</li>
                      </ul>
                    </p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </Segment>
      </Fragment>
    );
  }
}

export default NearbyAllRestaurantsSegment;
