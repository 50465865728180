import debounce from 'debounce';
import { divIcon } from 'leaflet';
import React, { useState } from 'react';
import { Tooltip, Pane, Marker, useMapEvent } from 'react-leaflet';
import { useMarketDriveTimes } from '../../../api/requests';
import Auth from '../../../auth/Auth';
import { Location } from '../../../api/models';
import { BrandStateStuff } from '../../redux/testSlice';

interface Props {
  auth: Auth;
  market: string;
  locations: Location[];
  selectedBrands: Array<BrandStateStuff>;
}

const BrandsPaneV2: React.FC<Props> = ({ auth, market, locations, selectedBrands }: Props) => {
  const [hoveredLocation, setHoveredLocation] = useState<any>();

  const marketDriveTimesRes = useMarketDriveTimes(auth, market, hoveredLocation?.chainId);

  const getDriveTime = (to: string) => {
    const fromIndex = marketDriveTimesRes.data.locations.indexOf(hoveredLocation.locationId);
    const toIndex = marketDriveTimesRes.data.locations.indexOf(to);
    const driveTimeSecs = marketDriveTimesRes.data.times[fromIndex][toIndex].time;
    return `${Math.ceil(driveTimeSecs / 60)} min`;
  };

  useMapEvent(
    'mousemove',
    debounce(() => {
      const brandIcon = document.querySelector('.brand-icon:hover');

      if (brandIcon instanceof HTMLElement) {
        setHoveredLocation({
          chainId: brandIcon.dataset.chainId,
          locationId: brandIcon.dataset.locationId,
        });
      } else {
        setHoveredLocation(undefined);
      }
    }, 200)
  );

  return (
    <Pane name="BrandsPaneV2" style={{ zIndex: 405 }}>
      {locations
        .filter((location: any) => !!location.chain && selectedBrands.some((o) => o.name == location.chain.name))
        .map(({ coords, name, chain, fsq_id }) => (
          <Marker
            position={coords}
            icon={divIcon({
              html: `
            <div>
            <img style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; background-color: white;" 
            src="/static/icons/${chain!.id}.png"
            aria-hidden="true"
            class="ui circular image brand-icon"
            data-chain-id="${chain!.id}" data-location-id="${fsq_id}" />
            ${
              marketDriveTimesRes.data &&
              hoveredLocation?.chainId === chain!.id &&
              hoveredLocation?.locationId !== fsq_id
                ? `<div style="width: 50px"><b>${getDriveTime(fsq_id)}</b></div>`
                : ''
            }
            </div>`,
              className: '',
              iconSize: [32, 32],
            })}
          >
            <Tooltip pane="tooltipPane">{name}</Tooltip>
          </Marker>
        ))}
    </Pane>
  );
};

export default BrandsPaneV2;
