import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Auth from '../auth/Auth';
import ConceptForm from './ConceptForm';
import Report from './report/Report';
import EnsureReportRequestState from './report/EnsureReportRequestState';
import ReportLocationForm from './report/LocationForm';
import ReportConceptForm from './report/ConceptForm';
import ReportContactForm from './report/ContactForm';
import ReportPurchaseForm from './report/PurchaseForm';
import ReportPurchaseComplete from './report/PurchaseComplete';
import Results from './Results';
import Authenticated from '../auth/Authenticated';
import Users from '../api/Users';
import LandingPage from './pages/login';
import styled from '@emotion/styled';
import Logout from './generic/Logout';
import ConceptDetail from './pages/ConceptDetail';
import Markets from './pages2/Markets';
import MarketComparisonPage from './pages/ScaleReport/MarketComparisonPage';
import MarketDetailPage from './pages/ScaleReport/MarketDetailPage';
import IndividualReportPage from './pages/IndividualReport/IndividualReportPage';
import Settings from './pages/Settings';
import Concepts from './pages/Concepts';
import SiteReport from './pages/SiteReport';
import { Sites } from './pages/Sites';
import MarketDetail from './pages/MarketDetail';
import { AppContext } from './AppContext';
import Dashboard from './pages2/Dashboard';
// import Help from './pages2/Help';
import StudyAreas from './pages2/StudyAreas';
import { StudyAreasDetails } from './pages2/StudyAreasDetailPage';
import { StudyAreasComparison } from './pages2/StudyAreasComparison';
import SiteReports from './pages2/SiteReports';
import SingleSiteReport from './pages2/SingleSiteReport';
import LocationDetail from './pages/LocationDetail/LocationDetail';

import type { User } from '../api/models';
import { Profile } from './pages2/Profile';
import BorneReportRequestPage from './pages2/BorneReport/BorneReportPage';
import { MantineProvider } from '@mantine/core';
import BorneReportStatus from './pages2/BorneReport/BorneReportStatus';
import MyReports from './pages2/BorneReport/UserReportInfo';

const queryClient = new QueryClient();

const BigContainer = styled('div')`
  height: 100%;
  min-height: 100vh;
  margin: 0 0 0 0;
`;

const stripe = loadStripe(
  window.location.hostname === 'app.bornellc.com'
    ? 'pk_live_51J085JGM57AlXR2oExHeXrbAKS3XiYqt6ZOgrs5o0eyOQNhUukl8b7NmSplsH5e69g9OSWBsWYr684jyEt6yfbnw00NjqzxrFh'
    : 'pk_test_51J085JGM57AlXR2oqz0yYKwsiBfYxMs0ndXyJa9qXXwvUirxB7bq16cFHrf4Dc3h4K0jrwh8fHUPpNOimLBqlMRL00GAEtDeWx'
);

const App = () => {
  const auth = new Auth();
  const [user, setUser] = useState<User>();
  const [explorePageCurrentMarket, setExplorePageCurrentMarket] = useState<number>();

  const rerender = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (auth.hasCredentials()) {
      const userAPI = new Users(auth);
      userAPI.getCurrentUser().then(setUser);
    }
  }, []);

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={{ auth, user, stripe }}>
          <Router>
            <BigContainer>
              {user && user.enterprise_id == 31 ? (
                <Routes>
                  <Route path="/logout" element={<Logout auth={auth} rerender={rerender} />} />
                  <Route
                    path="/*"
                    element={<LocationDetail auth={auth} ignoreParams entLocId="164" studyAreaId="17002" />}
                  />
                </Routes>
              ) : (
                <Routes>
                  <Route
                    path="/"
                    element={
                      auth.hasCredentials() ? (
                        user && (
                          <div className="new-ui">
                            {user?.settings.BORNE_REPORT_SUBSCRIBER ? (
                              <BorneReportRequestPage auth={auth} />
                            ) : (
                              <Dashboard
                                auth={auth}
                                currentMarket={explorePageCurrentMarket}
                                setCurrentMarket={(marketIndex: number) => setExplorePageCurrentMarket(marketIndex)}
                              />
                            )}
                          </div>
                        )
                      ) : (
                        <LandingPage auth={auth} rerender={rerender} />
                      )
                    }
                  />
                  {!user?.settings.BORNE_REPORT_SUBSCRIBER ? (
                    <>
                      <Route path="/report/location" element={<ReportLocationForm />} />
                      <Route
                        path="/report/concept"
                        element={
                          <EnsureReportRequestState>
                            <ReportConceptForm />
                          </EnsureReportRequestState>
                        }
                      />
                      <Route
                        path="/report/contact"
                        element={
                          <EnsureReportRequestState>
                            <ReportContactForm />
                          </EnsureReportRequestState>
                        }
                      />
                      <Route
                        path="/report/purchase"
                        element={
                          <EnsureReportRequestState>
                            <ReportPurchaseForm />
                          </EnsureReportRequestState>
                        }
                      />
                      <Route path="/report/complete/:orderNumber" element={<ReportPurchaseComplete />} />
                      <Route path="/report/:id" element={<Report />} />
                      <Route
                        path="*"
                        element={
                          <Authenticated auth={auth}>
                            <Routes>
                              {user && (
                                <>
                                  <Route
                                    path="/dashboard"
                                    element={
                                      <div className="new-ui">
                                        <Dashboard
                                          auth={auth}
                                          currentMarket={explorePageCurrentMarket}
                                          setCurrentMarket={(marketIndex: number) =>
                                            setExplorePageCurrentMarket(marketIndex)
                                          }
                                        />
                                      </div>
                                    }
                                  />
                                  <Route
                                    path="/locationdetail/:id/:studyareaid"
                                    element={<LocationDetail auth={auth} />}
                                  />
                                  <Route
                                    path="/studyareas"
                                    element={
                                      <div className="new-ui">
                                        <StudyAreas
                                          auth={auth}
                                          currentMarket={explorePageCurrentMarket}
                                          setCurrentMarket={(marketIndex: number) =>
                                            setExplorePageCurrentMarket(marketIndex)
                                          }
                                        />
                                      </div>
                                    }
                                  />
                                  <Route
                                    path="/studyareascomparison"
                                    element={
                                      <div className="new-ui">
                                        <StudyAreasComparison
                                          auth={auth}
                                          currentMarket={explorePageCurrentMarket}
                                          setCurrentMarket={(marketIndex: number) =>
                                            setExplorePageCurrentMarket(marketIndex)
                                          }
                                        />
                                      </div>
                                    }
                                  />
                                  <Route path="/sitereport/:id" element={<SiteReport auth={auth} user={user} />} />
                                  <Route path="/concepts" element={<Concepts auth={auth} />} />
                                  <Route
                                    path="/markets"
                                    element={
                                      <div className="new-ui">
                                        <Markets
                                          auth={auth}
                                          currentMarket={explorePageCurrentMarket}
                                          setCurrentMarket={(marketIndex: number) =>
                                            setExplorePageCurrentMarket(marketIndex)
                                          }
                                        />
                                      </div>
                                    }
                                  />
                                  <Route path="/concept/:id" element={<ConceptDetail auth={auth} />} />
                                  <Route path="/users" />
                                  <Route path="/settings" element={<Settings auth={auth} />} />
                                  <Route path="/sites" element={<Sites auth={auth} />} />
                                  {/*
                      <Route path="/marketreport/comparison/:reportId" element={<MarketComparisonPage auth={auth} />} />
                      <Route path="/marketreport/detail/:reportId/:index" element={<MarketDetailPage auth={auth} />} />
                      */}
                                  <Route
                                    path="/studyareas/:id"
                                    element={
                                      <div className="new-ui">
                                        <StudyAreasDetails
                                          currentMarket={explorePageCurrentMarket}
                                          auth={auth}
                                          user={user}
                                        />
                                      </div>
                                    }
                                  />
                                  <Route
                                    path="/sitereports"
                                    element={
                                      <div className="new-ui">
                                        <SiteReports auth={auth} />
                                      </div>
                                    }
                                  />
                                  <Route
                                    path="/sitereports/:id"
                                    element={
                                      <div className="new-ui">
                                        <SingleSiteReport auth={auth} user={user} />
                                      </div>
                                    }
                                  />
                                  <Route path="/markets/:id" element={<MarketDetail auth={auth} />} />
                                  <Route path="/scaleReport/:reportId" element={<MarketComparisonPage auth={auth} />} />
                                  <Route
                                    path="/scaleReport/:reportId/:index"
                                    element={<MarketDetailPage auth={auth} />}
                                  />
                                  <Route path="/logout" element={<Logout auth={auth} rerender={rerender} />} />
                                  {/* Legacy concept create page */}
                                  <Route path="/concept" element={<ConceptForm />} />
                                  <Route path="/individualreportpage" element={<IndividualReportPage auth={auth} />} />
                                  <Route path="/results" element={<Results auth={auth} />} />
                                  {user.enterprise_id === 1 && (
                                    <>
                                      <Route path="/borneReport" element={<BorneReportRequestPage auth={auth} />} />
                                      <Route
                                        path="/profile"
                                        element={
                                          <div className="new-ui">
                                            <Profile auth={auth} user={user} />
                                          </div>
                                        }
                                      />
                                    </>
                                  )}
                                </>
                              )}
                            </Routes>
                          </Authenticated>
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Route path="/*" element={<BorneReportRequestPage auth={auth} />} />
                      <Route path="/reportstatus/:id" element={<BorneReportStatus auth={auth} />} />
                      <Route path="/reports" element={<MyReports auth={auth} />} />
                    </>
                  )}
                </Routes>
              )}
            </BigContainer>
          </Router>
        </AppContext.Provider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </MantineProvider>
  );
};

export default App;
