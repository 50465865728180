import Auth from 'borne_ui/src/auth/Auth';
import React, { useMemo, useState } from 'react';
import { TileLayer, CircleMarker, Tooltip } from 'react-leaflet';
import GeoJSON from '../generic/GeoJSON';
import { Checkbox, Loader } from 'semantic-ui-react';
import { useMarketGeo, useMarketLocations } from '../../api/requests';
import withMapContext from './WithMapContext';

interface Props {
  auth: Auth;
  marketId: string;
  status: 'open' | 'closed';
  color?: string;
}

const MarketSingleLocationMapComponent: React.FC<Props> = ({ auth, marketId, status, color = '#0c7c59' }: Props) => {
  const [closedOnlyRecent, setClosedOnlyRecent] = useState<boolean>(false);

  const currentDate = new Date();

  const marketGeoRes = useMarketGeo(auth, marketId, {
    refetchOnWindowFocus: false,
  });

  const locations = useMarketLocations(auth, marketId, status, {
    refetchOnWindowFocus: false,
  });

  const locationsLayer = useMemo(
    () => (
      <>
        {locations.data?.map(({ coords, name, date_closed, address }, idx) => {
          return (
            <>
              {(status === 'open' ||
                !closedOnlyRecent ||
                (currentDate.getTime() - new Date(date_closed!).getTime() < 60 * 24 * 60 * 60 * 1000 &&
                  !!date_closed)) && (
                <CircleMarker key={idx} center={coords} stroke={false} fillOpacity={1} fillColor={color} radius={3}>
                  <Tooltip>
                    {name}
                    <br />
                    {address}
                    <br />
                    {status === 'closed' && date_closed ? `Closed since ${date_closed}` : 'Close date unknown'}
                  </Tooltip>
                </CircleMarker>
              )}
            </>
          );
        })}
      </>
    ),
    [locations.data, closedOnlyRecent]
  );

  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
    ),
    []
  );

  return (
    <>
      {marketGeoRes.isSuccess && !marketGeoRes.isFetching && locations.isSuccess ? (
        <>
          {Tiles}
          <GeoJSON
            data={marketGeoRes.data}
            style={(_) => ({
              color: 'rgba(0,0,0,.4)',
              fillColor: 'rgba(0,0,0,0.01)',
            })}
          />
          {locationsLayer}
          {status === 'closed' && (
            <>
              <div
                className="leaflet-top leaflet-right leaflet-control"
                style={{
                  backgroundColor: '#FFF',
                  marginTop: 10,
                  marginRight: 10,
                  borderStyle: 'solid',
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  borderColor: 'rgba(0,0,0,.2)',
                  borderWidth: 2,
                }}
              >
                <Checkbox
                  slider
                  className="leaflet-control"
                  label="Recently closed"
                  style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }}
                  checked={closedOnlyRecent}
                  onChange={(e, _) => {
                    e.stopPropagation();
                    setClosedOnlyRecent(!closedOnlyRecent);
                  }}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export const MarketSingleLocationMap = withMapContext(MarketSingleLocationMapComponent, { height: '60vh' });
