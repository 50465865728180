import React from 'react';
import BaseChart from './BaseChart';
import { curveCardinal, curveLinear } from '@visx/curve';
import { AnimatedAreaSeries, AnimatedLineSeries } from '@visx/xychart';
import { ChartProps, Row } from './Common';

interface LineChartProps extends ChartProps {
  x: string;
  y: string | Array<string>;
  isArea?: boolean;
  isCurve?: boolean;
  margin?: any;
  xLabelFormatter?: (value: any) => any;
  yLabelFormatter?: (value: any) => any;
}

const LineChart: React.FC<LineChartProps> = (props: LineChartProps) => {
  const {
    data,
    dataKey,
    height,
    width,
    grid,
    orientation = 'h',
    xScaleOptions = { type: 'linear' },
    yScaleOptions = { type: 'linear' },
    tooltip,
    tooltipRenderer,
    isArea = false,
    isCurve = false,
    margin,
    axes,
    xLabelFormatter,
    yLabelFormatter,
  } = props;
  const { x, y } = props;

  const isHorizontal = orientation === 'h';
  const independentAccessor = (d: Row) => d[x];
  const singleSeriesDependentAccessor = (d: Row) => d[y as string];

  const isSeriesData = y instanceof Array;

  const seriesArray = isSeriesData
    ? (y as Array<string>).map((key, idx) => {
        const seriesDependentAccessor = (d: Row) => d[key];
        return isArea ? (
          <AnimatedAreaSeries
            data={data}
            key={`${dataKey}-series${idx}`}
            dataKey={key}
            xAccessor={isHorizontal ? independentAccessor : seriesDependentAccessor}
            yAccessor={isHorizontal ? seriesDependentAccessor : independentAccessor}
            curve={isCurve ? curveCardinal : curveLinear}
          />
        ) : (
          <AnimatedLineSeries
            data={data}
            key={`${dataKey}-series${idx}`}
            dataKey={key}
            xAccessor={isHorizontal ? independentAccessor : seriesDependentAccessor}
            yAccessor={isHorizontal ? seriesDependentAccessor : independentAccessor}
            curve={isCurve ? curveCardinal : curveLinear}
          />
        );
      })
    : [
        isArea ? (
          <AnimatedAreaSeries
            data={data}
            key={`${dataKey}-series`}
            dataKey={y as string}
            xAccessor={isHorizontal ? independentAccessor : singleSeriesDependentAccessor}
            yAccessor={isHorizontal ? singleSeriesDependentAccessor : independentAccessor}
            curve={isCurve ? curveCardinal : curveLinear}
          />
        ) : (
          <AnimatedLineSeries
            data={data}
            key={`${dataKey}-series`}
            dataKey={y as string}
            xAccessor={isHorizontal ? independentAccessor : singleSeriesDependentAccessor}
            yAccessor={isHorizontal ? singleSeriesDependentAccessor : independentAccessor}
            curve={isCurve ? curveCardinal : curveLinear}
          />
        ),
      ];

  return (
    <BaseChart
      data={data}
      dataKey={dataKey}
      grid={grid ?? 'full'}
      xScaleOptions={xScaleOptions}
      yScaleOptions={yScaleOptions}
      height={height}
      width={width}
      orientation={orientation}
      seriesArray={seriesArray}
      axes={axes || (isHorizontal ? undefined : ['top', 'left'])}
      tooltip={tooltip}
      tooltipCrosshair={isHorizontal ? 'v' : 'h'}
      tooltipRenderer={tooltipRenderer}
      margin={margin}
      xLabelFormatter={xLabelFormatter}
      yLabelFormatter={yLabelFormatter}
    />
  );
};

export default LineChart;
