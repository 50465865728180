import BarChart from '@bornellc/bach/BarChart';
import React from 'react';
import { Header, Grid, Form, List, Icon, Segment } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { strSnakeToProperCase } from '../../../../helpers';

interface DescriptionProps {
  brandNames: Array<string>;
  selectedBrands: Set<string>;
  dispatchBrandSelection: React.Dispatch<{
    action: string;
    brandId: string;
  }>;
  brandId: string;
}

const ChartColumn = styled(Grid.Column)`
  height: 35vh;
`;

export const BrandImpactDescriptionSegment: React.FC<DescriptionProps> = ({
  brandNames,
  selectedBrands,
  dispatchBrandSelection,
  brandId,
}) => {
  return (
    <>
      <Header as="h1">Brand Impact</Header>
      <Header as="p" color="grey" size="tiny">
        Select up to four brands to compare
      </Header>
      <Form>
        <Form.Dropdown
          placeholder="Area"
          fluid
          search
          selection
          options={brandNames
            .filter((name) => name != brandId)
            .map((brand: string) => ({
              text: brand,
              value: brand,
            }))}
          onChange={(_, data) => {
            dispatchBrandSelection({ action: 'add', brandId: data.value as string });
          }}
        />
      </Form>
      <Header as="h4">Selected brands:</Header>
      <List selection verticalAlign="middle">
        {selectedBrands.size ? (
          Array.from(selectedBrands).map((brand) => (
            <List.Item key={brand} onClick={() => dispatchBrandSelection({ action: 'remove', brandId: brand })}>
              <Icon name="close" />
              <List.Content>
                <List.Description>{brand}</List.Description>
              </List.Content>
            </List.Item>
          ))
        ) : (
          <List.Item disabled>
            <List.Content>
              <List.Header style={{ color: '#a0a0a0' }}>No brands selected</List.Header>
            </List.Content>
          </List.Item>
        )}
      </List>
    </>
  );
};

interface DataProps {
  selectedBrands: Set<string>;
  report: any;
  brandId: string;
}

export const BrandImpactDataSegment: React.FC<DataProps> = ({ selectedBrands, report, brandId }) => {
  const brands = report['brands'].filter(
    (record: any) => selectedBrands.has(record.brand_name) || record.brand_name == brandId
  );

  console.log(brands);

  return (
    <>
      {selectedBrands ? (
        <Grid padded="vertically" attached="top" columns="equal" textAlign="center">
          <Grid.Row>
            <ChartColumn>
              <Header style={{ marginBottom: '-2em' }} color="grey" as="h3">
                Locations in Market
              </Header>

              <BarChart
                orientation="v"
                x="brand_name"
                y="num_locations"
                tooltip
                tooltipRenderer={(keyStr, value, color, _) => (
                  <span style={{ color: color, fontWeight: 300, fontSize: '12pt' }}>
                    <strong>{strSnakeToProperCase(keyStr)}:</strong>
                    {value}
                  </span>
                )}
                data={brands}
                dataKey="brands_num_locations"
              />
            </ChartColumn>
            <ChartColumn>
              <Header style={{ marginBottom: '-2em' }} color="grey" as="h3">
                10 Minute Coverage (% Population)
              </Header>

              <BarChart
                orientation="v"
                x="brand_name"
                y="pct_population"
                tooltip
                tooltipRenderer={(keyStr, value, color, _) => (
                  <span style={{ color: color, fontWeight: 300, fontSize: '12pt' }}>
                    <strong>{strSnakeToProperCase(keyStr)}:</strong>
                    {(value as number).toFixed(2)}
                  </span>
                )}
                data={brands}
                dataKey="brands_pct_population"
              />
            </ChartColumn>
          </Grid.Row>
          <Grid.Row>
            <ChartColumn width={8}>
              <Header style={{ marginBottom: '-2em' }} color="grey" as="h3">
                10 Minute Coverage (Total Residents)
              </Header>
              <BarChart
                orientation="v"
                x="brand_name"
                y="population"
                tooltip
                tooltipRenderer={(keyStr, value, color, _) => (
                  <span style={{ color: color, fontWeight: 300, fontSize: '12pt' }}>
                    <strong>{strSnakeToProperCase(keyStr)}:</strong>
                    {Math.round(value as number)}
                  </span>
                )}
                data={brands}
                dataKey="brands_population"
              />
            </ChartColumn>
          </Grid.Row>
        </Grid>
      ) : (
        <Segment placeholder />
      )}
    </>
  );
};
