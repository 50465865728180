import React, { useMemo } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';

interface AccessMapProps {
  geojson: any;
  width?: number;
  height?: number;
  center?: [number, number];
  zoom?: number;
}

const AccessMap: React.FC<AccessMapProps> = ({
  geojson,
  width = 800,
  height = 500,
  center = [35.2219971, -101.8312969],
  zoom = 11,
}) => {
  const Tiles = useMemo(
    () => (
      <TileLayer
        attribution='&copy; <a href="https://carto.com/basemaps/">Carto</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
    ),
    []
  );

  return (
    <MapContainer center={center} zoom={zoom} style={{ width: width, height: height }}>
      <GeoJSON data={geojson} style={(record) => ({ color: record?.properties['color'] })} />
      {Tiles}
    </MapContainer>
  );
};

export default AccessMap;
