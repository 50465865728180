import AnimatedSample from '@bornellc/bach/AnimatedSample';
import React from 'react';
import { Form, Header, Radio } from 'semantic-ui-react';
import { strSnakeToProperCase } from '../../../../helpers';

import type { Sample, SampleBreakdown } from '@bornellc/bach/Common';

interface DescriptionProps {
  marketName: string;
  populationPerCircle: number;
  keys: Array<string>;
  setCurKey: React.Dispatch<React.SetStateAction<string>>;
  curKey: string;
}

interface DataProps {
  breakdown: SampleBreakdown;
  sample: Sample;
  keys: Array<string>;
  curKey: string;
}

const DemographicDescriptionSegment: React.FC<DescriptionProps> = (props: DescriptionProps) => {
  const { marketName, populationPerCircle, keys, setCurKey, curKey } = props;

  return (
    // Title of segment
    <>
      <Header as="h1">Demographics</Header>
      {/* Description  */}
      <p>
        For the <strong>{marketName}</strong> market, here is the demographic breakdown. Each circle represents{' '}
        <strong>{populationPerCircle}</strong> people.
      </p>

      <Form>
        {keys.map((key) => (
          <Form.Field>
            <Radio
              value={key}
              name={key}
              label={strSnakeToProperCase(key)}
              checked={key == curKey}
              onChange={(_) => setCurKey(key)}
              style={{ textTransform: 'capitalize' }}
            />
          </Form.Field>
        ))}
      </Form>
    </>
    // Depending on segment: menu
  );
};

const DemographicDataSegment: React.FC<DataProps> = (props: DataProps) => {
  const { breakdown, sample, keys, curKey } = props;

  return (
    <AnimatedSample
      keys={keys}
      dataKey="DemographicAnimatedSample"
      currentKey={curKey}
      width="100%"
      height="400px"
      breakdown={breakdown}
      labels
      labelPadding={0.08}
      labelTransform={(seriesKey: string) => strSnakeToProperCase(seriesKey)}
      sample={sample}
    />
  );
};

export { DemographicDataSegment, DemographicDescriptionSegment };
