import AnimatedSample from '@bornellc/bach/AnimatedSample';
import React from 'react';
import { Form, Header, Radio } from 'semantic-ui-react';
import { strSnakeToProperCase } from '../../../../helpers';

import type { Sample, SampleBreakdown } from '@bornellc/bach/Common';

interface DescriptionProps {
  marketName: string;
  totalClosedRestaurants: number;
  keys: Array<string>;
  setCurKey: React.Dispatch<React.SetStateAction<string>>;
  curKey: string;
}

interface DataProps {
  breakdown: SampleBreakdown;
  sample: Sample;
  totalClosedRestaurants: number;
  keys: Array<string>;
  curKey: string;
}

const ClosedRestaurantDescriptionSegment: React.FC<DescriptionProps> = (props: DescriptionProps) => {
  const { marketName, totalClosedRestaurants, keys, setCurKey, curKey } = props;

  return (
    // Title of segment
    <>
      <Header as="h1">Closed Restaurants</Header>
      {/* Description  */}
      <p>
        In <strong>{marketName}</strong>, <strong>{totalClosedRestaurants}</strong> restaurants have closed in the last
        three years. Each circle represents
        {totalClosedRestaurants < 100 ? ' a ' : <strong> {totalClosedRestaurants / 100} </strong>} closed
        {totalClosedRestaurants < 100 ? ' restaurant' : ' restaurants'}.
      </p>

      <Form>
        {keys.map((key) => (
          <Form.Field>
            <Radio
              value={key}
              name={key}
              label={strSnakeToProperCase(key)}
              checked={key == curKey}
              onChange={(_) => setCurKey(key)}
              style={{ textTransform: 'capitalize' }}
            />
          </Form.Field>
        ))}
      </Form>
    </>
    // Depending on segment: menu
  );
};

const ClosedRestaurantDataSegment: React.FC<DataProps> = (props: DataProps) => {
  const { breakdown, sample, totalClosedRestaurants, keys, curKey } = props;

  return (
    <AnimatedSample
      keys={keys}
      dataKey="ClosedRestaurantAnimatedSample"
      currentKey={curKey}
      width="100%"
      height="400px"
      breakdown={breakdown}
      labels
      nCircles={totalClosedRestaurants < 100 ? totalClosedRestaurants : 100}
      labelPadding={0.08}
      labelTransform={(seriesKey: string) => strSnakeToProperCase(seriesKey.replace(`${curKey}_`, ''))}
      sample={sample}
    />
  );
};

export { ClosedRestaurantDataSegment, ClosedRestaurantDescriptionSegment };
